import React from "react";
import OwlCarousel from 'react-owl-carousel3';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import logo from "../images/java.jpg"; // Default image for the first card


const WebinarCards = () => {
    const options = {
        margin: 0,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
        
        
        },
      };
    
      return (
        <div>
          <div
            className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix"
            id="services"
            style={{marginTop:'160px' ,marginLeft:'350px',marginRight:'250px'}} // Add margin-top here
          >
            <div className="containerdc">
              <div className="section-head text-center text-white">
              
                <div className="dlab-separator bg-primary"></div>
              </div>
              <div className="section-content">
                <div className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
                  <OwlCarousel
                    className="owl-theme owl-stage owlCarousel"
                    loop
                    margin={0}
                    nav
                    {...options}
                  >
                    <Link to="/java" style={{ textAlign: "center", alignItems: "center" }}>
                      <div className="card_3" style={{ backgroundImage: `url(${logo})`  }}>
                        <div className="card3_body">
                          <h5 className="card3_title">TOOL AND FIXTURE DESIGN</h5>
                          <p className="desc">
                            Tool and fixture design is creating special helpers for
                            making things. Tools are like special equipment designed
                            to cut, shape, or manipulate materials, while fixtures
                            are devices that hold things in place while you work on
                            them.
                          </p>
                          <Link to="/tool-&-fixture-design" className="read_more">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </Link>
                    
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
          <div className="ad-container">
                    <div className="ad-content">
                        <div className="ad-item" style={{marginRight:'15px'}}>Advertisement 1</div>
                        <div className="ad-item" style={{marginRight:'15px'}}>Advertisement 2</div>
                        <div className="ad-item" style={{marginRight:'15px'}}>Advertisement 3</div>
                        {/* Add more advertisements as needed */}
                    </div>
                </div>
                <style>
                    {`
       
            .ad-container {
      position: fixed;
      right: -75px;
      bottom: 0;
      top: 0;
      width: 320px; /* Adjust width as needed */
      overflow: hidden;
      background-color:'white'; /* Background color for container */
    }
    
    .ad-content {
      display: flex;
      flex-direction: column;
      animation: scrollUp 15s linear infinite;
      position: absolute;
      bottom: 0; /* Start from the bottom */
      width: 80%;
    }
    
    .ad-item {
      padding: 20px;
      color: white;
    background-color: #1367c8;
      margin: 5px 0;
      text-align: center;
    }
    
    /* Keyframes for scrolling up */
    @keyframes scrollUp {
      0% {
        transform: translateY(100%); /* Start from bottom */
      }
      100% {
        transform: translateY(-100%); /* Move to top */
      }
    }
        `}
    
                </style>
        </div>
        
      );
    };
export default WebinarCards;
