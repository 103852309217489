import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";
const Registration  = () => {

     const [isPopupOpen, setIsPopupOpen] = useState(false);
     const [courseduration, setCoursess] = useState([]);
      const [offlinerobotics, setOfflinerobotics] = useState(null);
        const [error, setError] = useState(null);
     const [availableSubFields, setAvailableSubFields] = useState([]);
const [optionss, setOptionss] = useState([]);
     const [formData, setFormData] = useState({
         source: '',
         sourceName: '',
         courses: '',
         duration: '_160_HR',
         timings: '_9AM_6PM',
         mode: '',
         branches: '',
         coursePrice: '10' // Include coursePrice if it's part of the form
       });
    const handlePopUp = () => {

        setIsPopupOpen(true); // Open the popup
      };


      const handleChange1 = (e) => {
        setFormData({ ...formData, mode: e.target.value });
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };



      const handleFieldChange = async (e) => {
        const { name, value } = e.target;
    
        if (name === "duration") {
          // Update the formData with the selected course duration
          setFormData({ ...formData, duration: value });
    
          console.log("Fetching timings for duration:", value);
    
          await fetchTime(value); // Fetch timings based on selected course duration
        } else {
          const selectedField = value;
          let subFields = [];
    
          // Set subFields based on the selected course
          // if (selectedField === "mechanical-engineering") {
          //   subFields = [
          //     { value: "mech1", label: "9AM-6PM" },
          //   ];
          // } else if (selectedField === "technotalks") {
          //   subFields = [
          //     { value: "tech1", label: "9AM-1PM" },
          //     { value: "tech2", label: "2PM-6PM" },
          //   ];
          // }
    
          setAvailableSubFields(subFields);
          setFormData({ ...formData, course: selectedField });
    
          // Reset subfield when main field changes
    
          // Fetch price for the selected course
          try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=${selectedField}`);
            if (!response.ok) {
              throw new Error("Failed to fetch price data");
            }
            const result = await response.json();
            setOfflinerobotics(result);
            setFormData((prev) => ({ ...prev, coursePrice: result })); // Update price in formData
          } catch (error) {
            setError(error);
          }
        }
      };


      const fetchTime = async (time) => {

        try {
          const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/timings?courseDuration=${time}`);
          if (!response.ok) {
            throw new Error("Failed to fetch timings");
          }
          const result = await response.json();
    
          // Map the response to a more usable format
          const subFieldOptions = result.map((item) => ({
            value: item,  // Keep the original value
            label: item.replace(/_/g, ' ').substring(1), // Replace underscores and remove leading underscore
          }));
    
          setAvailableSubFields(subFieldOptions);
        } catch (error) {
          setError(error);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page
    
    
        var options = {
          key: "rzp_test_rA2UalGsJwTy9v",
          key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",
          amount: 3 * 100,
          currency: "INR",
          captured: true,
          payment_capture: true,
          name: "International Institute Of Automate",
          handler: function (response) {
            alert(
              "Thanks For Your Intrest \n Your Payment has been successfully completed"
            );
            handlePopUp();
    
          },
    
          notes: {
            address: "International Institute Of Automate",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var pay = new window.Razorpay(options);
        pay.open();
    
    
    
        const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
        if (!userId) {
          console.error("User ID not found in localStorage.");
          return;
        }
        // Log the userId to check its value before submission
        console.log("User ID:", userId);
    
        // Prepare post data
        const postData = {
          source: formData.source,
          sourceName: formData.sourceName,
          courses: formData.courses.replace(/\s/g, ''), // Remove spaces if needed
          courseDuration: formData.duration,
          timings: formData.timings,
          mode: formData.mode,
          branches: formData.branches,
          registrationFees: formData.coursePrice // Include coursePrice in postData
        };
    
        // Log post data before making the API call
        console.log("Post Data:", JSON.stringify(postData, null, 2));
    
        // Check for missing required fields
        const missingFields = Object.keys(postData).filter(key => !postData[key]);
    
        if (missingFields.length > 0) {
          console.error('Missing required fields:', missingFields);
          alert(`Please fill in the following fields: ${missingFields.join(', ')}`);
          return; // Prevent submission if required fields are empty
        }
    
        try {
          const response = await fetch(
            `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/course/addcourse/${userId}`, // Ensure userId is appended correctly
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            }
          );
    
          if (!response.ok) {
            // Log the response for debugging
            const errorData = await response.text(); // Get response body as text
            throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorData}`);
          }
    
          const data = await response.json();
          console.log('API Response:', data);
          alert('Data added successfully!'); // Success alert
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('Error submitting form: ' + error.message);
        }
      };
    

    <div>
    <form onSubmit={handleSubmit} className="donate-form">
      <div className="row" style={{ paddingBottom: '25px' }}>







        <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Source*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="source"
            value={formData.source}
            onChange={handleChange}
            required
          >
            <option value="">Select Field</option>
            {/* Map options from API response */}
            {optionss.length > 0 && optionss.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group
          className="col-md-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Source Name*</Form.Label>
          <Form.Control
            type="text"
            style={{ height: '36px' }}
            placeholder="Source Name"

            name="sourceName" // API field name
            value={formData.sourceName} // Ensure value is linked to formData.sourceName
            onChange={handleChange}
            required
          />
        </Form.Group>




        <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Courses*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="courses" // API field name
            value={formData.courses} // Ensure value is linked to formData.courses
            onChange={handleChange} // Handle change to update formData
            required
          >
            <option value="">Select Course</option>
            <option value="ToolandFixtureDesign">Tool & Fixture Design</option>
            <option value="Offline Robotics">Offline Robotics</option>
            <option value="Online Robotics">Online Robotics</option>
            <option value="Java">Java</option>
            <option value="React Js">React Js</option>
            <option value="Flutter">Flutter</option>
          </Form.Select>
        </Form.Group>


        <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Courses Duration*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="duration"
            value={formData.duration}  // Correct value field
            onChange={handleFieldChange}
            required
          >
            <option value="">Choose your course</option>
            {courseduration.map((duration, index) => (
              <option key={index} value={duration}>
                {duration.replace(/_/g, ' ')} {/* Replace underscores with spaces */}
              </option>
            ))}
          </Form.Select>
        </Form.Group>





      </div>
      <div className="row" style={{ paddingBottom: "25px" }}>


        <Form.Group className="col-md-3" controlId="subFieldSelect">
          <Form.Label>Time*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="timings"
            value={formData.timings}
            onChange={(e) => setFormData({ ...formData, timings: e.target.value })}
            required
          >
            <option value="">Select Sub-Field</option>
            {availableSubFields.map((subField) => (
              <option key={subField.value} value={subField.value}>
                {subField.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>


        <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Mode*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="mode"
            value={formData.mode} // Ensure the value is linked to formData.mode 
            onChange={handleChange1} // Update the form data on change
            required
          >
            <option value="">Select Mode</option> {/* Optional placeholder */}
            <option value="Offline">OFFLINE</option>
            <option value="Online">ONLINE</option>
          </Form.Select>
        </Form.Group>



        <Form.Group
          className="col-md-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Branches*</Form.Label>
          <Form.Select
            aria-label="Default select example "
            name="branches" onChange={handleChange} value={formData.branches}
            required
          >
            <option>Select Any Field</option>
            <option value="Hyderabad" >Hyderabad</option>
            <option value="Bangloore" >Bangloore</option>
            <option value="Chennai" >Chennai</option>
            <option value="Pune" >Pune</option>
          </Form.Select>
        </Form.Group>

        <Form.Group
          className="col-md-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Registration Fee*</Form.Label>
          <Form.Control
            type="text"
            style={{ height: '36px' }}
            placeholder={offlinerobotics?.coursePrice || 10}
            value={formData.coursePrice || ""}

            required
          />
        </Form.Group>






      </div>
      <div className="row" style={{ paddingBottom: "25px" }}>


        {/* <Form.Group
        className="col-md-3"
        controlId="exampleForm.ControlInput1"
        >
        <Form.Label>Date*</Form.Label>
        <Form.Control
          type="date"

          placeholder="date of birth"
          name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange}
          required
        />
        </Form.Group> */}




      </div>





      {/* Add the Next button */}
      {/* <button type="button" class="btn btn-primary" onClick={handleNextNested}>
        Next
      </button> */}
      <button type="button" class="btn btn-primary" style={{ marginLeft: '45%' }} onClick={handleSubmit}>
        PAY NOW
      </button>
    </form>
  </div>


}

export default Registration;