import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Advertisecode from "./Advertisecode";
import apiUrls from './apiUrls';
import { Link, useNavigate } from 'react-router-dom';
const YourComponent = ({ users }) => {
    const [data, setData] = useState([]); // Main navigation data
    const [subNavData, setSubNavData] = useState({}); // Store sub-navigation data grouped by navId
    const [loading, setLoading] = useState(true); // Loading state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [posterImage, setPosterImage] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [image, setImage] = useState('');
    const [roleOptions, setRoleOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState(''); // Initial state for selected role
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [name, setName] = useState('');
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [organization, setOrganization] = useState('');
    const [loadingOrganization, setLoadingOrganization] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isSecondModalOpen, setIsSecondModalOpen] = useState(false); // Second modal state
    const [apiData, setApiData] = useState([]);
    const [navId, setNavId] = useState(1); // Initialize navId with a default value another data also 
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState({}); // State to track checked items
    const [filteredVendorOptions, setFilteredVendorOptions] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedImage, setEditedImage] = useState('');
    // Function to handle form submission for editing
    // Function to handle form submission for editing data not all the fetched data also not combined with the real time 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [clientOptions, setClientOptions] = useState([]);
    const [popupNavId, setPopupNavId] = useState(null);
    const [subNavId, setSubNavId] = useState(null);
    const [subName, setSubName] = useState('');
    const [subPosterImage, setSubPosterImage] = useState('');
    const [subPopupVisible, setSubPopupVisible] = useState(false);
    const [itemData, setItemData] = useState(null); // New state for item data
    const [selectedItems, setSelectedItems] = useState([]);
    const [headerChecked, setHeaderChecked] = useState({});
    const [selectedNavId, setSelectedNavId] = useState([]); // Nav IDs as an array
    const [selectedSubNavId, setSelectedSubNavId] = useState([]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
    const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false);
    const [showRoleDropdown, setShowRoleDropdown] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);






    const handleSubmitsssss = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/editbyid/${selectedSubNavId}`, {
                name: name // Body with the new name
            });
            console.log('Edit response:', response.data); // Log response from the API 
            setIsPopupOpen(false); // Close the popup after successful edit
            // Optionally, you can fetch the updated data again or update state directly
        } catch (error) {
            console.error('Error updating sub navigation data:', error);
        }
    };


    const handleEdit = () => {
        // Logic for editing goes here
        console.log('Edit button clicked');
    };

    const handleSave = () => {
        // Logic for saving goes here
        console.log('Save button clicked');
    };



    const handleNameClicks = (subNavId) => {

        console.log("Clicked SubNav ID:", subNavId);
        setSelectedSubNavId(subNavId);
        setIsPopupOpen(true);
    };

    const fetchSubNavData = async (subNavId) => {
        try {
            const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/getbyidforview/${subNavId}`);
            console.log(response.data); // Check the structure of the response
            if (response.data) {
                setItemData(response.data);
                setName(response.data.name || ''); // Use empty string if name is undefined not having the fetched in the calling 
            }
        } catch (error) {
            console.error('Error fetching sub navigation data:', error);
        }
    };






    useEffect(() => {
        const fetchVendorOptions = async () => {
            try {
                const response = await fetch(
                    apiUrls.PUT_enum);
                if (!response.ok) {
                    throw new Error('Failed to fetch vendor options');
                }
                const data = await response.json();

                console.log('Fetched vendor options:', data); // Log the fetched data all the fetching data also not comin gin the 

                // Map each role to an object with value and label for dropdown all the data couses also not fetching in the reality 
                const options = data.map((role) => ({
                    value: role, // Assuming each role is a string; adjust if needed  
                    label: role,
                }));

                setFilteredVendorOptions(options); // Update the state with options
            } catch (error) {
                console.error('Error fetching vendor options:', error);
            }
        };

        fetchVendorOptions();
    }, []);




    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value); // Update selectedRole state when the dropdown changes
    };


    // const handleApplyClick = async () => {
    //     const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    //     if (!userId) {
    //       console.error("User ID not found in localStorage.");
    //       return;
    //     }
    //     try {


    //         // Transform the data into the required format
    //         const data = {
    //             userId: vendor, // Extracted user ID
    //             navId: selectedNavId, // Directly use selectedNavId
    //             subNavId: selectedItems, // Directly use selectedItems for subNavId
    //             role: selectedRole, // Fixed role value
    //             category: selectedCategory,
    //             organisation: organization,
    //         };

    //         console.log('Data to submit:', JSON.stringify(data)); // Debugging log

    //         // Make the API call
    //         const response = await fetch(`http://10.10.20.9:7071/IIA-Website-1.0.0/controllerScreen/save`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(data),
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to submit data');
    //         }

    //         const result = await response.json();
    //         console.log('API response:', result);

    //         // Show a success alert
    //         alert('Data submitted successfully!');
    //     } catch (error) {
    //         console.error('Error submitting data:', error);
    //     }
    // };


    // const handleEditClick = async () => {
    //     const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    //     if (!userId) {
    //       console.error("User ID not found in localStorage.");
    //       return;
    //     }
    //     try {


    //         // Transform the data into the required format
    //         const data = {
    //             userId: vendor, // Extracted user ID
    //             navId: selectedNavId, // Directly use selectedNavId
    //             subNavId: selectedItems, // Directly use selectedItems for subNavId
    //             role: selectedRole, // Fixed role value
    //             category: selectedCategory,
    //             organisation: organization,
    //         };

    //         console.log('Data to submit:', JSON.stringify(data)); // Debugging log

    //         // Make the API call
    //         const response = await fetch(`http://10.10.20.9:7071/IIA-Website-1.0.0/controllerScreen/update/user/${userId}`, {
    //             method: 'PUT',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(data),
    //         });

    //         if (!response.ok) {
    //             throw new Error('Failed to submit data');
    //         }

    //         const result = await response.json();
    //         console.log('API response:', result);

    //         // Show a success alert
    //         alert('Data submitted successfully!');
    //     } catch (error) {
    //         console.error('Error submitting data:', error);
    //     }
    // };

    const handleSaveOrUpdateClick = async () => {

        // Prepare the data for API calls
        const data = {
            userId: vendor, // Extracted user ID
            navId: selectedNavId, // Directly use selectedNavId
            subNavId: selectedItems, // Directly use selectedItems for subNavId
            role: selectedRole, // Fixed role value
            category: selectedCategory,
            organisation: organization,
        };

        console.log('Data to submit:', JSON.stringify(data)); // Debugging log

        let isUpdateSuccessful = false;

        try {
            // Attempt the PUT API call (update)
            const updateResponse = await fetch(
                `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/controllerScreen/update/user/${vendor}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }
            );
            if (updateResponse.status === 200) {
                const result = await updateResponse.json();
                console.log('Update API response:', result);
                alert('Data updated successfully!');
                window.location.reload();
                isUpdateSuccessful = true;
                return; // Exit the function after a successful update
            } else if (updateResponse.status === 500) {
                console.error('Server error during update, attempting to create new data...');
            } else {
                console.error('Update failed, attempting to create new data...');
            }
        } catch (error) {
            console.error('Error during update:', error);
        }

        if (!isUpdateSuccessful) {
            try {
                // Attempt the POST API call (create)
                const createResponse = await fetch(
                    apiUrls.PUT_controller_screen,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    }
                );

                if (createResponse.ok) {
                    const result = await createResponse.json();
                    console.log('Create API response:', result);
                    alert('Data submitted successfully!');
                } else {
                    console.error('Failed to create new data');
                    alert('Failed to submit data.');
                }
            } catch (error) {
                console.error('Error submitting data:', error);
                alert('Error occurred while submitting data.');
            }
        }
    };





    useEffect(() => {
        if (selectedRole) {
            const fetchUsers = async () => {
                setLoadingUsers(true);
                try {
                    const url = `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/by-role/${selectedRole}`;
                    console.log("Fetching URL:", url); // Log the URL to ensure it's correct

                    const response = await fetch(url);
                    if (!response.ok) throw new Error("Failed to fetch users");

                    const data = await response.json();

                    // Check the structure of the fetched data
                    console.log("Fetched data:", data);

                    // Check if data is an array and contains expected properties
                    const options = Array.isArray(data)
                        ? data.map((user) => {
                            console.log("Mapping user:", user); // Log individual user for debugging

                            return {
                                label: user.name || "Unknown", // Fallback if name is missing
                                value: user.userId || "", // Fallback if userId is missing
                            };
                        })
                        : [];

                    setUserOptions(options); // Set the options for the dropdown
                } catch (error) {
                    console.error("Error fetching users:", error);
                } finally {
                    setLoadingUsers(false);
                }
            };

            fetchUsers(); // Fetch users when a role is selected
        }
    }, [selectedRole]);





    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(
                    apiUrls.PUT_enum_category);
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();

                // Convert each category string into an object with value and label for the dropdown
                const options = data.map((category) => ({
                    value: category,
                    label: category,
                }));

                setClientOptions(options);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);


    useEffect(() => {
        if (selectedCategory) {
            const fetchOrganizations = async () => {
                setLoadingOrganizations(true);
                try {
                    const url = `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/by-category/${selectedCategory}`;

                    console.log("Fetching URL:", url); // Print the URL

                    const response = await fetch(url);
                    console.log("Response status:", response.status); // Check status

                    if (!response.ok) throw new Error("Network response was not ok");

                    const data = await response.json();
                    console.log("Fetched data:", data); // Log data

                    const options = data.map((org) => ({ label: org, value: org }));
                    setOrganizationOptions(options); // Update organization options for the dropdown
                } catch (error) {
                    console.error("Error fetching organizations:", error);
                } finally {
                    setLoadingOrganizations(false);
                }
            };

            fetchOrganizations();
        } else {
            setOrganizationOptions([]);
        }
    }, [selectedCategory]);



    useEffect(() => {
        if (selectedSubNavId && isPopupOpen) {
            fetchSubNavData(selectedSubNavId);
        }
    }, [selectedSubNavId, isPopupOpen]);

    const handleSubNavSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission procedures of the ParentComponent.js,FeesCollections.js,EventManagement.js,CoursesTableData.js,AllWebinars.js code int the  
        setLoading(true);

        const editedData = {
            subNavId,
            name,
            image: posterImage.split(',')[1] // Send image data without prefix
        };

        try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/editbyid/${subNavId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Handle success (close popup, refresh data, etc.)
            closeSubNavPopup(); // Close the subNav popup after submission
            console.log('SubNav data updated successfully!');

        } catch (error) {
            console.error("Error updating subNav data:", error);
        } finally {
            setLoading(false);
        }
    };

    const closeSubNavPopup = () => {
        setSubNavId(null);
        setName('');
        setPosterImage('');
    };











    const handleNameClick = (navId) => {
        setPopupNavId(navId);
        fetchUserData(navId); // Fetch data for this navId when the name is clicked
    };

    const closePopup = () => {
        setPopupNavId(null); // Close the popup
        setName(''); // Clear form data
        setPosterImage(null); // Clear image preview
    };

    const fetchUserData = async (navId) => {
        try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/getbyid/${navId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const fetchedData = await response.json(); // Parse the JSON response

            // Set the state with the fetched data
            setName(fetchedData.name);

            // Check if the image is not empty and prepend the base64 prefix
            if (fetchedData.image) {
                setPosterImage(`data:image/png;base64,${fetchedData.image}`); // Update according to the image format
            } else {
                setPosterImage(''); // Clear image state if no image
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };


    const handleSubmitssss = async (event) => {
        event.preventDefault(); // Prevent default form submission
        setLoading(true);

        const editedData = {
            navId: popupNavId, // Send the ID along with the data
            name,
            image: posterImage.split(',')[1] // Remove the prefix before sending
        };

        try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/editbyid/${popupNavId}`, {
                method: 'PUT', // Use PUT for editing
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Optionally, handle success (e.g., close popup, refresh data, show message, etc.)
            console.log('Data updated successfully!');
        } catch (error) {
            console.error("Error updating data:", error);
        } finally {
            setLoading(false);
        }
    };


    //   const handleImageChangess = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         setPosterImage(reader.result); // Preview the image
    //       };
    //       reader.readAsDataURL(file);
    //     }
    //   };

    const handleSubmitsss = (e) => {
        e.preventDefault();
        // Submit form logic here (API call or update data)
        console.log("Submitted data:", { navId: popupNavId, name, posterImage });
    };
    // const handleUserNameClick = async (user) => {
    //     try {
    //         const response = await axios.get(`http://10.10.20.9:7071/IIA-Website-1.0.0/sidenav/getbyid/${user.navId}`);
    //         setUserData(response.data); // Set the user data
    //         setNavId(user.navId);
    //         setIsEditModalOpen(true); // Open the edit modal
    //     } catch (error) {
    //         console.error("Error fetching user data:", error);
    //     }
    // };



    const handleEditSubmit = async (e) => {
        e.preventDefault();

        try {
            // Prepare the image as base64 (assuming image is handled as base64)
            const cleanImage = editedImage ? editedImage.replace(/^data:image\/[a-zA-Z]+;base64,/, '') : '';

            const response = await axios.put(
                `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/editbyid/${selectedUser.navId}`,
                {
                    name: editedName, // Send updated name
                    image: cleanImage, // Send updated image (as base64)
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                alert('User details updated successfully');
                setIsEditModalOpen(false);
                // Optionally, update the user details in the table without reloading
                // e.g., by updating the state that stores users
            }
        } catch (error) {
            console.error('Error updating user details:', error.response?.data || error.message);
            alert('Failed to update user details');
        }
    };



    const handleEditButtonClick = async (user) => {
        console.log('Editing user:', user); // Confirm this is being called
        try {
            const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/getbyid/${user.navId}`);
            console.log('User data fetched:', response.data); // Log the response data
            setUserData(response.data); // Assuming the response contains the user data
            setNavId(user.navId);
            setIsEditModalOpen(true); // Open the edit modal
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setUserData(null); // Reset userData on close
    };


    const handleSubmits = async (event) => {
        event.preventDefault();
        const cleanImage = image.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
        const formData = {
            name: name,
            image: cleanImage,
        };

        try {
            const response = await axios.post(
                'https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/add',
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Form submitted successfully:', response.data);
            closeSubNavPopup();
            alert('Data added successfully!');
            window.location.reload();

            // navigate('/login');
        } catch (error) {
            console.error('Error submitting form:', error.response?.data || error.message);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
                setPosterImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    // Fetch initial data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/all');
                console.log('API Response:', response.data); // Log response data
                setData(response.data); // Set fetched data

                // Fetch sub-navigation data for each navId
                const initialSubNavData = {};
                await Promise.all(response.data.map(async (item) => {
                    const subNavResponse = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/subnav/${item.navId}`);
                    const subNavData = await subNavResponse.json();
                    initialSubNavData[item.navId] = subNavData; // Group data by navId
                }));

                setSubNavData(initialSubNavData); // Set grouped sub-navigation data
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // End loading state
            }
        };

        fetchData();
    }, []);






    // Form submission handler
    const handleSubmitss = async (e) => {
        e.preventDefault();

        //    alert(imageFields)
        console.log(imageFields)

        // Prepare the data to be sent to the API
        const requestBody = {
            navId, // Use the navId from the selected course
            name,

        };

        // POST API call
        try {
            const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Failed to submit the form');
            }

            const data = await response.json();
            console.log('Form submitted successfully:', data);

            // Handle the success, close modal, or reset form
            setIsSecondModalOpen(false);
            setName('');
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };
    // Handle checkbox change for individual items
    // Handle individual subNav checkbox change
    const handleCheckboxChange = (subNavId) => {
        // Toggle selection of individual subNavId items
        setSelectedItems((prev) => {
            const updated = prev.includes(subNavId)
                ? prev.filter((id) => id !== subNavId) // Deselect if already selected
                : [...prev, subNavId]; // Add if not selected
            console.log('Updated selectedItems:', updated); // Debug log
            return updated;
        });
    };

    // Handle header checkbox change to check/uncheck the navId only
    const handleHeaderCheckboxChange = (navId,name) => {
        // Toggle the navId in selectedNavId list (checkbox for the nav)
        if (name === "ADVERTISEMENT") {
            // Store the navId directly in localStorage (as a string, since localStorage only stores strings)
            localStorage.setItem('selectedNavId', navId);
            console.log('Stored in localStorage (ADVERTISEMENT):', navId); // Debug log
        }
        setSelectedNavId((prev) => {
            const updated = prev.includes(navId)
                ? prev.filter((id) => id !== navId) // Deselect if already selected
                : [...prev, navId]; // Add if not selected
            console.log('Updated selectedNavId:', updated); // Debug log
            return updated;
        });
    };

    // Handle header checkbox checked state
    const isHeaderChecked = (navId) => {
        // Check if the navId is selected in selectedNavId
        return selectedNavId.includes(navId);
    };



    // Function to delete a nav item by navId
    const handleDelete = async (navId) => {
        try {
            // Replace with your API endpoint
            await axios.delete(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/deletedata/${navId}`);

            // Update local state to remove the deleted item  
            setData(prevData => prevData.filter(user => user.navId !== navId));
            setSubNavData(prevSubNavData => {
                const { [navId]: _, ...rest } = prevSubNavData; // Remove subNavData for deleted navId
                return rest;
            });
        } catch (error) {
            console.error('Error deleting the item:', error);
            // Handle error appropriately (e.g., show a notification)
        }
    };




    // Function to delete a sub-navigation item by subNavId
    const handleDeleteSubNav = async (subNavId) => {
        // alert(subNavId)
        try {
            // Replace with your API endpoint
            await axios.delete(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/deletesubsidenavbar/${subNavId}`);

            // Update local state to remove the deleted item
            setSubNavData(prevSubNavData => {
                const updatedSubNavData = { ...prevSubNavData };
                Object.keys(updatedSubNavData).forEach(navId => {
                    updatedSubNavData[navId] = updatedSubNavData[navId].filter(item => item.subNavId !== subNavId);
                });
                return updatedSubNavData;
            });

            // Optionally, you may want to remove the checked item from checkedItems and all
            setCheckedItems(prev => {
                const { [subNavId]: _, ...rest } = prev; // Remove the checked state for deleted subNavId
                return rest;
            });

        } catch (error) {
            console.error('Error deleting the sub-navigation item:', error);
            // Handle error appropriately (e.g., show a notification)
        }
    };


    // State to track selected values of dropdowns
    const [client, setClient] = useState('');
    const [vendor, setVendor] = useState('');
    const [employee, setEmployee] = useState('');
    const [user, setUser] = useState('');

    // // Mock data for dropdowns
    // const clientOptions = [
    //     { value: '_160_HR', label: 'Client' },
    //     { value: '_320_HR', label: 'Vendor' },
    // ];

    // const vendorOptions = [
    //     { value: '_160_HR', label: 'Employee' },
    //     { value: '_320_HR', label: 'Admin' },
    // ];

    // const employeeOptions = [
    //     { value: '_160_HR', label: 'User' },
    //     { value: '_320_HR', label: 'Student' },
    // ];

    // const userOptions = [
    //     { value: '_160_HR', label: 'Akhil' },
    //     { value: '_320_HR', label: 'Naresh' },
    // ];

    // Filter logic for dependent dropdowns (Example)
    // const filteredVendorOptions = vendorOptions.filter((option) =>
    //     client === '_160_HR' ? option.value !== '_320_HR' : option
    // );

    // const filteredEmployeeOptions = employeeOptions.filter((option) =>
    //     vendor === '_160_HR' ? option.value !== '_320_HR' : option
    // );

    // const filteredUserOptions = userOptions.filter((option) =>
    //     employee === '_160_HR' ? option.value !== '_320_HR' : option
    // );


    const [textFields, setTextFields] = useState(['']);
    const [imageFields, setImageFields] = useState([null]);
    const [videoFields, setVideoFields] = useState([null]);

    const imageInputRef = useRef(null);
    const videoInputRef = useRef(null);

    const addField = (type) => {
        if (type === 'text') {
            setTextFields([...textFields, '']);
        } else if (type === 'image') {
            // Programmatically click the hidden file input for images 
            imageInputRef.current.click();
        } else if (type === 'video') {
            // Programmatically click the hidden file input for 
            videoInputRef.current.click();
        }
    };

    const handleFileChange = (type, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result; // Base64 string
                if (type === 'image') {
                    setImage(reader.result);
                    setPosterImage(reader.result);
                    setImageFields((prev) => [...prev, base64String]);
                } else if (type === 'video') {
                    setVideoFields((prev) => [...prev, base64String]);
                }
            };

            // Read the file as a Data URL (Base64 string)
            reader.readAsDataURL(file);
        }
    };


    return (


        <div style={{ marginTop: '182px' }} className='controlers-page'>

<nav className="navbar1">
                <div className="row" style={{ marginTop: '0px' }}>
                    <div className="col-lg-1 col-md-6 col-sm-12" style={{ marginTop: '4px', marginRight: '22px' }}>
                        <button onClick={() => setIsModalOpen(true)} className="btn btn-primary">
                            Add
                        </button>
                    </div>

                    {/* Client Dropdown Backend.js, CoursesCards.js,CoursesE*/}
                    <div className="col-lg-2 col-md-6 col-sm-12" style={{ marginRight: '22px' }}>
                        <div className="form-group">
                            <select
                                className="form-control"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                disabled={loading}
                            >
                                <option value="">Category</option>
                                {clientOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>


                    <div className="col-lg-2 col-md-6 col-sm-12" style={{ marginRight: '22px' }}>
                        <div className="form-group">
                            <select
                                className="form-control"
                                value={organization}
                                onChange={(e) => setOrganization(e.target.value)}

                            >
                                <option value="" disabled>Organization</option>
                                {organizationOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {loadingOrganization && <p>Loading organizations...</p>}
                        </div>
                    </div>

                    {/* Vendor Dropdown */}
                    <div className="col-lg-2 col-md-6 col-sm-12" style={{ marginRight: '22px' }}>
                        <div className="form-group">
                            <select
                                className="form-control"
                                value={selectedRole} // Bind the select value to selectedRole
                                onChange={handleRoleChange} // Handle change
                            >
                                <option value="" disabled>Roles</option>
                                {filteredVendorOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12" style={{ marginRight: '22px' }}>
                        <div className="form-group">
                            <select
                                className="form-control"
                                value={vendor}
                                onChange={(e) => setVendor(e.target.value)} // Update vendor state on selection
                                disabled={loadingUsers} // Disable the dropdown while loading
                            >
                                <option value="" disabled>Select User</option>
                                {userOptions.map((option) => (
                                    <option key={option.value} value={option.value}> {/* Use option.value for key and value */}
                                        {option.label} {/* Use option.label for displaying the name */}
                                    </option>
                                ))}
                            </select>
                            {/* Show loading message while loading */}
                            {loadingUsers && <p>Loading users...</p>}
                        </div>
                    </div>



                    <div
                        className="col-lg-1 col-md-6 col-sm-12"
                        style={{
                            marginTop: '4px',
                            marginRight: '22px',
                            display: 'flex', // Use flexbox for layout
                            justifyContent: 'space-between', // Add spacing between buttons
                            alignItems: 'center', // Center buttons vertically
                            gap: '8px', // Optional: Add a consistent gap between buttons
                        }}
                    >
                        <button onClick={handleSaveOrUpdateClick} className="btn btn-primary">
                            Apply
                        </button>

                    </div>





                </div>
            </nav>





            <div style={{ overflowX: 'auto', marginTop: '70px',marginRight:'10px' }}>
                <table style={{ borderCollapse: 'collapse', width: '145%' }}>
                    <thead>
                        <tr>
                            {data.map((user, index) => (
                                <th
                                    key={user.navId}
                                    style={{
                                        border: '1px solid #000',
                                        padding: '8px',
                                        backgroundColor: '#f2f2f2',
                                        width:
                                            index === data.length - 1
                                                ? '200px' // Last column width
                                                : index === data.length - 2
                                                    ? '215px' // Second last column width
                                                    : index === 1
                                                        ? '220px' // Third column width
                                                        : index === 2
                                                            ? '200px' // Third column width
                                                            : '250px', // Default width for other columns
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        checked={headerChecked[user.navId] || isHeaderChecked(user.navId)}
                                        onChange={() => handleHeaderCheckboxChange(user.navId,user.name)}
                                    />
                                    <span
                                        style={{ cursor: 'pointer', fontSize: '13px', marginLeft: '10px' }}
                                        onClick={() => handleNameClick(user.navId)}
                                    >
                                        {user.name}
                                    </span>
                                    <button
                                        onClick={() => {
                                            setIsSecondModalOpen(true);
                                            setNavId(user.navId);
                                        }}
                                        className="btn btn-primary"
                                        style={{ marginLeft: '8px', width: '50px' }}
                                    >
                                        Add
                                    </button>
                                    <button
                                        onClick={() => handleDelete(user.navId)}
                                        className="btn btn-danger"
                                        style={{ marginLeft: '2px', width: '35px' }}
                                    >
                                        <i className="la la-trash-o"></i>
                                    </button>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {data.map((user) => (
                                <td key={user.navId} style={{ border: '1px solid #ccc', padding: '8px', verticalAlign: 'top' }}>
                                    {subNavData[user.navId] && subNavData[user.navId].length > 0 ? (
                                        subNavData[user.navId].map((item) => (
                                            <div key={item.subNavId} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedItems.includes(item.subNavId)}
                                                        onChange={() => handleCheckboxChange(item.subNavId)}
                                                    />
                                                    <span onClick={() => handleNameClicks(item.subNavId)} style={{ cursor: 'pointer', marginLeft: '15px' }}>
                                                        {item.name}
                                                    </span>
                                                </div>
                                                <button
                                                    onClick={() => handleDeleteSubNav(item.subNavId)}
                                                    className="btn btn-danger"
                                                    style={{
                                                        marginLeft: '8px',
                                                        marginRight:
                                                            item.subNavId === 129 || item.subNavId === 130 || item.subNavId === 131 || item.subNavId === 132 || item.subNavId === 133 || item.subNavId === 134
                                                                ? '20px'
                                                                : '0px',
                                                    }}
                                                >
                                                    <i className="la la-trash-o"></i>
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <span>No data available</span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>



            {isPopupOpen && itemData && ( // Ensure itemData is defined before rendering
                <div className="popup">
                    <div className="popup-content">
                        {/* <h2>Edit Details for {selectedSubNavId}</h2> */}
                        {/* Close button */}
                        <button
                            className="close-button"
                            onClick={() => setIsPopupOpen(false)} // Close the popup when clicked
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                background: 'none',
                                border: 'none',
                                fontSize: '20px',
                                cursor: 'pointer',
                                marginTop: '-10px'
                            }}
                        >
                            &times; {/* This represents the "X" symbol */}
                        </button>
                        <form onSubmit={handleSubmitsssss}>
                            <div className="form-group">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name} // Controlled input using name state
                                    onChange={(e) => setName(e.target.value)} // Update name state
                                    required
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            {isEditModalOpen && userData && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeEditModal}>&times;</span>
                        {/* <h2>Edit User</h2> */}
                        <form>
                            <div>
                                <label>Name:</label>
                                <input
                                    type="text"
                                    value={userData.name || ''} // Default to empty string if name is undefined all 
                                    onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                />
                            </div>
                            <div>
                                <label>Image:</label>
                                <input
                                    type="text"
                                    value={userData.imageUrl || ''} // Default to empty string if imageUrl is undefined
                                    onChange={(e) => setUserData({ ...userData, imageUrl: e.target.value })}
                                />
                                {userData.imageUrl && <img src={userData.imageUrl} alt="User" style={{ width: '100px' }} />}
                            </div>
                            <button type="button" onClick={closeEditModal} className="btn btn-primary">Save</button>
                        </form>
                    </div>
                </div>
            )}


            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        {/* <button className="close-button" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </button> */}
                        <form onSubmit={handleSubmits}>
                            <table className="form-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Text
                                            <button type="button" className="add-icon" onClick={() => addField('text')}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </th>
                                        <th>
                                            Upload Image
                                            <button type="button" className="add-icon" onClick={() => addField('image')}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </th>
                                        <th>
                                            Upload Video
                                            <button type="button" className="add-icon" onClick={() => addField('video')}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {textFields.map((field, index) => (
                                                <div className="input-group" key={index}>
                                                    <input
                                                        type="text"
                                                        className="form-control1"
                                                        value={field}
                                                        onChange={(e) => {
                                                            const updatedFields = [...textFields];
                                                            updatedFields[index] = e.target.value;
                                                            setTextFields(updatedFields);
                                                            setName(e.target.value);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            {imageFields.map((image, index) => (
                                                <div key={index} className="file-preview">
                                                    {image ? (
                                                        <img src={image} alt="Uploaded" className="file-image-preview" />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={imageInputRef}
                                                className="file-upload"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange('image', e)}
                                            />
                                        </td>
                                        <td>
                                            {videoFields.map((video, index) => (
                                                <div key={index} className="file-preview">
                                                    {video ? (
                                                        <video controls src={video} className="file-video-preview" />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <input
                                                type="file"
                                                accept="video/*"
                                                ref={videoInputRef}
                                                className="file-upload"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange('video', e)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="button-group">
                                <button type="button" className="btn btn-secondary" onClick={handleEdit}>
                                    Edit
                                </button>
                                <button type="button" className="btn btn-success" onClick={handleSave}>
                                    Save
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                                <button type="submit" className="btn btn-primary" onClick={() => setIsModalOpen(false)}>
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            {isSecondModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        {/* <button className="close-button" onClick={() => setIsSecondModalOpen(false)}>
                        &times;
                  </button> */}
                        <form onSubmit={handleSubmitss} className="modal-form">
                            <table className="form-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Text
                                            <button type="button" className="add-icon" onClick={() => addField('text')}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </th>
                                        <th>
                                            Upload Image
                                            <button type="button" className="add-icon" onClick={() => addField('image')}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </th>
                                        <th>
                                            Upload Video
                                            <button type="button" className="add-icon" onClick={() => addField('video')}>
                                                <i className="fas fa-plus"></i>
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {textFields.map((field, index) => (
                                                <div className="input-group" key={index}>
                                                    <input
                                                        type="text"
                                                        className="form-control1"
                                                        value={field}
                                                        onChange={(e) => {
                                                            const updatedFields = [...textFields];
                                                            updatedFields[index] = e.target.value;
                                                            setTextFields(updatedFields);
                                                            setName(e.target.value);
                                                        }}
                                                        required
                                                    />
                                                </div>
                                            ))}
                                        </td>
                                        <td>
                                            {imageFields.map((image, index) => (
                                                <div key={index} className="file-preview">
                                                    {image ? (
                                                        <img src={image} alt="Uploaded" className="file-image-preview" />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={imageInputRef}
                                                className="file-upload"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange('image', e)}
                                            />
                                        </td>
                                        <td>
                                            {videoFields.map((video, index) => (
                                                <div key={index} className="file-preview">
                                                    {video ? (
                                                        <video controls src={video} className="file-video-preview" />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <input
                                                type="file"
                                                accept="video/*"
                                                ref={videoInputRef}
                                                className="file-upload"
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleFileChange('video', e)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="button-group">
                                <button type="button" className="btn btn-secondary" onClick={handleEdit}>
                                    Edit
                                </button>
                                <button type="button" className="btn btn-success" onClick={handleSave}>
                                    Save
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                                <button type="submit" className="btn btn-primary" onClick={() => setIsSecondModalOpen(false)}>
                                    Close
                                </button>
                            </div>
                        </form>

                    </div>
                </div>


            )}

            {popupNavId && (
                <div className="popup">
                    <div className="popup-content">
                        <button onClick={closePopup} className="close-button" style={{ float: 'right', cursor: 'pointer' }}>
                            &times; {/* X symbol */}
                        </button>
                        {/* <h2>Details for NavId: {popupNavId}</h2> */}
                        <form onSubmit={handleSubmitssss}>
                            <div className="col-lg-2 col-md-12 col-sm-12">
                                <div className="add-choosen">
                                    <div className="input-blocks">
                                        <div className="image-upload" style={{ marginRight: '100px' }}>
                                            <input
                                                type="file"
                                                id="imageInput"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                                required
                                            />
                                            <label htmlFor="imageInput" className="image-uploads">
                                                <i data-feather="plus-circle" className="plus-down-add me-0"></i>
                                                <h4>Add Image</h4>
                                                {posterImage && (
                                                    <img
                                                        src={posterImage}
                                                        alt="Preview"
                                                    />
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} // Update the name state
                                    required
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            {/* SubNav Popup */}
            {subNavId && (
                <div className="popup">
                    <div className="popup-content">
                        <button onClick={closeSubNavPopup} className="close-button" style={{ float: 'right', cursor: 'pointer' }}>
                            &times;
                        </button>
                        <h2>Edit SubNavId: {subNavId}</h2>
                        <form onSubmit={handleSubNavSubmit}>
                            <div className="form-group">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Image</label>
                                <input
                                    type="file"
                                    id="imageInput"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    required
                                />
                                <label htmlFor="imageInput" className="image-uploads" style={{ cursor: 'pointer' }}>
                                    <i data-feather="plus-circle" className="plus-down-add me-0"></i>
                                    <h4>Add Image</h4>
                                    {posterImage && (
                                        <img src={posterImage} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                    )}
                                </label>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <style>
                {`
                .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

button {
  margin-top: 10px;
}

  /* Modal Styles */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 1000px; /* Adjust as needed */
  }

  .modal-content {
    display: flex;
    min-width:800px;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .form-label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .btn {
    padding: 8px 12px;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }

  .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
  }

  .btn-primary:hover {
    background-color: #0056b3;
  }

  .btn-secondary {
    background-color: #6c757d;
    color: white;
    border: none;
  }

  .btn-secondary:hover {
    background-color: #5a6268;
  }

  /* Optional: Backdrop for modal */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  /* Table Header Styles */
  th {
    text-align: left;
    border: 1px solid #000;
    padding: 8px;
    background-color: #f2f2f2;
  }

  th span {
    cursor: pointer;
 
  }

  th span:hover {
    color: darkblue;
  }

  th .btn {
    margin-left: 8px;
  }
`}
            </style>
            <Advertisecode />
        </div>
    );
};

export default YourComponent;
