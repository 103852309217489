import { Link } from "react-router-dom";
import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import toolfix from "../images/banners/JAVA.jpg";

const Layout3 = () => {
    { window.scrollTo({ top: 0, behavior: 'smooth' }); };

    const [activeTab, setActiveTab] = useState('tab1');

    const advertisements = [
        "Advertisement 1: Learn Java in-depth!",
        "Advertisement 2: Sign up for Java Oriented Training!",
        "Advertisement 3: 100% Placement Support available!",
    ];


    const handleTabClick = (e, tabId) => {
        e.preventDefault(); // Prevent default behavior
    
        // Capture current scroll position
        const scrollPosition = window.scrollY;
    
        // Update the active tab
        setActiveTab(tabId);
    
        // Force a delay to allow the state to update before restoring scroll position
        setTimeout(() => {
          // Restore scroll position after state update
          window.scrollTo(0, scrollPosition);
        }, 0);
      };

      const tabs = [
        {
          id: 'tab1',
          label: 'OVERVIEW',
          content: (
            <>
              <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
                <span style={{ color: '#f58842' }}>Java</span>
              </h2>
              <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
                  Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
                </li>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
                  Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
                </li>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
                  Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
                </li>
                <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
                  Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
                </li>
              </ul>
              <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
                Register Now
              </button>
    
            </>
          )
        },
        {
          id: 'tab2',
          label: 'Level 1 Certification',
          content: (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <h2 style={{ color: "rgb(224 120 44)" }}>Level 1 Certification<span style={{ color: "#fff" }}></span></h2>
    
                </div>
              </div>
              <div className="row">
                <div>
                  <p style={{ color: "#fff", fontSize: "20px", textAlign: "justify", marginTop: "35px" }}>
    
                  </p>
                </div>
              </div>
            </>
          )
        },
        {
          id: 'tab3',
          label: 'Level 2 Certification',
          content: (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <h2 style={{ color: "rgb(224 120 44)" }}>DIPLOMA <span style={{ color: "#fff" }}></span></h2>
                </div>
              </div>
            </>
          )
        },
        {
          id: 'tab4',
          label: 'Level 3 Certification',
          content: (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <h2 style={{ color: "rgb(224 120 44)" }}>POST<span style={{ color: "#fff" }}> GRADUCATION</span></h2>
                </div>
              </div>
            </>
          )
        }
      ];
    
    
    const currentTab = tabs.find(tab => tab.id === activeTab);
    return (
        <div>
            <section style={{marginTop:'50px'  }} className="courses-banner-page-imge">
                <Carousel className="res-nav-mob no-indicators" interval={300000} controls={false} indicators={false}>
                    <img className="d-block full-img" src={toolfix} alt="Tool & Fixture Design" style={{ height: "600px", width: "85.3% ",}} />
                    <Carousel.Caption style={{ width: "100%", textAlign: "left", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                        <div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
                            <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "240px" }}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginBottom: "20px" }}>
                                    <h2
                                        className="banner-content banner-second-data12"
                                        style={{
                                            fontFamily: "Roboto",
                                            fontSize: "40px",
                                            fontWeight: "800",
                                            marginTop: "30px",
                                            color: "#fff",
                                
                                            marginLeft: '-100px' // Remove any default margins
                                        }}
                                    >
                                        JAVA
                                    </h2>

                                    <h2
                                        className="banner-content banner-second-data1"
                                        style={{
                                            fontFamily: "Poppins, SansSerif",
                                            fontSize: "32px",
                                            fontWeight: "600",
                                            marginTop: "30px",
                                            color: "#fff",
                                            width: "100%", // Takes up half of the row
                                            textAlign: "right", // Aligns text to the right within its column
                                         
                                        }}
                                    >
                                        Unlock your potential in JAVA
                                    </h2>
                                </div>

                                <div style={{ marginTop: "80px", textAlign: "center",marginLeft:'-100px' }}> {/* Add some space below the headings */}
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
                                        1. Java Oriented Training (JOT)
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
                                        2. Job Oriented Skill Enhancement (JOSE)
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
                                        3. 100% Placement Support
                                    </p>
                                    <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
                                        OOPS Concepts
                                    </p>
                                </div>
                                <button
                                    style={{
                                        backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
                                        color: "#fff", // White text color
                                        border: "none",
                                        width: "240px",
                                        borderRadius: "5px", // Rounded corners
                                        padding: "10px 20px", // Padding around the text
                                        fontSize: "16px", // Font size
                                        cursor: "pointer", // Cursor changes to pointer on hover
                                        marginTop: "50px", // Space above the button
                                    }}
                                    onClick={() => {
                                        // Add your download logic here
                                        console.log("Download brochure clicked");
                                    }}
                                >
                                    Download Brochure
                                </button>

                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel>
            </section>






            <div className="tab-wrapper" style={{ padding: '30px 69px', backgroundColor: "#fff",}}>
        <div className="tab-container">
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
              onClick={(e) => handleTabClick(e, tab.id)}
              style={{
                padding: '10px',
                marginRight: '10px',
                cursor: 'pointer',
                backgroundColor: activeTab === tab.id ? '#e0782c' : '#ccc',
                color: 'black' // Set the text color to black
              }}
            >
              {tab.label}
            </button>

          ))}
        </div>

        <div className="tab-content">
          {tabs.map(tab => (
            activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
          ))}
        </div>
      </div>

                {/* Auto-scrolling Advertisement Section */}
                {/* <div className="ad-container">
                    <div className="ad-content">
                        <div className="ad-item" >Advertisement 1</div>
                        <div className="ad-item">Advertisement 2</div>
                        <div className="ad-item">Advertisement 3</div> */}
                        {/* Add more advertisements as needed */}
                    {/* </div>
                </div> */}
                <style>
                    {`
      .tab-container {
        // display: flex;
        justify-content: center; /* Center the tabs horizontally */
        margin-bottom: 20px; /* Space between tabs and content */
      }
      .tab-button {
        flex: 1; /* Make all tabs take equal width */
        max-width: 500px; /* Set a maximum width for each tab */
        padding: 10px;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
        margin-right: 5px; /* Add some space between tabs */
        outline: none; /* Remove default outline */
       
        // border-radius: 50px;
            background: #4f4e70;
             color: #f58842; /* Default text color */
                margin-top: 10px;
                font-size: 18px;
      }
      .tab-button:hover {
        background-color: #f0f0f0;
      }
      .tab-button.active {
        border-bottom: 2px solid #524DA1; /* Active tab color */
        font-weight: bold;
        color: #f58842; /* Change text color for active tab */
            // border-radius: 50px;
            background: #efefef;
                margin-top: 10px;
                font-size: 18px;
      }
    
      .tab-content {
        padding: 20px;
        border: 1px solid #ccc;
        border-top: none; /* Remove top border to connect with tabs */
        background-color: rgb(91 86 110);
            color: #fff;
      }
             .tab-button.disabled {
        background-color: #ddd; /* Grey out background */
        color: #888; /* Lighter text color */
        cursor: not-allowed; /* Show not-allowed cursor */
        pointer-events: none; /* Disable pointer events */
      }
         .tab-button:hover:not(.disabled) {
        background-color: #f0f0f0;
      }

     .ad-container {
  position: fixed;
  right: -55px;
  bottom: 0;
  top: 0;
  width: 324px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
  background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

                </style>
            </div>





        
    )
}

export default Layout3