import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import Advertisecode from "./Advertisecode";
import { Tab, Tabs } from "react-bootstrap";
import AdvertisementShow from './AdverstimentDes';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
// import Contacts from "./Contacts";
import logo from "../images/logo-white-2.png";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from 'axios'; // Or you can use fetch
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
} from '@mui/material';

const EnrollForm = () => {
  const { enrollId } = useParams();
  const fieldSelectRef = useRef(null);
  const [showWebDevDropdown, setShowWebDevDropdown] = useState(false);
  const [showMechanicalDropdown, setShowMechanicalDropdown] = useState(false);
  const [datasource, setDatasource] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filteredData, setFilteredData] = useState(datasource);
  const [enrollData, setEnrollData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [date, setDate] = useState('');
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [key, setKey] = useState('registration'); // Managing active tab
  const [offlinerobotics, setOfflinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  
  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [punchInTime, setPunchInTime] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0); // Store total seconds to calculate hours

  // const [formData, setFormData] = useState({
  //   fullName: "",
  //   gender: "",
  //   dateOfBirth: "",
  //   emailId: "",
  //   contactNumber: "",
  //   course: "",
  //   timings: "",
  //   mode: "",
  //   courseDuration: "",
  //   categories: "",
  //   coursePrice: "",
  //   councillorName: "",
  // });
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [availableSubFields, setAvailableSubFields] = useState([]);
  const [courses, setCourses] = useState([]); // State for course options
  const [courseduration, setCoursess] = useState([]); // State for course options
  const [isSubmitClicked, setIsSubmitClicked] = useState(false); // To enable/disable Pay Now button
  const [isTermsAccepted1, setIsTermsAccepted1] = useState(false);
  const [isTermsAccepted2, setIsTermsAccepted2] = useState(false);
  const [step, setStep] = useState(1);
  const [nestedKey, setNestedKey] = useState('step1');
  const [subNestedKey, setSubNestedKey] = useState('subStep3'); // For second-level sub-tabs
  const [subSubNestedKey, setSubSubNestedKey] = useState('subSubStep1'); // For third-level sub-tabs
  const [subSubSubStepKey, setSubSubSubStepKey] = useState('detail1'); // Add this line for subSubSubStepKey
  const [optionss, setOptionss] = useState([]);
  // State for userId entered by the user
  const [userId, setUserId] = useState(''); // State for userId input

  const [formData, setFormData] = useState({
    source: '',
    sourceName: '',
    courses: '',
    duration: '_160_HR',
    timings: '_9AM_6PM',
    mode: '',
    branches: '',
    coursePrice: '10' // Include coursePrice if it's part of the form
  });


  useEffect(() => {
    setFilteredData(datasource);
  }, [datasource]);  // Only run when datasource changes

  // Handle input change
  

  useEffect(() => {
    // Get the current date in YYYY-MM-DD format (without the time)
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }
    // Construct the API URL with the current date
    const apiUrl = `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance/${userId}/${formattedDate}`;

    // Fetch the data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setAttendanceData(data); // Set the data
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        setError(error.message); // Set error if there's an issue with the request
        setLoading(false);
      });
  }, []); // Empty dependency array means this effect runs once on component mount



  const attendedTimeMinutes = attendanceData?.attendedTime;
  const attendedTimeHours = attendedTimeMinutes ? (attendedTimeMinutes / 60).toFixed(2) : 0;
  const remainingTimeMinutes = attendanceData?.remainingTime;
  const remainingTimeHours = remainingTimeMinutes ? (remainingTimeMinutes / 60).toFixed(2) : 0;

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }

    const fetchData = async () => {
      try {
        // Replace this with your actual API URL
        const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance/${userId}`);

        setDatasource(response.data); // Set the data from the API to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
 

  // Function to handle API call
  const fetchOptionss = async () => {
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/sources'); // Replace with your API endpoint
      const data = await response.json();
      setOptionss(data); // Assuming the response is an array like ["SocialMedia", "Councillor", "Reference"]
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call API when component mounts
  fetchOptionss();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetchCourseDuration = async () => {
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/courseduration"); // Replace with your courses API endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const result = await response.json();
        setCoursess(result); // Assuming result is an array of course objects
      } catch (error) {
        setError(error);
      }
    };

    fetchCourseDuration();

    // Initial fetch for default course price (if needed)
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=Java");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
        setFormData((prev) => ({ ...prev, coursePrice: result }));
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);




  const handleTabSelect = (key) => {
    setKey(key)
    // setActiveKey(key); // Update active tab
    if (key !== "subStep4") {
      setIsPopupOpen3(false) // Hide popup3 when switching from STAGE 3
    }
  };



  // Handle main field dropdown change
 



  useEffect(() => {
  
    fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryregister/getforview/${enrollId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Data:", data);
        setEnrollData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);





  const handleSubNestedTabSelect = (selectedKey) => {
    // Set the selected sub-tab key
    setSubNestedKey(selectedKey);

   

   

    // If needed, add logic to close the popup for other steps
    // For example, when switching away from subStep4, hide other popups.
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  const handleModalSubmit = () => {
    setIsSubmitClicked(true); // Enable Pay Now button
    setShowModal(false); // Hide modal
  };
  const handleCheckboxClick = () => {
    const newValue = !isTermsAccepted;
    setIsTermsAccepted(newValue);


  };



 


  const handlePopUp1 = () => {
    // alert()
    setIsPopupOpen1(true); // Open the popup
  };


  // Function to handle nested tab selection
  const handleNestedTabSelect = (selectedKey) => {
    setNestedKey(selectedKey);
    // Hide the popup when switching nested tabs
    setIsPopupOpen3(false); // You can modify this behavior based on your requirement
  };

  

  const handlePopUp2 = () => {
    // alert()
    initiateRazorpayPayment();
   
  };


  const updatePaymentStatus = async () => {
    try {
      // Get enrollId from localStorage
    
      // Check if registration fees are 0
      const paymentStatus = enrollData?.registrationFees === 0 ? 'FREE' : 'COMPLETED';
  
      // Set loading state if needed
      setLoading(true);
  
      // API request to update the payment status
      const response = await axios.put(
        `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryregister/editfullpaymentstatus/${enrollId}`, // Use enrollId from localStorage
        {
          fullPaymentStatus: paymentStatus, // Set payment status based on condition
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Handle successful response
      alert(`Payment status updated successfully to ${paymentStatus}!`);
      setIsPopupOpen2(true); // Open the popup
      console.log('Payment status updated:', response.data);
  
      // Optionally close the popup after successful update
      // setIsPopupOpen2(false); // Close the popup
    } catch (error) {
      console.error('Error updating payment status:', error);
      alert('An error occurred while updating payment status.');
    } finally {
      setLoading(false); // Hide the loading spinner after the API call is completed
    }
  };
  
  
  // Razorpay Integration (placed inside a function)
  const initiateRazorpayPayment = () => {
    // Check if registration fees are 0 (FREE course)
    const paymentStatus = enrollData?.registrationFees === 0 ? 'FREE' : 'COMPLETED';
  
    if (paymentStatus === 'FREE') {
      alert("This course is free. No payment required.");
  
      // Directly update payment status to 'FREE'
      updatePaymentStatus();
      return; // Exit the function to prevent Razorpay from opening
    }
  
    // Proceed with Razorpay payment if not free
    var options = {
      key: "rzp_test_rA2UalGsJwTy9v", // Test key, replace with live key for production
      key_secret: "RG7A51JvDSuFfhUzkGVbpAtn", // Test secret, replace with live secret for production
      amount: enrollData.registrationFees * 100, // Convert price to paise (1 INR = 100 paise)
      currency: "INR",
      payment_capture: true,
      name: "International Institute Of Automate",
      handler: async function (response) {
        try {
          alert("Thanks for your interest! Your payment has been successfully completed.");
  
          // Call the updatePaymentStatus function after payment completion
          await updatePaymentStatus();
  
        } catch (error) {
          console.error('Error updating payment status:', error);
          alert('Error updating payment status: ' + error.message);
        }
      },
      notes: {
        address: "International Institute Of Automate",
      },
      theme: {
        color: "#3399cc", // Customize the color of the Razorpay UI
      },
    };
  
    // Trigger Razorpay payment
    var pay = new window.Razorpay(options);
    pay.open();
  };
  
  
  // Trigger Razorpay payment
 
  

  const handleNextNesteds = () => {
    setIsPopupOpen(false)
    setSubNestedKey("subStep3"); // Change the active tab to 'subStep2'
  };
  const handleNextNesteded = () => {
    setSubNestedKey("subStep4"); // Change the active tab to 'subStep2' 
  };
 


  const handleNextNestedses = () => {
    setIsPopupOpen1(false)
    setSubNestedKey("subStep5"); // Change the active tab to 'subStep2'
  };
 
  


  const handleDownload = () => {
    const input = document.querySelector(".form-content");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // A4 dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Adjust top margin here
      const topMargin = 20; // Set your desired top margin in mm
      let heightLeft = imgHeight;
      let position = topMargin;

      // Add the image to the first page with the top margin
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= (pageHeight - topMargin);

      // Add extra pages if content is larger than one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("form-data.pdf");
    });
  };



  const handlePrintS = () => {
    const printContent = document.querySelector(".form-content");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color:rgb(143, 43, 43); }
                @media print {
                    @page { margin: 50px; }
                    body { margin: 50px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };



  // <--------------------------------------------------   OPTION 2 DOWNLOAD BUTTON ---------------------------------------------------->

  const handleDownloads = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contents");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          pdf.save("form-data.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };




  const handlePrint = () => {
    const printContent = document.querySelector(".form-contents");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
      <html>
          <head>
              <title>Print Table</title>
              <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                  @media print {
                      @page { margin: 50px; }
                      body { margin: 50px; }
                  }
              </style>
          </head>
          <body>
              <h2 style="text-align: center;">Form Details</h2>
              ${printContent.innerHTML}
          </body>
      </html>
  `);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };



  


  

  // <---------------------------------------------- STAGE 6 DOWNLOAD AND PRINT BUTTON ----------------------------------------->//



  // <---------========================================== PUNCH OUT =====================================----------------------->
  useEffect(() => {
    let timer;

    // Start the timer if punched in
    if (isPunchedIn) {
      timer = setInterval(() => {
        setTotalSeconds(prev => prev + 1); // Increment seconds data not funding in the combined in the user data not fe
      }, 1000); // Update every second
    }

    // Cleanup function to clear the timer
    return () => clearInterval(timer);
  }, [isPunchedIn]); // Effect depends on isPunchedIn data comunity and all the 

 
 

  // Convert totalSeconds to hours, minutes, and seconds 
  


  return (
    <div>
      <style>
        {`
          .tab-content {
            margin-top: 20px; /* Adjust this value to set the gap */
          }
               nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  
    background-color: none !important;
  
    
}
    /* General container styling */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Punch-status card styles */
.punch-status .punch-det h6 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.punch-status .punch-det p {
  font-size: 14px;
}

.punch-status .punch-info .punch-hours {
  text-align: center;
  margin-bottom: 10px;
}

.punch-status .punch-btn {
  width: 100%;
  font-size: 14px;
  padding: 10px;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    margin-bottom: 15px;
  }
}
.clock-background {
    border-radius: 50%;
    background-color: #efefef; /* Your desired color */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px; /* Set a width for the circle */
    height: 105px; /* Set a height for the circle */
    border: 2px solid #000; /* Add a 2px solid border, change #000 to your desired border color */
    margin-left:25px;
}


.card-title-bg {
    background-color: #e7f1ff; /* Change this to your desired background color */
    padding: 10px; /* Add some padding for spacing */
    border-radius: 5px; /* Optional: Add rounded corners */
    display: inline-block; /* Make the background fit the content */
        margin-left: 20px;
}




.custom-width {
  width: 250px; /* Adjust this value as needed */
}



.form-select {
  display: block;
  width: 100%;
  padding: none !important;
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.937rem;
  font-weight: 400; */


}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
* Bootstrap v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/


table td {
padding: 7px 33px !important;
}

table {
background-color: transparent;
width: 100%;
}
table thead th {
color: #333;
}
table td {
padding: 15px 10px;
}
p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
margin-bottom: 4px;
}


.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    background-color:black;
   width:300px;
    border: 1px solid transparent;
   border-radius:10px;
  }


  .nav-tabs .nav-link:hover {
  background-color: orange; /* Background color when hovered */
  color: black; /* Text color when hovered */
  border-color: orange; /* Optional: Add a border color */
}

/* Active tab styling */
.nav-tabs .nav-link.active {
  background-color: orange; /* Background color for active tab */
  color: black; /* Text color for active tab */
  border-color: orange; /* Optional: Border for active tab */
}

.ad-container {
  position: fixed;
  right: -55px;
  bottom: 0;
  top: 0;
  width: 324px; /* Adjust width as needed */
  overflow: hidden;
  background-color: 'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
 background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
         
        `}
      </style>

      <section 
      style={{
        maxHeight: '500px', // Set the maximum height for the section
        overflowY: 'auto', // Enable vertical scrolling
        overflowX: 'hidden', // Disable horizontal scrolling
      
        padding: '10px', // Optional: Add padding for inner content
      }}
      >
        <div className="container-fluid tabs-data" style={{}}>
          <div
            className="container boderform"
            style={{
              // backgroundColor: "#efefef",
              paddingBottom: "30px",
              borderRadius: "10px",
              marginTop: "-24px",
            }}
          >

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}

              className="mb-3"
              style={{ display: 'flex', justifyContent: 'space-evenly', width: '101.8%', marginLeft: '-11px', padding: "7px", fontSize: '20px',background: 'none',  }}
            >

              <Tab
                eventKey="registration"
              // title={<span style={{ fontWeight: '500', color: '#f58938' }}>COURSES</span>}
              >

                <Tabs
                  id="nested-tab-example"
                  activeKey={nestedKey}
                  onSelect={handleNestedTabSelect}
                  className="mb-3"
                  style={{ marginTop: '-20px', justifyContent: 'space-evenly', width: '101.8%', marginLeft: '-11px', fontSize: '20px', padding: '5px', borderBottom: 'none', background: 'none',  }}
                >
                  {/* Sub Tab 1 */}
                  <Tab eventKey="step1"

                  >
                    <Tabs
                      id="sub-nested-tab-example"
                      activeKey={subNestedKey}
                      onSelect={(k) => handleSubNestedTabSelect(k)}
                      className="mb-3"
                      style={{ marginTop: '-50px', justifyContent: 'space-evenly', background: '#65646a', width: '101.8%', marginLeft: '-11px', padding: "3px", fontSize: '20px',background: 'none',  }}
                    >
                      {/* Sub Tab under REGISTRATION */}
                      


                      

    
                      <Tab eventKey="subStep3" 
                       title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '12px',
                      }}>T & C</span>} >
                        <div>

                          <h2
                            style={{
                              textAlign: "center",
                              color: "#5B4B99",
                              paddingBottom: "50px",
                              paddingTop: "30px",
                            }}
                          >
                            Terms And Conditions
                          </h2>
                          <div className="row" style={{ paddingBottom: '25px', width: "70%", margin: "0 auto" }}>
                            <p style={{ textAlign: "justify" }}>
                              By submitting the IIA Automate course payment form, you agree to the terms and conditions
                              outlined. Payment is required in full upon registration, and refunds are only available
                              within 14 days of payment, provided course materials have not been accessed. Course access
                              is granted after successful payment and will remain available for the specified duration.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              All course materials are the intellectual property of IIA Automate and may not be reproduced
                              without permission. Participants must adhere to professional conduct during the course.
                              Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
                              right to update these terms at any time.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              Any breach of these terms and conditions may result in the immediate termination of course access
                              without refund. IIA Automate retains the right to revoke access if it is determined that the terms
                              have been violated, including but not limited to sharing login credentials or course materials
                              with unauthorized individuals. Participants are expected to complete the course independently, and
                              any form of academic dishonesty will not be tolerated.
                            </p>

                            <p style={{ textAlign: "justify" }}>
                              The course content is designed for educational purposes only, and IIA Automate does not guarantee
                              specific career outcomes or certifications upon completion. While we strive to provide accurate and
                              up-to-date information, we are not liable for any errors or omissions in the course materials. It is
                              the participant's responsibility to ensure they meet any technical or hardware requirements needed
                              to complete the course.
                            </p>


                            <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
                              <input
                                type="checkbox"
                                id="terms"
                                checked={isTermsAccepted}
                                onClick={handleModalSubmit} // Use the new handleModalSubmit function
                                required
                                style={{ display: "none" }}
                              />
                              <div
                                onClick={handleCheckboxClick}
                                style={{
                                  cursor: "pointer",
                                  width: "15px",
                                  marginLeft:'30%',
                                  height: "15px",
                                  border: "2px solid green",
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: isTermsAccepted ? "green" : "white",
                                  marginTop: "6px",
                                }}
                              >
                                {isTermsAccepted && (
                                  <FontAwesomeIcon icon={faCheckSquare} style={{ color: "white" }} />
                                )}
                              </div>
                              <label htmlFor="terms" style={{ marginLeft: "20px", cursor: "default",marginTop:'10px' }}>
                                I agree to the{" "}
                                <span
                                  style={{ color: "blue", cursor: "pointer" }}

                                >
                                  Terms and Conditions
                                </span>
                              </label>


                            </div>

                          </div>


                          <button
                            className="custom-btn "
                            onClick={handleNextNesteded}
                            disabled={!isTermsAccepted} // Disable the button if terms are not accepted

                            style={{
                              marginLeft: '50%',
                              backgroundColor: isTermsAccepted ? "#f6954e" : "lightgray", // Green if enabled, light gray if disabled
                              color: isTermsAccepted ? "white" : "black", // White text if enabled, dark gray if disabled
                              cursor: isTermsAccepted ? "pointer" : "not-allowed", // Pointer if enabled, not-allowed if disabled
                              border: "none", // Optional: remove border if you want
                              padding: "10px 20px", // Optional: padding for the button
                              borderRadius: "5px", // Optional: rounded corners
                              transition: "background-color 0.3s ease", // Smooth transition for color change
                            }}

                          >
                            Next
                          </button>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="subStep4"
                        id="nested-tab-example"
                        activeKey={nestedKey}
                        onSelect={handleNestedTabSelect}
                        title={
                          <span
                            style={{
                              fontWeight: "500",
                              
                              // height: "36px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "2px", fontSize: '12px'
                            }}
                          >
                           FULLPAY
                          </span>
                        }
                      >
                        
                        <div
      style={{
        display: "table",
        width: "50%",
        border: "1px solid #ccc",
        borderRadius: "8px",
        marginLeft: "26%",
        marginTop: "80px",
      }}
    >
      {/* Table Header */}
      <div style={{ display: "table-row", backgroundColor: "#f7f7f7" }}>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            fontWeight: "bold",
            border: "1px solid #ddd",
            backgroundColor: "#f7f7f7",
          }}
        >
          Category
        </div>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            border: "1px solid #ddd",
            backgroundColor: "#f7f7f7",
          }}
        >
          {enrollData?.course?.replace(/([a-z])([A-Z])/g, "$1 $2")  || "N/A"}
        </div>
      </div>

      {/* Table Rows */}
      <div style={{ display: "table-row" }}>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            fontWeight: "bold",
            border: "1px solid #ddd",
          }}
        >
          Program
        </div>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            border: "1px solid #ddd",
          }}
        >
          {enrollData?.program?.replace(/_/g, ' ') || "N/A"}
        </div>
      </div>

      <div style={{ display: "table-row" }}>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            fontWeight: "bold",
            border: "1px solid #ddd",
          }}
        >
          Fee amount
        </div>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            border: "1px solid #ddd",
          }}
        >
          {enrollData?.totalAmount}
        </div>
      </div>

      <div style={{ display: "table-row" }}>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            fontWeight: "bold",
            border: "1px solid #ddd",
          }}
        >
          Paid amount
        </div>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            border: "1px solid #ddd",
          }}
        >
          {enrollData?.registrationFees}
        </div>
      </div>

      <div style={{ display: "table-row" }}>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            fontWeight: "bold",
            border: "1px solid #ddd",
          }}
        >
          Pending amount
        </div>
        <div
          style={{
            display: "table-cell",
            padding: "10px",
            border: "1px solid #ddd",
          }}
        >
          {enrollData?.pendingAmount}
        </div>
      </div>
    </div>

          {/* Pay Button Section */}
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', marginRight: '0px', }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handlePopUp2}
            >
              Pay
            </button>
          </div>
                        



                        

                      </Tab>

                      <Tab eventKey="subStep5" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '12px',
                      }}>FLEXIPAY</span>} >
                         
              <div style={{ display: 'table', width: '50%', border: '1px solid #ccc', borderRadius: '8px',marginLeft:'26%',marginTop:'80px'  }}>
                {/* Table Header */}
                <div style={{ display: 'table-row', backgroundColor: '#f7f7f7' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    Category
                  </div>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    Course
                  </div>
                </div>

                {/* Table Rows */}
                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Program
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    Online Robotics
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Fee amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    10,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Paid amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    30,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Pending amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    20,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Partial amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      defaultValue="20,000"
                      style={{
                        width: '50%', // Make the input take the full width of the cell
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Remaining amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      defaultValue="20,000"
                      style={{
                        width: '50%', // Make the input take the full width of the cell
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              </div>
          



         



          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px',marginLeft:'0%' }}>
            
            <button type="button" class="btn btn-primary">
              EMI
            </button>
            <button type="button" class="btn btn-primary">
              Next
            </button>
          </div>



                      </Tab>

                     




                    </Tabs>

                    {/* Second-Level Sub Tabs */}

                  </Tab>

                  {/* Sub Tab 2 */}

                </Tabs>
              </Tab>

              {/* Parent Tab 2 */}









            </Tabs>
          </div>
        </div>
      </section>

     
    

      {/* Modal for Terms and Conditions */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>

        <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'none' }}>
          {/* Add your terms and conditions text here */}
          <p style={{ textAlign: "justify" }}>
            By submitting the IIA Automate course payment form, you agree to the terms and conditions
            outlined. Payment is required in full upon registration, and refunds are only available
            within 14 days of payment, provided course materials have not been accessed. Course access
            is granted after successful payment and will remain available for the specified duration.
          </p>
          <p style={{ textAlign: "justify" }}>
            All course materials are the intellectual property of IIA Automate and may not be reproduced
            without permission. Participants must adhere to professional conduct during the course.
            Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
            right to update these terms at any time.
          </p>
          <p style={{ textAlign: "justify" }}>
            Any breach of these terms and conditions may result in the immediate termination of course access
            without refund. IIA Automate retains the right to revoke access if it is determined that the terms
            have been violated, including but not limited to sharing login credentials or course materials
            with unauthorized individuals. Participants are expected to complete the course independently, and
            any form of academic dishonesty will not be tolerated.
          </p>

          <p style={{ textAlign: "justify" }}>
            The course content is designed for educational purposes only, and IIA Automate does not guarantee
            specific career outcomes or certifications upon completion. While we strive to provide accurate and
            up-to-date information, we are not liable for any errors or omissions in the course materials. It is
            the participant's responsibility to ensure they meet any technical or hardware requirements needed
            to complete the course.
          </p>


          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="secondary"
              onClick={handleModalSubmit}
              style={{ backgroundColor: "linear-gradient(0deg, rgb(247, 136, 55) 0%, rgb(243 166 109) 100%)" }}
            >
              Submit
            </Button>

          </div>
        </Modal.Body>

      </Modal>




      {isPopupOpen && (
        <div className="popup stage-three-pop-up" style={{ height: '50%', width: '56%', overflowY: 'auto', marginTop: '80px' }}>
          <button className="close-button1 three-pop-up-close" style={{ marginLeft: '670px' }} onClick={() => setIsPopupOpen(false)}>
            &times; {/* Close symbol */}
          </button>
          <div className="popup-content" style={{ textAlign: 'left' }}>

            <div className="container">

              {/* <hr></hr> */}


              <div className="row" style={{ fontSize: "22px" }}>
                <div></div>

                <div className="form-contents stage-three-forms" style={{
                  width: "794px", // A4 width in pixels
                  height: "923px", // A4 height in pixels
                  backgroundColor: "#ffffff", // Change to your desired background color
                  padding: "20px", // Add padding around the content
                  border: "1px solid rgb(98 86 86)", // Optional: add a border
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                  margin: "0 auto" // Center the A4 container
                }}>
                  <div className="row" style={{ fontSize: "22px" }}>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px",
                      flexWrap: "wrap",
                      paddingBottom: "16px",
                      marginTop: "20px"
                    }}>
                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                      <h3 style={{ color: "#f58938", margin: 0 }}>
                        INTERNATIONAL INSTITUTE OF AUTOMATE
                      </h3>

                      {/* <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                    </p>
                                                    <p style={{ textAlign: "right" }}>
                                                     {" "}
                                                     <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                   <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                    Info@iiautomate.com
                                                </p> */}


                    </div>
                    {/* <div >
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "right" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              {" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div> */}

                  </div>
                  {/* <br></br> */}
                  <table method="Get" style={{ paddingTop: '15px', paddingBottom: "0px", borderSpacing: '10px', textAlign: "Left" }}>
                    <tbody>
                      <tr>
                        <td>Full Name</td>
                        <td>Naresh</td>
                      </tr>
                      <tr>
                        <td>Email Id</td>
                        <td style={{ wordBreak: "break-word", }}>nareshbojja0009@gmail.com</td>
                      </tr>
                      <tr>
                        <td>Mobile Number</td>
                        <td>84774874884</td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>Male</td>
                      </tr>
                      <tr>
                        <td>Date of Birth</td>
                        <td>6-10-1998</td>
                      </tr>
                      <tr>
                        <td>Course</td>
                        <td>MCA</td>
                      </tr>
                      {/* <tr>
                                            <td>Particular</td>
                                            <td>Offline Robotics</td>
                                          </tr> */}
                      <tr>
                        <td>Mode</td>
                        <td>Online</td>
                      </tr>
                      {/* <tr>
                                            <td>Batch</td>
                                            <td>{toolbarfixture[0].batch ?? ""}</td>
                                          </tr> */}
                      <tr>
                        <td>Duration</td>
                        <td>160 HRS</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>10:00PM</td>
                      </tr>
                      <tr>
                        <td>Branch</td>
                        <td>MCA</td>
                      </tr>
                      <tr>
                        <td>Registration fee</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>Paid</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <button type="button" class="btn btn-primary" onClick={handleDownloads}>
                      Download
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handlePrint}>
                      Print
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handleNextNesteds}>
                      Next
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}


      {isPopupOpen1 && (
        <div className="popup stage-three-pop-up" style={{ height: '50%', width: '56%', overflowY: 'auto', marginTop: '80px' }}>
          <button className="close-button1 three-pop-up-close" style={{ marginLeft: '670px' }} onClick={() => setIsPopupOpen1(false)}>
            &times; {/* Close symbol */}
          </button>
          <div className="popup-content" style={{ textAlign: 'left' }}>

            <div className="container">

              {/* <hr></hr> */}


              <div className="row" style={{ fontSize: "22px" }}>
                <div></div>

                <div className="form-contents stage-three-forms" style={{
                  width: "794px", // A4 width in pixels
                  height: "923px", // A4 height in pixels
                  backgroundColor: "#ffffff", // Change to your desired background color
                  padding: "20px", // Add padding around the content
                  border: "1px solid rgb(98 86 86)", // Optional: add a border
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                  margin: "0 auto" // Center the A4 container
                }}>
                  <div className="row" style={{ fontSize: "22px" }}>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px",
                      flexWrap: "wrap",
                      paddingBottom: "16px",
                      marginTop: "20px"
                    }}>
                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                      <h3 style={{ color: "#f58938", margin: 0 }}>
                        INTERNATIONAL INSTITUTE OF AUTOMATE
                      </h3>

                      {/* <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                    </p>
                                                    <p style={{ textAlign: "right" }}>
                                                     {" "}
                                                     <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                   <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                    Info@iiautomate.com
                                                </p> */}


                    </div>
                    {/* <div >
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "right" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              {" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div> */}

                  </div>
                  {/* <br></br> */}
                  <table method="Get" style={{ paddingTop: '15px', paddingBottom: "15PX", textAlign: "Left" }}>

                    <tr>
                      <td>Full Name</td>
                      <td>Naresh</td>
                    </tr>
                    <tr>
                      <td>Email Id</td>
                      <td style={{ wordBreak: "break-word" }}>nareshbojja0009@gmail.com</td>
                    </tr>
                    <tr>
                      <td>Mobile Number</td>
                      <td>8688037081</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>Male</td>
                    </tr>

                    <tr>
                      <td>Date of Birth</td>
                      <td>10-05-2000</td>
                    </tr>


                    <tr>
                      <td>Category</td>
                      <td>Courses</td>
                    </tr>
                    <tr>
                      <td>Particular</td>
                      <td>Offline Rootics</td>
                    </tr>
                    <tr>
                      <td>Mode</td>
                      <td>
                        Online
                      </td>
                    </tr>

                    <tr>
                      <td>Duration</td>
                      <td>160 HRS</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td>10-05-2000</td>
                    </tr>
                    <tr>
                      <td>Time</td>
                      <td>9AM-6PM</td>
                    </tr>
                    <tr>
                      <td>Start Date</td>
                      <td>10-06-2024</td>
                    </tr>


                    <tr>
                      <td>End Date</td>
                      <td>18-09-2024</td>
                    </tr>
                    <tr>
                      <td>Batch</td>
                      <td>45</td>
                    </tr>


                    <tr>
                      <td>Status</td>
                      <td>Paid</td>
                    </tr>




                  </table>

                </div>
                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <button type="button" class="btn btn-primary" onClick={handleDownloads}>
                      Download
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handlePrint}>
                      Print
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handleNextNestedses}>
                      Next
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}

     

      {isPopupOpen4 &&  (
        <div style={{ padding: "20px",  }} className="stagefour-data">
          <div >
            <div style={{ padding: '20px', marginLeft: '10px', backgroundColor: 'white', borderRadius: '8px' }}>
              <div style={{ display: 'table', width: '100%', marginRight: '100px', border: '1px solid #ccc', borderRadius: '8px',marginLeft:'20%' }}>
                {/* Table Header */}
                <div style={{ display: 'table-row', backgroundColor: '#f7f7f7' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    Category
                  </div>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    Course
                  </div>
                </div>

                {/* Table Rows */}
                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Particular
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    Online Robotics
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Fee amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    10,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Paid amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    30,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Pending amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    20,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Partial amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      defaultValue="20,000"
                      style={{
                        width: '50%', // Make the input take the full width of the cell
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Remaining amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      defaultValue="20,000"
                      style={{
                        width: '50%', // Make the input take the full width of the cell
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>



          </div>




          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', marginRight: '0px',marginLeft:'30%' }}>
            
            <button type="button" class="btn btn-primary">
              EMI
            </button>
            <button type="button" class="btn btn-primary">
              Next
            </button>
          </div>
        </div>




      )}


      {isPopupOpen5 && (


        <div style={{ padding: "20px", }}>

          <table method="Get" style={{
            paddingTop: '15px',
            paddingBottom: '15px',
            fontSize: '22px',
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            borderCollapse: 'collapse',
            textAlign: 'left',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}>

            <tbody>
              <tr style={{}}>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>Total Amount</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>30,000</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>Number of EMI's</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>10</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>Monthly EMI Cost</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>3000</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>EMI Starting Month</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>10-05-2024</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>EMI End Month</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>18-09-2024</td>
              </tr>
            </tbody>
          </table>
          <br></br>

          <div style={{ display: 'flex', marginLeft: "20px", justifyContent: 'center', }}>
            <button type="button" class="btn btn-primary" onClick={handlePopUp1}>
              Pay
            </button>
          </div>

        </div>




      )}






    </div>


  )
}

export default EnrollForm