import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation,matchPath } from "react-router-dom";
import SignUpEmp from "./Component/SignUp";
import Login from "./Component/Login";
import Logout from "./Component/Logout";
import ForgotPassword from "./Component/ForgotPassword";
import Home from "./Component/Home";
import NavBar from "./Component/NavBar";
import SideNavBar from "./Component/SideNavBar";
import TopNavBar from "./Component/TopNavBar";
import ControllersTableData from "./Component/ControllersTableData";
import Layout1 from "./Component/Layout1";
import Layout2 from "./Component/Layout2";
import Layout3 from "./Component/Layout3";
import DashBoard from "./Component/DashBoard";
import Onboarding from "./Component/Onboarding";
import Cards from "./Component/Cards";
import Profile from "./Component/Profile";
import CourseCards from "./Component/CoursesCards";
import HrExcutive from "./Component/HrExcutive";
import HrManger from "./Component/HrManger";
import Summary from "./Component/Summary";

import DataScience from "./Component/DataScience";
import Enroll from "./Component/Enroll";

import CoursesEnroll from "./Component/CoursesEnroll";
import WebDevelopment from "./Component/WebDevelopment";
import WebinarEnroll from "./Component/WebinarEnroll";
import WebinarCards from "./Component/WebinarCards";
import FrontEnd from "./Component/FrontEnd";

import Testing from "./Component/Testing";
import DFS from "./Component/DFS";
import Virtual from "./Component/Virtual";
import DataEngineer from "./Component/DataEngineer";
import DataScience1 from "./Component/DataScience1";
import DataAnlyst from "./Component/DataAnlyst";
import BackEnd from "./Component/Backend";
import EnrollTopNavBar from "./Component/EnrollTopNavBar";
import Webinar from "./Component/Webinars";
import WorkShop from "./Component/Workshop";
import Certification from "./Component/Certification";
import InternShip from "./Component/Internship";
import Advertisement from "./Component/Adverstisment";
import AdvertisementShow from "./Component/AdverstimentDes";
import Registered from "./Component/RegisteredTable";
import { Table } from "react-bootstrap";
import TableStages from "./Component/Tables";
import Registration from "./Component/Registration";
import EnrollForm from "./Component/sendFormDetails";









function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   const authStatus = localStorage.getItem('isAuthenticated');
  //   if (authStatus) {
  //     setIsAuthenticated(JSON.parse(authStatus));
  //   }
  // }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
  };

  function AppContent() {
    const location = useLocation();
    const hideNavPages = ["/","/login", "/forgot", "/signup",'/enrollform'];
    const isEnrollPage = matchPath("/enroll/:userId", location.pathname);
    const isTcPage = matchPath("/enrollform/:enrollId", location.pathname);
    return (
      <>
       {!hideNavPages.includes(location.pathname) && !isEnrollPage && !isTcPage && (
        <>
         
          <NavBar />
          <SideNavBar />
        
        </>
      )}

        {/* {
        location.pathname !== "/home"  && location.pathname !== "/enroll/:userId" && location.pathname !== "/enrollnav" && 
        location.pathname !== "/cntrollers-table-data" &&  location.pathname !== "/enroll1"  &&  location.pathname !== "/ITVWroks" && location.pathname !== "/layout1" &&  location.pathname !== "/ITVW" && location.pathname !== "/enrollform" &&  location.pathname !== "/ITIn" &&  location.pathname !== "/ITCe"  &&  location.pathname !== "/advesitment" &&   location.pathname !== "/" && location.pathname !== "/login" && location.pathname !== "/forgot" &&  location.pathname !== "/signup"  &&   location.pathname !== "/registertable" && location.pathname !== "/toolTable" && location.pathname !== "/workshoptable" && location.pathname !== "/certificationtable" && location.pathname !== "/internshiptable" && location.pathname !== "/virtualTable" && location.pathname !== "/dfsTable" && location.pathname !== "/coursescard"  && location.pathname !== "/java" &&   location.pathname !== "/table"   && <TopNavBar />} */}
      
        
        <Routes>
      
        <Route path="/registertable" element={<Registered />} />
        <Route path="/enrollform/:enrollId" element={<EnrollForm />} />
        <Route path="/Registration" element={<Registration  />} />
        <Route path="/table" element={<TableStages  />} />
        <Route path="/backend" element={<BackEnd />} />
        <Route path="/advesitment" element={<Advertisement />} />
        <Route path="/advesitmentsh" element={<AdvertisementShow />} />
        <Route path="/webinars" element={<Webinar />} />
        <Route path="/workshop" element={<WorkShop />} />
        <Route path="/certification" element={<Certification />} />
        <Route path="/Internship" element={<InternShip />} />
        <Route path="/datanaly" element={<DataAnlyst />} />
        <Route path="/datascience1" element={<DataScience1 />} />
        <Route path="/dataengineer" element={<DataEngineer />} />
        <Route path="/virtual" element={<Virtual />} />
        <Route path="/enrollnav" element={<EnrollTopNavBar />} />
        <Route path="/testing" element={<Testing />} />
        <Route path="/" element={<Login/>}/>
        <Route path="/dfs" element={<DFS />} />
        <Route path="/frontend" element={<FrontEnd />} />
         <Route path="/tool&fixturedesign" element={<Layout2 />} />
          <Route path="/signup" element={<SignUpEmp />} />
          <Route path="/login" element={<Login/>}/>
          <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/topnavbar" element={<TopNavBar />} />
          <Route path="/coursescard" element={<CourseCards />} />
          <Route path="/home" element={<Home />} />
          <Route path="/webinar" element={<WebinarCards />} />
          <Route path="/datascience" element={<DataScience />} />
          <Route path="/webdevelopment" element={<WebDevelopment />} />
          <Route path="/tiles" element={<Cards />} />
          <Route path="/layout1" element={<Layout1 />} />
          <Route path="/cntrollers-table-data" element={<ControllersTableData />} />
          <Route path="/java" element={<Layout3 />} />
          <Route path="/dash-board" element={< DashBoard />} />
          <Route path="/Onboarding" element={< Onboarding />} />
          <Route path="/profile" element={< Profile />} />
          <Route path="/hrexcutive" element={< HrExcutive />} />
          <Route path="/hrmanger" element={< HrManger />} /> 
          <Route path="/summary" element={< Summary />} />
          <Route path="/enroll/:userId" element={<  Enroll />} />
          <Route path="/enroll1" element={<  Enroll />} />
          <Route path="/courses-enroll" element={<  CoursesEnroll />} />
          <Route path="/webinar-enroll" element={<  WebinarEnroll />} />
          {/* <Route path="*" element={<div>Page Not Found</div>} /> */}
        </Routes>
      </>
    );
  }

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
