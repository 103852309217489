import React from "react";
import ReactDOM from "react-dom";

const Dashboard = () => {
  const cardData = [
    { title: "Total Sales", value: "$5,300" },
    { title: "New Users", value: "120" },
    { title: "Active Sessions", value: "75" },
    { title: "Revenue", value: "$12,450" },
  ];

  return (
    <div style={styles.dashboard}>
      {cardData.map((data, index) => (
        <div key={index} style={styles.card}>
          <h3 style={styles.title}>{data.title}</h3>
          <p style={styles.value}>{data.value}</p>
        </div>
      ))}
    </div>
  );
};

// Internal CSS for the dashboard 
const styles = {
  dashboard: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "20px",
    justifyContent: "center",
    backgroundColor: "#f4f4f9",
  },
  card: {
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    borderRadius: "8px",
    width: "200px",
    textAlign: "center",
  },
  title: {
    margin: "0 0 10px",
    fontSize: "18px",
    color: "#333",
  },
  value: {
    fontSize: "18px",
    color: "#333",
  },
};

export default Dashboard;