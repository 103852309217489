import React, { useState } from 'react';
import logo from "../images/Logo-headIIA.png";
import { useNavigate } from 'react-router-dom';
import backgroundImage from "../images/image1.png";

const ForgotEmp = ({ onLogin }) => {
  const [loginObj, setLoginObj] = useState({ emailId: '', password: '' });
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginObj({ ...loginObj, [name]: value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!loginObj.emailId || !loginObj.password) {
      setError('Email and password are required.');
      return;
    }
    setError('');
    console.log('Login successful:', loginObj);
  };

  const [loginData, setLoginData] = useState({
    emailId: "",
    password: "",
  });

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    // Make sure all required fields are filled
    if (!loginData.emailId || !loginData.password) {
      alert("Please enter both identifier and password.");
      return;
    }

    try {
      // API call to login
      const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/superadmin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      const result = await response.json();

      if (response.ok) {
        alert("Login successful!");
        const userId = result.userId;
        const token = result.token;

        // Store userId and token in localStorage
        localStorage.setItem("userId", userId);
        localStorage.setItem("userToken", token);

        onLogin(); // Call the login handler
        // Use useNavigate() if using react-router-dom v6+
        // const navigate = useNavigate();
        navigate("/"); // Redirect to home page
      } else {
        alert(result.message || "Login failed, please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Something went wrong, please try again later.");
    }
  };


  return (
    <div className="login-body2"  style={{
      backgroundImage: `url(${backgroundImage})`, // Use the imported background image
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    }}>
      <div className="login-container2">
        {/* Login Form Section */}
        <div className="form-section2">
          <div className="form-content">
            <img src={logo}  alt="ACTUS Logo" className="logo" />
            <h5>Forgot Password</h5>
            {/* <div className="form-group">
 
  <select
    name="options"
    
    required
    className="custom-dropdown"
  >
    <option value="" disabled>Select an option</option>
    <option value="option1">Client</option>
    <option value="option2">Vendor</option>
    <option value="option3">Employee</option>
    <option value="option3">User</option>
  </select>
</div> */}

            <form onSubmit={handleLogin}>
              <div className="form-group">
                {/* <label>Email ID <span style={{ color: 'red' }}>*</span></label> */}
                <input
                  type="email"
                  name="emailId"
                  value={loginObj.emailId}
                  onChange={handleChange}
                  required
                  placeholder="Enter Email ID/Mobile No  *"
                  className="form-control"
                />
              </div>

              
             
              <div className="form-footer">
                {/* <a href="/forgot-password" className="forgot-password">
                  Forgot Password?
                </a> */}
                <button type="submit" className="btn btn-primary">
                  Send Mail
                </button>
              </div>
            </form>
            {/* <p className="signup-text">
              Don't have an account? <a href="/signup" className="signup-link">Signup</a>
            </p> */}
            {/* {error && <span className="error-text">{error}</span>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotEmp;
