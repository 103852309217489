
import { Category } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import Advertisecode from "./Advertisecode";
import { CloudUpload, CloudDownload, CheckCircleOutline, RemoveRedEye, Edit } from '@mui/icons-material';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    IconButton,
} from '@mui/material';


const HrManger = () => {

    // const datasource = [
    //     {
    //         SNo: '1',
    //         Category: "",
    //         Organization: "",
    //         Name: 'Kumar Ravi',
    //         contactNumber: '9876543210',
    //         emailId: 'rcreddy2222@gmail.com',
    //         Role: '',
    //         Action: '',
    //         Remarks: '',

    //     },

    // ];



    const [isJobDesignationOpen, setIsJobDesignationOpen] = useState(false);
    const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(false);
    const [isSalaryInfoOpen, setIsSalaryInfoOpen] = useState(false);
    const [isJoiningInfoOpen, setIsJoiningInfoOpen] = useState(false);
    const [isWorkLocationOpen, setIsWorkLocationOpen] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filter, setFilter] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen1, setIsPopupOpen1] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedSubNavId, setSelectedSubNavId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { navId: routeNavId } = useParams(); // Get navId from route parameters
    const [navId, setNavId] = useState(() => (routeNavId ? Number(routeNavId) : undefined)); // Initialize navId with routeNavId
    const [isSelected, setIsSelected] = useState(false);
    const [directoryNavId, setDirectoryNavId] = useState(null);
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        organisation: "",
        name: "",
        email: "",
        phoneNumber: "",
        role: "",
        categories: ""

    });

    const [selectedRole, setSelectedRole] = useState("");
    const [isDataVisible, setIsDataVisible] = useState(true); // To control visibility of data

    const [selectedJobDesignation, setSelectedJobDesignation] = useState("");



    const handleNext = () => {
        navigate('/summary'); // Adjust the path as necessary for your application
    };

    const handleJobDesignationChange = (e) => {
        setSelectedJobDesignation(e.target.value);
    };

    const staticData = [
        {
            newId: 1,
            candidateId: 'C001',
            jobTitle: 'Client',
            designation: 'IIA',
            candidateName: 'John',
            contactNumber: '9876543210',
            emailId: 'test123@gmail.com',
            serviceCommitment: 2,
            serviceBreakAmount: '2,00,000',
            ctc: '2,40,000',
            status: 'Pending',
        },

    ];


    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/roles');
                const data = await response.json();
                setRoles(data); // Assuming API returns an array of role strings
                setLoading(false);
            } catch (error) {
                console.error("Error fetching roles:", error);
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);


    const handleRoleChange = (e) => {
        setUserDetails({ ...userDetails, role: e.target.value });
    };


    const handleRoleChange1 = (e) => {
        setSelectedRowData({ ...selectedRowData, role: e.target.value });
    };



    const handleCategoryChange = (e) => {
        setUserDetails({ ...userDetails, category: e.target.value });
    };


    const handleCategoryChange1 = (e) => {
        setSelectedRowData({ ...selectedRowData, category: e.target.value });
    };



    const [userDetailss, setUserDetailss] = useState({
        remarks: '',
        date: null, // Initialize date state
    });

    const handleDateChange = (date) => {
        setUserDetails({ ...userDetails, date: date });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/userData/getall'); // Replace with your API URL
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDatasource(data); // Set fetched data to the datasource state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/categoryies');
                const data = await response.json();
                setCategories(data); // Assuming API returns an array of strings
                setLoading(false);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);


    const AddRecord = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page
        try {
            const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/userData/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organisation: userDetails.organisation,
                    name: userDetails.name,
                    phoneNumber: userDetails.phoneNumber,
                    email: userDetails.emailId,
                    password: userDetails.password,
                    category: userDetails.categories,
                }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to register. Please try again.');
            }

            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                console.log('Registration successful:', data);
                navigate('/layout1')
                window.location.reload();
                alert('Data Successfully Saved');
                setIsPopupOpen(false);  // Close the popup

            } else {
                const textResponse = await response.text();
                console.log('Response:', textResponse);
                throw new Error(textResponse);
            }
        } catch (error) {
            console.error('Error:', error);
            // setError(error.message || 'Something went wrong, please try again.');
        }
    };



    const updateRecord = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/userData/${selectedRowData.newId}`, {
                method: 'PUT', // Change to PUT for updating
                headers: {
                    'Content-Type': 'application/json',
                },



                body: JSON.stringify(selectedRowData),
            });



            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to update. Please try again.');
            }

            const updatedData = await response.json();
            console.log('Update successful:', updatedData);
            window.location.reload();
            alert('Record updated successfully');
            setIsPopupOpen1(false);  // Close the popup
            // Optionally navigate or refresh data here

        } catch (error) {
            console.error('Error:', error);
            alert(error.message || 'Something went wrong, please try again.'); // Display error message
        }
    };




    const handlePopup = () => {
        setIsPopupOpen(true);
    };

    const handlePopup1 = (rowData) => {
        // alert(rowData)
        setSelectedRowData(rowData);
        setIsPopupOpen1(true);
    };


    const exportToExcel = () => {
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(datasource);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        // Generate a binary Excel file
        XLSX.writeFile(workbook, 'exported_data.xlsx');
    };



    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row.candidateId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.candidateId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = datasource.map((row) => row.candidateId);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };


    return (



        <Card  className='layout-card-data'>
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '2%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        {/* <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white' }}>
                            + Add
                        </Button> */}
                    </>



                }
            />
            <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < 2}
                                    checked={2 > 0 && selected.length === 2}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>S.No</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Job Title/Designation</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Candidate Id</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Candidate Name</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Contact Number</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Email Id</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Service Commitment</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Service Break Amount</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>CTC (INR)</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staticData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                role="checkbox"
                                tabIndex={-1}
                                key={row.newId}
                            >
                                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                    <Checkbox
                                        checked={selected.indexOf(row.candidateId) !== -1}
                                        onChange={() => handleClick(null, row)}
                                    />
                                </TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.jobTitle}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateId}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateName}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.contactNumber}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceCommitment}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceBreakAmount}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.ctc}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                <TableCell align="center" style={{ minWidth: '120px' }}>
                                    <IconButton onClick={() => console.log('View', row.candidateId)}>
                                        <RemoveRedEye style={{ color: 'green' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handlePopup1(row)}>
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading categories...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.categories}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="" disabled>Job Designation</option>
                                        {categories.map((category, index) => (
                                            <option key={index} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Candidate Name"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Number"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="tel" // Changed to 'tel' for better contact number validation
                                    className="form-control"
                                    placeholder="Email Id"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Service Commitment"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Service Break Amount"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="CTC(INR)"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>

                            <div className="form-group">

                                <select
                                    className="form-control"
                                    placeholderText="work location"
                                    value={userDetails.ctc} // Controlled input
                                    onChange={(e) => setUserDetails({ ...userDetails, ctc: e.target.value })} // Handle input change
                                    required
                                >
                                    <option value="" >Work location</option>
                                    <option value="500000">Hyderabad</option>
                                    <option value="600000">Bangloore</option>
                                    <option value="700000">Pune</option>
                                    <option value="800000">Chennai</option>

                                </select>
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && (
                <div className="modal-overlay">
                    <div className="modal-content1">
                        <div className="header-row">
                            <div className="serial-number">S No-1</div> {/* Static S No */}
                            <button className="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            <div className="form-group">
                                <select className="form-control" required defaultValue="">
                                    <option value="" disabled>Job Designation</option>
                                    <option value="Category1">Junior Excutive</option> {/* Static Category Option */}
                                    <option value="Category2">Senior Excutive </option>
                                    <option value="Category3">Associative Engineering</option>
                                    <option value="Category3">Associative Excutive</option>
                                    <option value="Category3">Senior Excutive</option>
                                    <option value="Category3">Team Lead</option>
                                    <option value="Category3">Project Manger</option>
                                    <option value="Category3">Program Manger</option>
                                    <option value="Category3">Ceo</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value="John Doe" // Static Organization
                                    placeholder="Jhon"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value="9876543210" // Static Name
                                    placeholder="Contact Number"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    value="test123@gmail.com" // Static Contact Number
                                    placeholder="email"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    value="2" // Static Email
                                    placeholder="Service Comitment"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    value="2,00,000" // Static Contact Number
                                    placeholder="Service Break Amount"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    value="2,40,000" // Static Email
                                    placeholder="CTC"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    value="10/10/2023" // Static Email
                                    placeholder="Joning Date"
                                    required
                                    onChange={(e) => { }}
                                />
                            </div>
                            <div className="form-group">
                                <select className="form-control" required defaultValue="">
                                    <option value="" disabled>Work location</option>
                                    <option value="Role1">Hyderabad</option> {/* Static Role Option */}
                                    <option value="Role2">Bangloore</option>
                                    <option value="Role3">Chennai</option>
                                    <option value="Role3">Pune</option>
                                </select>
                            </div>

                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
                                <button type="submit" className="btn btn-save">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            <div className="ad-container">
                <div className="ad-content">
                    <div className="ad-item" style={{marginRight:'50px'}}>Advertisement 1</div>
                    <div className="ad-item" style={{marginRight:'50px'}}>Advertisement 2</div>
                    <div className="ad-item" style={{marginRight:'50px'}}>Advertisement 3</div>
                    {/* Add more advertisements as needed */}
                </div>
            </div>

            <style>
                {`
   
        .ad-container {
  position: fixed;
  right: -46px;
  bottom: 0;
  top: 0;
  width: 384px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
 background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

            </style>


            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '20px',marginRight:'30px' }}>
                {/* Other content can go here */}

                <Button
                    variant="contained" // Use 'contained' for a solid button style
                    onClick={handleNext}
                    style={{ backgroundColor: 'blue', color: 'white' }}
                >
                    Next
                </Button>
            </div>
            <Advertisecode />
        </Card>




    )


}
export default HrManger;