// src/Component/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Call the onLogout function to clear authentication
    onLogout();
    // Navigate to the Login page
    navigate("/login");
  }, [onLogout, navigate]);

  return null; // This component does not render anything
};

export default Logout;
