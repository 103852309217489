
import { Category } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Advertisecode from "./Advertisecode";
import DatePicker from 'react-datepicker';
import uploadIcon from '../images/edit-icon-removebg-preview.png';
import posterImages from '../images/upload-icon.PNG';
import { CloudUpload, CloudDownload, CheckCircleOutline, RemoveRedEye, Edit } from '@mui/icons-material';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    IconButton,
} from '@mui/material';


const Profile = () => {

    // const datasource = [
    //     {
    //         SNo: '1',
    //         Category: "",
    //         Organization: "",
    //         Name: 'Kumar Ravi',
    //         contactNumber: '9876543210',
    //         emailId: 'rcreddy2222@gmail.com',
    //         Role: '',
    //         Action: '',
    //         Remarks: '',

    //     },

    // ];
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');

    const [activeTab, setActiveTab] = useState(0);
    const [isJobDesignationOpen, setIsJobDesignationOpen] = useState(false);
    const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(false);
    const [isPersonalInfoOpens, setIsPersonalInfoOpens] = useState(false);
    const [isPersonalInfoOpend, setIsPersonalInfoOpend] = useState(false);
    const [isSalaryInfoOpen, setIsSalaryInfoOpen] = useState(false);
    const [isJoiningInfoOpen, setIsJoiningInfoOpen] = useState(false);
    const [isWorkLocationOpen, setIsWorkLocationOpen] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filter, setFilter] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen1, setIsPopupOpen1] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedSubNavId, setSelectedSubNavId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { navId: routeNavId } = useParams(); // Get navId from route parameters
    const [navId, setNavId] = useState(() => (routeNavId ? Number(routeNavId) : undefined)); // Initialize navId with routeNavId
    const [isSelected, setIsSelected] = useState(false);
    const [directoryNavId, setDirectoryNavId] = useState(null);
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        organisation: "",
        name: "",
        email: "",
        phoneNumber: "",
        role: "",
        categories: ""

    });

    const [selectedRole, setSelectedRole] = useState("");
    const [isDataVisible, setIsDataVisible] = useState(true); // To control visibility of data

    const [selectedJobDesignation, setSelectedJobDesignation] = useState("");

    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);

    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [posterImage, setPosterImage] = useState(null);
    const goToNextTab = () => {
        setCurrentTab((prevTab) => prevTab + 1);
    };



    // Step 2: Add handler function for department selection
    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
    };
    const handleNext = () => {
        navigate('/hrexcutive'); // Adjust the path as necessary for your application
    };
    const handleJobDesignationChange = (e) => {
        setSelectedJobDesignation(e.target.value);
    };

    const staticData = [
        {
            newId: 1,
            candidateId: 'C001',
            jobTitle: 'Jhon',
            designation: 'Father',
            candidateName: '22',
            contactNumber: '18-04-2024',
            emailId: '9876543210',
            serviceCommitment: '500124',
            serviceBreakAmount: 'Hyderabad',
            // ctc: '2,40,000',
            // status: 'Pending',
        },

    ];
    const staticDatas = [
        {
            newId: 1,
            candidateId: 'B.Tech',
            jobTitle: 'Regular',
            designation: 'Mechanical',
            candidateName: 'Mechanical',
            contactNumber: ' 15-09-2021',
            emailId: ' 18-04-2024',
            serviceCommitment: ' JNTU',
            serviceBreakAmount: ' 9876543210',
            ctc: ' abcd@gmail.com',
            status: ' 90%',
        },


    ];
    const staticDatass = [
        {
            newId: 1,
            candidateId: 'Software',
            jobTitle: 'Infosys',
            designation: 'Mechanical',
            candidateName: '01-02-2023',
            contactNumber: ' 15-08-2024',
            emailId: ' 18-04-2024',
            serviceCommitment: ' Jhon',
            serviceBreakAmount: ' Yes',
            ctc: '2,40,000',
            status: ' Yes',
        },


    ];

    const staticDatadd = [
        {
            newId: 1,
            candidateId: 'Pending',
            jobTitle: 'Personal',
            designation: 'Mechanical',
            candidateName: 'Nothing',


        },


    ];



    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/enum/roles');
                const data = await response.json();
                setRoles(data); // Assuming API returns an array of role strings
                setLoading(false);
            } catch (error) {
                console.error("Error fetching roles:", error);
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);


    const handleRoleChange = (e) => {
        setUserDetails({ ...userDetails, role: e.target.value });
    };


    const handleRoleChange1 = (e) => {
        setSelectedRowData({ ...selectedRowData, role: e.target.value });
    };



    const handleCategoryChange = (e) => {
        setUserDetails({ ...userDetails, category: e.target.value });
    };


    const handleCategoryChange1 = (e) => {
        setSelectedRowData({ ...selectedRowData, category: e.target.value });
    };



    const [userDetailss, setUserDetailss] = useState({
        remarks: '',
        date: null, // Initialize date state
    });

    const handleDateChange = (date) => {
        setUserDetails({ ...userDetails, date: date });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/userData/getall'); // Replace with your API URL
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDatasource(data); // Set fetched data to the datasource state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/enum/categoryies');
                const data = await response.json();
                setCategories(data); // Assuming API returns an array of strings
                setLoading(false);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);


    const AddRecord = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page
        try {
            const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/userData/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organisation: userDetails.organisation,
                    name: userDetails.name,
                    phoneNumber: userDetails.phoneNumber,
                    email: userDetails.emailId,
                    password: userDetails.password,
                    category: userDetails.categories,
                }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to register. Please try again.');
            }

            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                console.log('Registration successful:', data);
                navigate('/layout1')
                window.location.reload();
                alert('Data Successfully Saved');
                setIsPopupOpen(false);  // Close the popup

            } else {
                const textResponse = await response.text();
                console.log('Response:', textResponse);
                throw new Error(textResponse);
            }
        } catch (error) {
            console.error('Error:', error);
            // setError(error.message || 'Something went wrong, please try again.');
        }
    };



    const updateRecord = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const response = await fetch(`http://10.10.20.9:7071/IIA-Website-1.0.0/userData/${selectedRowData.newId}`, {
                method: 'PUT', // Change to PUT for updating
                headers: {
                    'Content-Type': 'application/json',
                },



                body: JSON.stringify(selectedRowData),
            });



            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to update. Please try again.');
            }

            const updatedData = await response.json();
            console.log('Update successful:', updatedData);
            window.location.reload();
            alert('Record updated successfully');
            setIsPopupOpen1(false);  // Close the popup
            // Optionally navigate or refresh data here

        } catch (error) {
            console.error('Error:', error);
            alert(error.message || 'Something went wrong, please try again.'); // Display error message
        }
    };




    const handlePopup = () => {
        setIsPopupOpen(true);
    };

    const handlePopup1 = (rowData) => {
        // alert(rowData)
        setSelectedRowData(rowData);
        setIsPopupOpen1(true);
    };


    const exportToExcel = () => {
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(datasource);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        // Generate a binary Excel file
        XLSX.writeFile(workbook, 'exported_data.xlsx');
    };



    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row.candidateId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.candidateId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = datasource.map((row) => row.candidateId);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };
    const handleNextTab = () => {
        setActiveTab((prevTab) => (prevTab + 1) % tabs.length);
    };

    const handlePreviousTab = () => {
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
        }
    };


    const handleImageUpload = (event) => {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPosterImage(reader.result); // Set the base64 encoded image as posterImage
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };


    const tabs = [
        {
            title: "Personal Details",
            content: (
                <div>
                    {/* Job Designation Section */}
                    <div style={{ marginBottom: '20px' }}>
                        <button
                            className="card-button"
                            onClick={() => setIsJobDesignationOpen(!isJobDesignationOpen)}
                            style={{
                                display: 'block',
                                width: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                marginBottom: '15px',
                                textAlign: 'left',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {isJobDesignationOpen ? "Personal Details" : "Personal Details"}
                        </button>
                        {isJobDesignationOpen && (
                            <div className="form-group" style={{ marginBottom: '15px' }}>
                                {/* Image Upload Section */}
                                <div style={{ marginTop: '15px' }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        style={{
                                            display: 'none', // Hide the default file input
                                        }}
                                        id="imageUploadInput"
                                    />
                                    <button
                                        onClick={() => document.getElementById('imageUploadInput').click()} // Trigger the file input when clicked
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            backgroundColor: '#f9f9f9',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            marginBottom: '10px', // Space between button and image preview
                                        }}
                                    >
                                        {posterImage && (
                                            <div style={{ marginTop: '10px', width: '100px', height: '100px', overflow: 'hidden', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img
                                                    src={posterImage}
                                                    alt="Preview"
                                                    style={{
                                                        width: '100%', // Ensure it covers the container
                                                        height: '100%', // Ensure it covers the container
                                                        borderRadius: '50%', // Make it circular
                                                        objectFit: 'cover', // Maintain aspect ratio
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <img
                                            src={uploadIcon}
                                            style={{ width: '20px', height: '20px', marginBottom: '5px', marginTop: '-19px', marginLeft: '46px' }} // Adjusted margin to space above the icon
                                            alt="Upload Icon"
                                        />
                                    </button>
                                </div>

                                {/* Input Fields for Name Side by Side */}
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            width: '500px'
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Middle Name"
                                        value={middleName}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Father's Name "
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            width: '500px'
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Mother's Name"
                                        value={middleName}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Date of Birth"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <select
                                        value={firstName} // You may want to rename `firstName` to something more appropriate like `gender`
                                        onChange={(e) => setFirstName(e.target.value)} // Consider renaming this function to `setGender`
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            width: '500px'
                                        }}
                                    >
                                        <option value="" disabled>Select Gender</option> {/* Default option */}
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>

                                    <select
                                        value={firstName} // You may want to rename `firstName` to something more appropriate like `gender`
                                        onChange={(e) => setFirstName(e.target.value)} // Consider renaming this function to `setGender`
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            width: '200px'
                                        }}
                                    >
                                        <option value="" disabled>Blood Group</option> {/* Default option */}
                                        <option value="male">A+</option>
                                        <option value="female">A-</option>
                                        <option value="male">B+</option>
                                        <option value="female">B-</option>
                                        <option value="male">AB+</option>
                                        <option value="female">AB-</option>
                                        <option value="male">O+</option>
                                        <option value="female">O-</option>
                                    </select>
                                    <select
                                        value={firstName} // You may want to rename `firstName` to something more appropriate like `gender`
                                        onChange={(e) => setFirstName(e.target.value)} // Consider renaming this function to `setGender`
                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            width: '200px'
                                        }}
                                    >
                                        <option value="" disabled>Marital Status</option> {/* Default option */}
                                        <option value="male">Single</option>
                                        <option value="female">Married</option>
                                        <option value="male">Divorced</option>
                                        <option value="female">widowed</option>
                                        <option value="male">AB+</option>

                                    </select>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Personal Mail Id"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            width: '500px'
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Mobile Number"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Alternative Number"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                            </div>
                        )}
                    </div>


                    {/* Personal Information Section */}
                    <div>
                        <button
                            onClick={() => setIsPersonalInfoOpen(!isPersonalInfoOpen)}
                            style={{
                                display: 'block',
                                width: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                marginBottom: '15px',
                                textAlign: 'left',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {isPersonalInfoOpen ? "Address Details" : "Address Details"}
                        </button>
                        {isPersonalInfoOpen && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Country"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="State"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="city"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="House No"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Area"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Landmark"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="PinCode"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />


                                </div>
                            </>
                        )}
                    </div>

                    {/* Salary Information Section */}
                    <div>
                        <button
                            onClick={() => setIsSalaryInfoOpen(!isSalaryInfoOpen)}
                            style={{
                                display: 'block',
                                width: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                marginBottom: '15px',
                                textAlign: 'left',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {isSalaryInfoOpen ? "KYC Details" : "KYC Details"}
                        </button>
                        {isSalaryInfoOpen && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="House No"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            height: '52px',
                                            marginTop: '28px',
                                        }}
                                    />
                                    <div style={{ flex: 1, marginRight: '5px' }}>
                                        <label htmlFor="aadharFront" style={{ display: 'block', marginBottom: '5px' }}>Aadhar Card Front</label>
                                        <input
                                            type="file"
                                            id="aadharFront"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'front')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label htmlFor="aadharBack" style={{ display: 'block', marginBottom: '5px' }}>Aadhar Card Back</label>
                                        <input
                                            type="file"
                                            id="aadharBack"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Pan Card Number"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            height: '52px',
                                            marginTop: '28px',
                                        }}
                                    />
                                    <div style={{ flex: 1, marginRight: '5px' }}>
                                        <label htmlFor="PanCardFront" style={{ display: 'block', marginBottom: '5px' }}>Pan Card Front</label>
                                        <input
                                            type="file"
                                            id="aadharFront"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'front')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label htmlFor="aadharBack" style={{ display: 'block', marginBottom: '5px' }}>Pan Card Back</label>
                                        <input
                                            type="file"
                                            id="aadharBack"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Driving License Number"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            height: '52px',
                                            marginTop: '28px',
                                        }}
                                    />
                                    <div style={{ flex: 1, marginRight: '5px' }}>
                                        <label htmlFor="aadharFront" style={{ display: 'block', marginBottom: '5px' }}>Driving License Front</label>
                                        <input
                                            type="file"
                                            id="aadharFront"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'front')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label htmlFor="aadharBack" style={{ display: 'block', marginBottom: '5px' }}>Driving License Back</label>
                                        <input
                                            type="file"
                                            id="aadharBack"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Passport Number"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                            height: '52px',
                                            marginTop: '28px',
                                        }}
                                    />
                                    <div style={{ flex: 1, marginRight: '5px' }}>
                                        <label htmlFor="aadharFront" style={{ display: 'block', marginBottom: '5px' }}>Passport Front</label>
                                        <input
                                            type="file"
                                            id="aadharFront"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'front')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <label htmlFor="aadharBack" style={{ display: 'block', marginBottom: '5px' }}>Passport Back</label>
                                        <input
                                            type="file"
                                            id="aadharBack"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>

                                </div>
                            </>
                        )}
                    </div>
                    {/* Personal Information Section */}
                    <div>
                        <button
                            onClick={() => setIsPersonalInfoOpens(!isPersonalInfoOpens)}
                            style={{
                                display: 'block',
                                width: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                marginBottom: '15px',
                                textAlign: 'left',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {isPersonalInfoOpens ? "Bank Details" : "Bank Details"}
                        </button>
                        {isPersonalInfoOpens && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Account Holder Name"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Bank Name"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Account Number"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="IFCS Code"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Branch Name"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Branch City"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <div style={{ flex: 1 }}>
                                        <label htmlFor="aadharBack" style={{ display: 'block', marginBottom: '5px' }}>Bank Passbook</label>
                                        <input
                                            type="file"
                                            id="aadharBack"
                                            accept="image/*" // Accept only image files
                                            onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler
                                            style={{
                                                padding: '10px',
                                                borderRadius: '5px',
                                                border: '1px solid #ddd',
                                                width: '100%', // Ensures it takes the full width of the container
                                                boxSizing: 'border-box', // Ensures padding is included in width
                                            }}
                                        />
                                    </div>


                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <button
                            onClick={() => setIsPersonalInfoOpend(!isPersonalInfoOpend)}
                            style={{
                                display: 'block',
                                width: '100%',
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                marginBottom: '15px',
                                textAlign: 'left',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {isPersonalInfoOpend ? "Social Links" : "Social Links"}
                        </button>
                        {isPersonalInfoOpend && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                    <input
                                        type="text"
                                        placeholder="Linkdin"


                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Facebook"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                            marginRight: '5px', // Space between fields
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Instagram"

                                        style={{
                                            flex: 1,
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ddd',
                                        }}
                                    />

                                </div>


                            </>
                        )}
                    </div>
                </div>
            ),
        },
        {
            title: "Family",
            content: (
                <>
                    {/* Your Card Component with Table */}
                    <Card  className='layout-card-data'>
                        <CardHeader
                            style={{
                                backgroundColor: 'whitesmoke',
                                marginTop: '2%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            title={
                                <TextField
                                    placeholder="Filter"
                                    value={filter}
                                    onChange={handleFilterChange}
                                    className="filterAlignment"
                                    style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here all other comunity of the changes and all offered community of the preparations oc the code 
                                    variant="outlined"
                                    InputProps={{
                                        style: {
                                            padding: '4px 10px',
                                            height: '38px',
                                        },
                                        inputProps: {
                                            style: { height: '38px' }
                                        }
                                    }}
                                />
                            }
                            action={
                                <>
                                    <input
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        style={{ display: 'none' }}
                                        id="fileUpload"
                                    />
                                    <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white' }}>
                                        + Add
                                    </Button>
                                </>
                            }
                        />
                        <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Table>
                                <TableHead style={{ backgroundColor: 'lightblue' }}>
                                    <TableRow>
                                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <Checkbox
                                                indeterminate={selected.length > 0 && selected.length < 2}
                                                checked={2 > 0 && selected.length === 2}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>S.No</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Name</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Relationship</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Age</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Date Of Birth</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Phone Number</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Pin Code</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Address</TableCell>
                                        {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>CTC (INR)</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Status</TableCell> */}
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staticData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.newId}
                                        >
                                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                <Checkbox
                                                    checked={selected.indexOf(row.candidateId) !== -1}
                                                    onChange={() => handleClick(null, row)}
                                                />
                                            </TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.jobTitle}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateId}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateName}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.contactNumber}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceCommitment}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceBreakAmount}</TableCell>

                                            <TableCell align="center">
                                                <IconButton onClick={() => console.log('View', row.candidateId)}>
                                                    <RemoveRedEye style={{ color: 'green' }} />
                                                </IconButton>
                                                <IconButton onClick={() => handlePopup1(row)}>
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </>
            ),
        },
        {
            title: "Education",
            content: (
                <>
                    <Card 
                    
                    // style={{ marginRight: '36px', width: 'fit-content', padding: '20px', marginTop: '180px', }}  
                    className='layout-card-data'
                    >
                        <CardHeader
                            style={{
                                backgroundColor: 'whitesmoke',
                                marginTop: '2%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            title={
                                <TextField
                                    placeholder="Filter"
                                    value={filter}
                                    onChange={handleFilterChange}
                                    className="filterAlignment"
                                    style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                                    variant="outlined"
                                    InputProps={{
                                        style: {
                                            padding: '4px 10px',
                                            height: '38px',
                                        },
                                        inputProps: {
                                            style: { height: '38px' }
                                        }
                                    }}
                                />
                            }
                            action={
                                <>
                                    <input
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        style={{ display: 'none' }}
                                        id="fileUpload"
                                    />
                                    <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '100px' }}>
                                        + Add
                                    </Button>
                                </>
                            }
                        />
                        <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Table>
                                <TableHead style={{ backgroundColor: 'lightblue' }}>
                                    <TableRow>
                                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <Checkbox
                                                indeterminate={selected.length > 0 && selected.length < 2}
                                                checked={2 > 0 && selected.length === 2}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>S.No</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Qualification</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Course Type</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Stram</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Start Date</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>End Date</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Collage Name</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Phone No</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Email ID</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Percentage/Grade</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staticDatas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.newId}
                                        >
                                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                <Checkbox
                                                    checked={selected.indexOf(row.candidateId) !== -1}
                                                    onChange={() => handleClick(null, row)}
                                                />
                                            </TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.jobTitle}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateId}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateName}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.contactNumber}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceCommitment}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceBreakAmount}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.ctc}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => console.log('View', row.candidateId)}>
                                                    <RemoveRedEye style={{ color: 'green' }} />
                                                </IconButton>
                                                <IconButton onClick={() => handlePopup1(row)}>
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </>
            ),
        },

        {
            title: "Work Experiance",
            content: (
                <>
                    <Card
                    //  style={{ marginRight: '36px', width: 'fit-content', padding: '20px', marginTop: '120px', }}
                     className='layout-card-data'
                     >
                        <CardHeader
                            style={{
                                backgroundColor: 'whitesmoke',
                                marginTop: '2%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            title={
                                <TextField
                                    placeholder="Filter"
                                    value={filter}
                                    onChange={handleFilterChange}
                                    className="filterAlignment"
                                    style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                                    variant="outlined"
                                    InputProps={{
                                        style: {
                                            padding: '4px 10px',
                                            height: '38px',
                                        },
                                        inputProps: {
                                            style: { height: '38px' }
                                        }
                                    }}
                                />
                            }
                            action={
                                <>
                                    <input
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        style={{ display: 'none' }}
                                        id="fileUpload"
                                    />
                                    <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white' }}>
                                        + Add
                                    </Button>
                                </>
                            }
                        />
                        <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Table>
                                <TableHead style={{ backgroundColor: 'lightblue' }}>
                                    <TableRow>
                                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <Checkbox
                                                indeterminate={selected.length > 0 && selected.length < 2}
                                                checked={2 > 0 && selected.length === 2}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>S.No</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Company Name </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Previous Designation</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Tenure (To)</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Tenure (From)</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Last CTC</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Last Reporting Manger Name</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Last Month Pay Slip</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Last Appraisal Letter</TableCell>

                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staticDatass.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.newId}
                                        >
                                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                <Checkbox
                                                    checked={selected.indexOf(row.candidateId) !== -1}
                                                    onChange={() => handleClick(null, row)}
                                                />
                                            </TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.jobTitle}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateId}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateName}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.contactNumber}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceCommitment}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceBreakAmount}</TableCell>
                                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.ctc}</TableCell> */}
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => console.log('View', row.candidateId)}>
                                                    <RemoveRedEye style={{ color: 'green' }} />
                                                </IconButton>
                                                <IconButton onClick={() => handlePopup1(row)}>
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </>
            ),
        },
        {
            title: "Agreement",
            content: (
                <>
                    <Card
                    //  style={{ marginRight: '36px', width: 'fit-content', padding: '20px', marginTop: '120px', width: '100%' }} 
                     className='layout-card-data'
                     >
                        <CardHeader



                        />
                        {/* Replace the TableContainer data fetching of the code also not happening in this statements of the code and all other than that various stories of the combined data also not having the perfect timming for the real*/}
                        <div style={{ marginTop: '20px' }}>

                            <p>
                                THIS AGREEMENT made at Hyderabad on <span style={{ fontWeight: 'bold' }}>02nd </span>day of<span style={{ fontWeight: 'bold' }}> November</span>, Two thousand and<span style={{ fontWeight: 'bold' }}> Twenty Four</span> BETWEEN <span style={{ fontWeight: 'bold' }}> Gaurav</span>  S/o, D/o aged <span style={{ fontWeight: 'bold' }}>0 </span>years,residing at and having permanent address at

                                (Hereinafter referred to as “Employee”) of the First Party



                            </p>
                            <p style={{ textAlign: 'center' }}>AND</p>
                            <p>Envision Integrated Services Private Limited, a company incorporated under the Companies Act 1956 and having its Corporate Office at #913, 9th floor, Manjeera Trinity Corporate Building, E- Seva lane, KPHB, Hyderabad – 500085, Reg. Office at #8-3-940/5/501, Tirumala Apartments, Yellareddyguda Cross Roads, Ameerpet, Hyderabad, Telangana – 500038 (hereinafter referred to as “Employer”) of the Second Party.</p>

                            <p>WHEREAS Employer is involved in the business of Solution provider or Consultancy and as of present and the foreseeable future specifically in the business of Business Support Services – offering services in Mechanical Engineering domain and products both in India and abroad.</p>


                            <p>WHEREAS the possession of the above problem solving techniques and effective use of high technologies equipment can be acquired mainly through specific on the job.</p>

                            <p>WHEREAS the said duration of <span style={{ fontWeight: 'bold' }}>2</span> year (From <span style={{ fontWeight: 'bold' }}>02-11-2024 to 02-11-2026</span>) and further extension based on the mutual understanding of Employee (First Party) and Employer. In the event Employee (First Party) does not fulfill the terms of the agreement, Employee (First Party) stand guarantee and will be liable to the liquidated damages of Rs 1,234/-(Rupees <span style={{ fontWeight: 'bold' }}>One Thousand Two Hundred And Thirty Four</span>    only).</p>


                            <p>WHEREAS it involves considerable expenditure– both direct and Indirect while under profession, Employer expects a commitment (elaborated below) from the employee to recover its expenditure or seek a penalty for non-fulfillment of the same.</p>

                            <p>WHEREAS the expenditure involved in imparting the said profession to Employee (First Party) is several times in excess of the penalty demanded from him/her.</p>
                            <h3 style={{ fontWeight: 'bold' }}>IT IS NOW HEREBY AGREED AS UNDER:</h3>

                            <p>In consideration of the profession to be impart by Employer, Employee undertakes irrevocably to serve Employer or any of its associated or affiliated companies to which he/ she may be transferred for a minimum period of <span style={{ fontWeight: 'bold' }}>2</span>  year (excluding Leave without pay period and/or unauthorized absence, if any) from the date of joining Envision Integrated Services Pvt. Ltd.</p>


                            <p>As a guarantee Employee agreed to keep the following Original documents & Bank Cheques with the custody of Employer <span style={{ fontWeight: 'bold' }}>HR</span></p>

                            <p>Employee can be transferred to any other location, department, function, establishment, or branch of the Company or subsidiary, associate or affiliate Company in case if the Company requires so for business purposes. In such case Employee will be governed by the terms and conditions of service applicable to the new assignment. Upon being so required by the Company, Employee shall make, sign and execute all deeds, documents, and declarations as may be deemed necessary by the Company and/or its clients (including privacy and confidentiality agreements).</p>

                            <p>Employee shall acknowledge that the services to be rendered to him/ her are unique and personal. During his/ her service with the Company, Employee shall not assign any of the rights or delegate any of the duties or obligations under this Agreement without the prior written consent of the Company.
                            </p>

                            <p>Employee shall keep the Company indemnified for any damages, which the Company or its client may suffer due to any act/ acts by you including breach of any terms of this agreement. Violation of the duties of organization was likely to provoke the wrath of the assets/ colleagues/ goods/ projects; but it does not appear that anything directly and Indirectly will tends to legal consideration. Depends on Project severity and effect, several times in excess of the penalty demanded from him/ her.
                            </p>

                            <p>Employee unauthorized absence from work for a continuous period of more than three days or more will be treated as absconding from duty, and in the event of not reporting to work. Organizational Development team will take necessary action on absconding employee process or Employer can claim employee cheque no _______________
                                ,</p>

                            {/* <p>_____________for liquidate expenditure/ damages/ compensation for any loss suffered by the Company or its Client due to such an absence.</p>
                             */}

                            <p>Employee is giving this undertaking in view of the considerable expenditure incurred by Employer on him/ her. Employee agrees not to take employment with any other person, firm or company during the period of applicability of this agreement.</p>


                            <p>In the event of any dispute or disagreement over the interpretation of any of the terms hereinabove contained or any claim of liability of any part the same shall be referred to a person to be nominated by Employer whose decision shall be final and binding upon the parties hereto. Such reference shall be deemed to a submission to arbitration under The Arbitration and Conciliation Act, 1996 or of any modification or re-enactment thereof. The venue of arbitration shall be Hyderabad.</p>

                            <p>During the period of <span style={{ fontWeight: 'bold' }}>2</span> year (excluding Leave without pay period and/ or unauthorized absence, if any) from the date of joining, if Employee leaves/ resigns/ abandons the services or violates the terms of this Agreement, Employee will have to pay liquidated damages amounting to Rs <span style={{ fontWeight: 'bold' }}>1,234 /-</span>  (Rupees <span style={{ fontWeight: 'bold' }}> One Thousand Two Hundred And Thirty Four </span>Only) and give Three calendar months’ written notice or salary in lieu thereof. Employee agrees that the said amount of Rs <span style={{ fontWeight: 'bold' }}>1,234 /-</span>  (Rupees <span style={{ fontWeight: 'bold' }}>One Thousand Two Hundred And Thirty Four</span>  Only) can be recovered/adjusted by Employer from the legal dues, if any, payable to him/ her. On being absorbed as an Employee of Employer, after completion of the said period, Employer would be entitled to terminate the services of the Employee with Three Calendar Months’ written notice during the tenure of service agreement.</p>
                            <p>The provisions stated herein for breach by Employee of the provisions of this agreement shall be without prejudice to other remedies available to Employer. In case of any dispute arising out of the Agreement, it shall be subject to jurisdiction of appropriate Court of Hyderabad, Telangana, India.

                            </p>

                            <p>IN WITNESS WHEREOF, THIS AGREEMENT TO BE EXECUTED AS DATE FIRST MENTIONED ABOVE.</p>

                            <p>I,solely accept to submit my original documents & Bank Cheques for a period of my employment with the company. As a company or in person never forced me or mandated me to submit but limited to security towards my agreed service for the tenure in the company.</p>

                            <br></br><div style={{ textAlign: 'center' }}>
                                <p> <input type="checkbox" style={{ marginRight: '8px' }} />Terms & Conditions</p>
                            </div>

                            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>NON-DISCLOSURE AGREEMENT</h3>

                            <p>
                                THIS AGREEMENT made at Hyderabad on <span style={{ fontWeight: 'bold' }}>02nd</span>  day of <span style={{ fontWeight: 'bold' }}>November</span>, Two thousand and <span style={{ fontWeight: 'bold' }}>Twenty Four</span> This is to certify that I, Mr./Ms <span style={{ fontWeight: 'bold' }}>Gaurav</span>  has joined ENVISION INTEGRATED SERVICES PRIVATE LIMITED and executed an agreement on <span style={{ fontWeight: 'bold' }}>02-11-2024</span> .

                            </p><br></br>

                            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>I HEREBY GIVE THE FOLLOWING UNDERTAKING:</h3><br></br>

                            <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}> {/* Ensuring numbering is displayed */}
                                <li>1.That I am not involved in any civil/ criminal/ cases/ proceedings/ charges/ enquiry prior to Joining Envision Integrated Services Pvt. Ltd.</li><br></br>
                                <li>2.That I am not involved in any Disciplinary/ malpractices and/ or any other charges/ proceedings/ enquiry/ case pending against me in any company/ Organization/ university or any other educational authority/ institution prior to joining in Envision Integrated Services Pvt. Ltd.</li><br></br>
                                <li>3.That I was not employed gainfully and/ or honorary in any organization including Private/ public/ Government/ Educational institution etc., prior to joining Envision Integrated Services Pvt. Ltd.</li><br></br>
                                <li>4. That I must protect any confidential information/ Data to which they have been granted access and not reveal, release, share, email unencrypted, exhibit, display, distribute, or discuss the information unless necessary to do his/ her job or the action is approved by his/ her Board of Directors/ Project Managers/ Team Leader of the company.
                                </li><br></br>
                                <li>5.Any unintentional breach of this policy will be thoroughly investigated and will be punished appropriately depending on its magnitude and seriousness. This policy is binding even after separation of employment.
                                </li><br></br>
                                <li>6.That I shall be bound by all the policies, rules, regulations and procedures currently prevailing or that may be established by the Company in future, and any modifications thereof or additions thereto, as may be declared by the Company from time to time.
                                </li><br></br>
                                <li>7.That I shall not approach any of the other parties/ organization/ clients/ vendors of Envision Integrated Services Pvt. Ltd. for a period of two years after the termination/ resignation/ tenure of an employee of either party or termination/ efflux of time of this agreement. I shall use the best of my efforts to promote, develop and extend the business of the Company and comply with the directions and regulations of the Company at all times, and in all respects.
                                </li><br></br>
                                <li>8.The company may terminate this agreement at any time before the expiry of the stipulated term by giving one month's notice in writing to him/ her. The company can terminate your contract any time if you-
                                    a. Commit any material or persistent breach of any of the provisions contained.</li><br></br>
                                <li>
                                    9.The company may terminate this agreement at any time before the expiry of the stipulated term by Employee shall keep all proprietary information confidential in accordance with the normal procedures of Mutual Non-Disclosure Agreement (NDA) between us.

                                </li><br></br>
                                <li>10.That in case the above undertaking is found to be false, then Envision Integrated Services Pvt. Ltd. shall be within its rights to forthwith terminate my services without any liability and take such legal recourse/ actions against me as it may be advised.
                                </li><br></br>
                                <li>11. That I shall fully indemnity and protect Envision Integrated Services Pvt. Ltd. firm and against any acts or omissions attributable to me for breach hereof or otherwise, including but not limited to any loss, damage, or liability suffered by Envision Integrated Services Pvt. Ltd. and/or civil criminal action against Envision Integrated Services Pvt. Ltd. for reasons hereof.</li>
                                <li>12.That whatever is stated above is true to my knowledge and belief and that no part of it is false. I shall be fully responsible and liable for all my acts omissions.

                                </li>

                            </ul><br></br>
                            <p style={{ fontWeight: 'bold' }}>Name Of the Employee:</p>
                            <p style={{ textAlign: 'right', marginTop: '-38px', marginLeft: '-10px', marginRight: '66px', fontWeight: 'bold' }}>Date:</p>
                            <br></br>
                            <p>Signature: <input
                                type="file"
                                id="aadharBack"
                                accept="image/*" // Accept only image files
                                onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler

                            /></p>

                            <div style={{ textAlign: 'right', marginTop: '-52px' }}>
                                <p>Left Thumb: <input
                                    type="file"
                                    id="aadharBack"
                                    accept="image/*" // Accept only image files
                                    onChange={(e) => handleImageUpload(e, 'back')} // Replace with your upload handler

                                /></p>
                            </div>

                            <div style={{ textAlign: 'center' }}>
                                <p> <input type="checkbox" style={{ marginRight: '8px' }} />Terms & Conditions</p>
                            </div>
                        </div>
                    </Card>
                </>
            ),
        },

        {
            title: "Summary",
            content: (
                <>
                    <Card 
                    
                    // style={{ marginRight: '36px', width: '100%', padding: '20px', marginTop: '120px', }}
                    className='layout-card-data'
                    > 
                        <CardHeader

                        />
                        <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Table>
                                <TableHead style={{ backgroundColor: 'lightblue' }}>
                                    <TableRow>
                                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <Checkbox
                                                indeterminate={selected.length > 0 && selected.length < 2}
                                                checked={2 > 0 && selected.length === 2}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>S.No</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Description </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Status</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Remarks</TableCell>




                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staticDatadd.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.newId}
                                        >
                                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                <Checkbox
                                                    checked={selected.indexOf(row.candidateId) !== -1}
                                                    onChange={() => handleClick(null, row)}
                                                />
                                            </TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.jobTitle}</TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateId}</TableCell>



                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                                <IconButton onClick={() => console.log('View', row.candidateId)}>
                                                    <RemoveRedEye style={{ color: 'green' }} />
                                                </IconButton>
                                                <IconButton onClick={() => handlePopup1(row)}>
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateName}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </>
            ),
        },

        // Add more sections here, for example "Salary Information", "Joining Information", etc.
    ];

    return (

        {
            title: "Job Designation",
            content: (
                <div className="form-group">
                    <select
                        className="form-control"
                        required
                        value={selectedJobDesignation}
                        onChange={handleJobDesignationChange}
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd',
                        }}
                    >
                        <option value="" disabled>Job Designation</option>
                        <option value="Junior Executive">Junior Executive</option>
                        <option value="Senior Executive">Senior Executive</option>
                        <option value="Associative Engineering">Associative Engineering</option>
                        <option value="Associative Executive">Associative Executive</option>
                        <option value="Team Lead">Team Lead</option>
                        <option value="Project Manager">Project Manager</option>
                        <option value="Program Manager">Program Manager</option>
                        <option value="CEO">CEO</option>
                    </select>
                </div>
            ),
        },
        {
            title: "Personal Information",
            content: (
                <>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            value="John Doe"
                            placeholder="Name"
                            required
                            onChange={(e) => { }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            value="9876543210"
                            placeholder="Contact Number"
                            required
                            onChange={(e) => { }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            value="test123@gmail.com"
                            placeholder="Email"
                            required
                            onChange={(e) => { }}
                        />
                    </div>
                </>
            ),
        },

        <Card 
        
        // style={{ marginLeft: '149px', marginRight: '36px', width: '68%', padding: '20px', marginTop: '70px' }} 
        className='layout-card-data'
        >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '2%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '40px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        {/* <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white' }}>
                            + Add
                        </Button> */}
                    </>



                }
            />
            <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < 2}
                                    checked={2 > 0 && selected.length === 2}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>S.No</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Job Title/Designation</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Candidate Id</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Candidate Name</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Contact Number</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Email Id</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Service Commitment</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Service Break Amount</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>CTC (INR)</TableCell>
                            <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Status</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staticData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow
                                hover
                                onClick={(event) => handleClick(event, row)}
                                role="checkbox"
                                tabIndex={-1}
                                key={row.newId}
                            >
                                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                    <Checkbox
                                        checked={selected.indexOf(row.candidateId) !== -1}
                                        onChange={() => handleClick(null, row)}
                                    />
                                </TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.jobTitle}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateId}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateName}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.contactNumber}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceCommitment}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.serviceBreakAmount}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.ctc}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                <TableCell align="center" style={{ minWidth: '120px' }}>
                                    <IconButton onClick={() => console.log('View', row.candidateId)}>
                                        <RemoveRedEye style={{ color: 'green' }} />
                                    </IconButton>
                                    <IconButton onClick={() => handlePopup1(row)}>
                                        <Edit />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading categories...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.categories}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="" disabled>Job Designation</option>
                                        {categories.map((category, index) => (
                                            <option key={index} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Candidate Name"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Contact Number"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="tel" // Changed to 'tel' for better contact number validation
                                    className="form-control"
                                    placeholder="Email Id"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Service Commitment"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Service Break Amount"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="CTC(INR)"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>

                            <div className="form-group">

                                <select
                                    className="form-control"
                                    placeholderText="work location"
                                    value={userDetails.ctc} // Controlled input
                                    onChange={(e) => setUserDetails({ ...userDetails, ctc: e.target.value })} // Handle input change
                                    required
                                >
                                    <option value="" >Work location</option>
                                    <option value="500000">Hyderabad</option>
                                    <option value="600000">Bangloore</option>
                                    <option value="700000">Pune</option>
                                    <option value="800000">Chennai</option>

                                </select>
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && (
                <div className="modal-overlay" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 999, // Ensure it's on top of other content
                }}>
                    <div className="modal-content1" style={{
                        background: '#fff', // White background for the modal
                        padding: '20px',
                        borderRadius: '8px',

                        maxHeight: '80vh', // Maximum height of modal
                        overflowY: 'auto', // Enable vertical scrolling
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
                        maxWidth: '1155px'
                    }}>
                        <div className="header-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="serial-number">S No-1</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen1(false)} style={{
                                background: 'none',
                                border: 'none',
                                fontSize: '24px',
                                cursor: 'pointer',
                            }}>&times;</button>
                        </div>

                        {/* Tab Navigation */}
                        <div className="tab-navigation" style={{ display: 'flex', marginBottom: '20px' }}>
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    onClick={() => setActiveTab(index)} // Change active tab on click data not fetching in the reality of the code and all
                                    style={{
                                        padding: '10px 15px',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        backgroundColor: activeTab === index ? '#007bff' : '#f8f9fa', // Highlight active  
                                        color: activeTab === index ? '#fff' : '#007bff', // Change text color based on active tab 
                                        marginRight: '10px',
                                    }}
                                >
                                    {tab.title}
                                </div>
                            ))}
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <h3>{tabs[activeTab].title}</h3>
                            {tabs[activeTab].content}
                        </div>

                        <div className="button-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)} style={{
                                backgroundColor: '#dc3545',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '10px 20px',
                                cursor: 'pointer',
                            }}>Cancel</button>

                            <div>
                                <button
                                    type="button"
                                    className="btn btn-previous"
                                    onClick={handlePreviousTab}
                                    disabled={activeTab === 0}
                                    style={{
                                        display: 'inline-block',
                                        width: '100px',
                                        backgroundColor: '#007bff',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s',
                                        marginRight: '10px',
                                    }}
                                >
                                    Previous
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-next"
                                    onClick={handleNextTab}
                                    style={{
                                        display: 'inline-block',
                                        width: '100px',
                                        backgroundColor: '#007bff',
                                        border: 'none',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s',
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                                >
                                    Next
                                </button>

                                <button type="submit" className="btn btn-save" onClick={updateRecord} style={{
                                    backgroundColor: '#28a745',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    cursor: 'pointer',
                                }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}



            <div className="ad-container">
                <div className="ad-content">
                    <div className="ad-item" style={{ marginRight: '50px' }}>Advertisement 1</div>
                    <div className="ad-item" style={{ marginRight: '50px' }}>Advertisement 2</div>
                    <div className="ad-item" style={{ marginRight: '50px' }}>Advertisement 3</div>
                    {/* Add more advertisements as needed */}
                </div>
            </div>

            <style>
                {`
   
        .ad-container {
  position: fixed;
  right: -50px;
  bottom: 0;
  top: 0;
  width: 384px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
  background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

            </style>






            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '20px', marginRight: '30px' }}>
                {/* Other content can go here */}

                <Button
                    variant="contained" // Use 'contained' for a solid button style
                    onClick={handleNext}
                    style={{ backgroundColor: 'blue', color: 'white' }}
                >
                    Next
                </Button>
            </div>
            <Advertisecode />
        </Card>




    )


}

// Inline styling for reusable styles
const buttonStyle = {
    display: 'block',
    width: '100%',
    backgroundColor: '#f8f9fa',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '15px',
    marginBottom: '15px',
    textAlign: 'left',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
};

const selectStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ddd',
};

export default Profile;