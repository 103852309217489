import React, { useState, useEffect, createContext, useContext } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Advertisecode from "./Advertisecode";
import { Link } from "react-router-dom";
import logo from "../images/internship.jpg"; // Default image for the first card
import webinarLogo from "../images/courses.jpg"; // Different image for the second card
import axios from 'axios';
import AdvertisementShow from './AdverstimentDes';
const Advertisement = () => {
  const options = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },

    },
  };
  const [ads, setAds] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentAd, setCurrentAd] = useState(null);
  const handleSubmits = async (e, subNavId) => {
   
    e.preventDefault();
    try {
        const response = await axios.put(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/editbyid/${subNavId}`, {
            image: posterImage.split(',')[1] ,
            name: name // Body with the new name
        });
        console.log('Edit response:', response.data); // Log response from the API 
        // setIsPopupOpen(false); // Close the popup after successful edit
        // Optionally, you can fetch the updated data again or update state directly
    } catch (error) {
        console.error('Error updating sub navigation data:', error);
    }
};

const fetchAdvertisements = async () => {
  const selectedNavId = localStorage.getItem('selectedNavId');
    try {
      const response = await fetch(
        `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/subnav/${selectedNavId}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch advertisements');
      }

      const data = await response.json();
      setAds(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdvertisements();
  }, []);

  const handleEditClick = (subNavId) => {
  
    // Find the ad using subNavId and set it for editing
    const ad = ads.find((ad) => ad.subNavId === subNavId);
    setCurrentAd(ad);
    setIsModalOpen(true);
    // Set posterImage and name for editing
    setPosterImage(ad.base64Image || '');
    setName(ad.name || '');
  };


  const handleLinkClick = () => {
    localStorage.setItem('directoryNavId', 3);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [posterImage, setPosterImage] = useState('');
  const [image, setImage] = useState('');
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setPosterImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  

  return (
    <div>
      <div
        className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix popyuia"
        id="services"
        style={{ marginTop: "0px", }} // Add margin-top here
      >
        <div className="containerdc">
          <div className="section-head text-center text-white">

            <div className="dlab-separator bg-primary"></div>
          </div>
          <div
  className="section-content home-courses-cards"
  style={{ marginLeft: '-100px', marginTop: '-70px' }}
>
  <div  className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
    <OwlCarousel
       className="owl-theme owl-stage owlCarousel"
       loop
       marginTop={0}
       nav
       {...options}
    >
    
    <div style={{width:'100%'}} className="ads-container">
      {ads.map((ad) => (
        <div
          key={ad.subNavId}
          className="card_3"
          style={{
            backgroundImage: `url(${ad.image || 'placeholder.jpg'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            color: 'white',
            height: '350px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Centered Text */}
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              fontSize: '24px',
              fontWeight: 'bold',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {ad.name || 'Advertisement'}
          </div>

          {/* Edit Button */}
          {ad.image && (
              <img
                src={`data:image/jpeg;base64,${ad.image}`}
                alt="Advertisement"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            )}

          <button
            style={{
              backgroundColor: 'white',
              color: 'black',
              padding: '8px 16px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              position: 'absolute',
              top: '10px',
              left: '10px',
              zIndex: 10,
            }}
            onClick={() => handleEditClick(ad.subNavId)}
          >
            Edit
          </button>
        </div>
      ))}   
      </div> 
      </OwlCarousel>

      <style>
      {`
          .ads-container {
            display: flex;
            flex-wrap: nowrap;
            gap: 20px;
            overflow-x: auto;
            padding: 20px;
            margin: 0 auto;
          }

          .card_3 {
            flex: 0 0 auto;
            background-size: cover;
            background-position: center;
            position: relative;
            color: white;
            height: 350px;
            width: 260px;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }

          .card_3 button {
            background-color: white;
            color: black;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 10;
          }

          .modal {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            z-index: 1000;
          }
        `}
      </style>

    {isModalOpen &&  currentAd && (
        <div style={{marginTop:'40px'}} className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <form onSubmit={(e) => handleSubmits(e, currentAd.subNavId)}>
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="add-choosen">
                  <div className="input-blocks">
                    <div className="image-upload">
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        required
                      />
                      <label htmlFor="imageInput" className="image-uploads">
                        <i data-feather="plus-circle" className="plus-down-add me-0"></i>
                        <h4>Add Image</h4>
                        {posterImage && (
                          <img
                            src={posterImage}
                            alt="Preview"
                          />
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Adverstisment Image</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)} // Update the name state
                  required
                />
              </div>
              <div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
  </div>
</div>

<AdvertisementShow/>

</div>
</div>


      
      {/* <div className="ad-container piop">
        <div className="ad-content"> */}
          {/* <div className="ad-item" style={{ marginRight: '15px' }}>Advertisement 1</div>
          <div className="ad-item" style={{ marginRight: '15px' }}>Advertisement 2</div>
          <div className="ad-item" style={{ marginRight: '15px' }}>Advertisement 3</div> */}
          {/* Add more advertisements as needed */}
        {/* </div>
      </div> */}
      <style>
        {`
   
        .ad-container {
  position: fixed;
  right: -60px;
  bottom: 0;
  top: 0;
  width: 320px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 80%;
}

.ad-item {
  padding: 20px;
  color: white;
  background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

      </style>
      
      <div style={{marginTop:'10px'}}>
      <Advertisecode />
      </div>
    </div>

  );
};

export default Advertisement;
