import React, { useState, useEffect, createContext, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// Create the SidebarContext
const SidebarContext = createContext();

// Custom hook to use the Sidebar context
export const useSidebar = () => {
  return useContext(SidebarContext);
};
const SideNavBar = ({ isHidden }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false); // Second modal state
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);
  const [posterImage, setPosterImage] = useState('');
  const [apiData, setApiData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); // Store role status
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    
    // Retrieve sidebar state from localStorage
    const savedState = localStorage.getItem('isSidebarOpen');
   
    return savedState ? JSON.parse(savedState) : false; // Default to false if no state is saved
  });
  const [selectedCourse, setSelectedCourse] = useState(null); // Track selected course 


  const [navId, setNavId] = useState(1); // Initialize navId with a default value
  const [subNavId, setSubNavId] = useState(1); // Initialize subNavId with a default value

  const [selectedCourseId, setSelectedCourseId] = useState(null); // Track selected cour
  const [subNavData, setSubNavData] = useState([]);



  const toggleSidenav = () => {
    setIsSidebarOpen((prev) => {
      const newState = !prev;
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState)); // Save the new state to localStorage
      return newState;
    });
  };



  useEffect(() => {
    // Function to sync state with localStorage every second
    const syncSidebarState = () => {
      const savedState = localStorage.getItem('isSidebarOpen');
      setIsSidebarOpen(savedState ? JSON.parse(savedState) : false);
    };

    const intervalId = setInterval(syncSidebarState, 1000); // Check every 1 second

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  


  const handleNavigation = (courseName) => {
    const route = getCourseRoute(courseName);
    navigate(route); // Navigate to the calculated route
  };

  
  


  const handleCourseClick = (course) => {

    if (selectedCourseId === course?.navId) {
      
      // Deselect the course if clicked again
      setSelectedCourseId(null);
      setSubNavData([]); // Clear subNavData when closing
      
      localStorage.setItem('directoryNavId', course?.navId);

    } else {
      // Set the selected course ID
      setSelectedCourseId(course?.subsidenavbar?.subNavId);
      // fetchSubNavData(course.navId); // Fetch sub-navigation dat
      // If the course is 'DIRECTORY', store its navId in localStorage
     
      localStorage.setItem('directoryNavId', course?.navId);
     

    }
  };



  



  // Fetch sub-navigation data based on navId
  // const fetchSubNavData = async (navId) => {
  //   if (!navId) {
  //     console.error('navId is not set');
  //     return; // Exit if navId is not defined
  //   }

  //   try {
  //     const response = await fetch(`http://10.10.20.9:7071/IIA-Website-1.0.0/subsidenavbar/subnav/${navId}`);
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch sub-navigation data: ' + response.statusText);
  //     }
  //     const data = await response.json();
  //     console.log('Fetched sub-navigation data:', data); // Log fetched data for debugging
  //     setSubNavData(data); // Set the fetched data to state
  //   } catch (error) {
  //     console.error('Error fetching sub-navigation data:', error);
  //   }
  // };







  // Form submission handler
  const handleSubmitss = async (e) => {
    e.preventDefault();

    // Prepare the data to be sent to the API
    const requestBody = {
      navId, // Use the navId from the selected course
      name,
    };

    // POST API call
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to submit the form');
      }

      const data = await response.json();
      console.log('Form submitted successfully:', data);

      // Handle the success, close modal, or reset form
      setIsSecondModalOpen(false);
      setName('');
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };



  useEffect(() => {

    const fetchData = async () => {
      const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
      if (!userId) {
        console.error("User ID not found in localStorage.");
        return;
      }

      try {
        const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/controllerScreen/user/${userId}`);
        console.log('API Response:', response.data);

        if (response.data && response.data[0]) {
          setApiData(response.data); // Set the first entry to state (assuming the array)
          // alert(response.data[2].navBar[0].name)
          // Check if the user is an Admin (based on role field in the API response)
          if (response.data[0].user.role === 'User' || response.data[0].user.role === 'Excutive' || response.data[0].user.role === 'Admin') {
            setIsAdmin(false);
          } else {
            setIsAdmin(true);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);



  const handleSubmits = async (event) => {
    event.preventDefault();
    const cleanImage = image.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
    const formData = {
      name: name,
      image: cleanImage,
    };

    try {
      const response = await axios.post(
        'https://iiaserver.iiautomate.com/IIA-Website-1.0.0/sidenav/add',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Form submitted successfully:', response.data);
      alert('Data added successfully!');
      navigate('/login');
    } catch (error) {
      console.error('Error submitting form:', error.response?.data || error.message);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setPosterImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };





  const getCourseRoute = (courseName) => {
    switch (courseName) {
      case "DIRECTORY":
        return "/layout1";
      case "COURSES":
        return "/coursescard";
      case "WEBINARS":
        return "/java";
      case "ENROLL":
        return "/enroll1";
      case "DASHBOARD":
        return "/home";
        case "ADVERTISEMENT":
          return "/advesitment";
          case "REGISTERED":
            return "/table";
      default:
        return "/not-found"; // Fallback route
    }
  };




  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidenav }}>
      <div>
        <style>
          {`
      .hamburgers {
  width: 23px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space the lines evenly */
  cursor: pointer; /* Change cursor on hover */
  // background:#2a2a2a;
  margin-left:0px;
}

.line {
  height: 3px; /* Thickness of the lines */
  margin-bottom:7px;
  width: 86%; /* Full width of the hamburger */
  background-color: #2a2a2a; /* Color of the lines */
  transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

/* Optional: Add hover effects */
.hamburger:hover .line {
  background-color: #ccc; /* Change color on hover */
}
  
  .vertical-line {
   
    background-color: #ccc; /* Line color */
    height: 100vh; /* Full height of the viewport */
    position: absolute; /* Positioning if needed */
    left: 150px; /* Adjust to align with sidebar's width */
    top: 0;
   /* Position the line above content */
}

           
        `}
        </style>

        <div className={`sidenav ${isSidebarOpen ? 'open' : 'closed'}`}>
          <div className={`nav-control yui  ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidenav}>
            {/* Apply dynamic class based on isSidebarOpen */}
            <div className={`hamburgers opeood  ${isSidebarOpen ? 'active' : ''}`}>

              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>

            </div>
          </div>
          <div className={`deznav ipioo  ${isHidden ? 'hidden' : ''}`} style={{ overflowY: 'auto',  height: '90vh'  }}>
            <div className="deznav-scroll" style={{ marginTop: '20px', }}>
              <ul className="metismenu" id="menu">
                <li className="nav-label first">Main Menu</li>
                {isAdmin && (
                  <>
                    <li>
                      <Link
                        to="/cntrollers-table-data"
                        style={{
                          textAlign: 'center',
                          cursor: 'pointer',
                          color: '#fff',
                          padding: '10px',
                          textDecoration: 'none',
                          display: 'flex', // Align icon and text
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50px',
                          backgroundColor: '#007BFF', // Background color
                          gap: '8px', // Space between icon and text
                        }}
                        onMouseEnter={(e) => (e.target.style.color = '#000')} // Change text color to black on hover
                        onMouseLeave={(e) => (e.target.style.color = '#fff')} // Reset to white when mouse leaves
                      >
                        <i className="la la-cogs" style={{ fontSize: '20px' }}></i> {/* Add icon */}
                        Controllers
                      </Link>


                    </li>
                    {/* Additional list items can go here */}
                  </>
                )}




                <li>



                  <li></li>

                  {/* <button onClick={() => setIsModalOpen(true)} className="btn btn-primary">Add</button> */}
                </li>
              </ul>
              <div>

              

                {loading ? (
                  <p>Loading...</p> // Show loading message while fetching data
                ) : (
                  <div>
                    {apiData.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          position: 'relative',
                          marginTop: '20px',
                          marginLeft: '27px',
                        }}
                      >
                        {apiData.map((course) => (
                          <div
                            key={course?.id}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '30px',
                              marginBottom: '30px',
                              position: 'relative',
                              flexDirection: 'column',
                            }}
                          >
                            {course.navBar.map((subItem, index) => (
                              <div
                                key={subItem?.navId || index}
                                className="course-item"
                                onClick={() => handleCourseClick(subItem)} // Handle click event
                                style={{
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  color: '#fff',
                                }}
                              >
                                <div
      onClick={() => handleNavigation(subItem?.name)} // Handle click to navigate
      style={{
        textAlign: 'center',
        cursor: 'pointer',
        color: '#fff',
        textDecoration: 'none',
      }}
    >
      <div style={{ marginTop: '10px', }}>
        {/* Display Image or Placeholder */}
        {subItem?.image ? (
          <img
            src={`data:image/png;base64,${subItem?.image}`}
            alt={subItem?.name}
            style={{ width: '35px', height: '35px' }}
          />
        ) : (
          <div>No image available</div>
        )}
        {/* Display Name */}
        <strong style={{ display: 'block', marginTop: '5px',}}>
          {subItem?.name || 'No name available'}
        </strong>
      </div>
    </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No data available</p>
                    )}

                  </div>
                )}


              </div>

            </div>

          </div>


       

          <div className="vertical-line"></div>

        </div>


      </div>
      {/* Second Modal */}

      {isModalOpen1 && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() =>  setIsModalOpen1(false)}>
              &times;
            </button>
            <form onSubmit={handleSubmitss}>
              <div className="form-group">
                <label className="form-label">Advertisement</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Second Modal */}
      {isSecondModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setIsSecondModalOpen(false)}>
              &times;
            </button>
            <form onSubmit={handleSubmitss}>
              <div className="form-group">
                <label className="form-label">Banner Image Title 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}


      {/* Modal for adding an image */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <form onSubmit={handleSubmits}>
              <div className="col-lg-2 col-md-12 col-sm-12">
                <div className="add-choosen">
                  <div className="input-blocks">
                    <div className="image-upload">
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        required
                      />
                      <label htmlFor="imageInput" className="image-uploads">
                        <i data-feather="plus-circle" className="plus-down-add me-0"></i>
                        <h4>Add Image</h4>
                        {posterImage && (
                          <img
                            src={posterImage}
                            alt="Preview"
                          />
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Banner Image Title 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)} // Update the name state
                  required
                />
              </div>
              <div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </SidebarContext.Provider>

  );
};

export default SideNavBar;
