import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Advertisecode = () => {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAdvertisementData = async () => {
    try {
      const response = await fetch(
        'https://iiaserver.iiautomate.com/IIA-Website-1.0.0/subsidenavbar/subnav/6'
      );

      if (!response.ok) {
        throw new Error('Failed to fetch advertisement data');
      }

      const data = await response.json();
      setCardData(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdvertisementData();
  }, []);

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  const styles = {
    sliderContainer: {
      width: '100%',
      margin: 'auto',
    },
    slickCard: {
      padding: '20px',
      backgroundColor: '#f4f4f4',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      height: '250px',
      textAlign: 'center',
    },
    card: {
      textAlign: 'center',
      padding: '10px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#000',
      color: '#fff',
      borderRadius: '8px',
    },
    cardImage: {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      marginBottom: '10px',
      objectFit: 'cover',
    },
    cardTitle: {
      fontSize: '1.2em',
      marginBottom: '5px',
      color: '#fff',
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={styles.sliderContainer} className="advertisement-mobileview">
      <Slider {...settings}>
        {cardData.map((card) => (
          <div key={card.id} style={styles.slickCard}>
            <div style={styles.card}>
              <img
                src={card.image || 'https://via.placeholder.com/100'}
                alt={card.name || 'Advertisement'}
                style={styles.cardImage}
              />
              <h3 style={styles.cardTitle}>{card.name || 'Advertisement'}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Advertisecode;
