import React from "react";
import OwlCarousel from 'react-owl-carousel3';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import logo from "../images/front1.jpeg"; // Default image for the first card
import webinarLogo from "../images/bancked.jpg"; // Different image for the second card
import webinarLogo1 from "../images/test.jpg"; // Different image for the second card
const WebDevelopment= () => {
  const options = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1300: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <div
        className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix"
        id="services"
        style={{ marginTop:'160px',marginLeft:'150px',marginRight:'240px'}} // Add margin-top here
      >
        <div className="containerdc">
          <div className="section-head text-center text-white">
          
            <div className="dlab-separator bg-primary"></div>
          </div>
          <div className="section-content">
            <div className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
              <OwlCarousel
                className="owl-theme owl-stage owlCarousel"
                loop
                margin={0}
                nav
                {...options}
              >
                <Link to="/frontend" style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3" style={{ backgroundImage: `url(${logo})`  }}>
                  <div 
      className="card_text_overlay" 
      style={{
        marginTop:'50%',
        marginLeft:'10%',
        top: "50%",
        left: "40%",
        width:'80%',
        textAlign: "center",
        padding: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        borderRadius: "8px"
      }}
    >
      <h5 className="card3_title" style={{ margin: "10px 0",color:'white',fontSize:'18px' }}>FRONTEND</h5>
     
      
    </div>
                  </div>
                </Link>
                <Link to="/backend" style={{ textAlign: "center", alignItems: "center"}}>
                  <div className="card_3"  style={{ backgroundImage: `url(${webinarLogo})`  }}>
                  <div 
      className="card_text_overlay" 
      style={{
        marginTop:'50%',
        marginLeft:'10%',
        top: "50%",
        left: "40%",
        width:'80%',
        textAlign: "center",
        padding: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        borderRadius: "8px"
      }}
    >
      <h5 className="card3_title" style={{ margin: "10px 0",color:'white',fontSize:'18px' }}>BACKEND</h5>
     
      
    </div>
                  </div>
                </Link>
                <Link to="/testing" style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3"  style={{ backgroundImage: `url(${webinarLogo1})`  }}>
                  <div 
      className="card_text_overlay" 
      style={{
        marginTop:'50%',
        marginLeft:'10%',
        top: "50%",
        left: "40%",
        width:'80%',
        textAlign: "center",
        padding: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        borderRadius: "8px"
      }}
    >
      <h5 className="card3_title" style={{ margin: "10px 0",color:'white',fontSize:'18px' }}>TESTING</h5>
     
      
    </div>
                  </div>
                </Link>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      
      <div className="ad-container">
                <div className="ad-content">
                    <div className="ad-item" style={{marginRight:'15px'}}>Advertisement 1</div>
                    <div className="ad-item" style={{marginRight:'15px'}}>Advertisement 2</div>
                    <div className="ad-item" style={{marginRight:'15px'}}>Advertisement 3</div>
                    {/* Add more advertisements as needed */}
                </div>
            </div>
            <style>
                {`
   
        .ad-container {
  position: fixed;
  right: -75px;
  bottom: 0;
  top: 0;
  width: 320px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 80%;
}

.ad-item {
  padding: 20px;
  color: white;
background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

            </style>
    </div>
    
  );
};

export default  WebDevelopment;
