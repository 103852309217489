import React from "react";
import OwlCarousel from 'react-owl-carousel3';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Advertisecode from "./Advertisecode";
import AdvertisementShow from './AdverstimentDes';
import { Link } from "react-router-dom";
import logo from "../images/Webinar.jpg"; // Default image for the first card
import webinarLogo from "../images/courses.jpg"; // Different image for the second card

const Courses = () => {
  const options = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },

    },
  };


  const handleLinkClick = () => {
    localStorage.setItem('directoryNavId', 3);
  };



  return (
    <div>
      <div
        className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix popyuia"
        id="services"
        style={{ marginTop: "0px", }} // Add margin-top here
      >
        <div className="containerdc">
          <div className="section-head text-center text-white">

            <div className="dlab-separator bg-primary"></div>
          </div>
          <div className="section-content home-courses-cards" style={{ marginLeft: '20%',marginTop:'-70px',width:'100px' }}>
            <div className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
              {/* <OwlCarousel
                className="owl-theme owl-stage owlCarousel"
                loop
                margin={0}
                nav
                {...options}
              > */}

                <Link to="/coursescard" onClick={handleLinkClick} style={{ textAlign: "center", alignItems: "center",marginTop:'-300px' }}>
                  <div className="card_3" style={{ backgroundImage: `url(${webinarLogo})` }}>
                    <div className="card3_body">
                      <h1 className="card3_title">ONLINE ROBOTICS</h1>
                      <p className="desc">
                        Online robotics is a virtual playground where you can
                        build, test, and experiment with robots using just a
                        computer and internet connection. It's a digital
                        environment that mimics real-life scenarios, letting you
                        design and see how robots would behave without actually
                        building them physically.
                      </p>

                    </div>
                  </div>
                </Link>

              {/* </OwlCarousel> */}
            </div>
          </div>
        </div>
      </div>

      <AdvertisementShow/>
      
      {/* <div className="ad-container piop">
        <div className="ad-content"> */}
          {/* <div className="ad-item" style={{ marginRight: '15px' }}>Advertisement 1</div>
          <div className="ad-item" style={{ marginRight: '15px' }}>Advertisement 2</div>
          <div className="ad-item" style={{ marginRight: '15px' }}>Advertisement 3</div> */}
          {/* Add more advertisements as needed */}
        {/* </div>
      </div> */}
      <style>
        {`
   
        .ad-container {
  position: fixed;
  right: -60px;
  bottom: 0;
  top: 0;
  width: 320px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 80%;
}

.ad-item {
  padding: 20px;
  color: white;
  background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

      </style>
      
      <div style={{marginTop:'10px'}}>
      <Advertisecode />
      </div>
    </div>

  );
};

export default Courses;
