
import OwlCarousel from 'react-owl-carousel3';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Tabs, Tab } from '@mui/material';
import test from "../images/mech.jpg"; 
import DFS from "../images/DIGITALFACILITYSIMULATIONSV1.jpg"; 
import DFS1 from "../images/DIGITALFACILITYSIMULATIONSV2.jpg"; 
import DFS2 from "../images/DIGITAL FACILITY SIMULATIONS V3.jpg";
import DFS3 from "../images/DIGITAL FACILITY SIMULATIONS V4.jpg"; 
import DFS4 from "../images/DIGITAL FACILITY SIMULATIONS V5.jpg"; 
import test909 from "../images/java V1.jpg"; 
import test1 from "../images/Workshop.png";
import toolfix from "../images/banners/Tool-&-Fixture-v2.jpg";
import toolfixb from "../images/TOOL&FIXTUREV1.jpg";
import test4 from "../images/V3 copy.jpg";
import v111 from "../images/V11copy.jpg";
import test3 from "../images/certfi.jpg";
import FLUTTER11 from "../images/FLUTTERV1.jpg";
import FLUTTER1 from "../images/FLUTTER V2.jpg";
import FLUTTER4 from "../images/FLUTTER V4.jpg";
import FLUTTER5 from "../images/FLUTTER V5.jpg";
import FLUTTER2 from "../images/FLUTTER V3.jpg";
import image80 from "../images/image (80).png";
import react2 from "../images/React js v5.jpg";
import test23 from "../images/Reactjs.jpg";
import Reactjs1 from "../images/Reactjs1.jpg"
import FLUTTER from "../images/FLUTTER V1.jpg";
import react from "../images/React js v3.jpg";
import Toolv2 from "../images/TOOL & FIXTURE V3.jpg";
import Toolv3 from "../images/TOOL & FIXTURE V4.jpg";
import Toolv4 from "../images/TOOL & FIXTURE V5.jpg";
import react1 from "../images/React js v4.jpg";
import java1 from "../images/javav1.jpg";
import test167 from "../images/V4 copy.jpg";
import test1p from "../images/PYTHON V1.jpg";
import python78 from "../images/PYTHONV1.jpg";
import test1pp from "../images/PYTHON V2.jpg";
import test1ppj from "../images/PYTHON V3.jpg";
import vr from "../images/V1copy.jpg";
import python from "../images/PYTHON V4.jpg";
import test1670 from "../images/java V4.jpg";
import tooll from "../images/TOOL & FIXTURE V1.jpg";
import test312 from "../images/V2 copy.jpg";
import test899 from "../images/java V2.jpg"; 
import test111 from "../images/java V3.jpg"; 
import test11187 from "../images/java V5.jpg"; 
import lop from "../images/TOOL & FIXTURE V2.jpg"; 
import front1 from "../images/onrobo.jpg";
import ddf from "../images/DIGITALFACILITYSIMULATIONSV1.jpg";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 
import AdvertisementShow from './AdverstimentDes';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import Advertisecode from "./Advertisecode";
import React, { useState, useEffect } from 'react';
import javaBanner  from "../images/java V1.jpg";
import logo from "../images/mech.jpg"; // Default image for the first card
import webinarLogo from "../images/onrobo.jpg"; // Different image for the second card
import webinarLogo1 from "../images/Auto.jpeg"; // Different image for the second card
const Courses = () => {
  const [loading, setLoading] = useState(false);
  const [mainTabValue, setMainTabValue] = useState(0);  // Main tab (Mechanical or IT)
    const [nestedTabValue, setNestedTabValue] = useState(0);  // Nested tab (e.g., Tool & Fixture Design)
    const [subNestedTabValue, setSubNestedTabValue] = useState(0);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('tab1');
  const [activeTab1, setActiveTab1] = useState('tab3');
  // const subNavData = [
  //   { subNavId: 1, name: "Mechanical Engineering", subsidenavbar: { name: "Mechanical Engineering" } },
  //   { subNavId: 2, name: "Data Science", subsidenavbar: { name: "Data Science" } },
  //   { subNavId: 3, name: "Web Development", subsidenavbar: { name: "Web Development" } },
  //   // Other existing items
  // ];
  const navigate = useNavigate();
  const options = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1300: {
        items: 3,
      },
    },
  };


  


  const scrollTabs = (direction) => {
    const container = document.querySelector('.tab-container');
    const scrollAmount = direction === 'left' ? -300 : 300;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };


  const handleLinkClick = () => {
    setMainTabValue(0); // Set "Mechanical" tab
    setNestedTabValue(2); // Set "Digital Facility Solutions" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
   
  };


  const handleDropdownChange = (setter, event) => {
    setter(parseInt(event.target.value, 10));
  };


  const handleReactLinkClick = () => {
    setMainTabValue(1); // Set "Mechanical" tab
    setNestedTabValue(3); // Set "Digital Facility Solutions" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
   
  };


  const handleJavaLinkClick = () => {
    setMainTabValue(1); // Set "Mechanical" tab
    setNestedTabValue(4); // Set "Virtual Robotics" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
  };


  const handleVirtualLinkClick = () => {
    setMainTabValue(0); // Set "Mechanical" tab
    setNestedTabValue(1); // Set "Virtual Robotics" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
  };
  

  const handleToolLinkClick = () => {
    setMainTabValue(0); // Set "Mechanical" tab
    setNestedTabValue(0); // Set "Virtual Robotics" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
  };


  const handlePythonLinkClick = () => {
    setMainTabValue(1); // Set "Mechanical" tab
    setNestedTabValue(5); // Set "Virtual Robotics" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
  };


  const handleFlutterLinkClick = () => {
    setMainTabValue(1); // Set "Mechanical" tab
    setNestedTabValue(6); // Set "Virtual Robotics" tab
    // setSubNestedTabValue(0); // Default sub-nested tab
  };

  


  // useEffect(() => {
  //   if (currentLocation.pathname === "/DFS") {
  //     setMainTabValue(0); // Select "Mechanical"
  //     setNestedTabValue(2); // Select "Digital Facility Solution"
  //   } else if (currentLocation.pathname === "/IT") {
  //     setMainTabValue(1); // Select "IT"
  //   }
  // }, [currentLocation]);


  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setNestedTabValue(null); // Reset nested tab selection
    setSubNestedTabValue(null); 
  };

  // Nested tab change handler (Tool & Fixture Design, etc.)
  const handleNestedTabChange = (event, newValue) => {
    setNestedTabValue(newValue);
    setSubNestedTabValue(0); // Reset sub nested tab when nested tab changes
  };

  // Sub Nested tab change handler (Webinars, Workshop, Internship, Certification)
  const handleSubNestedTabChange = (event, newValue) => {
    setSubNestedTabValue(newValue);
  };


  return (

    <div style={{ marginTop: '80px' }}>
      
                
      
    {/* Main Tabs: Mechanical and IT */}
    <div style={{
      // flex:'wrap',
  display: 'flex',
  justifyContent: 'center', // Centers horizontally
  alignItems: 'center', // Centers vertically
  height: '10vh', // Full viewport height to center vertically
  flexDirection: 'column', // Stacks the main tabs, nested tabs, and sub-nested tabs vertically
  textAlign: 'center', // Centers the content inside the tabs
}}>

  {/* Main Tabs */}
  <button
             className="scroll-button left"
             onClick={() => scrollTabs('left')}
             style={{
               position: 'absolute',
               left: '190px',
               zIndex: '1',
               background: '#e0782c',
               border: 'none',
               borderRadius: '50%', // Makes the button circular
               width: '40px',
               height: '40px',
              
               display: 'flex',
               marginTop:'50px',
               justifyContent: 'center',
               alignItems: 'center',
               cursor: 'pointer',
             }}
           >
            
             <FaChevronLeft style={{ color: 'white', fontSize: '20px' }} />
           </button>
 
           
 
           <ul
     style={{
       display: 'flex',
       flexWrap: 'wrap', // Allows wrapping for mobile
       justifyContent: 'flex-start', // Align tabs
       listStyleType: 'none', // Removes bullets
       padding: '0',
       marginLeft: '90px',
     }}
       className="desktop-only"
   >
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: mainTabValue === 0 ? '#e0782c' : '#ccc',
         color: mainTabValue === 0 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => handleMainTabChange(null, 0)}
     >
       Mechanical
     </li>
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: mainTabValue === 1 ? '#e0782c' : '#ccc',
         color: mainTabValue === 1 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() =>handleMainTabChange(null, 1)}
     >
       IT
     </li>
   </ul>

   <select
              className="mobile-only"
              value={mainTabValue}
              onChange={(event) => handleDropdownChange(setMainTabValue, event)}
              style={{
                width: "100%",
                padding: "10px",
                marginLeft: "20px",
                fontSize: "16px",
                borderRadius: "5px",
                marginTop:'150px',
                border: "1px solid #ccc",
              }}
            >
              <option value={0}>Mechanical</option>
              <option value={1}>IT</option>
            </select>
   
 
   {/* Nested Tabs */}
 
  {mainTabValue === 0 && (
      <>
   <ul
     style={{
       display: 'flex',
       flexWrap: 'wrap', // Allows wrapping for small screens
       justifyContent: 'flex-start',
       listStyleType: 'none',
       padding: '0',
       marginTop: '10px',
       marginLeft: '90px',
     }}
       className="desktop-only"
   >
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 0 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 0 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(0)}
     >
       Tool & Fixture Design
     </li>
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 1 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 1 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(1)}
     >
       Virtual Robotics
     </li>
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 2 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 2 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(2)}
     >
       Digital Factory Solution
     </li>
   </ul>


<select
className="mobile-only"
style={{
  width: '100%',
  padding: '10px',
  borderRadius: '10px',
  marginTop: '10px',
}}
value={nestedTabValue}
onChange={(e) => setNestedTabValue(Number(e.target.value))}
>
<option value={0}>Tool & Fixture Design</option>
<option value={1}>Virtual Robotics</option>
<option value={2}>Digital Factory Solution</option>
</select>
  </>
  )}
 
 
 {mainTabValue === 1 && (
    <>
   <ul
     style={{
       display: 'flex',
       flexWrap: 'wrap', // Allows wrapping for small screens
       justifyContent: 'flex-start',
       listStyleType: 'none',
       padding: '0',
       marginTop: '10px',
       marginLeft: '90px',
     }}
       className="desktop-only"
   >
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 3 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 3 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(3)}
     >
      React Js
     </li>
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 4 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 4 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(4)}
     >
      Java Full Stack
     </li>
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 5 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 5 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(5)}
     >
     Python Full Stack
 
     </li>
     <li
       style={{
         borderRadius: '10px',
         backgroundColor: nestedTabValue === 6 ? '#e0782c' : '#ccc',
         color: nestedTabValue === 6 ? 'white' : 'black',
         marginRight: '30px',
         padding: '10px 20px',
         cursor: 'pointer',
       }}
       onClick={() => setNestedTabValue(6)}
     >
    Flutter
 
     </li>
   </ul>

 <select
 className="mobile-only"
 style={{
   width: '100%',
   padding: '10px',
   borderRadius: '10px',
   marginTop: '10px',
 }}
 value={nestedTabValue}
 onChange={(e) => setNestedTabValue(Number(e.target.value))}
>
 <option value={3}>React Js</option>
 <option value={4}>Java Full Stack</option>
 <option value={5}>Python Full Stack</option>
 <option value={6}>Flutter</option>
</select>
    </>
  )}
 
   {/* Sub-Nested Tabs */}
   {mainTabValue === 0 && (
      <>
     <ul
       style={{
         display: 'flex',
         flexWrap: 'wrap',
         justifyContent: 'flex-start',
         listStyleType: 'none',
         padding: '0',
         marginTop: '10px',
         marginLeft: '90px',
       }}
         className="desktop-only"
     >
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 0 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 0 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(0)}
       >
         Webinars
       </li>
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 1 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 1 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(1)}
       >
         Placement
       </li>
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 2 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 2 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(2)}
       >
         Training
       </li>
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 3 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 3 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(3)}
       >
         Internship
       </li>
     </ul>

<select
className="mobile-only"
style={{
  width: '100%',
  padding: '10px',
  borderRadius: '10px',
  marginTop: '10px',
}}
value={subNestedTabValue}
onChange={(e) => setSubNestedTabValue(Number(e.target.value))}
>
<option value={0}>Webinars</option>
<option value={1}>Placement</option>
<option value={2}>Training</option>
<option value={3}>Internship</option>
</select>
</>
   )}

     {mainTabValue === 1 && (
        <>
     <ul
       style={{
         display: 'flex',
         flexWrap: 'wrap',
         justifyContent: 'flex-start',
         listStyleType: 'none',
         padding: '0',
         marginTop: '10px',
         marginLeft: '90px',
       }}
         className="desktop-only"
     >
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 4 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 4 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(4)}
       >
         Webinars
       </li>
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 5 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 5 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(5)}
       >
         Placement
       </li>
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 6 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 6 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(6)}
       >
         Training
       </li>
       <li
         style={{
           borderRadius: '10px',
           backgroundColor: subNestedTabValue === 7 ? '#e0782c' : '#ccc',
           color: subNestedTabValue === 7 ? 'white' : 'black',
           marginRight: '30px',
           padding: '10px 20px',
           cursor: 'pointer',
         }}
         onClick={() => setSubNestedTabValue(7)}
       >
         Internship
       </li>
     </ul>

<select
className="mobile-only"
style={{
  width: '100%',
  padding: '10px',
  borderRadius: '10px',
  marginTop: '10px',
}}
value={subNestedTabValue}
onChange={(e) => setSubNestedTabValue(Number(e.target.value))}
>
<option value={4}>Webinars</option>
<option value={5}>Placement</option>
<option value={6}>Training</option>
<option value={7}>Internship</option>
</select>
</>
     )}
     
     
 
   <button
             className="scroll-button right"
             onClick={() => scrollTabs('right')}
             style={{
               position: 'absolute',
               right: '30px',
               zIndex: '1',
               background: '#e0782c',
               border: 'none',
               borderRadius: '50%', // Circular button
               width: '40px',
               height: '40px',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               cursor: 'pointer',
             //   marginTop:'100px'
             }}
           >
             <FaChevronRight style={{ color: 'white', fontSize: '20px' }} />
           </button>

</div>


<style>
        {`
          @media (max-width: 768px) {
            .main-tabs,
            .nested-tabs {
              flex-direction: column; /* Change to column for vertical arrangement */
              margin-left: 10px; /* Reduce margin for mobile */
            }
            .scroll-button.left,
            .scroll-button.right {
              display: none !important; /* Hide scroll buttons on small screens */
            }
          }
        `}
      </style>


     
 

    

{mainTabValue === 0 && nestedTabValue !== 0 && nestedTabValue !== 1 && nestedTabValue!==2 &&  nestedTabValue!==3 && (
      <div
        className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix courses-cards-data"
        id="services"
        style={{ marginTop: '90px', }} // Add margin-top here
      >
        <div className="containerdc">
          <div className="section-head text-center text-white">

            <div className="dlab-separator bg-primary"></div>
          </div>
          <div className="section-content desktop">
            <div className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
              <OwlCarousel

                className="owl-theme owl-stage owlCarousel"
                loop
                marginTop={0}
                nav
                {...options}
              >
                <Link onClick={handleLinkClick} style={{ textAlign: "center", alignItems: "center" }}>
                  <div
                    className="card_3"
                    style={{
                      backgroundImage: `url(${ddf})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "relative",
                      color: "white"
                    }}
                  >
                    {/* Overlay for text */}
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>DIGITAL FACILITY SOLUTIONS</h5>


                    </div>
                  </div>
                </Link>

                <Link onClick={handleVirtualLinkClick}  style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3" style={{ backgroundImage: `url(${v111})` }}>
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>Virtual Robotics</h5>


                    </div>
                  </div>
                </Link>
                <Link onClick={handleToolLinkClick} style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3" style={{ backgroundImage: `url(${toolfixb})` }}>
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>TOOL & FIXTURE DESIGN</h5>


                    </div>
                  </div>
                </Link>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>

                    )}

      
{mainTabValue === 0 && nestedTabValue === 1 && subNestedTabValue!==0 && subNestedTabValue!==1 && subNestedTabValue!==2 && subNestedTabValue!==3    && (
        <div>
        <section className="courses-banner-page-imge">

<img className="d-block full-img " src={vr} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.4%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "10%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Virtual Robotics</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",

            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}


        >
          Unlock your potential in Virtual Robotics
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>



<div className="tab-content1" style={{ padding: '30px 69px',width: "85.4%", marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Virtual Robotics</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
      )}


{mainTabValue === 0 && nestedTabValue === 2 && subNestedTabValue!==0 && subNestedTabValue!==1 && subNestedTabValue!==2 && subNestedTabValue!==3    && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={DFS} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-25px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-10 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Digital Facility Solution</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '100%'
          }}

        
        >
          Unlock your potential in Digital Facility Solution
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>




<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Digital Facility Solution</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
 </div>
</div>
)}


{subNestedTabValue === 0 && nestedTabValue === 0 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={lop} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}

{subNestedTabValue === 1 && nestedTabValue === 0 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={Toolv2} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>
)}


{subNestedTabValue === 2 && nestedTabValue === 0 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={Toolv3} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}


{subNestedTabValue === 3 && nestedTabValue === 0 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={Toolv4} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",  marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}





{mainTabValue === 0 && nestedTabValue === 0 && subNestedTabValue!==0 && subNestedTabValue!==1 && subNestedTabValue!==2 && subNestedTabValue!==3    && (
  <div>
 <section  style={{  }} className="courses-banner-page-imge">
        
        <img className="d-block full-img " src={tooll} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.4%",marginTop:'-30px' }} />
       
        <div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
        <div className="col-lg-10 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
              <h2
                className="main-banner-content"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "2.5rem",  // Use rem or vw for responsiveness
                  fontWeight: "800",
                  color: "#fff",
                  marginTop: "-450px",  // Adjust as needed for mobile view
                  textAlign: 'center'
                }}
              >
                <span style={{ color: "#F58842"}}>Tool And Fixture Design</span>
              </h2>
            </div>

            <div className="col-6 col-md-6 d-flex flex-column">
              <h2
                className="banner-content banner-second-data"
                style={{
                  fontFamily: "Poppins, SansSerif",
            
                  marginTop: "-450px", // Adjust for mobile view
                  width: '100%'
                }}
      
              
              >
                Unlock your potential in Tool And Fixture Design
              </h2>
            </div>
          </div>

          <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
            <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
              1. Industrial Oriented Training (IOT)
            </p>
            <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
              2. Job Oriented Skill Enhancement (JOSE)
            </p>
            <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
              3. 100% Placement Support
            </p>
            <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
              NX & CATIA Software free training
            </p>
          </div>
          <div class="row courses-download-btns">
            <button
              style={{
                backgroundColor: "orange", // or "#524DA1"
                color: "#fff", // White text color
                border: "none",
                width: "140px",
                marginLeft: '30px',
                pointerEvents: "auto",
                borderRadius: "5px", // Rounded corners
                padding: "10px 20px", // Padding around the text
                fontSize: "16px", // Font size
                cursor: "pointer", // Cursor changes to pointer on hover
                marginTop: "40px", // Space above the button
              }}

              onClick={(e) => {
                e.stopPropagation();



                // Add your download logic here
                navigate("/enroll")
              }}
            >
              ENROLL
            </button>
            <button
              style={{
                backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
                color: "#fff", // White text color
                border: "none",
                width: "240px",
                borderRadius: "5px", // Rounded corners
                padding: "10px 20px", // Padding around the text
                fontSize: "16px", // Font size
                cursor: "pointer", // Cursor changes to pointer on hover
                marginTop: "40px", // Space above the button
              }}
              onClick={() => {
                // Add your download logic here
                console.log("Download brochure clicked");
              }}
            >
              DOWNLOAD BROCHURE
            </button>


          </div>

        </div>
      </div>
      
      
    </section>

    <div className="tab-content1" style={{ padding: '30px 69px',marginTop:'0px', width: "85.4%"}}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
          <span style={{ color: '#f58842' }}>Tool And Fixture Design</span>
        </h2>
        <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
            Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
          </li>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
            Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
          </li>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
            Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
          </li>
          <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
            Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
          </li>
        </ul>
        <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
          Register Now
        </button>
        {/* {tabs.map(tab => (
          activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
        ))} */}
      </div>
</div>
)}






{subNestedTabValue === 0 && nestedTabValue === 1 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test312} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}




{subNestedTabValue === 1 && nestedTabValue === 1 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test167} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%" ,marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>
)}




{subNestedTabValue === 2 && nestedTabValue === 1 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test312} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{subNestedTabValue === 3 && nestedTabValue === 1 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test4} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%" ,  marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{subNestedTabValue === 0 && nestedTabValue === 2 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={DFS1} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%" ,marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{subNestedTabValue === 1 && nestedTabValue === 2 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={DFS2} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>
)}




{subNestedTabValue === 2 && nestedTabValue === 2 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={DFS3} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%" ,marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}


{subNestedTabValue === 3 && nestedTabValue === 2 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={DFS4} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p>
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",  marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{mainTabValue === 1 && nestedTabValue !== 3 && nestedTabValue !== 4 && nestedTabValue!==5 &&  nestedTabValue!==6 && (
      <div
        className="section-full bg-gray content-inner-1 what-we-do overlay-black-dark bg-img-fix courses-cards-data"
        id="services"
        style={{ marginTop: '0px', }} // Add margin-top here
      >
        <div className="containerdc">
          <div className="section-head text-center text-white">

            <div className="dlab-separator bg-primary"></div>
          </div>
          <div className="section-content desktop">
            <div className="course mfp-gallery gallery owl-none owl-loaded owl-theme owl-carousel owl-dots-white-full">
              <OwlCarousel

                className="owl-theme owl-stage owlCarousel"
                loop
                marginTop={0}
                nav
                {...options}
              >
                <Link onClick={handleReactLinkClick} style={{ textAlign: "center", alignItems: "center" }}>
                  <div
                    className="card_3"
                    style={{
                      backgroundImage: `url(${Reactjs1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "relative",
                      color: "white"
                    }}
                  >
                    {/* Overlay for text */}
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>React Js</h5>


                    </div>
                  </div>
                </Link>

                <Link onClick={handleJavaLinkClick}  style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3" style={{ backgroundImage: `url(${java1})` }}>
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>JAVA FULL STACK</h5>


                    </div>
                  </div>
                </Link>
                <Link onClick={handlePythonLinkClick} style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3" style={{ backgroundImage: `url(${python78})` }}>
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>PYTHON FULL STACK</h5>


                    </div>
                  </div>
                </Link>

                <Link onClick={handleFlutterLinkClick} style={{ textAlign: "center", alignItems: "center" }}>
                  <div className="card_3" style={{ backgroundImage: `url(${FLUTTER11})` }}>
                    <div
                      className="card_text_overlay"
                      style={{
                        marginTop: '50%',
                        marginLeft: '10%',
                        top: "50%",
                        left: "40%",
                        width: '80%',
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                        borderRadius: "8px"
                      }}
                    >
                      <h5 className="card3_title" style={{ margin: "10px 0", color: 'white', fontSize: '18px' }}>FLUTTER</h5>


                    </div>
                  </div>
                </Link>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>

                    )}








{mainTabValue === 1 && nestedTabValue === 3 && subNestedTabValue!==4 && subNestedTabValue!==5 && subNestedTabValue!==6 && subNestedTabValue!==7    && (
        <div>
        <section className="courses-banner-page-imge">

<img className="d-block full-img " src={image80} alt="Tool & Fixture Design" style={{ height: "480px", width: "85.4%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "10%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-520px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>React Js</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",

            marginTop: "-730px", // Adjust for mobile view
            width: '156%'
          }}


        >
          Unlock your potential in React Js
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-450px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>



<div className="tab-content1" style={{ padding: '30px 69px',width: "85.4%", marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>React Js</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
      )}


{mainTabValue === 1 && nestedTabValue === 4 && subNestedTabValue!==4 && subNestedTabValue!==5 && subNestedTabValue!==6 && subNestedTabValue!==7    && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test909} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-25px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-10 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Java Full Stack</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '100%'
          }}

        
        >
          Unlock your potential in Java Full Stack
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>




<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Java Full Stack</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
 </div>
</div>
)}





{mainTabValue === 1 && nestedTabValue === 5 && subNestedTabValue!==4 && subNestedTabValue!==5 && subNestedTabValue!==6 && subNestedTabValue!==7    && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test1p} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-25px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-10 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Python Full Stack</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '100%'
          }}

        
        >
          Unlock your potential in Python Full Stack
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>




<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Python Full Stack</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
 </div>
</div>
)}


{mainTabValue === 1 && nestedTabValue === 6 && subNestedTabValue!==4 && subNestedTabValue!==5 && subNestedTabValue!==6 && subNestedTabValue!==7    && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={FLUTTER} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-25px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-10 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Flutter</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '100%'
          }}

        
        >
          Unlock your potential in Flutter
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>




<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Flutter</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
 </div>
</div>
)}




{subNestedTabValue === 4 && nestedTabValue === 3 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test23} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}


{subNestedTabValue === 5 && nestedTabValue === 3 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={react} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>
)}




{subNestedTabValue === 6 && nestedTabValue === 3 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={react1} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{subNestedTabValue === 7 && nestedTabValue === 3 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={react2} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",  marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}




{subNestedTabValue === 4 && nestedTabValue === 4 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test899} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>

)}



{subNestedTabValue === 5 && nestedTabValue === 4 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test111} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>

)}



{subNestedTabValue === 6 && nestedTabValue === 4 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test1670} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{subNestedTabValue === 7 && nestedTabValue === 4 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test11187} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",  marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}




{subNestedTabValue === 4 && nestedTabValue === 5 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test1p} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>

)}



{subNestedTabValue === 5 && nestedTabValue === 5 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test1ppj} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>

)}




{subNestedTabValue === 6 && nestedTabValue === 5 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={test1pp} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}




{subNestedTabValue === 7 && nestedTabValue === 5 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={python} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",  marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}



{subNestedTabValue === 4 && nestedTabValue === 6 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={FLUTTER1} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Webinar</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Webinar
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Webinar</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>

)}


{subNestedTabValue === 5 && nestedTabValue === 6 && (
  <div>
 <section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={FLUTTER2} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>100% Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in 100% Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px', width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>100% Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>

</div>

)}


{subNestedTabValue === 6 && nestedTabValue === 6 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={FLUTTER4} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Training Placement Program</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Training Placement Program
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>

<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Training Placement Program</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}




{subNestedTabValue === 7 && nestedTabValue === 6 && (
  <div>
<section style={{  }} className="courses-banner-page-imge">

<img className="d-block  full-img " src={FLUTTER5} alt="Tool & Fixture Design" style={{ height: "500px", width: "85.3%", marginTop: '-30px' }} />

<div className="row" style={{ alignItems: "flex-start", height: "100%" }}>
  <div className="col-lg-8 dtd" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "0px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <div className="col-6 col-md-6 d-flex flex-column mb-4"> {/* Add margin-bottom to the first column */}
        <h2
          className="main-banner-content"
          style={{
            fontFamily: "Roboto",
            fontSize: "2.5rem",  // Use rem or vw for responsiveness
            fontWeight: "800",
            color: "#fff",
            marginTop: "-450px",  // Adjust as needed for mobile view
            textAlign: 'center'
          }}
        >
          <span style={{ color: "#F58842" }}>Internship</span>
        </h2>
      </div>

      <div className="col-6 col-md-6 d-flex flex-column">
        <h2
          className="banner-content banner-second-data"
          style={{
            fontFamily: "Poppins, SansSerif",
      
            marginTop: "-450px", // Adjust for mobile view
            width: '156%'
          }}

        
        >
          Unlock your potential in Internship
        </h2>
      </div>
    </div>

    <div style={{ marginTop: "-340px", textAlign: "center", marginLeft: '-10px' }} className="banner-orderlist-data" > {/* Add some space below the headings */}
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        1. Industrial Oriented Training (IOT)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        2. Job Oriented Skill Enhancement (JOSE)
      </p>
      <p className="banner-contentul" style={{ fontSize: "17px", color: "#fff", textAlign: "justify", textIndent: "50px", fontWeight: "bold" }}>
        3. 100% Placement Support
      </p>
      {/* <p className="banner-contentul" style={{ fontSize: "25px", color: "#fff", textAlign: "justify", fontWeight: "bold", marginLeft: "49px" }}>
        NX & CATIA Software free training
      </p> */}
    </div>
    <div  class="row courses-download-btns">
      <button
        style={{
          backgroundColor: "orange", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "140px",
          marginLeft: '30px',
          pointerEvents: "auto",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}

        onClick={(e) => {
          e.stopPropagation();



          // Add your download logic here
          navigate("/enroll")
        }}
      >
        ENROLL
      </button>
      <button
        style={{
          backgroundColor: "rgb(82, 77, 161)", // or "#524DA1"
          color: "#fff", // White text color
          border: "none",
          width: "240px",
          borderRadius: "5px", // Rounded corners
          padding: "10px 20px", // Padding around the text
          fontSize: "16px", // Font size
          cursor: "pointer", // Cursor changes to pointer on hover
          marginTop: "40px", // Space above the button
        }}
        onClick={() => {
          // Add your download logic here
          console.log("Download brochure clicked");
        }}
      >
        DOWNLOAD BROCHURE
      </button>


    </div>

  </div>
</div>


</section>


<div className="tab-content1" style={{ padding: '30px 69px',width: "85.3%",marginTop:'0px' }}>
<h2 style={{ fontSize: '24px', fontWeight: 'bold', color: '#fff' }}>
    <span style={{ color: '#f58842' }}>Internship</span>
  </h2>
  <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px', marginBottom: '30px' }}>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Explore the essential principles of designing and fabricating tools and fixtures to optimize manufacturing processes.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Learn to choose materials based on their characteristics to optimize performance, durability, and cost.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Apply GD&T principles to define precise dimensions and tolerances for engineering drawings.
    </li>
    <li style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#f58842', marginRight: '10px' }}>➤</span>
      Evaluate and optimize the cost-effectiveness of tool and fixture designs through detailed financial analysis.
    </li>
  </ul>
  <button style={{ padding: '10px 20px', backgroundColor: '#f58842', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}>
    Register Now
  </button>
  {/* {tabs.map(tab => (
    activeTab === tab.id && <div key={tab.id}>{tab.content}</div>
  ))} */}
</div>
</div>
)}





<style>
  {`
    .ad-container {
      position: fixed;
      right: -75px;
      top: 0;
      bottom: 0;
      width: 30vw; /* Use a dynamic width based on the viewport */
      max-width: 384px; /* Maximum width for larger screens */
      min-width: 190px; /* Minimum width to ensure ads are still visible on very small screens */
      overflow: hidden;
      background-color: white;
      transition: width 0.5s ease; /* Smooth transition for resizing */
    }

    .ad-content {
      display: flex;
      flex-direction: column;
      animation: scrollUp 15s linear infinite;
      position: absolute;
      bottom: 0; /* Start from the bottom */
      width: 100%; /* Ensure the content fills the container */
    }

    .ad-item {
      padding: 20px;
      color: white;
     background-color: #1367c8;
      margin: 5px 0;
      text-align: center;
    }

    /* Keyframes for scrolling up */
    @keyframes scrollUp {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(-150%);
      }
    }

    /* Media Queries for responsiveness */
    @media (max-width: 1200px) {
      .ad-container {
        width: 30vw; /* Adjust for medium screens */
      }
    }

    @media (max-width: 992px) {
      .ad-container {
        width: 35vw; /* Adjust for smaller screens */
      }
    }

    @media (max-width: 768px) {
      .ad-container {
        width: 40vw; /* Further adjust for mobile screens */
      }
    }

    @media (max-width: 480px) {
      .ad-container {
        width: 50vw; /* Max width for very small screens */
      }
    }

     .tab-container {
        // display: flex;
        justify-content: center; /* Center the tabs horizontally */
        margin-bottom: 20px; /* Space between tabs and content */
      }
      .tab-button {
        flex: 1; /* Make all tabs take equal width */
        max-width: 500px; /* Set a maximum width for each tab */
        padding: 10px;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
        margin-right: 5px; /* Add some space between tabs */
        outline: none; /* Remove default outline */
       
        // border-radius: 50px;
            background: #4f4e70;
             color: #f58842; /* Default text color */
                margin-top: 10px;
                font-size: 18px;
      }
      .tab-button:hover {
        background-color: #f0f0f0;
      }
      .tab-button.active {
        border-bottom: 2px solid #524DA1; /* Active tab color */
        font-weight: bold;
        color: #f58842; /* Change text color for active tab */
            // border-radius: 50px;
            background: #efefef;
                margin-top: 10px;
                font-size: 18px;
      }
    
      .tab-content1 {
        padding: 20px;
        border: 1px solid #ccc;
        border-top: none; /* Remove top border to connect with tabs */
        background-color: rgb(91 86 110);
            color: #fff;
      }
             .tab-button.disabled {
        background-color: #ddd; /* Grey out background */
        color: #888; /* Lighter text color */
        cursor: not-allowed; /* Show not-allowed cursor */
        pointer-events: none; /* Disable pointer events */
      }
         .tab-button:hover:not(.disabled) {
        background-color: #f0f0f0;
      }
        .ad-container {
  position: fixed;
  right:-65px;
  bottom: 0;
  top: 0;
  width: 384px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
  background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
  `}
</style>

<Advertisecode />
    </div>

  );
};

export default Courses;
