import Cards from "./Cards";
import React from 'react'





const Home = () => {
  return (
    <div>
      {/* <Stags/>
      <Charts/> */}
      {/* <Coursehome/> */}
      {/* <Results/> */}
      <Cards/>

    </div>
  )
}

export default Home