// const API_BASE_URL = "http://10.10.20.9:7071/IIA-Website-1.0.0";
const API_BASE_URL = "https://iiaserver.iiautomate.com/IIA-Website-1.0.0";


const apiUrls = {
    GET_ENROLL_DATA: `${API_BASE_URL}/enum/branches`,
    PUT_USER_ENROLL: `${API_BASE_URL}/enquiryform/addenrolldata`,
    PUT_USER_TIMING: `${API_BASE_URL}/enum/timings`,
    PUT_USER_SOURCE: `${API_BASE_URL}/enum/sources`,
    PUT_USER_CATEGORY: `${API_BASE_URL}/enum/categoryies`,
    PUT_USER_LOGIN: `${API_BASE_URL}/login/user`,
    PUT_Enquiryform_getall: `${API_BASE_URL}/enquiryform/getall`,
    PUT_Completed_getall: `${API_BASE_URL}/enrolledtable/getall`,
    PUT_enquiryenrolltable: `${API_BASE_URL}/enquiryregister/getall`,
    PUT_intrestedcandidatesgetall: `${API_BASE_URL}/intrestedcandidates/getall`,
    PUT_enum: `${API_BASE_URL}/enum/roles`,
    PUT_enum_category: `${API_BASE_URL}/enum/categoryies`,
    PUT_enum_category1: `${API_BASE_URL}/user/getallstudents`,
    PUT_controller_screen: `${API_BASE_URL}/controllerScreen/save`,
    PUT_side_all: `${API_BASE_URL}/sidenav/all`,
    // Add other API endpoints here if needed
  };
  
  export default apiUrls;