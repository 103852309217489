import React, { useState, useEffect,useRef  } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    IconButton,
} from '@mui/material';

import { CloudDownload, CloudUpload, RemoveRedEye, Edit } from '@mui/icons-material';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 

import { Tabs, Tab } from '@mui/material'; 

const Registered  = () => {
  const [mainTabValue, setMainTabValue] = useState(0);  // Main tab (Mechanical or IT)
  const [nestedTabValue, setNestedTabValue] = useState(0);  // Nested tab (e.g., Tool & Fixture Design)
  const [subNestedTabValue, setSubNestedTabValue] = useState(0);  // Additional sub-tabs under Webinars, Workshop, etc.
  const [filter, setFilter] = useState('');
  const [selected, setSelected] = useState([]);
  const [datasource, setDatasource] = useState([]); // Replace with actual data
  const [datasource1, setDatasource1] = useState([]);
  const [datasource2, setDatasource2] = useState([]);
  const [datasource3, setDatasource3] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [roles, setRoles] = useState([]);
  const [userDetails, setUserDetails] = useState({
    organisation: "",
    name: "",
    email: "Kumar@gmail.com",
    phoneNumber: "",
    role: "",
    category: "",
    remarks: "",
    password: ""


});


const handleDropdownChange = (setter, event) => {
    setter(parseInt(event.target.value, 10));
  };

  const [stages, setStages] = useState([]); 
  const [statusOptions, setStatusOptions] = useState([])
  const [interests, setInterests] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState('tab1');
   const [activeTab1, setActiveTab1] = useState('tab1');

 const [isPopupOpen, setIsPopupOpen] = useState(false);
 const [isPopupOpen1, setIsPopupOpen1] = useState(false);
 
 const [selectedRowData, setSelectedRowData] = useState(null);
 const [selectedSubNavId, setSelectedSubNavId] = useState(null);

 const { navId: routeNavId } = useParams(); // Get navId from route parameters
 const [navId, setNavId] = useState(() => (routeNavId ? Number(routeNavId) : undefined)); // Initialize navId with routeNavId
 const [isSelected, setIsSelected] = useState(false);
 const [directoryNavId, setDirectoryNavId] = useState(null);
 const navigate = useNavigate();
 const [category, setCategories] = useState([]);
 const [loading, setLoading] = useState(true);


  // Main tab change handler (Mechanical/IT)
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setNestedTabValue(0); // Reset nested tab when main tab changes
    setSubNestedTabValue(0); // Reset sub nested tab
  };

  // Nested tab change handler (Tool & Fixture Design, etc.)
  const handleNestedTabChange = (event, newValue) => {
    setNestedTabValue(newValue);
    setSubNestedTabValue(0); // Reset sub nested tab when nested tab changes
  };

  // Sub Nested tab change handler (Webinars, Workshop, Internship, Certification)
  const handleSubNestedTabChange = (event, newValue) => {
    setSubNestedTabValue(newValue);
  };


  function downloadBase64Pdf(base64String, fileName) {
    // Remove the base64 prefix if present
    const base64Prefix = 'data:application/pdf;base64,';
    if (base64String.startsWith(base64Prefix)) {
        base64String = base64String.replace(base64Prefix, '');
    }

    // Decode the base64 string into binary
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'document.pdf';
    link.click();

    // Revoke the Object URL after the download is triggered
    URL.revokeObjectURL(url);
}



  const handleViewResume = (base64Resume) => {
    // Convert Base64 to a Blob
    const byteCharacters = atob(base64Resume); // Decode Base64 string
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob);
  
    // Open the PDF in a new tab
    window.open(blobUrl, '_blank');
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = datasource.map((n) => n.userId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3, // Number of tabs visible at a time
    slidesToScroll: 1,
    nextArrow: <FaChevronRight style={{ fontSize: "20px", color: "white" }} />,
    prevArrow: <FaChevronLeft style={{ fontSize: "20px", color: "white" }} />,
    responsive: [
      {
        breakpoint: 768, // Adjust for smaller screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };


//   useEffect(() => {

//         const fetchData = async () => {
//             try {
//                 const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/getbycourseandprogram/6 Axis Industrial Robotic Virtual Simulation/WEBINAR PROGRAM`); // Replace with your API URL  
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
//                 setDatasource(data); // Set fetched data to the datasource state Layou2.js
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []); // Empty dependency array means this effect runs once on mount  



    useEffect(() => {
     
            const fetchData = async () => {
                try {
                    const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/getbycourseandprogram/6 Axis Industrial Robotic Virtual Simulation/TRAINING PLACEMENT PROGRAM`); // Replace with your API URL  
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setDatasource1(data); // Set fetched data to the datasource state Layou2.js
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
    
            fetchData();
        }, []); // Empty dependency array means this effect runs once on mount  
    

        useEffect(() => {
           
                const fetchData = async () => {
                    try {
                        const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/getbycourseandprogram/6 Axis Industrial Robotic Virtual Simulation/INTERNSHIP PLACEMENT PROGRAM`); // Replace with your API URL  
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const data = await response.json();
                        setDatasource2(data); // Set fetched data to the datasource state Layou2.js
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
        
                fetchData();
            }, []); // Empty dependency array means this effect runs once on mount  


            useEffect(() => {
                    const fetchData = async () => {
                        try {
                            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/getbycourseandprogram/6 Axis Industrial Robotic Virtual Simulation/100 Percent PLACEMENT PROGRAM`); // Replace with your API URL  
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            const data = await response.json();
                            setDatasource3(data); // Set fetched data to the datasource state Layou2.js
                        } catch (error) {
                            console.error('Error fetching data:', error);
                        }
                    };
            
                    fetchData();
                }, []); 


    useEffect(() => {
        const fetchStatusOptions = async () => {
          try {
            const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/status');
            setStatusOptions(response.data); // Assume the response is an array of statuses
          } catch (error) {
            console.error('Error fetching status options:', error);
          }
        };
    
        fetchStatusOptions();
      }, []);


      useEffect(() => {
        const fetchInterests = async () => {
          try {
            const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/intrest');
            setInterests(response.data); // Update with the response data
            setLoading(false); // Set loading to false
          } catch (error) {
            console.error('Error fetching interests:', error);
            setLoading(false);
          }
        };
    
        fetchInterests();
      }, []);


useEffect(() => {
    const fetchRoles = async () => {
        try {
            const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/roles');
            const data = await response.json();
            setRoles(data); // Assuming API returns an array of role strings
            setLoading(false);
        } catch (error) {
            console.error("Error fetching roles:", error);
            setLoading(false);
        }
    };

    fetchRoles();
}, []);


const handleRoleChange = (e) => {
    setUserDetails({ ...userDetails, role: e.target.value });
};


const handleRoleChange1 = (e) => {
    setSelectedRowData({ ...selectedRowData, role: e.target.value });
};



const handleCategoryChange = (e) => {
    setUserDetails({ ...userDetails, category: e.target.value });
};


const handleCategoryChange1 = (e) => {
    setSelectedRowData({ ...selectedRowData, categories: e.target.value });
};


// useEffect(() => {
//     const fetchData = async () => {
//         try {
//             const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/user/getallstudents'); // Replace with your API URL  
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const data = await response.json();
//             setDatasource(data); // Set fetched data to the datasource state Layou2.js
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     fetchData();
// }, []); // Empty dependency array means this effect runs once on mount  


useEffect(() => {
    const fetchCategories = async () => {
        try {
            const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/categoryies');
            const data = await response.json();
            setCategories(data); // Assuming API returns an array of strings
            setLoading(false);
        } catch (error) {
            console.error("Error fetching categories:", error);
            setLoading(false);
        }
    };

    fetchCategories();
}, []);


useEffect(() => {
    const fetchStages = async () => {
      try {
        const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/stage');
        setStages(response.data); // Assume response contains an array of stages
        setLoading(false); // Set loading to false
      } catch (error) {
        console.error('Error fetching stages:', error);
        setLoading(false);
      }
    };

    fetchStages();
  }, []);


const AddRecord = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                organisation: userDetails.organisation,
                name: userDetails.name,
                mobileNumber: userDetails.phoneNumber,
                emailId: userDetails.emailId,
                password: userDetails.password,
                categories: userDetails.category,
                role: userDetails.role,
                remarks: userDetails.remarks,
            }),
        });

        // Check if the response is successful
        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Failed to register. Please try again.');
        }

        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType.includes('application/json')) {
            // If the response is JSON, handle it accordingly
            const data = await response.json();
            console.log('Registration successful:', data);

            // You can navigate to a different page or close the form here
            navigate('/layout1');
            window.location.reload();
            alert('Data Successfully Saved');
            setIsPopupOpen(false);  // Close the popup
        } else if (contentType && contentType.includes('text/plain')) {
            // If the response is plain text (success message)
            const textResponse = await response.text();
            console.log('Response:', textResponse); // Log the response text (e.g., "User Registered Successfully")

            alert(textResponse); // Show the success message in an alert
            navigate('/layout1');
            window.location.reload();
            setIsPopupOpen(false);  // Close the popup
        } else {
            throw new Error('Unexpected response format.');
        }
    } catch (error) {
        console.error('Error:', error);
        // Handle error, show a message or perform other actions
        alert(error.message || 'Something went wrong, please try again.');
    }
};



const updateRecord = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);
  
    // Prepare the payload based on API requirements
    const payload = {
      candidateResponse: selectedRowData.candidateResponse,
      stage: selectedRowData.stage,
      status: selectedRowData.status, // Ensure this matches the API field
      remark: selectedRowData.remark,
      // Add other fields as necessary
    };
  
    try {
      const response = await axios.put(
        `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/edit/${selectedRowData.enquiryId}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json', // Ensuring the content is JSON
          },
        }
      );
      alert('Record updated successfully:')
      console.log('Record updated successfully:', response.data);
  
      // Optionally, refresh data or notify the user
      setIsPopupOpen1(false); // Close the modal
      // Reset selectedRowData or update parent state as needed
    } catch (error) {
      console.error('Error updating record:', error);
      // Optionally, display error message to the user
    } finally {
      setLoadingSubmit(false);
    }
  };
  


const handlePopup = () => {
    setIsPopupOpen(true);
};

const handlePopup1 = (rowData) => {
    // alert(rowData)
    setSelectedRowData(rowData);
    setIsPopupOpen1(true);
};


const exportToExcel = () => {
    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(datasource);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Generate a binary Excel file
    XLSX.writeFile(workbook, 'exported_data.xlsx');
};



// const handleClick = (event, row) => {
//     const selectedIndex = selected.indexOf(row.id);
//     let newSelected = [];

//     if (selectedIndex === -1) {
//         newSelected = newSelected.concat(selected, row.id);
//     } else if (selectedIndex === 0) {
//         newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//         newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//         newSelected = newSelected.concat(
//             selected.slice(0, selectedIndex),
//             selected.slice(selectedIndex + 1),
//         );
//     }

//     setSelected(newSelected);
// };


const handlePageChange = (event, newPage) => {
    setPage(newPage);
};

const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
};







const tabsContainerRef = useRef(null); // Reference for tab container

  // Function to scroll tabs left or right
  const scrollTabs = (direction) => {
    const container = tabsContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    } else {
      console.error('tabsContainerRef is null');
    }
  };




 
  return (
    <div style={{ marginTop: '80px' }}>
    {/* Main Tabs: Mechanical and IT */}
    <div style={{
  display: 'flex',
  justifyContent: 'center', // Centers horizontally
  alignItems: 'center', // Centers vertically
  height: '10vh', // Full viewport height to center vertically
  flexDirection: 'column', // Stacks the main tabs, nested tabs, and sub-nested tabs vertically
  textAlign: 'center', // Centers the content inside the tabs
}}>

  {/* Main Tabs */}
  <button
            className="scroll-button left"
            onClick={() => scrollTabs('left')}
            style={{
              position: 'absolute',
              left: '190px',
              zIndex: '1',
              background: '#e0782c',
              border: 'none',
              borderRadius: '50%', // Makes the button circular
              width: '40px',
              height: '40px',
             
              display: 'flex',
              marginTop:'50px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
           
            <FaChevronLeft style={{ color: 'white', fontSize: '20px' }} />
          </button>

          

          <ul
    style={{
      display: 'flex',
      flexWrap: 'wrap', // Allows wrapping for mobile
      justifyContent: 'flex-start', // Align tabs
      listStyleType: 'none', // Removes bullets
      padding: '0',
      marginLeft: '90px',
    }}
     className="desktop-only"
  >
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: mainTabValue === 0 ? '#e0782c' : '#ccc',
        color: mainTabValue === 0 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => handleMainTabChange(null, 0)}
    >
      Mechanical
    </li>
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: mainTabValue === 1 ? '#e0782c' : '#ccc',
        color: mainTabValue === 1 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() =>handleMainTabChange(null, 1)}
    >
      IT
    </li>
  </ul>


  <select
              className="mobile-only"
              value={mainTabValue}
              onChange={(event) => handleDropdownChange(setMainTabValue, event)}
              style={{
                width: "100%",
                padding: "10px",
                marginLeft: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                marginTop:'150px',
                border: "1px solid #ccc",
              }}
            >
              <option value={0}>Mechanical</option>
              <option value={1}>IT</option>
            </select>

  {/* Nested Tabs */}

 {mainTabValue === 0 && (
  <ul
    style={{
      display: 'flex',
      flexWrap: 'wrap', // Allows wrapping for small screens
      justifyContent: 'flex-start',
      listStyleType: 'none',
      padding: '0',
      marginTop: '10px',
      marginLeft: '90px',
    }}
     className="desktop-only"
  >
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 0 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 0 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(0)}
    >
      Tool & Fixture Design
    </li>
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 1 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 1 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(1)}
    >
      Virtual Robotics
    </li>
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 2 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 2 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(2)}
    >
      Digital Factory Solution
    </li>
  </ul>
 )}


{mainTabValue === 1 && (
  <ul
    style={{
      display: 'flex',
      flexWrap: 'wrap', // Allows wrapping for small screens
      justifyContent: 'flex-start',
      listStyleType: 'none',
      padding: '0',
      marginTop: '10px',
      marginLeft: '90px',
    }}
     className="desktop-only"
  >
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 0 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 0 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(0)}
    >
     React Js
    </li>
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 1 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 1 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(1)}
    >
     Java Full Stack
    </li>
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 2 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 2 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(2)}
    >
    Python Full Stack

    </li>
    <li
      style={{
        borderRadius: '10px',
        backgroundColor: nestedTabValue === 3 ? '#e0782c' : '#ccc',
        color: nestedTabValue === 3 ? 'white' : 'black',
        marginRight: '30px',
        padding: '10px 20px',
        cursor: 'pointer',
      }}
      onClick={() => setNestedTabValue(3)}
    >
   Flutter

    </li>
  </ul>
 )}

  {/* Sub-Nested Tabs */}
  {nestedTabValue !== null && (
    <ul
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        listStyleType: 'none',
        padding: '0',
        marginTop: '10px',
        marginLeft: '90px',
      }}
       className="desktop-only"
    >
      <li
        style={{
          borderRadius: '10px',
          backgroundColor: subNestedTabValue === 0 ? '#e0782c' : '#ccc',
          color: subNestedTabValue === 0 ? 'white' : 'black',
          marginRight: '30px',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
        onClick={() => setSubNestedTabValue(0)}
      >
        Webinars
      </li>
      <li
        style={{
          borderRadius: '10px',
          backgroundColor: subNestedTabValue === 1 ? '#e0782c' : '#ccc',
          color: subNestedTabValue === 1 ? 'white' : 'black',
          marginRight: '30px',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
        onClick={() => setSubNestedTabValue(1)}
      >
        Placement
      </li>
      <li
        style={{
          borderRadius: '10px',
          backgroundColor: subNestedTabValue === 2 ? '#e0782c' : '#ccc',
          color: subNestedTabValue === 2 ? 'white' : 'black',
          marginRight: '30px',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
        onClick={() => setSubNestedTabValue(2)}
      >
        Training
      </li>
      <li
        style={{
          borderRadius: '10px',
          backgroundColor: subNestedTabValue === 3 ? '#e0782c' : '#ccc',
          color: subNestedTabValue === 3 ? 'white' : 'black',
          marginRight: '30px',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
        onClick={() => setSubNestedTabValue(3)}
      >
        InternShip
      </li>
    </ul>
    
  )}

{mainTabValue === 0 && (
  <select
    className="mobile-only"
    value={nestedTabValue}
    onChange={(event) => setNestedTabValue(Number(event.target.value))}
    style={{
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      marginTop: "10px",
      border: "1px solid #ccc",
    }}
  >
    <option value={0}>Tool & Fixture Design</option>
    <option value={1}>Virtual Robotics</option>
    <option value={2}>Digital Factory Solution</option>
  </select>
)}

{mainTabValue === 1 && (
  <select
    className="mobile-only"
    value={nestedTabValue}
    onChange={(event) => setNestedTabValue(Number(event.target.value))}
    style={{
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      marginTop: "10px",
      border: "1px solid #ccc",
    }}
  >
    <option value={0}>React Js</option>
    <option value={1}>Java Full Stack</option>
    <option value={2}>Python Full Stack</option>
    <option value={3}>Flutter</option>
  </select>
)}

{/* Dropdown for Sub-Nested Tabs (Mobile Only) */}
{nestedTabValue !== null && (
  <select
    className="mobile-only"
    value={subNestedTabValue}
    onChange={(event) => setSubNestedTabValue(Number(event.target.value))}
    style={{
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      borderRadius: "5px",
      marginTop: "10px",
      border: "1px solid #ccc",
    }}
  >
    <option value={0}>Webinars</option>
    <option value={1}>Placement</option>
    <option value={2}>Training</option>
    <option value={3}>Internship</option>
  </select>
)}
  <button
            className="scroll-button right"
            onClick={() => scrollTabs('right')}
            style={{
              position: 'absolute',
              right: '30px',
              zIndex: '1',
              background: '#e0782c',
              border: 'none',
              borderRadius: '50%', // Circular button
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            //   marginTop:'100px'
            }}
          >
            <FaChevronRight style={{ color: 'white', fontSize: '20px' }} />
          </button>

</div>


<style>
        {`
          @media (max-width: 768px) {
            .main-tabs,
            .nested-tabs {
              flex-direction: column; /* Change to column for vertical arrangement */
              margin-left: 10px; /* Reduce margin for mobile */
            }
            .scroll-button.left,
            .scroll-button.right {
              display: none !important; /* Hide scroll buttons on small screens */
            }
          }
        `}
      </style>







  
    {/* Card and Table Content */}
    {subNestedTabValue === 0 && nestedTabValue === 0 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
        overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource.length}
                                    checked={datasource.length > 0 && selected.length === datasource.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume','Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                    <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>

                                    <TableCell align="center" style={{ minWidth: '120px' }}>
                                    {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                        
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Program Course"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                           
                            
                           
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Status"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            
                           
                        <div>
      {loading ? (
        <p>Loading interests...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.candidateResponse}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
        >
          <option value="" disabled>
            Select Interest
          </option>
          {interests.map((interest, index) => (
            <option key={index} value={interest.value}>
              {interest}
            </option>
          ))}
        </select>
      )}
    </div>
    <div className="form-group">
      {loading ? (
        <p>Loading stages...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.stage}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
        >
          <option value="" disabled>
            Select Stage
          </option>
          {stages.map((stage, index) => (
            <option key={index} value={stage.value}>
              {stage}
            </option>
          ))}
        </select>
      )}
    </div>
                            <select
        className="form-control"
        value={selectedRowData.status}
        onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
        required
      >
        <option value="" disabled>
          Select Status
        </option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
                           
                            
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
  <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
  <button type="submit" className="btn btn-save">Save</button>
  <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

                        </form>
                    </div>
                </div>

            )}


          

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
           
            {/* <Advertisecode /> */}
        </Card>

                )}
{subNestedTabValue === 1 && nestedTabValue === 0 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
        overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource.length}
                                    checked={datasource.length > 0 && selected.length === datasource.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                    <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                                    <TableCell align="center" style={{ minWidth: '120px' }}>
                                        {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                            <RemoveRedEye style={{ color: 'green' }} />
                                        </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                        
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Program Course"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                           
                            
                           
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Status"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            
                           
                        <div>
      {loading ? (
        <p>Loading interests...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.candidateResponse}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
        >
          <option value="" disabled>
            Select Interest
          </option>
          {interests.map((interest, index) => (
            <option key={index} value={interest.value}>
              {interest}
            </option>
          ))}
        </select>
      )}
    </div>
    <div className="form-group">
      {loading ? (
        <p>Loading stages...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.stage}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
        >
          <option value="" disabled>
            Select Stage
          </option>
          {stages.map((stage, index) => (
            <option key={index} value={stage.value}>
              {stage}
            </option>
          ))}
        </select>
      )}
    </div>
                            <select
        className="form-control"
        value={selectedRowData.status}
        onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
        required
      >
        <option value="" disabled>
          Select Status
        </option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
                           
                            
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
  <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
  <button type="submit" className="btn btn-save">Save</button>
  <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

                        </form>
                    </div>
                </div>

            )}


           
          

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
           
            {/* <Advertisecode /> */}
        </Card>

)}



{subNestedTabValue === 2 && nestedTabValue === 0 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
        overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource.length}
                                    checked={datasource.length > 0 && selected.length === datasource.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                    <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                                    <TableCell align="center" style={{ minWidth: '120px' }}>
                                        {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                            <RemoveRedEye style={{ color: 'green' }} />
                                        </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                       
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Program Course"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                           
                            
                           
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Status"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            
                           
                        <div>
      {loading ? (
        <p>Loading interests...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.candidateResponse}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
        >
          <option value="" disabled>
            Select Interest
          </option>
          {interests.map((interest, index) => (
            <option key={index} value={interest.value}>
              {interest}
            </option>
          ))}
        </select>
      )}
    </div>
    <div className="form-group">
      {loading ? (
        <p>Loading stages...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.stage}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
        >
          <option value="" disabled>
            Select Stage
          </option>
          {stages.map((stage, index) => (
            <option key={index} value={stage.value}>
              {stage}
            </option>
          ))}
        </select>
      )}
    </div>
                            <select
        className="form-control"
        value={selectedRowData.status}
        onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
        required
      >
        <option value="" disabled>
          Select Status
        </option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
                           
                            
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
  <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
  <button type="submit" className="btn btn-save">Save</button>
  <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

                        </form>
                    </div>
                </div>

            )}


           
          

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
           
            {/* <Advertisecode /> */}
        </Card>

)}



{subNestedTabValue === 0 && nestedTabValue === 1 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
        overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource.length}
                                    checked={datasource.length > 0 && selected.length === datasource.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume','Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                    <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                                    <TableCell align="center" style={{ minWidth: '120px' }}>
                                    {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                       
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Program Course"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                           
                            
                           
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Status"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            
                           
                        <div>
      {loading ? (
        <p>Loading interests...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.candidateResponse}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
        >
          <option value="" disabled>
            Select Interest
          </option>
          {interests.map((interest, index) => (
            <option key={index} value={interest.value}>
              {interest}
            </option>
          ))}
        </select>
      )}
    </div>
    <div className="form-group">
      {loading ? (
        <p>Loading stages...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.stage}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
        >
          <option value="" disabled>
            Select Stage
          </option>
          {stages.map((stage, index) => (
            <option key={index} value={stage.value}>
              {stage}
            </option>
          ))}
        </select>
      )}
    </div>
                            <select
        className="form-control"
        value={selectedRowData.status}
        onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
        required
      >
        <option value="" disabled>
          Select Status
        </option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
                           
                            
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
  <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
  <button type="submit" className="btn btn-save">Save</button>
  <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

                        </form>
                    </div>
                </div>

            )}


           
          

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
           
            {/* <Advertisecode /> */}
        </Card>

)}





{subNestedTabValue === 3 && nestedTabValue === 0 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
        overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource.length}
                                    checked={datasource.length > 0 && selected.length === datasource.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                    <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                                    <TableCell align="center" style={{ minWidth: '120px' }}>
                                        {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                            <RemoveRedEye style={{ color: 'green' }} />
                                        </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                      
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Program Course"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                           
                            
                           
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Status"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            
                           
                        <div>
      {loading ? (
        <p>Loading interests...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.candidateResponse}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
        >
          <option value="" disabled>
            Select Interest
          </option>
          {interests.map((interest, index) => (
            <option key={index} value={interest.value}>
              {interest}
            </option>
          ))}
        </select>
      )}
    </div>
    <div className="form-group">
      {loading ? (
        <p>Loading stages...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.stage}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
        >
          <option value="" disabled>
            Select Stage
          </option>
          {stages.map((stage, index) => (
            <option key={index} value={stage.value}>
              {stage}
            </option>
          ))}
        </select>
      )}
    </div>
                            <select
        className="form-control"
        value={selectedRowData.status}
        onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
        required
      >
        <option value="" disabled>
          Select Status
        </option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
                           
                            
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
  <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
  <button type="submit" className="btn btn-save">Save</button>
  <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

                        </form>
                    </div>
                </div>

            )}

{/* <AdvertisementShow/> */}
            {/* <div className="ad-container">
                <div className="ad-content">
                    <div className="ad-item">Advertisement 1</div> */}
                    {/* <div className="ad-item">Advertisement 2</div>
                    <div className="ad-item">Advertisement 3</div> */}
                    {/* Add more advertisements as needed */}
                {/* </div>
            </div> */}
           
          

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
           
            {/* <Advertisecode /> */}
        </Card>

)}




{subNestedTabValue === 2 && nestedTabValue === 1 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
        overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource1.length}
                                    checked={datasource1.length > 0 && selected.length === datasource1.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource1
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                    <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                                    <TableCell align="center" style={{ minWidth: '120px' }}>
                                    {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                       
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Program Course"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                           
                            
                           
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Status"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            
                           
                        <div>
      {loading ? (
        <p>Loading interests...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.candidateResponse}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
        >
          <option value="" disabled>
            Select Interest
          </option>
          {interests.map((interest, index) => (
            <option key={index} value={interest.value}>
              {interest}
            </option>
          ))}
        </select>
      )}
    </div>
    <div className="form-group">
      {loading ? (
        <p>Loading stages...</p>
      ) : (
        <select
          className="form-control"
          required
          value={selectedRowData.stage}
          onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
        >
          <option value="" disabled>
            Select Stage
          </option>
          {stages.map((stage, index) => (
            <option key={index} value={stage.value}>
              {stage}
            </option>
          ))}
        </select>
      )}
    </div>
                            <select
        className="form-control"
        value={selectedRowData.status}
        onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
        required
      >
        <option value="" disabled>
          Select Status
        </option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
                           
                            
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
  <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
  <button type="submit" className="btn btn-save">Save</button>
  <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

                        </form>
                    </div>
                </div>

            )}


           
          

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
           
            {/* <Advertisecode /> */}
        </Card>

)}


{subNestedTabValue === 3 && nestedTabValue === 1 && (

<Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource2.length}
                        checked={datasource2.length > 0 && selected.length === datasource2.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource2
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>

)}



{subNestedTabValue === 0 && nestedTabValue === 2 && (

<Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>

)}


{subNestedTabValue === 1 && nestedTabValue === 2 && (

<Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>

)}


{subNestedTabValue === 2 && nestedTabValue === 2 && (

<Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}



{subNestedTabValue === 3 && nestedTabValue === 2 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume','Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}


{subNestedTabValue === 1 && nestedTabValue === 1 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource3.length}
                        checked={datasource3.length > 0 && selected.length === datasource3.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume','Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource3
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                            {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                <RemoveRedEye style={{ color: 'green' }} />
                            </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}




{subNestedTabValue === 0 && nestedTabValue === 3 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                            {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                <RemoveRedEye style={{ color: 'green' }} />
                            </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}






{subNestedTabValue === 1 && nestedTabValue === 3 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                            {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                <RemoveRedEye style={{ color: 'green' }} />
                            </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}





{subNestedTabValue === 2 && nestedTabValue === 3 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume', 'Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                            {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                <RemoveRedEye style={{ color: 'green' }} />
                            </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}




{subNestedTabValue === 3 && nestedTabValue === 3 && (
    <Card class="usernameydf lllhji" style={{marginTop:'40px',height: '350px',marginRight:'20px'}} >
<CardHeader
    style={{
        backgroundColor: 'whitesmoke',
        marginTop: '5%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }}
    title={
        <TextField
            placeholder="Filter"
            value={filter}
            onChange={handleFilterChange}
            className="filterAlignment"
            style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
            variant="outlined" // Try using outlined or standard variant
            InputProps={{
                style: {
                    padding: '4px 10px', // Adjust padding for height
                    height: '38px', // Ensure this matches the height you want
                },
                inputProps: {
                    style: { height: '38px' } // Set the height of the input element itself
                }
            }}
        />
    }
    action={
        <>
            <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
                id="fileUpload"
            />
            <label htmlFor="fileUpload">
                <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                    <CloudDownload style={{ marginRight: '8px' }} />
                    Import
                </Button>
            </label>
            <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                <CloudUpload style={{ marginRight: '8px' }} />
                Export
            </Button>
            <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                + Add
            </Button>
        </>



    }
/>
<TableContainer style={{ marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
overflowY: 'auto',overflowX: 'auto',maxHeight: '190px', }}>
    <Table>
        <TableHead style={{ backgroundColor: 'lightblue' }}>
            <TableRow>
                <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Checkbox
                        indeterminate={selected.length > 0 && selected.length < datasource.length}
                        checked={datasource.length > 0 && selected.length === datasource.length}
                        onChange={handleSelectAllClick}
                    />
                </TableCell>
                {['S.No', 'Name', 'Email Id', 'Contact Number','Program', 'Course','Date','Time', 'Source','Conseller','Reference','Response','Stage','Status', 'Remarks','Resume','Action'].map((heading, index) => (
                    <TableCell
                        align="center"
                        key={index}
                        style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                    >
                        {heading}
                    </TableCell>
                ))}
                {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
            </TableRow>
        </TableHead>
        <TableBody>
            {datasource
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                    <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                    >
                        <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                            <Checkbox
                                checked={selected.indexOf(row.userId) !== -1}
                                onChange={() => handleClick(null, row)}
                            />
                        </TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.name}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '150px' }}>{row.mobileNumber}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.program}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '180px' }}>{row.course}</p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '100px' }}>
    {new Date(row.startDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}
  </p></TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.status}</TableCell>
                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                        <TableCell align="center" style={{borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
    {row.resume ? (
        <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
            <CloudDownload style={{ color: 'blue' }} />
        </IconButton>
    ) : (
        <span>No Resume</span>
    )}
</TableCell>
                        <TableCell align="center" style={{ minWidth: '120px' }}>
                            {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                <RemoveRedEye style={{ color: 'green' }} />
                            </IconButton> */}
                            <IconButton onClick={() => handlePopup1(row)}>
                                <Edit />
                            </IconButton>
                        </TableCell>


                    </TableRow>
                ))}
        </TableBody>
    </Table>
</TableContainer>


{isPopupOpen && (
    <div className="modal-overlay">
        <div className="modal-content1">

            <div className="header-row">
                <div className="serial-number">S No-[0]</div>
                <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={AddRecord}>
           
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Program Course"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                    />
                </div>
               
                
               
                <div className="form-group">
                    {loading ? (
                        <p>Loading roles...</p>
                    ) : (
                        <select
                            className="form-control"
                            required
                            value={userDetails.role}
                            onChange={handleRoleChange}
                        >
                            <option value="" disabled>SPOC</option>
                            {/* {roles.map((role, index) => (
                                // <option key={index} value={role}>
                                //     {role}
                                // </option>
                            ))} */}
                        </select>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Status"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        required
                        onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                    />
                </div>
                <div className="button-group">
                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button type="button" className="btn btn-save">Save</button>
                    <button type="submit" className="btn btn-submit">Submit</button>
                </div>
            </form>
        </div>
    </div>

)}



{isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

    <div className="modal-overlay">
        <div className="modal-content1">


            <div className="header-row">
                <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
            </div>
            <form className="form-grid" onSubmit={updateRecord}>
                
               
            <div>
{loading ? (
<p>Loading interests...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.candidateResponse}
onChange={(e) => setSelectedRowData({ ...selectedRowData, candidateResponse: e.target.value })}
>
<option value="" disabled>
Select Interest
</option>
{interests.map((interest, index) => (
<option key={index} value={interest.value}>
  {interest}
</option>
))}
</select>
)}
</div>
<div className="form-group">
{loading ? (
<p>Loading stages...</p>
) : (
<select
className="form-control"
required
value={selectedRowData.stage}
onChange={(e) => setSelectedRowData({ ...selectedRowData, stage: e.target.value })}
>
<option value="" disabled>
Select Stage
</option>
{stages.map((stage, index) => (
<option key={index} value={stage.value}>
  {stage}
</option>
))}
</select>
)}
</div>
                <select
className="form-control"
value={selectedRowData.status}
onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
required
>
<option value="" disabled>
Select Status
</option>
{statusOptions.map((status) => (
<option key={status} value={status}>
{status}
</option>
))}
</select>
               
                
                <div className="form-group">
                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:'130%' }}>
<button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
<button type="submit" className="btn btn-save">Save</button>
<button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
</div>

            </form>
        </div>
    </div>

)}





<CardActions>
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={datasource.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
    />
</CardActions>

{/* <Advertisecode /> */}
</Card>
)}











      {/* <AdvertisementShow/> */}
    

<style>
  {`
    .ad-container {
      position: fixed;
      right: -75px;
      top: 0;
      bottom: 0;
      width: 25vw; /* Use a dynamic width based on the viewport */
      max-width: 384px; /* Maximum width for larger screens */
      min-width: 150px; /* Minimum width to ensure ads are still visible on very small screens */
      overflow: hidden;
      background-color: white;
      transition: width 0.5s ease; /* Smooth transition for resizing */
    }

    .ad-content {
      display: flex;
      flex-direction: column;
      animation: scrollUp 15s linear infinite;
      position: absolute;
      bottom: 0; /* Start from the bottom */
      width: 100%; /* Ensure the content fills the container */
    }

    .ad-item {
      padding: 20px;
      color: white;
     background-color: #1367c8;
      margin: 5px 0;
      text-align: center;
    }

    /* Keyframes for scrolling up */
    @keyframes scrollUp {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(-150%);
      }
    }

    /* Media Queries for responsiveness */
    @media (max-width: 1200px) {
      .ad-container {
        width: 30vw; /* Adjust for medium screens */
      }
    }

    @media (max-width: 992px) {
      .ad-container {
        width: 35vw; /* Adjust for smaller screens */
      }
    }

    @media (max-width: 768px) {
      .ad-container {
        width: 40vw; /* Further adjust for mobile screens */
      }
    }

    @media (max-width: 480px) {
      .ad-container {
        width: 50vw; /* Max width for very small screens */
      }
    }
  `}
</style>

{/* <Advertisecode /> */}
    </div>

  
  
  );
};

export default Registered;
