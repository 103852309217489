import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import Advertisecode from "./Advertisecode";
import { Tab, Tabs } from "react-bootstrap";
import AdvertisementShow from './AdverstimentDes';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
// import Contacts from "./Contacts";
import logo from "../images/logo-white-2.png";
import apiUrls from './apiUrls'; 
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from 'axios'; // Or you can use fetch
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
} from '@mui/material';

const Enroll = () => {
  const { userId } = useParams();
  const fieldSelectRef = useRef(null);
  const [showWebDevDropdown, setShowWebDevDropdown] = useState(false);
  const [showMechanicalDropdown, setShowMechanicalDropdown] = useState(false);
  const [datasource, setDatasource] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filteredData, setFilteredData] = useState(datasource);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [date, setDate] = useState('');
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [key, setKey] = useState('registration'); // Managing active tab
  const [offlinerobotics, setOfflinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [punchInTime, setPunchInTime] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0); // Store total seconds to calculate hours

  // const [formData, setFormData] = useState({
  //   fullName: "",
  //   gender: "",
  //   dateOfBirth: "",
  //   emailId: "",
  //   contactNumber: "",
  //   course: "",
  //   timings: "",
  //   mode: "",
  //   courseDuration: "",
  //   categories: "",
  //   coursePrice: "",
  //   councillorName: "",
  // });
  const [toolbarfixture, setToolbarfixture] = useState(null);
  const [branches, setBranches] = useState([]); 
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [availableSubFields, setAvailableSubFields] = useState([]);
  const [courses, setCourses] = useState([]); // State for course options
  const [courseduration, setCoursess] = useState([]); // State for course options
  const [isSubmitClicked, setIsSubmitClicked] = useState(false); // To enable/disable Pay Now button
  const [isTermsAccepted1, setIsTermsAccepted1] = useState(false);
  const [isTermsAccepted2, setIsTermsAccepted2] = useState(false);
  const [step, setStep] = useState(1);
  const [nestedKey, setNestedKey] = useState('step1');
  const [subNestedKey, setSubNestedKey] = useState('subStep1'); // For second-level sub-tabs
  const [subSubNestedKey, setSubSubNestedKey] = useState('subSubStep1'); // For third-level sub-tabs
  const [subSubSubStepKey, setSubSubSubStepKey] = useState('detail1'); // Add this line for subSubSubStepKey
  const [optionss, setOptionss] = useState([]);
  // State for userId entered by the user
  

  const [formData, setFormData] = useState({
    source: '',
    sourceName: '',
    program: '',
    courses: '',
    duration: '_160_HR',
    timings: '_9AM_6PM',
    mode: '',
    branches: '',
    amount: '0',
    coursePrice: '0' // Include coursePrice if it's part of the form

  });


  useEffect(() => {
    setFilteredData(datasource);
  }, [datasource]);  // Only run when datasource changes

  // Handle input change
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };



  const fetchBranches = async () => {
    try {
      const response = await fetch(
        apiUrls.GET_ENROLL_DATA);
      if (!response.ok) {
        throw new Error('Failed to fetch branches');
      }
      const result = await response.json();

      setBranches(result); // Update state with branch data
    } catch (error) {
      setError(error.message); // Set error message
    }
  };

  // Fetch branches on component mount
  useEffect(() => {
    fetchBranches();
  }, []);


  useEffect(() => {
    // Get the current date in YYYY-MM-DD format (without the time)
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }
    // Construct the API URL with the current date
    const apiUrl = `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance/${userId}/${formattedDate}`;

    // Fetch the data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setAttendanceData(data); // Set the data
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        setError(error.message); // Set error if there's an issue with the request
        setLoading(false);
      });
  }, []); // Empty dependency array means this effect runs once on component mount



   const handleChange = async (e) => {
    const { name, value } = e.target;
   
    // Update formData state
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // if (name === 'program' && value) {
     
    //   try {
    //     // Make an API call based on selected program
    //     const response = await axios.get(
    //       `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/getdetails?programme=${value}`
    //     );

    //     const data = response.data;
      
    //     setFormData((prevData) => ({
    //       ...prevData,
    //       duration: data.duration,
    //       coursePrice: data.amount,
    //       amount:data.totalAmount

    //     }));
    //     setOfflinerobotics({
    //       amount:data.totalAmount,
    //       coursePrice: data.amount,
    //     });
    //   } catch (error) {
    //     console.error('Error fetching program details:', error);
    //   }
    // }
  };



  const attendedTimeMinutes = attendanceData?.attendedTime;
  const attendedTimeHours = attendedTimeMinutes ? (attendedTimeMinutes / 60).toFixed(2) : 0;
  const remainingTimeMinutes = attendanceData?.remainingTime;
  const remainingTimeHours = remainingTimeMinutes ? (remainingTimeMinutes / 60).toFixed(2) : 0;

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }

    const fetchData = async () => {
      try {
        // Replace this with your actual API URL
        const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance/${userId}`);

        setDatasource(response.data); // Set the data from the API to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const fetchEnrollData = async () => {
      try {
        const response = await axios.get(
          `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/getdataforenroll/${userId}`
        );
        const data = response.data;
  
        console.log("Fetched Data:", data); // Debugging log to verify response structure
  
        // Update state with API data
        setFormData({
          courses: data.course || "", // Default to an empty string if no course is returned
          program: data.program || "", // Default to an empty string if no program is returned
          coursePrice: data.registrationFees,
          amount: data.totalAmount
        });
  
        // Set courses if returned data contains valid course information
        if (data.course) {
          setCourses(Array.isArray(data.course) ? data.course : [data.course]);
        } else {
          setCourses([]); // Empty array if no courses are available
        }
  
        // Set programs if returned data contains valid program information
        if (data.program) {
          setPrograms(Array.isArray(data.program) ? data.program : [data.program]);
        } else {
          setPrograms([]); // Empty array if no programs are available
        }
  
        setLoading(false); // Mark loading as false
      } catch (error) {
        console.error("Error fetching data:", error);
        setCourses([]);
        setPrograms([]);
        setLoading(false); // Ensure loading is set to false even on error
      }
    };
  
    fetchEnrollData();
  }, [userId]); // Dependency array to refetch if `userId` changes
  

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page
  
   
     if (!userId) {
      console.error("User ID not found in URL.");
      alert("User ID is missing. Please check the URL.");
      return;
    }

  
    const postData = {
      enquiryId:userId,
      timings: formData.timings,
      mode: formData.mode,
      branches: formData.branches,
    };

  
    try {
      const response = await fetch(
        apiUrls.PUT_USER_ENROLL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.text(); // Get response body as text
        throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorData}`);
      }
  
      const data = await response.json();
      console.log('API Response:', data);
      alert('Data added successfully!');
  
      const enrollId = data.enquiryId; // Assuming enrollId is part of the response
      alert(enrollId)
      localStorage.setItem('enrollId', enrollId);
      if (!enrollId) {
        throw new Error('Enroll ID not found in the response.');
      }
  
      // Handle free course
      if (formData.coursePrice === 0) {
        try {
          const freeResponse = await fetch(
            `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/editpaymentstatus/${enrollId}`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ paymentStatus: "FREE" }),
            }
          );
  
          if (!freeResponse.ok) {
            const errorData = await freeResponse.text();
            throw new Error(
              `Payment Status Update Failed! Status: ${freeResponse.status}, Response: ${errorData}`
            );
          }
  
          const freeData = await freeResponse.json();
          console.log('Free Payment Status Updated:', freeData);
          alert('Course marked as free and payment status updated successfully!');
          return; // Skip Razorpay flow
        } catch (error) {
          console.error('Error updating payment status for free course:', error);
          alert('Error updating payment status for free course: ' + error.message);
        }
      }
  
      // Razorpay Payment Options for paid courses
      var options = {
        key: "rzp_test_rA2UalGsJwTy9v",
        key_secret: "RG7A51JvDSuFfhUzkGVbpAtn",
        amount: formData.coursePrice * 100, // Convert price to paise
        currency: "INR",
        payment_capture: true,
        name: "International Institute Of Automate",
        handler: async function (response) {
          alert(
            "Thanks For Your Interest \n Your Payment has been successfully completed"
          );
  
          // Call API to update payment status
          try {
            const paymentResponse = await fetch(
              `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/editpaymentstatus/${enrollId}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ paymentStatus: "COMPLETED" }),
              }
            );
  
            if (!paymentResponse.ok) {
              const errorData = await paymentResponse.text();
              throw new Error(
                `Payment Status Update Failed! Status: ${paymentResponse.status}, Response: ${errorData}`
              );
            }
  
            const paymentData = await paymentResponse.json();
            console.log('Payment Status Updated:', paymentData);
            alert('Payment status updated successfully!');
          } catch (error) {
            console.error('Error updating payment status:', error);
            alert('Error updating payment status: ' + error.message);
          }
        },
        notes: {
          address: "International Institute Of Automate",
        },
        theme: {
          color: "#3399cc",
        },
      };
  
      var pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form: ' + error.message);
    }
  };
  


  const fetchTime = async () => {
    try {
      const response = await fetch(
        apiUrls.PUT_USER_TIMING);
      if (!response.ok) {
        throw new Error('Failed to fetch timings');
      }
      const result = await response.json();
    

      // Map the response to a more usable format
      const subFieldOptions = result.map((item) => ({
        value: item, // Keep the original value
        label: item?.replace(/_/g, ' ').substring(1), // Replace underscores and remove leading underscore
      }));

      setAvailableSubFields(subFieldOptions); // Update state with formatted options
    } catch (error) {
      setError(error.message); // Set error message
    }
  };

  // Fetch timings on component mount
  useEffect(() => {
    fetchTime(); // Call fetch function
  }, []); // Empty dependency array ensures it runs once



 
  const formatDate = (dateString) => {
    const date = new Date(dateString); // Convert the string to a Date object
    const day = String(date.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month with leading zero
    const year = date.getFullYear(); // Get full year
    return `${day}-${month}-${year}`; // Return formatted date
  };

  

  


  // Function to handle API call
  const fetchOptionss = async () => {
    try {
      const response = await fetch(
        'https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/sources'); // Replace with your API endpoint
      const data = await response.json();
      setOptionss(data); // Assuming the response is an array like ["SocialMedia", "Councillor", "Reference"]
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call API when component mounts
  fetchOptionss();



 


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetchCourseDuration = async () => {
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/courseduration"); // Replace with your courses API endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const result = await response.json();
        setCoursess(result); // Assuming result is an array of course objects
      } catch (error) {
        setError(error);
      }
    };

    fetchCourseDuration();

    // Initial fetch for default course price (if needed)
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=Java");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
        setFormData((prev) => ({ ...prev, coursePrice: result }));
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);




  const handleTabSelect = (key) => {
    setKey(key)
    // setActiveKey(key); // Update active tab
    if (key !== "subStep4") {
      setIsPopupOpen3(false) // Hide popup3 when switching from STAGE 3
    }
  };




  const handleChange1 = (e) => {
    setFormData({ ...formData, mode: e.target.value });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Handle category selection
  //   if (name === "categories") {
  //     fetchCourses(value); 
  //   }


  //   setFormData({ ...formData, [name]: value });
  // };

  // Handle main field dropdown change
  const handleFieldChange = async (e) => {
    const { name, value } = e.target;

    if (name === "duration") {
      // Update the formData with the selected course duration
      setFormData({ ...formData, duration: value });

      console.log("Fetching timings for duration:", value);

      await fetchTime(value); // Fetch timings based on selected course duration
    } else {
      const selectedField = value;
      let subFields = [];

      // Set subFields based on the selected course
      // if (selectedField === "mechanical-engineering") {
      //   subFields = [
      //     { value: "mech1", label: "9AM-6PM" },
      //   ];
      // } else if (selectedField === "technotalks") {
      //   subFields = [
      //     { value: "tech1", label: "9AM-1PM" },
      //     { value: "tech2", label: "2PM-6PM" },
      //   ];
      // }

      setAvailableSubFields(subFields);
      setFormData({ ...formData, course: selectedField });

      // Reset subfield when main field changes

      // Fetch price for the selected course
      try {
        const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=${selectedField}`);
        if (!response.ok) {
          throw new Error("Failed to fetch price data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
        setFormData((prev) => ({ ...prev, coursePrice: result })); // Update price in formData
      } catch (error) {
        setError(error);
      }
    }
  };





  const handleSubNestedTabSelect = (selectedKey) => {
    // Set the selected sub-tab key
    setSubNestedKey(selectedKey);

    // Check if the selected sub-tab is subStep4
    if (selectedKey === "subStep4") {
      setIsPopupOpen3(true); // Show Popup 3 when subStep4 is selected
    } else {
      setIsPopupOpen3(false); // Hide Popup 3 when other tabs are selected
      setIsPopupOpen4(false); // Hide Popup 4 (if needed)
      setIsPopupOpen5(false); // Hide Popup 4 (if needed)
    }

    // If needed, add logic to close the popup for other steps
    // For example, when switching away from subStep4, hide other popups.
  };


 

  const handleSearch = () => {
    const selectedMonth = document.getElementById("monthSelect").value;
    const selectedYear = document.getElementById("yearSelect").value;

    const filtered = datasource.filter((row) => {
      const rowDate = new Date(row.date); // Assuming `row.date` is in ISO format (e.g., "2024-11-29")

      const matchesDate = date ? row.date === date : true;
      const matchesMonth = selectedMonth ? rowDate.getMonth() + 1 == selectedMonth : true; // Months are 0-indexed
      const matchesYear = selectedYear ? rowDate.getFullYear() == selectedYear : true;

      return matchesDate && matchesMonth && matchesYear;
    });

    setFilteredData(filtered);
  };




  function convertMinutesToTime(totalMinutes) {
    // Calculate hours
    const hours = Math.floor(totalMinutes / 60);

    // Calculate remaining minutes
    const minutes = Math.floor(totalMinutes % 60);

    // Calculate seconds
    const seconds = Math.round((totalMinutes - Math.floor(totalMinutes)) * 60);

    // Pad hours, minutes, and seconds with a leading zero if they are single digits
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    // Return the formatted time
    return `${paddedHours} : ${paddedMinutes} : ${paddedSeconds} `;
  }


  const handleTermsClick = (e) => {
    e.preventDefault();
    if (isTermsAccepted) {
      setShowModal(true);
    } else {
      alert("Please accept the Terms and Conditions to proceed.");
    }
  };



  const handleModalSubmit = () => {
    setIsSubmitClicked(true); // Enable Pay Now button
    setShowModal(false); // Hide modal
  };
  const handleCheckboxClick = () => {
    const newValue = !isTermsAccepted;
    setIsTermsAccepted(newValue);


  };

  const handleModalSubmit1 = () => {
    setIsTermsAccepted1(!isTermsAccepted1);
  };

  const handleModalSubmit2 = () => {
    setIsTermsAccepted2(!isTermsAccepted2);
  };

  const handleNext = () => {
    console.log("Next button clicked in nested tabs"); // Debugging line
    if (nestedKey === 'subStep1') {
      setNestedKey('subStep2'); // Move to subStep2 on next click
      console.log("nestedKey updated to: subStep2");
    } else if (nestedKey === 'subStep2') {
      setSubNestedKey('subSubStep1'); // Move to first sub-sub-step
      console.log("nestedKey updated to: subSubStep1");
    }
  };

  const handlePopUp = () => {

    setIsPopupOpen(true); // Open the popup
  };

  const handlePopUp3 = () => {
    setIsPopupOpen4(false);
    setIsPopupOpen3(true); // Open the popup
    setIsPopupOpen5(false)
  };

  const handlePopUp4 = () => {
    setIsPopupOpen3(false);
    setIsPopupOpen4(true); // Open the popup
    setIsPopupOpen5(false); // Open the popup
  };

  const handlePopUp5 = () => {
    setIsPopupOpen3(false);
    setIsPopupOpen4(false); // Open the popup
    setIsPopupOpen5(true); // Open the popup

  }


  const handlePopUp1 = () => {
    // alert()
    setIsPopupOpen1(true); // Open the popup
  };


  // Function to handle nested tab selection
  const handleNestedTabSelect = (selectedKey) => {
    setNestedKey(selectedKey);
    // Hide the popup when switching nested tabs
    setIsPopupOpen3(false); // You can modify this behavior based on your requirement
  };

  
  const handlePopUp2 = () => {
    // alert()
    setIsPopupOpen2(true); // Open the popup
  };

  const handleNextNested = async (e) => {
    e.preventDefault(); // Prevent default form behavior

    // First, handle the form submission
    try {
      await handleSubmit(e); // Wait for the form submission to complete

      // If submission is successful, change the active tab to 'subStep2'
      setSubNestedKey("subStep2");

    } catch (error) {
      // Handle form submission error (optional)
      console.error("Error during submission:", error);
    }
  };




  const handleNextNesteds = () => {
    setIsPopupOpen(false)
    setSubNestedKey("subStep3"); // Change the active tab to 'subStep2'
  };
  const handleNextNesteded = () => {
    setSubNestedKey("subStep4"); // Change the active tab to 'subStep2' 
  };
  const handleNextNestedsss = () => {
    setSubNestedKey("subStep6"); // Change the active tab to 'subStep2'
  };


  const handleNextNestedses = () => {
    setIsPopupOpen1(false)
    setSubNestedKey("subStep5"); // Change the active tab to 'subStep2'
  };
  const handleNextNestedspay = () => {
    setSubSubSubStepKey("detail2"); // Change the active tab to 'subStep2'
  };
  const handleNextNestedoptiont = () => {
    setSubSubSubStepKey("detail2"); // Change the active tab to 'subStep2'
  };
  const handleNextNestedoptiontp = () => {
    setSubSubSubStepKey("detail3"); // Change the active tab to 'subStep2'
  };

  const handleNextSubNested = () => {
    console.log("Next button clicked in sub-nested tabs"); // Debugging line
    if (subNestedKey === 'subSubStep1') {
      setSubNestedKey('subSubStep2'); // Move to subSubStep2 on next click
      console.log("subNestedKey updated to: subSubStep2");
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {

      setIsLoading(true);
      try {
        let id = localStorage.getItem('responseData');
        console.log(toolbarfixture);
        if (!id) {
          throw new Error('No ID found in localStorage');
        }
        const response = await fetch(
          `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/preview/18`,

          // Replace ":id" with the actual ID parameter you want to fetch
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        // alert(result.fullName)
        setToolbarfixture(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);


  const handleDownload = () => {
    const input = document.querySelector(".form-content");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // A4 dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Adjust top margin here
      const topMargin = 20; // Set your desired top margin in mm
      let heightLeft = imgHeight;
      let position = topMargin;

      // Add the image to the first page with the top margin
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= (pageHeight - topMargin);

      // Add extra pages if content is larger than one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("form-data.pdf");
    });
  };



  const handlePrintS = () => {
    const printContent = document.querySelector(".form-content");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color:rgb(143, 43, 43); }
                @media print {
                    @page { margin: 50px; }
                    body { margin: 50px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };



  // <--------------------------------------------------   OPTION 2 DOWNLOAD BUTTON ---------------------------------------------------->

  const handleDownloads = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contents");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          pdf.save("form-data.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };




  const handlePrint = () => {
    const printContent = document.querySelector(".form-contents");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
      <html>
          <head>
              <title>Print Table</title>
              <style>
                  body { font-family: Arial, sans-serif; }
                  table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                  th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                  @media print {
                      @page { margin: 50px; }
                      body { margin: 50px; }
                  }
              </style>
          </head>
          <body>
              <h2 style="text-align: center;">Form Details</h2>
              ${printContent.innerHTML}
          </body>
      </html>
  `);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };




  // <---------------------------------------------  STAGE 2 Registration form download button --------------------------------------------------------------------->



  const handleDownloade = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contentse");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 297; // A4 height in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          pdf.save("form-data.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };


  // <------------------------------------------- STAGE 2 PRINT BUTTON ------------------------------------------->


  const handlePrinte = () => {
    const printContent = document.querySelector(".form-contentse");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=600,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif;padding:20px; }
                table { width: 100%; border-collapse: collapse; margin-top: 50px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                @media print {
                    @page { margin: 10px; }
                    body { margin: 20px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };



  // <---------------------------------------------- STAGE 6 DOWNLOAD AND PRINT BUTTON ----------------------------------------->//

  const handleDownloadsL = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contentsL");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 1.5, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");

          const imgWidth = 210; // A4 width in mm
          const pageHeight = 200; // Reduce page height (default is 210mm)
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Scale the image to fit within the new page height
          let scaledImgHeight = imgHeight;
          if (imgHeight > pageHeight) {
            // Scale the height down if it exceeds page height
            const scaleFactor = pageHeight / imgHeight;
            scaledImgHeight = pageHeight;
            canvas.width *= scaleFactor; // Adjust width proportionally
            canvas.height *= scaleFactor; // Adjust height proportionally
          }

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = scaledImgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, position, imgWidth, scaledImgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages if content exceeds the page height
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - scaledImgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, position, imgWidth, scaledImgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          // Save the generated PDF
          pdf.save("Completion Certification.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };





  const handlePrintL = () => {
    const printContent = document.querySelector(".form-contentsL");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=500,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                @media print {
                    @page { margin: 50px; }
                    body { margin: 50px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };





  // <---------========================================== PUNCH OUT =====================================----------------------->
  useEffect(() => {
    let timer;

    // Start the timer if punched in
    if (isPunchedIn) {
      timer = setInterval(() => {
        setTotalSeconds(prev => prev + 1); // Increment seconds data not funding in the combined in the user data not fe
      }, 1000); // Update every second
    }

    // Cleanup function to clear the timer
    return () => clearInterval(timer);
  }, [isPunchedIn]); // Effect depends on isPunchedIn data comunity and all the 

  const handlePunchIn = async () => {
    setIsPunchedIn(true);
    setPunchInTime(new Date());
    setTotalSeconds(0); // Reset total seconds when punched in
    const currentDateTime = new Date();
    const currentDate = currentDateTime.toISOString();

    // Send the POST request to the API
    // try {
    //   const response = await axios.post('http://10.10.20.9:7071/IIA-Website-1.0.0/attendance/save', {
    //     user: { id: 235 },
    //     date: currentDate, // Current date-time as per your format
    //     punchIn:punchInTime, // Current punch-in time
    //     punchOut: null, // You can send null for punch-out initially or leave it to be updated later
    //   });

    //   console.log('Punch-in successfully recorded:', response.data);

    // } catch (error) {
    //   console.error('Error punching in:', error);
    // }
  };

  const handlePunchOut = () => {
    setIsPunchedIn(false);
    const totalHours = totalSeconds / 3600; // Calculate total hours from seconds
    console.log(`Punched Out. Total worked hours: ${totalHours.toFixed(2)} hrs`);
    // Here you could save the totalHours to your backend or perform other actions
  };

  // Convert totalSeconds to hours, minutes, and seconds 
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;


  return (
    <div>
      <style>
        {`
          .tab-content {
            margin-top: 20px; /* Adjust this value to set the gap */
          }
               nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
   
    background-color: none !important;
  
    
}

.nav-tabs .nav-link:hover {
  background-color: orange; /* Background color when hovered */
  color: black; /* Text color when hovered */
  border-color: orange; /* Optional: Add a border color */
}

/* Active tab styling */
.nav-tabs .nav-link.active {
  background-color: orange; /* Background color for active tab */
  color: black; /* Text color for active tab */
  border-color: orange; /* Optional: Border for active tab */
}
    /* General container styling */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Punch-status card styles */
.punch-status .punch-det h6 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.punch-status .punch-det p {
  font-size: 14px;
}

.punch-status .punch-info .punch-hours {
  text-align: center;
  margin-bottom: 10px;
}

.punch-status .punch-btn {
  width: 100%;
  font-size: 14px;
  padding: 10px;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    margin-bottom: 15px;
  }
}
.clock-background {
    border-radius: 50%;
    background-color: #efefef; /* Your desired color */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px; /* Set a width for the circle */
    height: 105px; /* Set a height for the circle */
    border: 2px solid #000; /* Add a 2px solid border, change #000 to your desired border color */
    margin-left:25px;
}


.card-title-bg {
    background-color: #e7f1ff; /* Change this to your desired background color */
    padding: 10px; /* Add some padding for spacing */
    border-radius: 5px; /* Optional: Add rounded corners */
    display: inline-block; /* Make the background fit the content */
        margin-left: 20px;
}




.custom-width {
  width: 250px; /* Adjust this value as needed */
}



.form-select {
  display: block;
  width: 100%;
  padding: none !important;
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.937rem;
  font-weight: 400; */


}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
* Bootstrap v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/


table td {
padding: 7px 33px !important;
}

table {
background-color: transparent;
width: 100%;
}
table thead th {
color: #333;
}
table td {
padding: 15px 10px;
}
p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
margin-bottom: 4px;
}

.ad-container {
  position: fixed;
  right: -55px;
  bottom: 0;
  top: 0;
  width: 324px; /* Adjust width as needed */
  overflow: hidden;
  background-color: 'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
 background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
         
        `}
      </style>

      <section>
        <div className="container-fluid tabs-data" style={{}}>
          <div
            className="container boderform"
            style={{
              // backgroundColor: "#efefef",
              paddingBottom: "30px",
              borderRadius: "10px",
              marginTop: "-24px",
            }}
          >

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}

              className="mb-3"
              style={{ display: 'flex', justifyContent: 'space-evenly', width: '101.8%', marginLeft: '-11px', padding: "7px", fontSize: '20px', }}
            >

              <Tab
                eventKey="registration"
              // title={<span style={{ fontWeight: '500', color: '#f58938' }}>COURSES</span>}
              >

                <Tabs
                  id="nested-tab-example"
                  activeKey={nestedKey}
                  onSelect={handleNestedTabSelect}
                  className="mb-3"
                  style={{ marginTop: '-20px', justifyContent: 'space-evenly', width: '101.8%', marginLeft: '-11px', fontSize: '20px', padding: '5px', borderBottom: 'none',  }}
                >
                  {/* Sub Tab 1 */}
                  <Tab eventKey="step1"

                  >
                    <Tabs
                      id="sub-nested-tab-example"
                      activeKey={subNestedKey}
                      onSelect={(k) => handleSubNestedTabSelect(k)}
                      className="mb-3"
                      style={{ marginTop: '-50px', justifyContent: 'space-evenly', width: '101.8%', marginLeft: '-11px', padding: "3px", fontSize: '20px',}}
                    >
                      {/* Sub Tab under REGISTRATION */}
                      <Tab eventKey="subStep1" 
                       
                      title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '12px',
                      }}>STAGE 1</span>} >

                        <div>
                          <form onSubmit={handleSubmit} className="donate-form">
                            <div className="row" style={{ paddingBottom: '25px' }}>







                              <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Source*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="source"
                                  value={formData.source}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select Field</option>
                                  {/* Map options from API response */}
                                  {optionss.length > 0 && optionss.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>

                             




<Form.Group className="col-md-3 mobk"  controlId="exampleForm.ControlInput1">
      <Form.Label>Courses*</Form.Label>
      <Form.Select
        aria-label="Default select example"
        name="courses" // API field name
        value={formData.courses} // Ensure value is linked to formData.courses
        onChange={handleChange} // Handle change to update formData
        readOnly
        required
      >
        <option value="">Select Course</option>
        {loading ? (
          <option disabled>Loading...</option> // Display a loading option
        ) : (
          courses.map((course, index) => (
            <option key={index} value={course}>
              {course?.replace(/([A-Z])/g, " $1").trim()} {/* Format course names */}
            </option>
          ))
        )}
      </Form.Select>
    </Form.Group>



    <Form.Group className="col-md-3 mobk" controlId="exampleForm.ControlInput2">
        <Form.Label>Program*</Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="program"
          value={formData.program}
          onChange={handleChange}
          readOnly
          required
        >
          <option value="">Select Program</option>
          {loading ? (
            <option disabled>Loading...</option>
          ) : (
            programs.map((program, index) => (
              <option key={index} value={program}>
                {program?.replace(/_/g, " ")} {/* Format underscores */}
              </option>
            ))
          )}
        </Form.Select>
      </Form.Group>


                            
                              <Form.Group
                                className="col-md-3 mobk"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Course Duration*</Form.Label>
                                <Form.Control
                                  type="text"
                                  style={{ height: '36px', }}
                                  placeholder={offlinerobotics?.coursePrice || 10}
                                  value={formData.duration?.replace(/_/g, ' ') || ""}

                                  required
                                />
                              </Form.Group>



                            </div>
                            <div className="row" style={{ paddingBottom: "25px" }}>


                           



                            <Form.Group className="col-md-3 mobkk" controlId="exampleForm.ControlInput1">
  <Form.Label>Time*</Form.Label>
  <Form.Select
    aria-label="Default select example"
    name="timings"
    value={formData.timings}
    onChange={handleChange}
    required
  >
    <option value="">Select Field</option>
    {/* Map options from API response */}
    {availableSubFields.length > 0 &&
      availableSubFields.map((option, index) => {
        // Replace spaces with hyphens in the label
        const formattedLabel = option.label?.replace(/\s+/g, "-");
        return (
          <option key={index} value={option.value}>
            {formattedLabel}
          </option>
        );
      })}
  </Form.Select>
</Form.Group>




                            


                              <Form.Group className="col-md-3 mobk" controlId="exampleForm.ControlInput1">
                                <Form.Label>Mode*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="mode"
                                  value={formData.mode} // Ensure the value is linked to formData.mode 
                                  onChange={handleChange1} // Update the form data on change
                                  required
                                >
                                  <option value="">Select Mode</option> {/* Optional placeholder */}
                                  <option value="Offline">OFFLINE</option>
                                  <option value="Online">ONLINE</option>
                                </Form.Select>
                              </Form.Group>



                             

                              <Form.Group className="col-md-3 mobk" controlId="exampleForm.ControlInput1">
                                <Form.Label>Branches*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="branches"
                                  value={formData.branches}
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select Field</option>
                                  {/* Map options from API response */}
                                  {branches.length > 0 && branches.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                             

                              <Form.Group
  className="col-md-3 mobk"
  controlId="exampleForm.ControlInput1"
>
  <Form.Label>Registration Fee*</Form.Label>
  <Form.Control
    type="text"
    style={{ height: "36px" }}
    placeholder="Enter registration fee"
    value={formData.coursePrice} // Display value from formData
    readOnly // Make it readonly if it's a fixed value from the API
    required
  />
</Form.Group>




                            </div>
                            <div className="row">
                           



                              <Form.Group
                                className="col-md-3 mobkk"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Course Fee*</Form.Label>
                                <Form.Control
                                  type="text"
                                  style={{ height: '36px', }}
                                  placeholder={offlinerobotics?.amount}
                                  value={formData.amount}
                                  readOnly
                                  required
                                />
                              </Form.Group>


                             




                            </div>





                            {/* Add the Next button */}
                            {/* <button type="button" class="btn btn-primary" onClick={handleNextNested}>
                              Next
                            </button> */}
                            <button type="button" class="btn btn-primary mkplakh" style={{ marginLeft: '45%' }} onClick={handleSubmit}>
                              PAY NOW
                            </button>
                            
                          </form>
                        </div>

                      </Tab>

                      <Tab eventKey="subStep2"
                      // title={<span style={{
                      //   fontWeight: '500', color: '#f58938', display: 'flex',
                      //   alignItems: 'center',
                      //   justifyContent: 'center', padding: "2px", fontSize: '14px',
                      // }}>STAGE 2</span>} 
                      >

                        <div className="form-contentse" style={{
                          width: "794px", // A4 width in pixels
                          height: "800px", // A4 height in pixels
                          backgroundColor: "#ffffff", // Background color
                          padding: "20px", // Padding around the content
                          border: "1px solid rgb(33 29 29)", // Optional: add a border
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                          margin: "0 auto", // Center the A4 container
                          overflow: "hidden" // Prevent content overflow
                        }}>
                          {isPopupOpen && (
                            <div className="row" style={{ paddingBottom: '25px' }}>
                              <div className="container-fluid " id="table-container" style={{ paddingTop: '60px' }}>
                                <div className="container">
                                  <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    padding: "0px",
                                    flexWrap: "wrap",
                                    paddingBottom: "16px",
                                    marginTop: "-30px"
                                  }}>
                                    <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                    <h3 style={{ color: "#f58938", margin: 0 }}>
                                      INTERNATIONAL INSTITUTE OF AUTOMATE
                                    </h3>

                                  </div>

                                  <div className="row" style={{ fontSize: "22px" }}>
                                    <div style={{ justifyContent: "textAlign:center", marginLeft: "100px" }}>

                                      {toolbarfixture && toolbarfixture.length > 0 && (
                                        <table method="Get" style={{ paddingTop: '15px', paddingBottom: "15px", borderSpacing: '10px' }}>
                                          <tbody>
                                            <tr>
                                              <td>Full Name</td>
                                              <td>{toolbarfixture[0].fullName ?? ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Email Id</td>
                                              <td style={{ wordBreak: "break-word" }}>{toolbarfixture[0].emailId ?? ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Mobile Number</td>
                                              <td>{toolbarfixture[0].contactNumber ?? ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Gender</td>
                                              <td>{toolbarfixture[0].gender ?? ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Date of Birth</td>
                                              <td>{toolbarfixture[0].dateOfBirth ? new Date(toolbarfixture[0].dateOfBirth).toLocaleDateString('en-GB') : ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Course</td>
                                              <td>{toolbarfixture[0].course ?? ""}</td>
                                            </tr>
                                            {/* <tr>
                                            <td>Particular</td>
                                            <td>Offline Robotics</td>
                                          </tr> */}
                                            <tr>
                                              <td>Mode</td>
                                              <td>{toolbarfixture[0].mode ?? ""}</td>
                                            </tr>
                                            {/* <tr>
                                            <td>Batch</td>
                                            <td>{toolbarfixture[0].batch ?? ""}</td>
                                          </tr> */}
                                            <tr>
                                              <td>Duration</td>
                                              <td>160 HRS</td>
                                            </tr>
                                            <tr>
                                              <td>Time</td>
                                              <td>{toolbarfixture[0].timings ?? ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Branch</td>
                                              <td>{toolbarfixture[0].branches ?? ""}</td>
                                            </tr>
                                            <tr>
                                              <td>Registration fee</td>
                                              <td>1000</td>
                                            </tr>
                                            <tr>
                                              <td>Status</td>
                                              <td>Paid</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                    {/* <hr></hr> */}
                                    {/* <h2 style={{ textAlign: "center", padding: "10px",paddingTop:'40px',paddingBottom:"40PX" }}>
                                      INTERNATIONAL INSTITUTE OF AUTOMATE
                                           </h2> */}
                                    <div >



                                      {/* <div class="row" style={{textAlign:"center"}}>
                                           <p>www.iiautomate.com</p>
                                        </div> */}


                                    </div>

                                  </div>
                                </div>

                              </div>
                              {/* Add other fields for step 2 */}
                            </div>

                          )}
                        </div>
                        <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <button type="button" class="btn btn-primary" onClick={handleDownloade}>
                              Download
                            </button>
                            <button type="button" class="btn btn-primary" onClick={handlePrinte}>
                              Print
                            </button>
                          </div>
                        </div>
                        <button type="button" class="btn btn-primary" onClick={handleNextNesteds}>
                          Next
                        </button>

                      </Tab>
                      {/* <Tab eventKey="subStep3" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '12px',
                      }}>STAGE 2</span>} >
                        <div>

                          <h2
                            style={{
                              textAlign: "center",
                              color: "#5B4B99",
                              paddingBottom: "50px",
                              paddingTop: "30px",
                            }}
                          >
                            Terms And Conditions
                          </h2>
                          <div className="row" style={{ paddingBottom: '25px', width: "70%", margin: "0 auto" }}>
                            <p style={{ textAlign: "justify" }}>
                              By submitting the IIA Automate course payment form, you agree to the terms and conditions
                              outlined. Payment is required in full upon registration, and refunds are only available
                              within 14 days of payment, provided course materials have not been accessed. Course access
                              is granted after successful payment and will remain available for the specified duration.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              All course materials are the intellectual property of IIA Automate and may not be reproduced
                              without permission. Participants must adhere to professional conduct during the course.
                              Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
                              right to update these terms at any time.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              Any breach of these terms and conditions may result in the immediate termination of course access
                              without refund. IIA Automate retains the right to revoke access if it is determined that the terms
                              have been violated, including but not limited to sharing login credentials or course materials
                              with unauthorized individuals. Participants are expected to complete the course independently, and
                              any form of academic dishonesty will not be tolerated.
                            </p>

                            <p style={{ textAlign: "justify" }}>
                              The course content is designed for educational purposes only, and IIA Automate does not guarantee
                              specific career outcomes or certifications upon completion. While we strive to provide accurate and
                              up-to-date information, we are not liable for any errors or omissions in the course materials. It is
                              the participant's responsibility to ensure they meet any technical or hardware requirements needed
                              to complete the course.
                            </p>


                            <div className="checkbox-container" style={{ display: "flex", alignItems: "center" }}>
                              <input
                                type="checkbox"
                                id="terms"
                                checked={isTermsAccepted}
                                onClick={handleModalSubmit} // Use the new handleModalSubmit function
                                required
                                style={{ display: "none" }}
                              />
                              <div
                                onClick={handleCheckboxClick}
                                style={{
                                  cursor: "pointer",
                                  width: "15px",
                                  height: "15px",
                                  border: "2px solid green",
                                  borderRadius: "4px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: isTermsAccepted ? "green" : "white",
                                  marginTop: "-6px",
                                }}
                              >
                                {isTermsAccepted && (
                                  <FontAwesomeIcon icon={faCheckSquare} style={{ color: "white" }} />
                                )}
                              </div>
                              <label htmlFor="terms" style={{ marginLeft: "10px", cursor: "default" }}>
                                I agree to the{" "}
                                <span
                                  style={{ color: "blue", cursor: "pointer" }}

                                >
                                  Terms and Conditions
                                </span>
                              </label>


                            </div>

                          </div>


                          <button
                            className="custom-btn "
                            onClick={handleNextNesteded}
                            disabled={!isTermsAccepted} // Disable the button if terms are not accepted

                            style={{
                              marginLeft: '50%',
                              backgroundColor: isTermsAccepted ? "#f6954e" : "lightgray", // Green if enabled, light gray if disabled
                              color: isTermsAccepted ? "white" : "black", // White text if enabled, dark gray if disabled
                              cursor: isTermsAccepted ? "pointer" : "not-allowed", // Pointer if enabled, not-allowed if disabled
                              border: "none", // Optional: remove border if you want
                              padding: "10px 20px", // Optional: padding for the button
                              borderRadius: "5px", // Optional: rounded corners
                              transition: "background-color 0.3s ease", // Smooth transition for color change
                            }}

                          >
                            Next
                          </button>
                        </div>
                      </Tab> */}

                      {/* <Tab
                        eventKey="subStep4"
                        id="nested-tab-example"
                        activeKey={nestedKey}
                        onSelect={handleNestedTabSelect}
                        title={
                          <span
                            style={{
                              fontWeight: "500",
                              
                              // height: "36px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "2px", fontSize: '12px'
                            }}
                          >
                            STAGE 3
                          </span>
                        }
                      > */}
                        <div
                          style={{
                            // backgroundColor: 'rgb(101, 100, 106)',
                            // Navbar background color
                            padding: '10px 20px', // Padding around the navbar
                            borderRadius: '5px', // Optional: rounded corners for the navbar
                            marginTop: '-20px',
                            width: '102%',
                            marginLeft: '-12px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-evenly',
                              color: 'black',
                              alignItems: 'center',
                              height: '30px'
                            }}
                          >
                            {/* First Dropdown (OPTION 1) */}
                            <div
                              className="dropdown"
                              style={{ position: 'relative' }}
                              onMouseEnter={() => setShowMechanicalDropdown(true)} // Show dropdown on hover
                              onMouseLeave={() => setShowMechanicalDropdown(false)}
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent dropdown toggle on button click
                                  handlePopUp3();
                                }}
                                className="dropdown-toggle"
                                style={{
                                  // width: '156px',
                                  padding: '10px',
                                  borderRadius: '5px',
                                  backgroundColor: '#f58938',
                                  color: 'white',
                                  border: 'none',
                                  
                                  // height: '40px'
                                }}
                              >
                                OPTION 1
                              </button>

                              {showMechanicalDropdown && (
                                <div className="dropdown-menu" style={{ width: '151px', marginLeft: '4px' }}>
                                  <Link onClick={(e) => {
                                    e.preventDefault(); // Prevent default link behavior
                                    handlePopUp3(); // Open PopUp4
                                  }} className="dropdown-item" style={{ fontSize: '11px' }}>DETAILS</Link>
                                </div>
                              )}
                            </div>

                            {/* Second Dropdown (OPTION 2) */}
                            <div
                              className="dropdown"
                              style={{ position: 'relative' }}
                              onMouseEnter={() => setShowWebDevDropdown(true)} // Show dropdown on hover
                              onMouseLeave={() => setShowWebDevDropdown(false)}
                            >
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent dropdown toggle on button click
                                  handlePopUp4(); // Open popup 4 when button is clicked
                                }}
                                className="dropdown-toggle"
                                style={{
                                  // width: '156px',
                                  padding: '10px',
                                  borderRadius: '5px',
                                  backgroundColor: '#f58938',
                                  color: 'white',
                                  // height: '40px',
                                  border: 'none'
                                }}
                              >
                                OPTION 2
                              </button>

                              {showWebDevDropdown && (
                                <div className="dropdown-menu" style={{ width: '151px', marginLeft: '4px' }}>
                                  <Link
                                    className="dropdown-item"
                                    style={{ fontSize: '11px' }}
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent default link behavior
                                      handlePopUp4(); // Open PopUp4
                                    }}
                                  >
                                    DETAILS
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    style={{ fontSize: '11px' }}
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevent default link behavior
                                      handlePopUp5(); // Open PopUp5
                                    }}
                                  >
                                    EMI
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>



                        

                      {/* </Tab> */}

                      {/* <Tab eventKey="subStep5" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '12px',
                      }}>STAGE 4</span>} > */}


                        <div className="row">
                          {/* Timesheet card */}
                          <div className="col-md-3">
                            <div className="card punch-status">


                              <div className="card-body">
                                <h5 className="card-title card-title-bg">
                                  Timesheet <small className="text-muted">{new Date().toLocaleDateString()}</small>
                                </h5>

                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                  <span style={{ fontSize: '14px', marginRight: '5px', color: 'black' }}>Course Duration:</span>
                                  <span style={{ fontSize: '13px', }}>360 Hrs</span>
                                </div>


                                <div className="punch-det" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                  <span style={{ fontSize: '14px', color: 'black', paddingRight: '4px' }}>Attendant Time: </span>
                                  <span style={{ fontSize: '12px', }}>{attendedTimeHours} Hrs</span>
                                </div>

                                <p style={{ marginTop: '10px', marginLeft: '20px' }}>Remaining Time</p>


                                <div className="clock-background" style={{ marginTop: '0px' }}>
                                  <span className="punch-hours">
                                    {remainingTimeHours}
                                  </span>
                                </div>


                              </div>



                            </div>
                          </div>

                          {/* Table Section */}
                          <div className="col-md-9">
                            <div className="d-flex align-items-center mb-3">
                              <div className="me-2 custom-width">
                                {/* <label htmlFor="dateSelect" className="form-label">Date:</label> */}
                                <input type="date" id="dateSelect" className="form-control" onChange={(e) => setDate(e.target.value)} value={date} style={{ height: '35px' }} />
                              </div>
                              <div className="me-2 custom-width">
                                {/* <label htmlFor="monthSelect" className="form-label">Month:</label> */}
                                <select id="monthSelect" className="form-select">
                                  <option value="">Select Month</option>
                                  {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month, index) => (
                                    <option key={index} value={index + 1}>{month}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="me-2 custom-width">
                                {/* <label htmlFor="yearSelect" className="form-label">Year:</label> */}
                                <select id="yearSelect" className="form-select">
                                  <option value="">Select Year</option>
                                  {[...Array(10).keys()].map((year) => {
                                    const currentYear = new Date().getFullYear();
                                    return (
                                      <option key={year} value={currentYear - year}>{currentYear - year}</option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="me-2 custom-width">

                                <div className="input-group">

                                  <button className="btn btn-primary" type="button" id="searchButton" style={{ background: "#f77f31", marginTop: '0px', height: '38px' }} onClick={handleSearch}>Search</button>
                                </div>
                              </div>
                            </div>


                            <div className="table-responsive" style={{ overflowX: 'auto' }}>
                              <Table>
                                <TableHead style={{ backgroundColor: 'lightblue' }}>
                                  <TableRow>
                                    {['S.No', 'Date', 'Punch In', 'Punch Out', 'Production', 'Break', 'TotalTime'].map((heading, index) => (
                                      <TableCell
                                        align="center"
                                        key={index}
                                        style={{
                                          borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined,
                                          width: heading === 'Date' ? '250px' : undefined, // Increase width for the Date column
                                        }}
                                      >
                                        {heading}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                          {row.id}
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', width: '250px' }}>
                                          <p style={{ width: '90px' }}>{formatDate(row.date)}</p>
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                          {row.punchIn}
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                          {row.punchOut}
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                          <p style={{ width: '82px' }}>{convertMinutesToTime(row.production)}</p>
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                          <p style={{ width: '75px' }}>{convertMinutesToTime(row.breakTime)}</p>
                                        </TableCell>
                                        <TableCell align="center" style={{ borderRight: '2px solid rgba(0, 0, 0, 0.12)' }}>
                                          <p style={{ width: '72px' }}>{convertMinutesToTime(row.totalTime)}</p>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={filteredData.length} // Update pagination count to match filtered data
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPageChange}
                              />
                            </div>

                          </div>




                        </div>
                        <div className="d-flex justify-content-center mb-3">
                          {/* <button type="button" className="custom-btn btn-3" onClick={handleNextNestedsss}>
                            Next
                          </button> */}
                          <button type="button" class="btn btn-primary" style={{ marginTop: '30px', marginLeft: '22%' }} onClick={handleNextNestedsss}>
                            Next
                          </button>
                        </div>

                      {/* </Tab> */}

                      {/* <Tab eventKey="subStep6" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '12px',
                      }}>STAGE 5</span>} > */}





                        <div className="row" >
                          <div className="container-fluid " id="table-container" style={{ paddingTop: '20px' }}>
                            <div className="container">
                              <div className="row" style={{ fontSize: "22px" }}>
                                <div></div>

                                <div className="a4-container stage-container-five-a4" style={{
                                  width: "794px", // A4 width in pixels
                                  height: "684px", // A4 height in pixels
                                  backgroundColor: "#f8f8f8", // Change to your desired background color
                                  padding: "20px", // Add some padding
                                  border: "1px solid #ccc", // Optional: add a border
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                                  // display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "0 auto" // Center the A4 container
                                }}>
                                  <div className="form-contentsL">
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      padding: "0px",
                                      flexWrap: "wrap",
                                      paddingBottom: "16px",
                                      marginTop: "10px"
                                    }}>
                                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                      <h3 style={{ color: "#f58938", margin: 0, wordBreak: "break-word" }}>
                                        INTERNATIONAL INSTITUTE OF AUTOMATE
                                      </h3>


                                    </div>
                                    <h2 style={{ textAlign: "center", color: "rgb(245 136 66)", marginBottom: "10px" }}>
                                      Certification Of Completion
                                    </h2>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "11px", marginTop: '30px' }}>
                                      This Certifies that
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", fontWeight: "bold", marginBottom: "15px", marginTop: '30px' }}>
                                      B. Naresh
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "11px", wordBreak: "break-word", marginTop: '30px' }}>
                                      Has successfully Completed the Course Of
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", fontWeight: "bold", marginBottom: "15px", marginTop: '30px' }}>
                                      Online Robotics
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "5px", marginTop: '30px' }}>
                                      Duration: 160 HRS
                                    </h3>

                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "50px",
                                        marginTop: "40px",
                                      }}
                                    >
                                      <h3 style={{ textAlign: "center", color: "#5B4B99" }}>
                                        Start Date: 10-02-2024
                                      </h3>
                                      <h3 style={{ textAlign: "center", color: "#5B4B99" }}>
                                        End Date: 15-08-2024
                                      </h3>
                                    </div>

                                    <br />

                                    <div className="date-signnature stage-five-signature"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        marginTop: "0px",
                                        gap: "374px"
                                      }}
                                    >
                                      <h5 style={{ color: "#5B4B99", marginBottom: "5px", marginTop: '20px' }}>Signature:</h5>
                                      <h5 style={{ color: "#5B4B99", marginBottom: "5px", marginTop: '20px' }}>Date: 15-08-2024</h5>
                                    </div>

                                    <h3 style={{ textAlign: "center", marginTop: "30px" }}>www.iiautomate.com</h3>
                                  </div>
                                </div>



                                <br />






                                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                    <button type="button" class="btn btn-primary" onClick={handleDownloadsL}>
                                      Download
                                    </button>
                                    <button type="button" class="btn btn-primary" onClick={handlePrintL}>
                                      Print
                                    </button>
                                  </div>
                                </div>


                              </div>
                            </div>

                          </div>
                          {/* Add other fields for step 2 */}
                        </div>
                      {/* </Tab> */}





                    </Tabs>

                    {/* Second-Level Sub Tabs */}

                  </Tab>

                  {/* Sub Tab 2 */}

                </Tabs>
              </Tab>

              {/* Parent Tab 2 */}









            </Tabs>
          </div>
        </div>
      </section>

      
    

      {/* Modal for Terms and Conditions */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>

        <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'none' }}>
          {/* Add your terms and conditions text here */}
          <p style={{ textAlign: "justify" }}>
            By submitting the IIA Automate course payment form, you agree to the terms and conditions
            outlined. Payment is required in full upon registration, and refunds are only available
            within 14 days of payment, provided course materials have not been accessed. Course access
            is granted after successful payment and will remain available for the specified duration.
          </p>
          <p style={{ textAlign: "justify" }}>
            All course materials are the intellectual property of IIA Automate and may not be reproduced
            without permission. Participants must adhere to professional conduct during the course.
            Personal data will be handled according to our Privacy Policy. IIA Automate reserves the
            right to update these terms at any time.
          </p>
          <p style={{ textAlign: "justify" }}>
            Any breach of these terms and conditions may result in the immediate termination of course access
            without refund. IIA Automate retains the right to revoke access if it is determined that the terms
            have been violated, including but not limited to sharing login credentials or course materials
            with unauthorized individuals. Participants are expected to complete the course independently, and
            any form of academic dishonesty will not be tolerated.
          </p>

          <p style={{ textAlign: "justify" }}>
            The course content is designed for educational purposes only, and IIA Automate does not guarantee
            specific career outcomes or certifications upon completion. While we strive to provide accurate and
            up-to-date information, we are not liable for any errors or omissions in the course materials. It is
            the participant's responsibility to ensure they meet any technical or hardware requirements needed
            to complete the course.
          </p>


          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="secondary"
              onClick={handleModalSubmit}
              style={{ backgroundColor: "linear-gradient(0deg, rgb(247, 136, 55) 0%, rgb(243 166 109) 100%)" }}
            >
              Submit
            </Button>

          </div>
        </Modal.Body>

      </Modal>




      {isPopupOpen && (
        <div className="popup stage-three-pop-up" style={{ height: '50%', width: '56%', overflowY: 'auto', marginTop: '80px' }}>
          <button className="close-button1 three-pop-up-close" style={{ marginLeft: '670px' }} onClick={() => setIsPopupOpen(false)}>
            &times; {/* Close symbol */}
          </button>
          <div className="popup-content" style={{ textAlign: 'left' }}>

            <div className="container">

              {/* <hr></hr> */}


              <div className="row" style={{ fontSize: "22px" }}>
                <div></div>

                <div className="form-contents stage-three-forms" style={{
                  width: "794px", // A4 width in pixels
                  height: "923px", // A4 height in pixels
                  backgroundColor: "#ffffff", // Change to your desired background color
                  padding: "20px", // Add padding around the content
                  border: "1px solid rgb(98 86 86)", // Optional: add a border
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                  margin: "0 auto" // Center the A4 container
                }}>
                  <div className="row" style={{ fontSize: "22px" }}>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px",
                      flexWrap: "wrap",
                      paddingBottom: "16px",
                      marginTop: "20px"
                    }}>
                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                      <h3 style={{ color: "#f58938", margin: 0 }}>
                        INTERNATIONAL INSTITUTE OF AUTOMATE
                      </h3>

                      {/* <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                    </p>
                                                    <p style={{ textAlign: "right" }}>
                                                     {" "}
                                                     <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                   <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                    Info@iiautomate.com
                                                </p> */}


                    </div>
                    {/* <div >
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "right" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              {" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div> */}

                  </div>
                  {/* <br></br> */}
                  <table method="Get" style={{ paddingTop: '15px', paddingBottom: "0px", borderSpacing: '10px', textAlign: "center" }}>
                    <tbody>
                      <tr>
                        <td>Full Name</td>
                        <td>Naresh</td>
                      </tr>
                      <tr>
                        <td>Email Id</td>
                        <td style={{ wordBreak: "break-word", }}>nareshbojja0009@gmail.com</td>
                      </tr>
                      <tr>
                        <td>Mobile Number</td>
                        <td>84774874884</td>
                      </tr>
                      <tr>
                        <td>Gender</td>
                        <td>Male</td>
                      </tr>
                      <tr>
                        <td>Date of Birth</td>
                        <td>6-10-1998</td>
                      </tr>
                      <tr>
                        <td>Course</td>
                        <td>MCA</td>
                      </tr>
                      {/* <tr>
                                            <td>Particular</td>
                                            <td>Offline Robotics</td>
                                          </tr> */}
                      <tr>
                        <td>Mode</td>
                        <td>Online</td>
                      </tr>
                      {/* <tr>
                                            <td>Batch</td>
                                            <td>{toolbarfixture[0].batch ?? ""}</td>
                                          </tr> */}
                      <tr>
                        <td>Duration</td>
                        <td>160 HRS</td>
                      </tr>
                      <tr>
                        <td>Time</td>
                        <td>10:00PM</td>
                      </tr>
                      <tr>
                        <td>Branch</td>
                        <td>MCA</td>
                      </tr>
                      <tr>
                        <td>Registration fee</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>Paid</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <button type="button" class="btn btn-primary" onClick={handleDownloads}>
                      Download
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handlePrint}>
                      Print
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handleNextNesteds}>
                      Next
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}


      {isPopupOpen1 && (
        <div className="popup stage-three-pop-up" style={{ height: '50%', width: '56%', overflowY: 'auto', marginTop: '80px' }}>
          <button className="close-button1 three-pop-up-close" style={{ marginLeft: '670px' }} onClick={() => setIsPopupOpen1(false)}>
            &times; {/* Close symbol */}
          </button>
          <div className="popup-content" style={{ textAlign: 'left' }}>

            <div className="container">

              {/* <hr></hr> */}


              <div className="row" style={{ fontSize: "22px" }}>
                <div></div>

                <div className="form-contents stage-three-forms" style={{
                  width: "794px", // A4 width in pixels
                  height: "923px", // A4 height in pixels
                  backgroundColor: "#ffffff", // Change to your desired background color
                  padding: "20px", // Add padding around the content
                  border: "1px solid rgb(98 86 86)", // Optional: add a border
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                  margin: "0 auto" // Center the A4 container
                }}>
                  <div className="row" style={{ fontSize: "22px" }}>
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px",
                      flexWrap: "wrap",
                      paddingBottom: "16px",
                      marginTop: "20px"
                    }}>
                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                      <h3 style={{ color: "#f58938", margin: 0 }}>
                        INTERNATIONAL INSTITUTE OF AUTOMATE
                      </h3>

                      {/* <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                    </p>
                                                    <p style={{ textAlign: "right" }}>
                                                     {" "}
                                                     <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                   <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                    Info@iiautomate.com
                                                </p> */}


                    </div>
                    {/* <div >
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "right" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              {" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div> */}

                  </div>
                  {/* <br></br> */}
                  <table method="Get" style={{ paddingTop: '15px', paddingBottom: "15PX", textAlign: "center" }}>

                    <tr>
                      <td>Full Name</td>
                      <td>Naresh</td>
                    </tr>
                    <tr>
                      <td>Email Id</td>
                      <td style={{ wordBreak: "break-word" }}>nareshbojja0009@gmail.com</td>
                    </tr>
                    <tr>
                      <td>Mobile Number</td>
                      <td>8688037081</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>Male</td>
                    </tr>

                    <tr>
                      <td>Date of Birth</td>
                      <td>10-05-2000</td>
                    </tr>


                    <tr>
                      <td>Category</td>
                      <td>Courses</td>
                    </tr>
                    <tr>
                      <td>Particular</td>
                      <td>Offline Rootics</td>
                    </tr>
                    <tr>
                      <td>Mode</td>
                      <td>
                        Online
                      </td>
                    </tr>

                    <tr>
                      <td>Duration</td>
                      <td>160 HRS</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td>10-05-2000</td>
                    </tr>
                    <tr>
                      <td>Time</td>
                      <td>9AM-6PM</td>
                    </tr>
                    <tr>
                      <td>Start Date</td>
                      <td>10-06-2024</td>
                    </tr>


                    <tr>
                      <td>End Date</td>
                      <td>18-09-2024</td>
                    </tr>
                    <tr>
                      <td>Batch</td>
                      <td>45</td>
                    </tr>


                    <tr>
                      <td>Status</td>
                      <td>Paid</td>
                    </tr>




                  </table>

                </div>
                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <button type="button" class="btn btn-primary" onClick={handleDownloads}>
                      Download
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handlePrint}>
                      Print
                    </button>
                    <button type="button" class="btn btn-primary" onClick={handleNextNestedses}>
                      Next
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}

      {isPopupOpen2 && (
        <div className="popup stage-three-pop-up" style={{ height: '50%', width: '56%', overflowY: 'auto', marginTop: '100px' }}>
          <button className="close-button1 three-pop-up-close" style={{ marginLeft: '650px' }} onClick={() => setIsPopupOpen2(false)}>
            &times; {/* Close symbol */}
          </button>
          <div className="popup-content" style={{ textAlign: 'left' }}>

            <div className="container">
              <div className="row" style={{ fontSize: "22px" }}>
                <div></div>

                <div className="form-content stage-three-form" style={{
                  width: "794px", // A4 width in pixels
                  height: "923px", // A4 height in pixels
                  backgroundColor: "#ffffff", // Change to your desired background color
                  padding: "20px", // Add padding around the content
                  border: "1px solid rgb(98 86 86)", // Optional: add a border
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                  margin: "0 auto" // Center the A4 container
                }}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px",
                    flexWrap: "wrap",
                    paddingBottom: "16px",
                    marginTop: "0px"
                  }}>
                    <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                    <h3 style={{ color: "#f58938", margin: 0 }}>
                      INTERNATIONAL INSTITUTE OF AUTOMATE
                    </h3>


                  </div>

                  {/* <br></br> */}
                  <table style={{ paddingTop: "15px", paddingBottom: "15PX", textAlign: "center" }}>
                    <tr>
                      <td>Full Name</td>
                      <td>Naresh</td>
                    </tr>
                    <tr>
                      <td>Email Id</td>
                      <td style={{ wordBreak: "break-word" }}>nareshbojja0009@gmail.com</td>
                    </tr>
                    <tr>
                      <td>Mobile Number</td>
                      <td>8688037081</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>Male</td>
                    </tr>
                    <tr>
                      <td>Date of Birth</td>
                      <td>10-05-2000</td>
                    </tr>
                    <tr>
                      <td>Category</td>
                      <td>Courses</td>
                    </tr>
                    <tr>
                      <td>Particular</td>
                      <td>Offline Robotics</td>
                    </tr>
                    <tr>
                      <td>Mode</td>
                      <td>Online</td>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <td>160 HRS</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td>10-05-2000</td>
                    </tr>
                    <tr>
                      <td>Time</td>
                      <td>9AM-6PM</td>
                    </tr>
                    <tr>
                      <td>Branch</td>
                      <td>Hyderabad</td>
                    </tr>
                    <tr>
                      <td>Fee amount</td>
                      <td>40,000</td>
                    </tr>
                    <tr>
                      <td>Paid amount</td>
                      <td>10,000</td>
                    </tr>
                    <tr>
                      <td>Pending Amount</td>
                      <td>30,000</td>
                    </tr>
                  </table>
                </div>

              </div>
              <br />




              <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <button type="button" class="btn btn-primary" onClick={handleDownload}>
                    Download
                  </button>
                  <button type="button" class="btn btn-primary" onClick={handlePrintS}>
                    Print
                  </button>
                </div>
              </div>
              {/* </div> */}

            </div>




          </div>
        </div>
      )}



      {isPopupOpen3 && (
        <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px', }} className="stage-three-data">
          <div style={{ display: 'table', width: '100%', marginRight: '100px', border: '1px solid #ccc', borderRadius: '8px' }}>
            {/* Table Header */}
            <div style={{ display: 'table-row', backgroundColor: '#f7f7f7' }}>
              <div
                style={{
                  display: 'table-cell',
                  padding: '10px',
                  fontWeight: 'bold',
                  border: '1px solid #ddd',
                  backgroundColor: '#f7f7f7',
                }}
              >
                Category
              </div>
              <div
                style={{
                  display: 'table-cell',
                  padding: '10px',
                  border: '1px solid #ddd',
                  backgroundColor: '#f7f7f7',
                }}
              >
                Course
              </div>
            </div>

            {/* Table Rows */}
            <div style={{ display: 'table-row' }}>
              <div
                style={{
                  display: 'table-cell',
                  padding: '10px',
                  fontWeight: 'bold',
                  border: '1px solid #ddd',
                }}
              >
                Particular
              </div>
              <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                Online Robotics
              </div>
            </div>

            <div style={{ display: 'table-row' }}>
              <div
                style={{
                  display: 'table-cell',
                  padding: '10px',
                  fontWeight: 'bold',
                  border: '1px solid #ddd',
                }}
              >
                Fee amount
              </div>
              <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                10,000
              </div>
            </div>

            <div style={{ display: 'table-row' }}>
              <div
                style={{
                  display: 'table-cell',
                  padding: '10px',
                  fontWeight: 'bold',
                  border: '1px solid #ddd',
                }}
              >
                Paid amount
              </div>
              <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                30,000
              </div>
            </div>

            <div style={{ display: 'table-row' }}>
              <div
                style={{
                  display: 'table-cell',
                  padding: '10px',
                  fontWeight: 'bold',
                  border: '1px solid #ddd',
                }}
              >
                Pending amount
              </div>
              <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                20,000
              </div>
            </div>
          </div>

          {/* Pay Button Section */}
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', marginRight: '20px' }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handlePopUp2}
            >
              Pay
            </button>
          </div>
        </div>
      )}



      {isPopupOpen4 && (
        <div style={{ padding: "20px",  }} className="stagefour-data">
          <div >
            <div style={{ padding: '20px', marginLeft: '10px', backgroundColor: 'white', borderRadius: '8px' }}>
              <div style={{ display: 'table', width: '100%', marginRight: '100px', border: '1px solid #ccc', borderRadius: '8px' }}>
                {/* Table Header */}
                <div style={{ display: 'table-row', backgroundColor: '#f7f7f7' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    Category
                  </div>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      border: '1px solid #ddd',
                      backgroundColor: '#f7f7f7',
                    }}
                  >
                    Course
                  </div>
                </div>

                {/* Table Rows */}
                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Particular
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    Online Robotics
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Fee amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    10,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Paid amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    30,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Pending amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    20,000
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Partial amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      defaultValue="20,000"
                      style={{
                        width: '50%', // Make the input take the full width of the cell
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>

                <div style={{ display: 'table-row' }}>
                  <div
                    style={{
                      display: 'table-cell',
                      padding: '10px',
                      fontWeight: 'bold',
                      border: '1px solid #ddd',
                    }}
                  >
                    Remaining amount
                  </div>
                  <div style={{ display: 'table-cell', padding: '10px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      defaultValue="20,000"
                      style={{
                        width: '50%', // Make the input take the full width of the cell
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>



          </div>




          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px', marginRight: '40px' }}>
            <button type="button" class="btn btn-primary" onClick={handleNextNestedoptiont}>
              Self Pay
            </button>
            <button type="button" class="btn btn-primary" onClick={handleNextNestedoptiont}>
              EMI
            </button>
          </div>
        </div>




      )}


      {isPopupOpen5 && (


        <div style={{ padding: "20px", }}>

          <table method="Get" style={{
            paddingTop: '15px',
            paddingBottom: '15px',
            fontSize: '22px',
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
            borderCollapse: 'collapse',
            textAlign: 'left',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}>

            <tbody>
              <tr style={{}}>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>Total Amount</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>30,000</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>Number of EMI's</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>10</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>Monthly EMI Cost</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>3000</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>EMI Starting Month</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>10-05-2024</td>
              </tr>
              <tr>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>EMI End Month</td>
                <td style={{ padding: '12px 15px', borderBottom: '1px solid #ddd' }}>18-09-2024</td>
              </tr>
            </tbody>
          </table>
          <br></br>

          <div style={{ display: 'flex', marginLeft: "20px", justifyContent: 'center', }}>
            <button type="button" class="btn btn-primary" onClick={handlePopUp1}>
              Pay
            </button>
          </div>

        </div>




      )}






    </div>


  )
}

export default Enroll