import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import Advertisecode from "./Advertisecode";
import { Tab, Tabs } from "react-bootstrap";
import { Form, Modal, Button } from "react-bootstrap";
import { Delete } from '@mui/icons-material';
import AdvertisementShow from './AdverstimentDes';
// import Contacts from "./Contacts";
import logo from "../images/logo-white-2.png";
import CircularProgress from '@mui/material/CircularProgress';
import { CloudDownload, CloudUpload, RemoveRedEye, Edit } from '@mui/icons-material';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import apiUrls from './apiUrls';
import html2canvas from "html2canvas";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
} from '@mui/material';

const TableStages = () => {
  const fieldSelectRef = useRef(null);
  const [stages, setStages] = useState([]);
  const [enroll, setEnroll] = useState('');
  const [statusOptions, setStatusOptions] = useState([])
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showWebDevDropdown, setShowWebDevDropdown] = useState(false);
  const [showMechanicalDropdown, setShowMechanicalDropdown] = useState(false);
  const [datasource, setDatasource] = useState([]);
  const [selected, setSelected] = useState([]);
  const [interests, setInterests] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  // const [filteredData, setFilteredData] = useState(datasource);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [filters, setFilters] = useState({
    program: "",
    course: "",
    stream: "",
  });
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    organisation: "",
    name: "",
    email: "Kumar@gmail.com",
    phoneNumber: "",
    role: "",
    category: "",
    remarks: "",
    password: ""


  });
  const [isPopupOpen3, setIsPopupOpen3] = useState(false);
  const [date, setDate] = useState('');
  const [isPopupOpen4, setIsPopupOpen4] = useState(false);
  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [key, setKey] = useState('registration'); // Managing active tab
  const [offlinerobotics, setOfflinerobotics] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);

  // Replace with actual data
  const [datasource1, setDatasource1] = useState([]);
  const [datasource2, setDatasource2] = useState([]);
  const [datasource3, setDatasource3] = useState([]);

  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Store total seconds to calculate hours

  // const [formData, setFormData] = useState({
  //   fullName: "",
  //   gender: "",
  //   dateOfBirth: "",
  //   emailId: "",
  //   contactNumber: "",
  //   course: "",
  //   timings: "",
  //   mode: "",
  //   courseDuration: "",
  //   categories: "",
  //   coursePrice: "",
  //   councillorName: "",
  // });
  const [toolbarfixture, setToolbarfixture] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [courseduration, setCoursess] = useState([]); // State for course options
  const [nestedKey, setNestedKey] = useState('step1');
  const [subNestedKey, setSubNestedKey] = useState('subStep1'); // For second-level sub-tabs
  const [optionss, setOptionss] = useState([]);
  // State for userId entered by the user


  const [formData, setFormData] = useState({
    source: '',
    sourceName: '',
    courses: '',
    duration: '_160_HR',
    timings: '_9AM_6PM',
    mode: '',
    branches: '',
    coursePrice: '10' // Include coursePrice if it's part of the form
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log("Filtered Data:", filteredData); // Log the filtered data
      setSelected(filteredData); // Set all rows as selected
    } else {
      setSelected([]); // Clear selection
      console.log("Selected rows cleared");
    }
  };




  const handleDelete = async (enquiryId) => {
    try {
      const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/deletebyid/${enquiryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is OK (status code 200)
      if (!response.ok) {
        const errorData = await response.text(); // Get error message from response body (as text)
        throw new Error(`Failed to delete: ${errorData}`);
      }

      // Parse the response body as text and log the result
      const data = await response.text(); // Assuming the response is in text format
      console.log('Response:', data); // Log the response
      alert('Enquiry deleted successfully!');

      // Optionally, update your component state or refresh the data here
      window.location.reload();

    } catch (error) {
      console.error('Error:', error);
      alert('Error deleting enquiry: ' + error.message);
    }
  };



  const handleDelete1 = async (intrestId) => {
    try {
      const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/intrestedcandidates/deletebyid/${intrestId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is OK (status code 200)
      if (!response.ok) {
        const errorData = await response.text(); // Get error message from response body (as text)
        throw new Error(`Failed to delete: ${errorData}`);
      }

      // Parse the response body as text and log the result
      const data = await response.text(); // Assuming the response is in text format
      console.log('Response:', data); // Log the response
      alert('Enquiry deleted successfully!');

      // Optionally, update your component state or refresh the data here
      window.location.reload();

    } catch (error) {
      console.error('Error:', error);
      alert('Error deleting enquiry: ' + error.message);
    }
  };



  const handleDelete2 = async (enrId) => {
    try {
      const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryenrolltable/deletebyid/${enrId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is OK (status code 200)
      if (!response.ok) {
        const errorData = await response.text(); // Get error message from response body (as text)
        throw new Error(`Failed to delete: ${errorData}`);
      }

      // Parse the response body as text and log the result
      const data = await response.text(); // Assuming the response is in text format
      console.log('Response:', data); // Log the response
      alert('Enquiry deleted successfully!');

      // Optionally, update your component state or refresh the data here
      window.location.reload();

    } catch (error) {
      console.error('Error:', error);
      alert('Error deleting enquiry: ' + error.message);
    }
  };





  const handleRoleChange = (e) => {
    setUserDetails({ ...userDetails, role: e.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader(); // Create a FileReader to read the file
      reader.onload = async (e) => {
        try {
          // Read file data as an ArrayBuffer
          const data = new Uint8Array(e.target.result);

          // Parse the workbook from the data
          const workbook = XLSX.read(data, { type: "array" });

          // Get the first sheet name and its content
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Convert sheet data to JSON
          const jsonData = XLSX.utils.sheet_to_json(sheet);

          // Update state with the imported data
          setDatasource(jsonData);


          console.log("Parsed Data:", jsonData);

          // Send the data to the API
          const response = await axios.post(
            "https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/addlist",
            jsonData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          console.log("API Response:", response.data);
          alert("Data imported successfully!");
        } catch (error) {
          console.error("Error processing file:", error);
          alert("Failed to import data. Please check the file and try again.");
        }
      };

      // Read the file as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    } else {
      alert("No file selected. Please choose a valid file.");
    }
  };


  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };


  const handleFilterChange = (field) => (event) => {
    // Normalize input by trimming, replacing multiple spaces with a single space, and ensuring it's not null/undefined
    setFilters({
      ...filters,
      [field]: event.target.value?.toLowerCase().trim().replace(/\s+/g, ' ') || '',
    });
  };

  const filteredData = datasource.filter((row) => {
    return (
      (row.program?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.program || !filters.program) &&
      (row.course?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.course || !filters.course) &&
      (row.stream?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.stream || !filters.stream)
    );
  });



  const filteredData1 = datasource1.filter((row) => {
    return (
      (row.program?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.program || !filters.program) &&
      (row.course?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.course || !filters.course) &&
      (row.stream?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.stream || !filters.stream)
    );
  });


  const filteredData2 = datasource2.filter((row) => {
    return (
      (row.program?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.program || !filters.program) &&
      (row.course?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.course || !filters.course) &&
      (row.stream?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.stream || !filters.stream)
    );
  });


  const filteredData3 = datasource3.filter((row) => {
    return (
      (row.program?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.program || !filters.program) &&
      (row.course?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.course || !filters.course) &&
      (row.stream?.toLowerCase().trim().replace(/\s+/g, ' ') === filters.stream || !filters.stream)
    );
  });



  useEffect(() => {
    const fetchInterests = async () => {
      try {
        const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/intrest');
        setInterests(response.data); // Update with the response data
        setLoading(false); // Set loading to false
      } catch (error) {
        console.error('Error fetching interests:', error);
        setLoading(false);
      }
    };

    fetchInterests();
  }, []);




  const updateRecord = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    // Prepare the payload based on API requirements
    const payload = {
      candidateResponse: selectedRowData.candidateResponse,

      remark: selectedRowData.remark,
      // Add other fields as necessary
    };

    try {
      const response = await axios.put(
        `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryform/edit/${selectedRowData.enquiryId}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json', // Ensuring the content is JSON
          },
        }
      );
      alert('Record updated successfully:')
      console.log('Record updated successfully:', response.data);
      window.location.reload();
      // Optionally, refresh data or notify the user
      setIsPopupOpen1(false); // Close the modal
      // Reset selectedRowData or update parent state as needed
    } catch (error) {
      console.error('Error updating record:', error);
      // Optionally, display error message to the user
    } finally {
      setLoadingSubmit(false);
    }
  };



  const updateRecordStage = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    // Prepare the payload based on API requirements
    const payload = {

      stage: selectedRowData.stage,

      // Add other fields as necessary
    };

    try {
      const response = await axios.put(
        `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/intrestedcandidates/edittable/${selectedRowData.intrestId}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json', // Ensuring the content is JSON
          },
        }
      );
      alert('Record updated successfully:')
      console.log('Record updated successfully:', response.data);
      window.location.reload();
      // Optionally, refresh data or notify the user
      setIsPopupOpen1(false); // Close the modal
      // Reset selectedRowData or update parent state as needed
    } catch (error) {
      console.error('Error updating record:', error);
      // Optionally, display error message to the user
    } finally {
      setLoadingSubmit(false);
    }
  };



  const updateRecordStage2 = async (enrId) => {
    // e.preventDefault();
    setLoadingSubmit(true);
    //  alert(selectedRowData?.enrId)

    localStorage.setItem("enrId", enrId);

    try {
      const response = await axios.post(
        `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enquiryregister/sendpaymentslink/${enrId}`,

        {
          headers: {
            'Content-Type': 'application/json', // Ensuring the content is JSON
          },
        }
      );
      alert('Link Send successfully')
      console.log('Link Send successfully:', response.data);
      window.location.reload();
      // Optionally, refresh data or notify the user
      setIsPopupOpen1(false); // Close the modal
      // Reset selectedRowData or update parent state as needed
    } catch (error) {
      console.error('Error updating record:', error);
      // Optionally, display error message to the user
    } finally {
      setLoadingSubmit(false);
    }
  };



  function downloadBase64Pdf(base64String, fileName) {
    // Remove the base64 prefix if present
    const base64Prefix = 'data:application/pdf;base64,';
    if (base64String.startsWith(base64Prefix)) {
      base64String = base64String.replace(base64Prefix, '');
    }

    // Decode the base64 string into binary
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'document.pdf';
    link.click();

    // Revoke the Object URL after the download is triggered
    URL.revokeObjectURL(url);
  }



  const handleClick = (event, row) => {
    const isSelected = selected.some((item) => item.enquiryId === row.enquiryId); // Check if the row is already selected
    let newSelected = [];

    if (isSelected) {
      // Remove the row if it's already selected
      newSelected = selected.filter((item) => item.enquiryId !== row.enquiryId);
    } else {
      // Add the row if it's not selected
      newSelected = [...selected, row];
    }

    setSelected(newSelected); // Update the state with the new selection
  };


  // Check if the row is selected
  const isSelected = (enquiryId) => selected.some((row) => row.enquiryId === enquiryId);



  // const handleFilterChange = (event) => {
  //   setFilter(event.target.value);
  // };


  useEffect(() => {
    const fetchStages = async () => {
      try {
        const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/stage');
        setStages(response.data); // Assume response contains an array of stages
        setLoading(false); // Set loading to false
      } catch (error) {
        console.error('Error fetching stages:', error);
        setLoading(false);
      }
    };

    fetchStages();
  }, []);






  const AddRecord = async (e) => {
    e.preventDefault(); // Prevent form from reloading the page

    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          organisation: userDetails.organisation,
          name: userDetails.name,
          mobileNumber: userDetails.phoneNumber,
          emailId: userDetails.emailId,
          password: userDetails.password,
          categories: userDetails.category,
          role: userDetails.role,
          remarks: userDetails.remarks,
        }),
      });

      // Check if the response is successful
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || 'Failed to register. Please try again.');
      }

      const contentType = response.headers.get('Content-Type');

      if (contentType && contentType.includes('application/json')) {
        // If the response is JSON, handle it accordingly
        const data = await response.json();
        console.log('Registration successful:', data);

        // You can navigate to a different page or close the form here
        navigate('/layout1');
        window.location.reload();
        alert('Data Successfully Saved');
        setIsPopupOpen(false);  // Close the popup
      } else if (contentType && contentType.includes('text/plain')) {
        // If the response is plain text (success message)
        const textResponse = await response.text();
        console.log('Response:', textResponse); // Log the response text (e.g., "User Registered Successfully")

        alert(textResponse); // Show the success message in an alert
        navigate('/layout1');
        window.location.reload();
        setIsPopupOpen(false);  // Close the popup
      } else {
        throw new Error('Unexpected response format.');
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error, show a message or perform other actions
      alert(error.message || 'Something went wrong, please try again.');
    }
  };


  const handlePopup1 = (rowData) => {
    // alert(rowData)
    setSelectedRowData(rowData);
    setIsPopupOpen1(true);
  };

  const exportToExcel = () => {
    const selectedData = selected.length > 0 ? selected : datasource;
    // alert(selectedData)
    console.log("Exporting Data:", selectedData);
    // alert(selectedData)
    if (!selectedData || selectedData.length === 0) {
      alert("No data available for export.");
      return;
    }

    const formattedData = selectedData.map((row, index) => ({
      "S.No": index + 1,
      Name: row.name || "",
      Email: row.emailId || "",
      Contact: row.mobileNumber || "",
      Program: row.program || "",
      Course: row.course || "",
      Stream: row.stream ? row.stream.replace(/_/g, " ") : "",
      Date: row.startDate
        ? new Date(row.startDate).toLocaleDateString("en-GB")
        : "",
      Time: row.startTime || "",
      Source: row.source || "",
      Counsellor: row.councellor || "",
      Reference: row.reference || "",
      Response: row.candidateResponse || "",
      Stage: row.stage || "",
      Remarks: row.remark || "",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exported Data");
    XLSX.writeFile(wb, "exported_data.xlsx");
  };




  useEffect(() => {
    // Get the current date in YYYY-MM-DD format (without the time)
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }
    // Construct the API URL with the current date
    const apiUrl = `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance/${userId}/${formattedDate}`;

    // Fetch the data from the API
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setAttendanceData(data); // Set the data
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        setError(error.message); // Set error if there's an issue with the request
        setLoading(false);
      });
  }, []); // Empty dependency array means this effect runs once on component mount



  const attendedTimeMinutes = attendanceData?.attendedTime;
  const attendedTimeHours = attendedTimeMinutes ? (attendedTimeMinutes / 60).toFixed(2) : 0;
  const remainingTimeMinutes = attendanceData?.remainingTime;
  const remainingTimeHours = remainingTimeMinutes ? (remainingTimeMinutes / 60).toFixed(2) : 0;

  useEffect(() => {


    const fetchData = async () => {
      try {
        // Replace this with your actual API URL
        const response = await axios.get(
          apiUrls.PUT_Completed_getall);

        setDatasource3(response.data); // Set the data from the API to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {


    const fetchData = async () => {
      try {
        // Replace this with your actual API URL
        const response = await axios.get(
          apiUrls.PUT_Enquiryform_getall);

        setDatasource(response.data); // Set the data from the API to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {


    const fetchData = async () => {
      try {
        // Replace this with your actual API URL
        const response = await axios.get(
          apiUrls.PUT_enquiryenrolltable);

        setDatasource2(response.data); // Set the data from the API to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {


    const fetchData = async () => {
      try {
        // Replace this with your actual API URL
        const response = await axios.get(
          apiUrls.PUT_intrestedcandidatesgetall);

        setDatasource1(response.data); // Set the data from the API to state
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);










  // Function to handle API call
  const fetchOptionss = async () => {
    try {
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/sources'); // Replace with your API endpoint
      const data = await response.json();
      setOptionss(data); // Assuming the response is an array like ["SocialMedia", "Councillor", "Reference"]
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call API when component mounts
  fetchOptionss();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetchCourseDuration = async () => {
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/courseduration"); // Replace with your courses API endpoint
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const result = await response.json();
        setCoursess(result); // Assuming result is an array of course objects
      } catch (error) {
        setError(error);
      }
    };

    fetchCourseDuration();

    // Initial fetch for default course price (if needed)
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch("https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=Java");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setOfflinerobotics(result);
        setFormData((prev) => ({ ...prev, coursePrice: result }));
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);




  const handleTabSelect = (key) => {
    setKey(key)
    // setActiveKey(key); // Update active tab
    if (key !== "subStep4") {
      setIsPopupOpen3(false) // Hide popup3 when switching from STAGE 3
    }
  };


  const handleSubNestedTabSelect = (selectedKey) => {
    // Set the selected sub-tab key
    setSubNestedKey(selectedKey);

    // Check if the selected sub-tab is subStep4
    if (selectedKey === "subStep4") {
      setIsPopupOpen3(true); // Show Popup 3 when subStep4 is selected
    } else {
      setIsPopupOpen3(false); // Hide Popup 3 when other tabs are selected
      setIsPopupOpen4(false); // Hide Popup 4 (if needed)
      setIsPopupOpen5(false); // Hide Popup 4 (if needed)
    }

    // If needed, add logic to close the popup for other steps
    // For example, when switching away from subStep4, hide other popups.
  };




  // Function to handle nested tab selection
  const handleNestedTabSelect = (selectedKey) => {
    setNestedKey(selectedKey);
    // Hide the popup when switching nested tabs
    setIsPopupOpen3(false); // You can modify this behavior based on your requirement
  };




  useEffect(() => {
    const fetchData = async () => {

      setIsLoading(true);
      try {
        let id = localStorage.getItem('responseData');
        console.log(toolbarfixture);
        if (!id) {
          throw new Error('No ID found in localStorage');
        }
        const response = await fetch(
          `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/preview/18`,

          // Replace ":id" with the actual ID parameter you want to fetch
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        // alert(result.fullName)
        setToolbarfixture(result);
      } catch (error) {
        setError(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);




  // <---------------------------------------------- STAGE 6 DOWNLOAD AND PRINT BUTTON ----------------------------------------->//

  const handleDownloadsL = () => {
    setTimeout(() => {
      const input = document.querySelector(".form-contentsL");
      if (!input) {
        console.error("Element not found for download");
        return;
      }

      html2canvas(input, { scale: 1.5, useCORS: true }).then((canvas) => {
        console.log("Canvas generated:", canvas); // Log the canvas
        const imgData = canvas.toDataURL("image/png");
        console.log("Image Data URL:", imgData); // Log the image data URL

        if (imgData && imgData.startsWith("data:image/png;base64,")) {
          const pdf = new jsPDF("p", "mm", "a4");

          const imgWidth = 210; // A4 width in mm
          const pageHeight = 200; // Reduce page height (default is 210mm)
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Scale the image to fit within the new page height
          let scaledImgHeight = imgHeight;
          if (imgHeight > pageHeight) {
            // Scale the height down if it exceeds page height
            const scaleFactor = pageHeight / imgHeight;
            scaledImgHeight = pageHeight;
            canvas.width *= scaleFactor; // Adjust width proportionally
            canvas.height *= scaleFactor; // Adjust height proportionally
          }

          const topMargin = 10; // Set your desired top margin in mm
          let heightLeft = scaledImgHeight - topMargin; // Adjust heightLeft to account for top margin
          let position = topMargin; // Start drawing after the top margin

          // Add the first image
          pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, position, imgWidth, scaledImgHeight);
          heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page

          // Loop for additional pages if content exceeds the page height
          while (heightLeft >= 0) {
            pdf.addPage(); // Add a new page
            position = heightLeft - scaledImgHeight + topMargin; // Maintain top margin for subsequent pages
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, position, imgWidth, scaledImgHeight);
            heightLeft -= pageHeight; // Reduce height left for the next iteration
          }

          // Save the generated PDF
          pdf.save("Completion Certification.pdf");
        } else {
          console.error("Invalid image data URL:", imgData);
        }

      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay added to ensure the element is fully rendered
  };





  const handlePrintL = () => {
    const printContent = document.querySelector(".form-contentsL");
    if (!printContent) {
      console.error("Element not found for printing");
      return;
    }

    const printWindow = window.open('', '_blank', 'height=500,width=800');
    printWindow.document.write(`
    <html>
        <head>
            <title>Print Table</title>
            <style>
                body { font-family: Arial, sans-serif; }
                table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
                @media print {
                    @page { margin: 50px; }
                    body { margin: 50px; }
                }
            </style>
        </head>
        <body>
            <h2 style="text-align: center;">Form Details</h2>
            ${printContent.innerHTML}
        </body>
    </html>
`);
    printWindow.document.close(); // Close the document to finish loading
    printWindow.print(); // Trigger the print dialog
    printWindow.close(); // Close the print window after printing
  };





  // <---------========================================== PUNCH OUT =====================================----------------------->




  return (
    <div>
      <style>
        {`
          .tab-content {
            margin-top: 20px; /* Adjust this value to set the gap */
          }
               nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
   
    background-color: none !important;
  
    
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    background-color:black;
   width:200px;
    border: 1px solid transparent;
   border-radius:10px;
  }


  .nav-tabs .nav-link:hover {
  background-color: orange; /* Background color when hovered */
  color: black; /* Text color when hovered */
  border-color: orange; /* Optional: Add a border color */
}

/* Active tab styling */
.nav-tabs .nav-link.active {
  background-color: orange; /* Background color for active tab */
  color: black; /* Text color for active tab */
  border-color: orange; /* Optional: Border for active tab */
}

    /* General container styling */
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Punch-status card styles */
.punch-status .punch-det h6 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.punch-status .punch-det p {
  font-size: 14px;
}

.punch-status .punch-info .punch-hours {
  text-align: center;
  margin-bottom: 10px;
}

.punch-status .punch-btn {
  width: 100%;
  font-size: 14px;
  padding: 10px;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .card {
    margin-bottom: 15px;
  }
}
.clock-background {
    border-radius: 50%;
    background-color: #efefef; /* Your desired color */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 107px; /* Set a width for the circle */
    height: 105px; /* Set a height for the circle */
    border: 2px solid #000; /* Add a 2px solid border, change #000 to your desired border color */
    margin-left:25px;
}


.card-title-bg {
    background-color: #e7f1ff; /* Change this to your desired background color */
    padding: 10px; /* Add some padding for spacing */
    border-radius: 5px; /* Optional: Add rounded corners */
    display: inline-block; /* Make the background fit the content */
        margin-left: 20px;
}




.custom-width {
  width: 250px; /* Adjust this value as needed */
}



.form-select {
  display: block;
  width: 100%;
  padding: none !important;
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.937rem;
  font-weight: 400; */


}


/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
* Bootstrap v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/


table td {
padding: 7px 33px !important;
}

table {
background-color: transparent;
width: 100%;
}
table thead th {
color: #333;
}
table td {
padding: 15px 10px;
}
p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
margin-bottom: 4px;
}

.ad-container {
  position: fixed;
  right: -55px;
  bottom: 0;
  top: 0;
  width: 324px; /* Adjust width as needed */
  overflow: hidden;
  background-color: 'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 100%;
}

.ad-item {
  padding: 20px;
  color: white;
 background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
         
        `}
      </style>

      <section>
        <div className="container-fluid tabs-data" style={{}}>
          <div
            className="container boderform"
            style={{
              // backgroundColor: "#efefef",
              paddingBottom: "30px",
              borderRadius: "10px",
              marginTop: "-24px",
            }}
          >

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => handleTabSelect(k)}

              className="mb-3"
              style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', padding: "7px", fontSize: '20px', }}
            >

              <Tab
                eventKey="registration"
              // title={<span style={{ fontWeight: '500', color: '#f58938' }}>COURSES</span>}
              >

                <Tabs
                  id="nested-tab-example"
                  activeKey={nestedKey}
                  onSelect={handleNestedTabSelect}
                  className="mb-3"
                  style={{ marginTop: '-20px', justifyContent: 'space-evenly', width: '100%', marginLeft: '-11px', fontSize: '20px', padding: '5px', borderBottom: 'none', }}
                >
                  {/* Sub Tab 1 */}
                  <Tab eventKey="step1"

                  >
                    <Tabs
                      id="sub-nested-tab-example"
                      activeKey={subNestedKey}
                      onSelect={(k) => handleSubNestedTabSelect(k)}
                      className="mb-3"
                      style={{ marginTop: '-50px', justifyContent: 'space-evenly', width: '100%', marginLeft: '-11px', padding: "3px", fontSize: '20px' }}
                    >
                      {/* Sub Tab under REGISTRATION */}
                      <Tab eventKey="subStep1" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>Enquiry</span>} >


                        {loadingSubmit && (
                          <div className="overlay-loader">
                            <div className="loader">
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  border: "5px solid #ccc",
                                  borderTop: "5px solid #e0782c",
                                  borderRadius: "50%",
                                  animation: "spin 1s linear infinite",
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <Card class="usernameydfu niop" style={{ marginTop: '-40px', }} >
                          <CardHeader
                            style={{
                              backgroundColor: 'whitesmoke',
                              marginTop: '5%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            title={
                              <>
                                <TextField
                                  placeholder="Program"
                                  value={filters.program}
                                  onChange={handleFilterChange("program")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px', color: 'black' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Course"
                                  value={filters.course}
                                  onChange={handleFilterChange("course")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Stream"
                                  value={filters.stream}
                                  onChange={handleFilterChange("stream")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                              </>
                            }
                            action={
                              <>
                                <input
                                  id="fileInput"
                                  type="file"
                                  accept=".xlsx, .xls"
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                                <label htmlFor="fileUpload">
                                  <Button variant="extendedFab" onClick={handleButtonClick} component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                    <CloudDownload style={{ marginRight: '8px' }} />
                                    Import
                                  </Button>
                                </label>
                                <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                  <CloudUpload style={{ marginRight: '8px' }} />
                                  Export
                                </Button>

                              </>



                            }
                          />
                          <TableContainer style={{
                            marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
                            width: '100%'
                          }}>
                            <Table>
                              <TableHead style={{ backgroundColor: 'lightblue' }}>
                                <TableRow>
                                  <TableCell style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    sx={{
                                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                      padding: '30px',
                                      width: '50px', // Set the desired width
                                    }}
                                  >

                                    <Checkbox
                                      indeterminate={selected.length > 0 && selected.length < filteredData.length}
                                      checked={filteredData.length > 0 && selected.length === filteredData.length}
                                      onChange={handleSelectAllClick}
                                    />
                                  </TableCell>
                                  {['S.No', 'Name', 'Email Id', 'Contact Number', 'Program', 'Course', 'Stream', 'Date', 'Time', 'Source', 'Conseller', 'Reference', 'Response', 'Stage', 'Remarks', 'Resume', 'Action'].map((heading, index) => (
                                    <TableCell
                                      align="center"
                                      key={index}
                                      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                      {heading}
                                    </TableCell>
                                  ))}
                                  {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredData
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => (
                                    <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, row)}
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.enquiryId}
                                    >
                                      <TableCell
                                        padding="checkbox"
                                        style={{
                                          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                          width: '0px', // Adjust the width as needed
                                        }}
                                      >
                                        <Checkbox
                                          checked={selected.some((item) => item.enquiryId === row.enquiryId)}
                                          onChange={(event) => handleClick(event, row)}
                                        />
                                      </TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.name}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.mobileNumber}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '170px' }}>{row.program?.replace(/_/g, ' ')}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '200px' }}>{row.course}</p></TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stream ? row.stream.replace(/_/g, ' ') : ''}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>
                                        {new Date(row.startDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric'
                                        })}
                                      </p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
                                        {row.resume ? (
                                          <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
                                            <CloudDownload style={{ color: 'blue' }} />
                                          </IconButton>
                                        ) : (
                                          <span>No Resume</span>
                                        )}
                                      </TableCell>

                                      <TableCell align="center" style={{ minWidth: '150px' }}>
                                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                          <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(row.enquiryId)} style={{ color: 'red' }}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell>


                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>





                          {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                            <div className="modal-overlay">
                              <div className="modal-content1">


                                <div className="header-row">
                                  <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                                  <button style={{ marginTop: '-50px' }} class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                                </div>
                                <form style={{ marginLeft: '90px', marginTop: '-80px' }} className="form-grid" onSubmit={updateRecord}>


                                  <div>
                                    {loading ? (
                                      <p>Loading interests...</p>
                                    ) : (
                                      <select
                                        className="form-control custom-textfield"
                                        required
                                        value={selectedRowData.candidateResponse || ""} // Default to empty string if null/undefined
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          console.log("Selected Value:", value); // Log selected value to console
                                          setSelectedRowData((prevData) => ({
                                            ...prevData,
                                            candidateResponse: value, // Update candidateResponse
                                          }));
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select Interest
                                        </option>
                                        {interests.map((interest, index) => {
                                          // Format interest to replace underscores and capitalize words
                                          const formattedInterest = interest
                                            .replace(/_/g, " ") // Replace underscores with spaces
                                            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
                                          return (
                                            <option key={index} value={interest}>
                                              {formattedInterest}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    )}
                                  </div>


                                  <div className="form-group">
                                    <input type="text" className="form-control custom-textfield" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                                  </div>




                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '5%', height: '50px' }}>
                                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
                                    {/* <button type="submit" className="btn btn-save">Save</button> */}
                                    <button type="submit" className="btn btn-send">Send</button>
                                  </div>

                                </form>
                              </div>
                            </div>

                          )}




                          <CardActions>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 100]}
                              component="div"
                              count={filteredData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                            />
                          </CardActions>

                          <style>
                            {`
      .overlay-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }


      .custom-textfield {
        margin-top: 30px; 
        width: 180px !important;
  height: 42px; /* Set the desired height */
  font-size: 14px; /* Adjust font size if needed */
  // padding: 4px 8px; /* Adjust padding for better appearance */
}


      
    `}
                          </style>

                          {/* <Advertisecode /> */}
                        </Card>


                      </Tab>
                      <Tab eventKey="subStep3" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>Interested</span>} >
                        {loadingSubmit && (
                          <div className="overlay-loader">
                            <div className="loader">
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  border: "5px solid #ccc",
                                  borderTop: "5px solid #e0782c",
                                  borderRadius: "50%",
                                  animation: "spin 1s linear infinite",
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <Card class="usernameydfu niop" style={{ marginTop: '-40px' }} >
                          <CardHeader
                            style={{
                              backgroundColor: 'whitesmoke',
                              marginTop: '5%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            title={
                              <>
                                <TextField
                                  placeholder="Program"
                                  value={filters.program}
                                  onChange={handleFilterChange("program")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Course"
                                  value={filters.course}
                                  onChange={handleFilterChange("course")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Stream"
                                  value={filters.stream}
                                  onChange={handleFilterChange("stream")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                              </>
                            }
                            action={
                              <>
                                <input
                                  type="file"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  style={{ display: 'none' }}
                                  id="fileUpload"
                                />
                                <label htmlFor="fileUpload">
                                  <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                    <CloudDownload style={{ marginRight: '8px' }} />
                                    Import
                                  </Button>
                                </label>
                                <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                  <CloudUpload style={{ marginRight: '8px' }} />
                                  Export
                                </Button>

                              </>



                            }
                          />
                          <TableContainer style={{
                            marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
                            overflowY: 'auto', overflowX: 'auto',
                          }}>
                            <Table>
                              <TableHead style={{ backgroundColor: 'lightblue' }}>
                                <TableRow>
                                  <TableCell padding="checkbox"
                                    style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    sx={{
                                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                      padding: '30px',
                                    }}
                                  >
                                    <Checkbox
                                      indeterminate={selected.length > 0 && selected.length < filteredData1.length}
                                      checked={filteredData1.length > 0 && selected.length === filteredData1.length}
                                      onChange={handleSelectAllClick}
                                    />
                                  </TableCell>
                                  {['S.No', 'Name', 'Email Id', 'Contact Number', 'Program', 'Course', 'Stream', 'Date', 'Time', 'Source', 'Conseller', 'Reference', 'Response', 'Stage', 'Remarks', 'Resume', 'Action'].map((heading, index) => (
                                    <TableCell
                                      align="center"
                                      key={index}
                                      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                      {heading}
                                    </TableCell>
                                  ))}
                                  {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredData1
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => (
                                    <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, row)}
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                    >
                                      <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                          checked={selected.indexOf(row.userId) !== -1}
                                          onChange={() => handleClick(null, row)}
                                        />
                                      </TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.name}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.emailId}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.mobileNumber}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '170px' }}>{row.program?.replace(/_/g, ' ')}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '200px' }}>{row.course}</p></TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stream ? row.stream.replace(/_/g, ' ') : ''}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>
                                        {new Date(row.startDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric'
                                        })}
                                      </p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
                                        {row.resume ? (
                                          <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
                                            <CloudDownload style={{ color: 'blue' }} />
                                          </IconButton>
                                        ) : (
                                          <span>No Resume</span>
                                        )}
                                      </TableCell>


                                      <TableCell align="center">

                                        <IconButton onClick={() => handleDelete1(row.intrestId)} style={{ color: 'red' }}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell>




                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>





                          {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                            <div className="modal-overlay">
                              <div className="modal-content1">


                                <div className="header-row">
                                  <div class="serial-number">S No-{selectedRowData.intrestId}</div>
                                  <button style={{ marginTop: '-50px' }} class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                                </div>
                                <form style={{ marginLeft: '90px', marginTop: '-80px' }} className="form-grid" onSubmit={updateRecordStage}>


                                  <div className="form-group">
                                    {loading ? (
                                      <p>Loading stages...</p>
                                    ) : (
                                      <select
                                        className="form-control"
                                        required
                                        value={enroll}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          console.log("Selected Value:", value); // Log the selected value to the console
                                          setEnroll(value);
                                          setSelectedRowData({ ...selectedRowData, stage: value }); // Update the state
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select Stage
                                        </option>
                                        {stages
                                          .filter((stage) => stage !== "Enquiry" && stage !== "Completed") // Exclude unwanted options
                                          .map((stage, index) => (
                                            <option key={index} value={stage}>
                                              {stage}
                                            </option>
                                          ))}
                                      </select>
                                    )}
                                  </div>



                                  {/* <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                            </div> */}




                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '50%', height: '50px' }}>
                                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
                                    {/* <button type="submit" className="btn btn-save">Save</button> */}
                                    <button type="submit" className="btn btn-send">Send</button>
                                  </div>

                                </form>
                              </div>
                            </div>

                          )}




                          <CardActions>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 100]}
                              component="div"
                              count={filteredData1.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                            />
                          </CardActions>


                          <style>
                            {`
      .overlay-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      
    `}
                          </style>

                          {/* <Advertisecode /> */}
                        </Card>
                      </Tab>

                      <Tab
                        eventKey="subStep4"
                        id="nested-tab-example"
                        activeKey={nestedKey}
                        onSelect={handleNestedTabSelect}
                        title={
                          <span
                            style={{
                              fontWeight: "500",

                              // height: "36px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "2px", fontSize: '14px'
                            }}
                          >
                            Registration
                          </span>
                        }
                      >
                        {loadingSubmit && (
                          <div className="overlay-loader">
                            <div className="loader">
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  border: "5px solid #ccc",
                                  borderTop: "5px solid #e0782c",
                                  borderRadius: "50%",
                                  animation: "spin 1s linear infinite",
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <Card class="usernameydfu niop" style={{ marginTop: '-40px' }} >
                          <CardHeader
                            style={{
                              backgroundColor: 'whitesmoke',
                              marginTop: '5%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            title={
                              <>
                                <TextField
                                  placeholder="Program"
                                  value={filters.program}
                                  onChange={handleFilterChange("program")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Course"
                                  value={filters.course}
                                  onChange={handleFilterChange("course")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Stream"
                                  value={filters.stream}
                                  onChange={handleFilterChange("stream")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                              </>
                            }
                            action={
                              <>
                                <input
                                  type="file"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  style={{ display: 'none' }}
                                  id="fileUpload"
                                />
                                <label htmlFor="fileUpload">
                                  <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                    <CloudDownload style={{ marginRight: '8px' }} />
                                    Import
                                  </Button>
                                </label>
                                <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                  <CloudUpload style={{ marginRight: '8px' }} />
                                  Export
                                </Button>

                              </>



                            }
                          />
                          <TableContainer style={{
                            marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
                            overflowY: 'auto', overflowX: 'auto',
                          }}>
                            <Table>
                              <TableHead style={{ backgroundColor: 'lightblue' }}>
                                <TableRow>
                                  <TableCell padding="checkbox"
                                    style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    sx={{
                                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                      padding: '30px',
                                    }}
                                  >
                                    <Checkbox
                                      indeterminate={selected.length > 0 && selected.length < datasource2.length}
                                      checked={datasource2.length > 0 && selected.length === datasource2.length}
                                      onChange={handleSelectAllClick}
                                    />
                                  </TableCell>
                                  {['S.No', 'Name', 'Email Id', 'Contact Number', 'Program', 'Course', 'Stream', 'Date', 'Time', 'Source', 'Conseller', 'Reference', 'Registration Fees', 'Payment Status', 'Pending Amount', 'Stage', 'Remarks', 'Resume', 'Action'].map((heading, index) => (
                                    <TableCell
                                      align="center"
                                      key={index}
                                      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                      {heading}
                                    </TableCell>
                                  ))}
                                  {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {datasource2
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => (
                                    <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, row)}
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                    >
                                      <TableCell padding="checkbox"
                                        style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}

                                      >
                                        <Checkbox
                                          checked={selected.indexOf(row.userId) !== -1}
                                          onChange={() => handleClick(null, row)}
                                        />
                                      </TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.name}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.mobileNumber}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '170px' }}>{row.program?.replace(/_/g, ' ')}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '200px' }}>{row.course}</p></TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stream ? row.stream.replace(/_/g, ' ') : ''}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>
                                        {new Date(row.startDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric'
                                        })}
                                      </p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.registrationFees}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.paymentStatus}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.pendingAmount}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
                                        {row.resume ? (
                                          <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
                                            <CloudDownload style={{ color: 'blue' }} />
                                          </IconButton>
                                        ) : (
                                          <span>No Resume</span>
                                        )}
                                      </TableCell>

                                      <TableCell align="center" style={{ minWidth: '100px', display: 'flex' }}>
                                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                                        <button
                                          onClick={() => updateRecordStage2(row.enquiryId)} // Wrap in an anonymous function
                                          style={{
                                            width: '120px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '8px',
                                            padding: '10px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                          }}
                                          type="button" // Use type="button" to avoid unintended form submission
                                          className="btn btn-send"
                                        >
                                          <i className="fas fa-paper-plane" style={{ fontSize: '16px' }}></i>
                                          Send Link
                                        </button>


                                        <IconButton onClick={() => handleDelete2(row.enrId)} style={{ color: 'red' }}>
                                          <Delete />
                                        </IconButton>


                                      </TableCell>


                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>




                          {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                            <div className="modal-overlay">
                              <div className="modal-content1">


                                <div className="header-row">
                                  <div class="serial-number">S No-{selectedRowData.enrId}</div>
                                  <button style={{ marginTop: '-50px' }} class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                                </div>
                                <form style={{ marginLeft: '80px', marginTop: '-80px' }} className="form-grid" onSubmit={updateRecordStage2}>


                                  {/* <div className="form-group">
  {loading ? (
    <p>Loading stages...</p>
  ) : (
    <select
      className="form-control"
      required
      value={enroll}
      onChange={(e) => {
        const value = e.target.value;
        console.log("Selected Value:", value); // Log the selected value to the console
        setEnroll(value); 
        setSelectedRowData({ ...selectedRowData, stage: value }); // Update the state
      }}
    >
      <option value="" disabled>
        Select Stage
      </option>
      {stages
        .filter((stage) => stage !== "Enroll" && stage !== "Enquiry") // Exclude unwanted options
        .map((stage, index) => (
          <option key={index} value={stage}>
            {stage}
          </option>
        ))}
    </select>
  )}
</div> */}








                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '50%', height: '50px' }}>
                                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
                                    {/* <button type="submit" className="btn btn-save">Save</button> */}
                                    <button
                                      style={{ width: '450px' }}
                                      type="submit"
                                      className="btn btn-send"
                                    >
                                      Send Link
                                    </button>
                                  </div>

                                </form>
                              </div>
                            </div>

                          )}




                          <CardActions>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 100]}
                              component="div"
                              count={datasource2.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                            />
                          </CardActions>

                          {/* <Advertisecode /> */}
                        </Card>

                      </Tab>

                      <Tab eventKey="subStep5" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>Enrolled Candidate</span>} >



                        {loadingSubmit && (
                          <div className="overlay-loader">
                            <div className="loader">
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  border: "5px solid #ccc",
                                  borderTop: "5px solid #e0782c",
                                  borderRadius: "50%",
                                  animation: "spin 1s linear infinite",
                                }}
                              />
                            </div>
                          </div>
                        )}


                        <Card class="usernameydfu niop" style={{ marginTop: '-40px' }} >
                          <CardHeader
                            style={{
                              backgroundColor: 'whitesmoke',
                              marginTop: '5%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            title={
                              <>
                                <TextField
                                  placeholder="Program"
                                  value={filters.program}
                                  onChange={handleFilterChange("program")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Course"
                                  value={filters.course}
                                  onChange={handleFilterChange("course")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                                <TextField
                                  placeholder="Stream"
                                  value={filters.stream}
                                  onChange={handleFilterChange("stream")}
                                  style={{ width: '30%', height: '32px', marginRight: '10px' }} // Adjust height here
                                  variant="outlined" // Try using outlined or standard variant
                                  InputProps={{
                                    style: {
                                      padding: '4px 10px', // Adjust padding for height
                                      height: '38px', // Ensure this matches the height you want
                                    },
                                    inputProps: {
                                      style: { height: '38px' } // Set the height of the input element itself
                                    }
                                  }}
                                />
                              </>
                            }
                            action={
                              <>
                                <input
                                  type="file"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  style={{ display: 'none' }}
                                  id="fileUpload"
                                />
                                <label htmlFor="fileUpload">
                                  <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                    <CloudDownload style={{ marginRight: '8px' }} />
                                    Import
                                  </Button>
                                </label>
                                <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px', height: '40px' }}>
                                  <CloudUpload style={{ marginRight: '8px' }} />
                                  Export
                                </Button>

                              </>



                            }
                          />
                          <TableContainer style={{
                            marginTop: '0px', border: '1px solid rgba(0, 0, 0, 0.12)', // Set a fixed height for the table
                            overflowY: 'auto', overflowX: 'auto'
                          }}>
                            <Table>
                              <TableHead style={{ backgroundColor: 'lightblue' }}>
                                <TableRow>
                                  <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                    <Checkbox
                                      indeterminate={selected.length > 0 && selected.length < datasource.length}
                                      checked={datasource.length > 0 && selected.length === datasource.length}
                                      onChange={handleSelectAllClick}
                                      sx={{
                                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                                        padding: '30px',
                                      }}
                                    />
                                  </TableCell>
                                  {['S.No', 'Name', 'Email Id', 'Contact Number', 'Program', 'Course', 'Stream', 'Date', 'Time', 'Source', 'Conseller', 'Reference', 'Response', 'Stage', 'Remarks', 'Resume', 'Action'].map((heading, index) => (
                                    <TableCell
                                      align="center"
                                      key={index}
                                      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
                                    >
                                      {heading}
                                    </TableCell>
                                  ))}
                                  {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredData3
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => (
                                    <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, row)}
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row.id}
                                    >
                                      <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                          checked={selected.indexOf(row.userId) !== -1}
                                          onChange={() => handleClick(null, row)}
                                        />
                                      </TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index + 1}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.name}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.mobileNumber}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{  width: '170px' }}>{row.program?.replace(/_/g, ' ')}</p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: '200px' }}>{row.course}</p></TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stream ? row.stream.replace(/_/g, ' ') : ''}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>
                                        {new Date(row.startDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: '2-digit',
                                          year: 'numeric'
                                        })}
                                      </p></TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.startTime}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.source}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.councellor}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.reference}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.candidateResponse}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.stage}</TableCell>

                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remark}</TableCell>
                                      <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', minWidth: '120px' }}>
                                        {row.resume ? (
                                          <IconButton onClick={() => downloadBase64Pdf(row.resume, `${row.name}_resume.pdf`)}>
                                            <CloudDownload style={{ color: 'blue' }} />
                                          </IconButton>
                                        ) : (
                                          <span>No Resume</span>
                                        )}
                                      </TableCell>

                                      <TableCell align="center" style={{ minWidth: '120px' }}>
                                        {/* <IconButton onClick={() => handleViewResume(row.resume)}>
    <RemoveRedEye style={{ color: 'green' }} />
  </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                          <Edit />
                                        </IconButton>
                                      </TableCell>


                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>


                          {isPopupOpen && (
                            <div className="modal-overlay">
                              <div className="modal-content1">

                                <div className="header-row">
                                  <div className="serial-number">S No-[0]</div>
                                  <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                                </div>
                                <form className="form-grid" onSubmit={AddRecord}>

                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Program Course"
                                      required
                                      onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                    />
                                  </div>



                                  <div className="form-group">
                                    {loading ? (
                                      <p>Loading roles...</p>
                                    ) : (
                                      <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                      >
                                        <option value="" disabled>SPOC</option>
                                        {/* {roles.map((role, index) => (
                                            // <option key={index} value={role}>
                                            //     {role}
                                            // </option>
                                        ))} */}
                                      </select>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Status"
                                      required
                                      onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Remarks"
                                      required
                                      onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                    />
                                  </div>
                                  <div className="button-group">
                                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                    <button type="button" className="btn btn-save">Save</button>
                                    <button type="submit" className="btn btn-submit">Submit</button>
                                  </div>
                                </form>
                              </div>
                            </div>

                          )}



                          {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                            <div className="modal-overlay">
                              <div className="modal-content1">


                                <div className="header-row">
                                  <div class="serial-number">S No-{selectedRowData.enquiryId}</div>
                                  <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                                </div>
                                <form className="form-grid" onSubmit={updateRecord}>


                                  <div>
                                    {loading ? (
                                      <p>Loading interests...</p>
                                    ) : (
                                      <select
                                        className="form-control"
                                        required
                                        value={selectedRowData.candidateResponse}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          console.log("Selected Value:", value); // Print selected value to console
                                          setSelectedRowData({ ...selectedRowData, candidateResponse: value });
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select Interest
                                        </option>
                                        {interests.map((interest, index) => (
                                          <option key={index} value={interest}>
                                            {interest}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </div>


                                  <div className="form-group">
                                    <input type="text" className="form-control" value={selectedRowData.remark} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remark: e.target.value })} />
                                  </div>




                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '50%' }}>
                                    <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
                                    <button type="submit" className="btn btn-save">Save</button>
                                    <button type="button" className="btn btn-send" onClick={() => setIsPopupOpen1(false)}>Send</button>
                                  </div>

                                </form>
                              </div>
                            </div>

                          )}




                          <CardActions>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 100]}
                              component="div"
                              count={filteredData3.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                            />
                          </CardActions>

                          {/* <Advertisecode /> */}
                        </Card>
                      </Tab>

                      <Tab eventKey="subStep6" title={<span style={{
                        fontWeight: '500', display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', padding: "2px", fontSize: '14px',
                      }}>Registered</span>} >





                        <div className="row" >
                          <div className="container-fluid " id="table-container" style={{ paddingTop: '20px' }}>
                            <div className="container">
                              <div className="row" style={{ fontSize: "22px" }}>
                                <div></div>

                                <div className="a4-container stage-container-five-a4" style={{
                                  width: "794px", // A4 width in pixels
                                  height: "684px", // A4 height in pixels
                                  backgroundColor: "#f8f8f8", // Change to your desired background color
                                  padding: "20px", // Add some padding
                                  border: "1px solid #ccc", // Optional: add a border
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                                  // display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "0 auto" // Center the A4 container
                                }}>
                                  <div className="form-contentsL">
                                    <div style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      padding: "0px",
                                      flexWrap: "wrap",
                                      paddingBottom: "16px",
                                      marginTop: "10px"
                                    }}>
                                      <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                      <h3 style={{ color: "#f58938", margin: 0, wordBreak: "break-word" }}>
                                        INTERNATIONAL INSTITUTE OF AUTOMATE
                                      </h3>


                                    </div>
                                    <h2 style={{ textAlign: "center", color: "rgb(245 136 66)", marginBottom: "10px" }}>
                                      Certification Of Completion
                                    </h2>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "11px", marginTop: '30px' }}>
                                      This Certifies that
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", fontWeight: "bold", marginBottom: "15px", marginTop: '30px' }}>
                                      B. Naresh
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "11px", wordBreak: "break-word", marginTop: '30px' }}>
                                      Has successfully Completed the Course Of
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", fontWeight: "bold", marginBottom: "15px", marginTop: '30px' }}>
                                      Online Robotics
                                    </h3>
                                    <h3 style={{ textAlign: "center", color: "#5B4B99", marginBottom: "5px", marginTop: '30px' }}>
                                      Duration: 160 HRS
                                    </h3>

                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "50px",
                                        marginTop: "40px",
                                      }}
                                    >
                                      <h3 style={{ textAlign: "center", color: "#5B4B99" }}>
                                        Start Date: 10-02-2024
                                      </h3>
                                      <h3 style={{ textAlign: "center", color: "#5B4B99" }}>
                                        End Date: 15-08-2024
                                      </h3>
                                    </div>

                                    <br />

                                    <div className="date-signnature stage-five-signature"
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                        justifyContent: "center",
                                        alignItems: "center",

                                        marginTop: "0px",
                                        gap: "374px"
                                      }}
                                    >
                                      <h5 style={{ color: "#5B4B99", marginBottom: "5px", marginTop: '20px' }}>Signature:</h5>
                                      <h5 style={{ color: "#5B4B99", marginBottom: "5px", marginTop: '20px' }}>Date: 15-08-2024</h5>
                                    </div>

                                    <h3 style={{ textAlign: "center", marginTop: "30px" }}>www.iiautomate.com</h3>
                                  </div>
                                </div>



                                <br />






                                <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                                  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                    <button type="button" class="btn btn-primary" onClick={handleDownloadsL}>
                                      Download
                                    </button>
                                    <button type="button" class="btn btn-primary" onClick={handlePrintL}>
                                      Print
                                    </button>
                                  </div>
                                </div>


                              </div>
                            </div>

                          </div>
                          {/* Add other fields for step 2 */}
                        </div>
                      </Tab>





                    </Tabs>

                    {/* Second-Level Sub Tabs */}

                  </Tab>

                  {/* Sub Tab 2 */}

                </Tabs>
              </Tab>

              {/* Parent Tab 2 */}









            </Tabs>
          </div>
        </div>
      </section>




      {/* Modal for Terms and Conditions */}


















    </div>


  )
}

export default TableStages 