import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Sidenav from './SideNavBar';
import uploadIcon from '../images/edit-icon-removebg-preview.png';
import Compress from 'compress.js'; // Ensure correct import
import dummyImage from '../images/upload-icon.PNG';
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from 'react-router-dom';




const NavBar = () => {
  const [isSidenavHidden, setSidenavHidden] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState('');
  const [posterImage, setPosterImage] = useState(null);
  const [posterImage1, setPosterImage1] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); // Store role status
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [base64Images, setBase64Images] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    // Retrieve sidebar state from localStorage
    const savedState = localStorage.getItem('isSidebarOpen');
    return savedState ? JSON.parse(savedState) : false; // Default to false if no state is saved
  });
  const [companyName, setCompanyName] = useState('');
  const [isFirstImageUploaded, setIsFirstImageUploaded] = useState(false);
  const [isSecondImageUploaded, setIsSecondImageUploaded] = useState(false);
  const [clock, setClock] = useState('Clock In');
  const imageInputRef = useRef(null);
  const imageInputRef1 = useRef(null);
  const [time, setTime] = useState({ hours: '00', minutes: '00', seconds: '00' });
  const [countcl, setCountcl] = useState(0);
  const navigate = useNavigate();
  const [isPunchedIn, setIsPunchedIn] = useState(false);
  const [punchInTime, setPunchInTime] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isContainerOpen, setIsContainerOpen] = useState(false);
  let timer;

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const iconButtonStyle = {
    backgroundColor: "white", // Set background color to white
    borderRadius: "50%", // Make the button circular
    padding: "10px", // Add padding inside the button
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for depth
  };

  const handleDropdownToggle = () => {
    console.log(isContainerOpen);
    setIsContainerOpen((prevState) => !prevState);


  };



  const toggleSidenav = () => {
    setIsSidebarOpen((prev) => {
      const newState = !prev;
      console.log("Sidebar state:", newState); 
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState));
      return newState;
    });
  };

  useEffect(() => {
    console.log("Sidebar state after render:", isSidebarOpen); // Log the state after render
  }, [isSidebarOpen]);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/logo/latestlogo', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch logo: ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log('Fetched data:', data); // Log the fetched data for debugging
  
        // Assuming `data.image` contains the base64 image string
        if (data?.image) {
          const base64Image = data.image.startsWith('data:image/')
            ? data.image
            : `data:image/png;base64,${data.image}`;
          
        
          setPosterImage(base64Image); // Set the base64 image
        } else {
          alert('No image found in the response');
          setPosterImage(dummyImage); // Use fallback dummy image if no valid image is found
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
        // alert('Failed to fetch logo');
        setPosterImage(dummyImage); // Use dummy image on error
      }
    };
  
    fetchLogo();
  }, []);
  // Empty dependency array to run only once on component mount

  // Fetch logo from the backend or localStorage when the component mounts
  // const fetchLogo = async () => {
  //   const storedImage = localStorage.getItem('posterImage'); // Retrieve from localStorage
  //   if (storedImage) {
  //     // If image exists in localStorage, use it
  //     setPosterImage([storedImage]); // Wrap the single image in an array
  //   } else {
  //     try {
  //       const response = await fetch('http://10.10.20.9:7071/IIA-Website-1.0.0/logo/latestlogo/images', {
  //         method: 'GET',
  //         headers: {
  //           'Accept': 'application/json',
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch logo: ${response.statusText}`);
  //       }

  //       const data = await response.json();
  //       // Check if there are multiple images and store them in an array
  //       setPosterImage(data.logoUrls || [dummyImage]); // Assuming the response contains an array 'logoUrls'

  //     } catch (error) {
  //       console.error('Error fetching logo:', error);
  //       setPosterImage([dummyImage]); // Use dummy image on error
  //     }
  //   }
  // };



  useEffect(() => {

    const fetchData = async () => {
      const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
      if (!userId) {
        console.error("User ID not found in localStorage.");
        return;
      }

      try {
        const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/controllerScreen/user/${userId}`);
        console.log('API Response:', response.data);

        if (response.data && response.data[0]) {
          setApiData(response.data); // Set the first entry to state (assuming the array)
          // alert(response.data[2].navBar[0].name)
          // Check if the user is an Admin (based on role field in the API response)

        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    // Fetch the role from localStorage when the component mounts
    const storedRole = localStorage.getItem('role'); // Retrieve role from localStorage

    // If the stored role is "Administrator", check the toggle
    if (storedRole === 'Administrator') {
      setIsChecked(true); // Set checkbox to checked
    } else {
      setIsChecked(false); // Set checkbox to unchecked
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts



  const handleClockClick = () => {
    if (countcl === 0) {
      setCountcl(1);
      handlePunchIn();
    } else {
      setCountcl(0);
      handlePunchOut();
    }
  };

  const userName = localStorage.getItem('name') || 'User';



  useEffect(() => {
    let timer;

    // Start the timer if punched in
    if (isPunchedIn) {
      timer = setInterval(() => {
        setTotalSeconds(prev => prev + 1); // Increment seconds data not funding in the combined in the user data not fe
      }, 1000); // Update every second
    }

    // Cleanup function to clear the timer
    return () => clearInterval(timer);
  }, [isPunchedIn]);

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');  // Format hour (00-23)
    const minutes = String(date.getMinutes()).padStart(2, '0');  // Format minutes (00-59)
    const seconds = String(date.getSeconds()).padStart(2, '0');  // Format seconds (00-59)
    return `${hours}:${minutes}:${seconds}`;  // Return time in HH:mm:ss format
  };

  const getCurrentDate = () => {
    const date = new Date();  // Get the current date
    const year = date.getFullYear(); // Get the full year (e.g., 2024)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (01-12)
    const day = String(date.getDate()).padStart(2, '0'); // Get the day of the month (01-31)

    return `${year}-${month}-${day}`;  // Return in YYYY-MM-DD format
  };


  // Function to get the current date in YYYY-MM-DD format


  // Store punchInTime in the state when punched in
  const handlePunchIn = async () => {
    setIsPunchedIn(true);  // Set the status to indicate user is punched in
    const punchInTime = new Date();  // Get current date and time when punch in happens
    setPunchInTime(punchInTime);  // Store punch-in time

    setTotalSeconds(0);  // Reset total seconds

    // Get current date in YYYY-MM-DD format
    const currentDate = getCurrentDate();
    // Format the punch-in time
    const formattedPunchInTime = formatTime(punchInTime);


    const totalWorkedSeconds = totalSeconds;
    const totalHours = totalWorkedSeconds / 3600;  // Convert total seconds to hours
    console.log(`Punched Out. Total worked hours: ${totalHours.toFixed(2)} hrs`);

    // Get current date again for API request



    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }

    try {
      // Send the punch-out data along with punch-in time to the backend
      const response = await axios.post('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance', {
        user: { userId: userId },  // Send the user ID
        date: currentDate,   // Current date in YYYY-MM-DD format
        punchIn: formatTime(punchInTime),  // Previously stored punch-in time
        punchOut: "00:00:00",  // Current punch-out time
      });

      console.log('Punch-out successfully recorded:', response.data);
    } catch (error) {
      console.error('Error punching out:', error);
    }

    console.log('Punch-in time:', formattedPunchInTime);

    // No API call here, just storing punch-in data locally
  };

  // Handle punch-out action
  const handlePunchOut = async () => {
    setIsPunchedIn(false);  // Set status to show user is punched out

    const punchOutTime = new Date();  // Get the current time when user punches out
    const formattedPunchOutTime = formatTime(punchOutTime);  // Format the punch-out time




    const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
    if (!userId) {
      console.error("User ID not found in localStorage.");
      return;
    }


    const currentDate = getCurrentDate();

    try {
      // Send the punch-out data along with punch-in time to the backend
      const response = await axios.put(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/attendance/${userId}/${currentDate}`, {
        punchOut: formattedPunchOutTime,  // Current punch-out time
      });

      console.log('Punch-out successfully recorded:', response.data);
    } catch (error) {
      console.error('Error punching out:', error);
    }
  };

  // Convert totalSeconds to hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format with leading zero if necessary
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  // Example output: "02:09:05"
  const timeFormatted = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  console.log(timeFormatted);




  useEffect(() => {

    const fetchData = async () => {
      const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
      if (!userId) {
        console.error("User ID not found in localStorage.");
        return;
      }

      try {
        const response = await axios.get(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/controllerScreen/user/${userId}`);
        console.log('API Response:', response.data);

        if (response.data && response.data[0]) {

          // alert(response.data[2].navBar[0].name)
          // Check if the user is an Admin (based on role field in the API response)
          if (response.data[0].user.role === 'User' || response.data[0].user.role === 'Excutive' || response.data[0].user.role === 'Admin') {
            setIsAdmin(false);
          } else {
            setIsAdmin(true);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const stop = () => {
    setCountcl(0);
    setClock('Clock In');
    clearInterval(timer);
  };

  useEffect(() => {
    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, []);

  // Call fetchLogo when the component mounts
  // useEffect(() => {
  //   fetchLogo();
  // }, []);


  const handleLogout = () => {
    navigate("/logout");
    handleMenuClose(); // Close the menu
    console.log("User logged out"); // Add your logout logic here
  };

  // File input change handler
  const onFileChange = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0]; // Handle the first image file

      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64Image = reader.result;

        // Store the image in localStorage and update the state
        setPosterImage(base64Image);
        localStorage.setItem('posterImage', base64Image);

        try {
          let compressedBase64Image = base64Image;

          // Compress if the image size exceeds 1MB
          if (file.size > 1e6) {
            const compress = new Compress();
            const compressedFiles = await compress.compress([file], {
              size: 1,
              quality: 0.75,
              maxWidth: 800,
              maxHeight: 800,
              resize: true,
            });

            compressedBase64Image = compressedFiles[0].data; // Get compressed base64 image
          }

          // Store the compressed image in the state
          setBase64Images((prevImages) => {
            const newImages = [...prevImages];
            if (!newImages.includes(compressedBase64Image)) {
              newImages[0] = compressedBase64Image; // Ensure the first image is added
            }
            return newImages;
          });

          setIsFirstImageUploaded(true);

          // If both images are uploaded, trigger the API call
       
            await handleImageUpload(); // Call API with both images
          
        } catch (err) {
          console.error('Error uploading first image:', err);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.log('No file selected for the first image');
    }
  };

  const onFileChange1 = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0]; // Handle the second image file

      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64Image = reader.result;

        // Store the image in localStorage and update the state
        setPosterImage1(base64Image);
        localStorage.setItem('posterImage1', base64Image);

        try {
          let compressedBase64Image = base64Image;

          // Compress if the image size exceeds 1MB
          if (file.size > 1e6) {
            const compress = new Compress();
            const compressedFiles = await compress.compress([file], {
              size: 1,
              quality: 0.75,
              maxWidth: 800,
              maxHeight: 800,
              resize: true,
            });

            compressedBase64Image = compressedFiles[0].data; // Get compressed base64 image
          }

          // Store the compressed image in the state
          setBase64Images((prevImages) => {
            const newImages = [...prevImages];
            if (!newImages.includes(compressedBase64Image)) {
              newImages[1] = compressedBase64Image; // Ensure the second image is added
            }
            return newImages;
          });

          setIsSecondImageUploaded(true);

          // If both images are uploaded, trigger the API call
          if (isFirstImageUploaded) {
            await handleImageUpload(); // Call API with both images
          }
        } catch (err) {
          console.error('Error uploading second image:', err);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.log('No file selected for the second image');
    }
  };

  // Handle image upload (when both images are uploaded)
  const handleImageUpload = async () => {
    alert('Uploading image...');
    setLoading(true);
  
    try {
      // Retrieve a single image from localStorage
      const posterImage = localStorage.getItem('posterImage');
  
      // Check if the image exists
      if (!posterImage) {
        setError('No image found to upload');
        setLoading(false);
        return;
      }
  
      // Clean the base64 string by removing the prefix
      const cleanedBase64Image = posterImage.replace(/^data:image\/[a-z]+;base64,/, '');
  
      // Check if the image exceeds the size limit (1MB)
      if (cleanedBase64Image.length > 1000000) {
        setError('The image exceeds the size limit');
        setLoading(false);
        return;
      }
  
      // Send the cleaned image to the API
      const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/logo/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          image: cleanedBase64Image, // Send only one image
        }),
      });
  
      if (!response.ok) {
        throw new Error('Image upload failed');
      }
  
      console.log('Image uploaded successfully');
      // Clear localStorage after upload if needed
      localStorage.removeItem('posterImage');
    } catch (error) {
      setError('Failed to upload the image');
      console.error('Error uploading image:', error.message);
    } finally {
      setLoading(false);
    }
  };
  

  // const toggleSidenav = () => {
  //   setSidenavHidden(!isSidenavHidden);
  // };

  // Fetch company data
  const fetchCompanyData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/companyname/getlatest');
      console.log('Response Data:', response.data);

      if (response.data && response.data.companyName) {
        setCompanyName(response.data.companyName);
      } else {
        throw new Error('Company name not found in response');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData(); // Fetch company data when component mounts
  }, []);

  // Handle image submission
  const handleSubmits = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null); // Reset any previous errors

    const formData = {
      logoId: 2,

      companyName: name,
    };

    try {
      const response = await axios.post(
        'https://iiaserver.iiautomate.com/IIA-Website-1.0.0/companyname/add',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Form submitted successfully:', response.data);
      alert('Data added successfully!');
      setIsModalOpen(false);
      setName('');
      setImage('');
      setPosterImage(null);
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      setError('Error submitting form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {

    setAnchorEl(null);
  };



  return (
    <>
      {/* Internal CSS */}
      <style>
        {`
          .nav-header {
            display: flex;
            align-items: center;
             justify-content: space-between; 
            padding: 10px;
            background-color: #333; /* Set the background color */
          }
          
          .nav-header img {
            margin-right: 20px; /* Space between logo and upload icon */
          }

          .upload-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%; /* Round shape */
            object-fit: cover; /* Maintain aspect ratio */
          }

          .error-message {
            color: red;
            margin-top: 10px; /* Space above the error message */
          }

          .loading-message {
            color: #fff; /* Change color to white for visibility */
          }
        `}
      </style>

      <style jsx>{`
  .clock-container {
    position: relative;
  }

  .dropdown-icon {
    display: none;
  }

  /* Media query for mobile view */
  @media (max-width: 768px) {
    .dropdown-icon {
      display: block; /* Show the dropdown icon on mobile */
    }

    .clock-info {
      flex-direction: column; /* Stack clock in and out info vertically */
      align-items: flex-start; /* Align to the start of the container */
    }
  }
    .toggle-container {
  transition: max-height 0.3s ease-in-out;
}

.toggle-container ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.toggle-container ol li {
  font-size: 16px;
  padding: 5px 0;
  cursor: pointer;
}

.toggle-container ol li:hover {
  // background-color: #f0f0f0;
  border-radius: 4px;
}

`}</style>

      {/* Nav Header */}
      <div>

        <div className="container-fluid p-0">
          <div className="nav-header-wrapper" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div className="nav-header" style={{ position: 'fixed', marginLeft: '20px' }}>
              <div className={`nav-control1 ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidenav}>
                {/* Apply dynamic class based on isSidebarOpen */}
                <div className={`hamburgers akhi   ${isSidebarOpen ? 'active' : ''}`}>

                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>

                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '4px' }}>
                


                <div class="mob image-another" style={{ position: 'relative', display: 'inline-block', marginLeft: '0px' }}>
                  <img
                    src={posterImage || dummyImage} // Use posterImage or fallback to dummyImage
                    alt="Selected"
                    style={{ width: '60px', height: '60px', borderRadius: '50%' }}
                  />
                  <button
                    onClick={() => imageInputRef1.current.click()}
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    {isAdmin && (
                      <>
                        <img
                          src={uploadIcon}
                          style={{ width: '20px', height: '20px', marginTop: '37px', marginLeft: '41px' }}
                          alt="Upload Icon"
                        />
                      </>
                    )}
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    ref={imageInputRef1}
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                  />
                </div>


                {/* Apply dynamic class based on isSidebarOpen */}



                <div className="dropdown-icon-container drop-togle" style={{ position: 'relative'}}>
                  <i
                    className="fas fa-chevron-down"
                    onClick={handleDropdownToggle}
                    style={{
                      marginLeft:'20px',
                      fontSize: '18px',
                      cursor: 'pointer',
                      color: '#000',

                    }}
                  ></i>
                </div>
                <div class="commob plopp ">
                  {loading && <p>Loading...</p>}
                  {error && <p className="error-message">{error}</p>}
                  {!loading && !error && (
                    <p
                      className="btn company-name mnmae"
                      onClick={() => setIsModalOpen(true)} // Set modal to open on click
                      style={{
                        marginTop: '20px',
                        // width: '560px',
                        cursor: 'pointer', // Change to pointer to indicate clickable
                        zIndex: 1,
                        display: 'inline-block',
                        padding: '10px',
                        fontSize: '18px',
                        color: '#fff',
                        textAlign: 'center',
                        borderRadius: '5px',
                      }}

                    >
                      {companyName || 'No company name available'}
                    </p>

                  )}
                </div>
              </div>

             
              <p className='nameuser' style={{ color: 'white', fontSize: '18px', margin: 0, minWidth: '115%' }}>
  {userName}
</p>

             

          
         


             
              <div
                className={`clock-container clock-hide clock ${countcl === 1 ? 'clock-out' : ''}`}
                onClick={handleClockClick}
                style={{
                  backgroundColor: countcl === 1 ? '#29e926' : '#fff', // Light green when Clock Out, light gray when Clock In
                  padding: '5px',
                  borderRadius: '8px',
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex', // Flexbox to align children horizontally
                  justifyContent: 'center', // Center the content horizontally
                  gap: '10px', // Space between the elements
                  whiteSpace: 'nowrap', // Prevent wrapping
                }}
               >
                <div className="clock-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {/* "Clock In" or "Clock Out" text */}
                  <p
                    className="clock-text"
                    style={{
                      marginRight: '0px', // Space between the text and the time
                      width: 'auto',
                      fontWeight: 'bold', // Bold font for better visibility
                      color: countcl === 1 ? '#fff' : '#000', // Change color for "Clock In" vs "Clock Out"
                    }}
                  >
                    {countcl === 0 ? 'Clock In' : 'Clock Out'} {/* Toggle text based on countcl */}
                  </p>

                  {/* Time display */}
                  <p
                    className={`time-display ${countcl === 1 ? "green-text" : ""}`}
                    style={{
                      marginLeft: '0',
                      marginTop: '0',
                      fontSize: '13px', // Make time bigger for better readability
                      fontWeight: 'bold', // Bold the time for emphasis
                    }}
                  >
                    {formattedHours}:{formattedMinutes}:{formattedSeconds}
                  </p>

                </div>
              </div>




              <div className="toggle-container toggl" style={{ marginLeft: '20px' }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    className="toggleSwitch"
                    checked={isChecked}
                    onChange={handleToggle}
                    id="service"
                  />
                  <span className="slider"></span>
                </label>
                <p className="status" id="status-1">
                  {isChecked ? "Admin" : "User"}
                </p>
              </div>

              <div className="notification-container" title="Notifications">
                <div className="notification-icon">
                  &#128276; {/* Notifications icon (bell emoji) */}
                </div>
                <div className="notification-badge">15</div>
                <div className="tooltip">Notifications</div>
              </div>






              <div class="kijj" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '13px' }}>
                <IconButton onClick={handleMenuOpen}>
                  <AccountCircleIcon style={{ color: '#FFF', fontSize: '36px' }} />
                </IconButton>

                {/* Profile Menu */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>




            </div>



            {/* <div className="header" style={{ position: 'fixed', top: '0', height: '100px' }}>
              <div>
                <div class="mkpop" style={{ marginTop: '10px', marginLeft: '64%', marginTop: '65px' }}>
                  <p style={{ color: 'white', fontSize: '18px', margin: 0, width: '480px' }}>
                    {userName}
                  </p>
                </div>
              </div>
            </div> */}



            {/* Header */}
            <div className="header" style={{ position: 'fixed', top: '0',}}>
            <div >

              <div style={{ marginTop: '10px', marginTop: '65px' }}>
                {/* <p style={{ color: 'white', fontSize: '18px', margin: 0, width: '480px' }}>
                  {userName}
                </p> */}
              </div>


            </div>
          </div> 

          </div>
        </div>
      </div>

      {/* Modal for adding an image */}
      <div
        className={`toggle-container data-toggle ${isContainerOpen ? 'open' : ''}`}
        style={{
          position: 'absolute',
          top: '80px', // Position below the icon
          left: '6px', // Align with the icon
          backgroundColor: 'rgb(19 103 200)',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 12, // Ensure it appears above other elements
          overflow: 'hidden', // Prevent overflow during animation
          maxHeight: isContainerOpen ? '300px' : '0', // Set a reasonable max height
          transition: 'max-height 0.3s ease', // Smooth animation
        }}
      >
        {/* Dropdown Header */}


        {/* Dropdown Content - Ordered List */}
        <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', margin: 0 }}>
          <li style={{ marginBottom: '8px' }}> <p style={{ color: '#fff', fontSize: '18px', margin: 0, marginBottom: '10px' }}>
            {userName}
          </p></li>
          <li style={{ marginBottom: '8px' }}>
            <div
              className={`clock-container clock ${countcl === 1 ? 'clock-out' : ''}`}
              onClick={handleClockClick}
              style={{
                backgroundColor: countcl === 1 ? '#29e926' : '#fff', // Light green when Clock Out, light gray when Clock In
                padding: '5px',
                borderRadius: '8px',
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex', // Flexbox to align children horizontally
                justifyContent: 'center', // Center the content horizontally
                gap: '10px', // Space between the elements
                whiteSpace: 'nowrap', // Prevent wrapping
              }}
            >
              <div className="clock-info" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {/* "Clock In" or "Clock Out" text */}
                <p
                  className="clock-text"
                  style={{
                    marginRight: '0px', // Space between the text and the time
                    width: 'auto',
                    fontWeight: 'bold', // Bold font for better visibility
                    color: countcl === 1 ? '#fff' : '#000', // Change color for "Clock In" vs "Clock Out"
                  }}
                >
                  {countcl === 0 ? 'Clock In' : 'Clock Out'} {/* Toggle text based on countcl */}
                </p>

                {/* Time display */}
                <p
                  className={`time-display ${countcl === 1 ? "green-text" : ""}`}
                  style={{
                    marginLeft: '0',
                    marginTop: '0',
                    fontSize: '13px', // Make time bigger for better readability
                    fontWeight: 'bold', // Bold the time for emphasis
                  }}
                >
                  {formattedHours}:{formattedMinutes}:{formattedSeconds}
                </p>

              </div>
            </div>
          </li>
          {/* <li style={{ marginBottom: '8px' }}> <label className="switch" style={{ marginTop: '10px' }}>
            <input
              type="checkbox"
              className="toggleSwitch"
              checked={isChecked}
              onChange={handleToggle}
            />
            <span className="slider"></span>
           </label>
            <p className="status" style={{ marginTop: '5px' }}>
              {isChecked ? 'Admin' : 'User'}
            </p></li>
          <li style={{ marginBottom: '8px' }}><div className="notification-container " title="Notifications">
            <div className="notification-icon">
              &#128276; 
            </div>
            <div className="notification-badge">15</div>
            <div className="tooltip">Notifications</div>
          </div></li> */}
        </ol>

        {/* Clock In/Out Section */}


        {/* Toggle Switch */}

      </div>





      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '30px' }}>
        <IconButton onClick={handleMenuOpen}>
          <AccountCircleIcon style={{ color: '#FFF', fontSize: '36px' }} />
        </IconButton>

        {/* Profile Menu */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>

      <div>
        {/* Show loading message while fetching data */}
      </div>

      {/* Header */}



      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <form onSubmit={handleSubmits}>

              <div className="form-group">
                <label className="form-label"> Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)} // Update the name state data not fetching in all 
                  required
                />
              </div>
              <div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default NavBar;
