
import { Category } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AdvertisementShow from './AdverstimentDes';
import Advertisecode from "./Advertisecode";
import * as XLSX from 'xlsx';
import apiUrls from './apiUrls';
import { Delete } from '@mui/icons-material';
import { CloudUpload, CloudDownload, CheckCircleOutline, RemoveRedEye, Edit } from '@mui/icons-material';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    IconButton,
} from '@mui/material';


const Layout1 = () => {

    // const datasource = [
    //     {
    //         SNo: '1',
    //         Category: "",
    //         Organization: "",
    //         Name: 'Kumar Ravi',
    //         contactNumber: '9876543210',
    //         emailId: 'rcreddy2222@gmail.com',
    //         Role: '',
    //         Action: '',
    //         Remarks: '',

    //     },

    // ];
    const [datasource, setDatasource] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filter, setFilter] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen1, setIsPopupOpen1] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedSubNavId, setSelectedSubNavId] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { navId: routeNavId } = useParams(); // Get navId from route parameters
    const [navId, setNavId] = useState(() => (routeNavId ? Number(routeNavId) : undefined)); // Initialize navId with routeNavId
    const [isSelected, setIsSelected] = useState(false);
    const [directoryNavId, setDirectoryNavId] = useState(null);
    const navigate = useNavigate();
    const [category, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        organisation: "",
        name: "",
        email: "",
        phoneNumber: "",
        role: "",
        category: "",
        remarks: "",
        password: ""


    });


    const handleNext = () => {
        navigate('/onboarding'); // Adjust the path as necessary for your application
    };


    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //       const reader = new FileReader();
    //       reader.onload = (e) => {
    //         const binaryStr = e.target.result;
    //         const workbook = XLSX.read(binaryStr, { type: 'binary' });
    //         const sheetName = workbook.SheetNames[0];
    //         const sheet = workbook.Sheets[sheetName];
    //         const data = XLSX.utils.sheet_to_json(sheet); // Converts sheet to JSON
    //         setTableData(data); // Update the table data
    //       };
    //       reader.readAsBinaryString(file);
    //     }
    //   };


    const handleDelete = async (userId) => {
        const confirmed = window.confirm("Are you sure you want to delete this entry?");
        if (!confirmed) return;
    
        try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/deletebyid/${userId}`, {
                method: 'DELETE',
            });
    
            if (response.ok) {
                alert("Entry deleted successfully!");
                setDatasource(datasource.filter((item) => item.userId !== userId));
            } else {
                alert("Failed to delete the entry.");
            }
        } catch (error) {
            console.error("Error deleting entry:", error);
            alert("An error occurred while deleting the entry.");
        }
    };
    


    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/enum/roles');
                const data = await response.json();
                setRoles(data); // Assuming API returns an array of role strings
                setLoading(false);
            } catch (error) {
                console.error("Error fetching roles:", error);
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);


    const handleRoleChange = (e) => {
        setUserDetails({ ...userDetails, role: e.target.value });
    };


    const handleRoleChange1 = (e) => {
        setSelectedRowData({ ...selectedRowData, role: e.target.value });
    };



    const handleCategoryChange = (e) => {
        setUserDetails({ ...userDetails, category: e.target.value });
    };


    const handleCategoryChange1 = (e) => {
        setSelectedRowData({ ...selectedRowData, categories: e.target.value });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrls.PUT_enum_category1); // Replace with your API URL  
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDatasource(data); // Set fetched data to the datasource state Layou2.js
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount  


    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(
                    apiUrls.PUT_enum_category);
                const data = await response.json();
                setCategories(data); // Assuming API returns an array of strings
                setLoading(false);
            } catch (error) {
                console.error("Error fetching categories:", error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);


    const AddRecord = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page

        try {
            const response = await fetch('https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    organisation: userDetails.organisation,
                    name: userDetails.name,
                    mobileNumber: userDetails.phoneNumber,
                    emailId: userDetails.emailId,
                    password: userDetails.password,
                    categories: userDetails.category,
                    role: userDetails.role,
                    remarks: userDetails.remarks,
                }),
            });

            // Check if the response is successful
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to register. Please try again.');
            }

            const contentType = response.headers.get('Content-Type');

            if (contentType && contentType.includes('application/json')) {
                // If the response is JSON, handle it accordingly
                const data = await response.json();
                console.log('Registration successful:', data);

                // You can navigate to a different page or close the form here
                navigate('/layout1');
                window.location.reload();
                alert('Data Successfully Saved');
                setIsPopupOpen(false);  // Close the popup
            } else if (contentType && contentType.includes('text/plain')) {
                // If the response is plain text (success message)
                const textResponse = await response.text();
                console.log('Response:', textResponse); // Log the response text (e.g., "User Registered Successfully")

                alert(textResponse); // Show the success message in an alert
                navigate('/layout1');
                window.location.reload();
                setIsPopupOpen(false);  // Close the popup
            } else {
                throw new Error('Unexpected response format.');
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle error, show a message or perform other actions
            alert(error.message || 'Something went wrong, please try again.');
        }
    };



    const updateRecord = async (e) => {
        e.preventDefault(); // Prevent default form submission

        try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/user/edituser/${selectedRowData.userId}`, {
                method: 'PUT', // Change to PUT for updating
                headers: {
                    'Content-Type': 'application/json',
                },



                body: JSON.stringify(selectedRowData),
            });


            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Failed to update. Please try again.');
            }

            const updatedData = await response.json();
            console.log('Update successful:', updatedData);
            window.location.reload();
            alert('Record updated successfully');
            setIsPopupOpen1(false);  // Close the popup
            // Optionally navigate or refresh data here

        } catch (error) {
            console.error('Error:', error);
            alert(error.message || 'Something went wrong, please try again.'); // Display error message
        }
    };




    const handlePopup = () => {
        setIsPopupOpen(true);
    };

    const handlePopup1 = (rowData) => {
        // alert(rowData)
        setSelectedRowData(rowData);
        setIsPopupOpen1(true);
    };


    const exportToExcel = () => {
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(datasource);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

        // Generate a binary Excel file
        XLSX.writeFile(workbook, 'exported_data.xlsx');
    };



    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = datasource.map((row) => row.userId);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };


    return (
        <Card class="usernameydf" style={{marginTop:'70px',marginRight:'10px' }}>
            <CardHeader
                style={{
                    backgroundColor: 'whitesmoke',
                    marginTop: '2%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                title={
                    <TextField
                        placeholder="Filter"
                        value={filter}
                        onChange={handleFilterChange}
                        className="filterAlignment"
                        style={{ width: '30%', height: '32px', margin: 0 }} // Adjust height here
                        variant="outlined" // Try using outlined or standard variant
                        InputProps={{
                            style: {
                                padding: '4px 10px', // Adjust padding for height
                                height: '38px', // Ensure this matches the height you want
                            },
                            inputProps: {
                                style: { height: '38px' } // Set the height of the input element itself
                            }
                        }}
                    />
                }
                action={
                    <>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <label htmlFor="fileUpload">
                            <Button variant="extendedFab" component="span" style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                                <CloudDownload style={{ marginRight: '8px' }} />
                                Import
                            </Button>
                        </label>
                        <Button variant="extendedFab" onClick={exportToExcel} style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}>
                            <CloudUpload style={{ marginRight: '8px' }} />
                            Export
                        </Button>
                        <Button variant="extendedFab" onClick={handlePopup} style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }}>
                            + Add
                        </Button>
                    </>



                }
            />
            <TableContainer style={{ marginTop: '20px', border: '1px solid rgba(0, 0, 0, 0.12)',width:'100%' }}>
                <Table>
                    <TableHead style={{ backgroundColor: 'lightblue' }}>
                        <TableRow>
                            <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <Checkbox
                                    indeterminate={selected.length > 0 && selected.length < datasource.length}
                                    checked={datasource.length > 0 && selected.length === datasource.length}
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                            {['S.No', 'Category', 'Organization', 'Name', 'Contact Number', 'Email Id', 'Role', 'Remarks', 'Action'].map((heading, index) => (
                                <TableCell
                                    align="center"
                                    key={index}
                                    style={{ borderRight: index !== 8 ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}
                                >
                                    {heading}
                                </TableCell>
                            ))}
                            {/* <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datasource
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell padding="checkbox" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                        <Checkbox
                                            checked={selected.indexOf(row.userId) !== -1}
                                            onChange={() => handleClick(null, row)}
                                        />
                                    </TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.categories}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.organisation}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}><p style={{ width: 'auto' }}>{row.name}</p></TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.mobileNumber}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.emailId}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.role}</TableCell>
                                    <TableCell align="center" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}>{row.remarks}</TableCell>
                                    <TableCell align="center" style={{ minWidth: '150px' }}>
                                        {/* <IconButton onClick={() => console.log('View', row.userId)}>
                                            <RemoveRedEye style={{ color: 'green' }} />
                                        </IconButton> */}
                                        <IconButton onClick={() => handlePopup1(row)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(row.userId)}>
                        <Delete style={{ color: 'red' }} />
                    </IconButton>
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {isPopupOpen && (
                <div className="modal-overlay">
                    <div className="modal-content1">

                        <div className="header-row">
                            <div className="serial-number">S No-[0]</div>
                            <button className="close-button1" onClick={() => setIsPopupOpen(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={AddRecord}>
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading categories...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.category}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="" disabled>Select a category</option>
                                        {category.map((category, index) => (
                                            <option key={index} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Organization"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, organisation: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="tel" // Changed to 'tel' for better contact number validation
                                    className="form-control"
                                    placeholder="Contact Number"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email Id"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={userDetails.role}
                                        onChange={handleRoleChange}
                                    >
                                        <option value="" disabled>Select a role</option>
                                        {roles.map((role, index) => (
                                            <option key={index} value={role}>
                                                {role}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Remarks"
                                    required
                                    onChange={(e) => setUserDetails({ ...userDetails, remarks: e.target.value })} // Handle input change
                                />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                <button type="button" className="btn btn-save">Save</button>
                                <button type="submit" className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}



            {isPopupOpen1 && selectedRowData && ( // Ensure itemData is defined before rendering

                <div className="modal-overlay">
                    <div className="modal-content1">


                        <div className="header-row">
                            <div class="serial-number">S No-{selectedRowData.userId}</div>
                            <button class="close-button1" onClick={() => setIsPopupOpen1(false)}>&times;</button>
                        </div>
                        <form className="form-grid" onSubmit={updateRecord}>
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading categories...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={selectedRowData.categories}
                                        onChange={handleCategoryChange1}
                                    >
                                        <option value="" disabled>Select a category</option>
                                        {category.map((category, index) => (
                                            <option key={index} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.organisation} placeholder="Organization" required onChange={(e) => setSelectedRowData({ ...selectedRowData, organisation: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.name} placeholder="Name" required onChange={(e) => setSelectedRowData({ ...selectedRowData, name: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <input type="tel" className="form-control" value={selectedRowData.mobileNumber} placeholder="Contact Number" required onChange={(e) => setSelectedRowData({ ...selectedRowData, mobileNumber: e.target.value })} />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" value={selectedRowData.emailId} placeholder="Email Id" required onChange={(e) => setSelectedRowData({ ...selectedRowData, email: e.target.value })} />
                            </div>
                            <div className="form-group">
                                {loading ? (
                                    <p>Loading roles...</p>
                                ) : (
                                    <select
                                        className="form-control"
                                        required
                                        value={selectedRowData.role}
                                        onChange={handleRoleChange1}
                                    >
                                        <option value="" disabled>Select a role</option>
                                        {roles.map((role, index) => (
                                            <option key={index} value={role}>
                                                {role}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={selectedRowData.remarks} placeholder="Remarks" required onChange={(e) => setSelectedRowData({ ...selectedRowData, remarks: e.target.value })} />
                            </div>
                            <div className="button-group">
                                <button type="button" className="btn btn-cancel" onClick={() => setIsPopupOpen1(false)}>Cancel</button>
                                <button type="submit" className="btn btn-save">Save</button>
                            </div>
                        </form>
                    </div>
                </div>

            )}

{/* <AdvertisementShow/> */}
            {/* <div className="ad-container">
                <div className="ad-content">
                    <div className="ad-item">Advertisement 1</div> */}
                    {/* <div className="ad-item">Advertisement 2</div>
                    <div className="ad-item">Advertisement 3</div> */}
                    {/* Add more advertisements as needed */}
                {/* </div>
            </div> */}
           
            <style>
                {`
   
        .ad-container {
  position: fixed;
  right: -50px;
  bottom: 0;
  top: 0;
  
  width: 384px; /* Adjust width as needed */
  overflow: hidden;
  background-color:'white'; /* Background color for container */
}

.ad-content {
  display: flex;
  flex-direction: column;
  animation: scrollUp 15s linear infinite;
  position: absolute;
  bottom: 0; /* Start from the bottom */
  width: 80%;
}

.ad-item {
  padding: 20px;
  color: white;
  background-color: #1367c8;
  margin: 5px 0;
  text-align: center;
}

/* Keyframes for scrolling up */
@keyframes scrollUp {
  0% {
    transform: translateY(100%); /* Start from bottom */
  }
  100% {
    transform: translateY(-150%); /* Move to top */
  }
}
    `}

            </style>

            <CardActions>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={datasource.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </CardActions>
            {/*  */}
            {/* <Advertisecode /> */}
        </Card>





    )


}
export default Layout1;