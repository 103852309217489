import React, { useState,useEffect,useRef } from 'react';
import logo from "../images/Logo-headIIA.png";
import { useNavigate } from 'react-router-dom';
import { Margin } from '@mui/icons-material';
import backgroundImage from "../images/image1.png"; 
import apiUrls from './apiUrls'; 

const LoginEmp = () => {
  const [loginObj, setLoginObj] = useState({identifier: '', password: '' });
  const [hidePassword, setHidePassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false); 
  const [error, setError] = useState('');
  const navigate = useNavigate(); 
  const [loading, setLoading] = useState(true); 
  const [selectedCategory, setSelectedCategory] = useState(""); // State for selected category
  const [categories, setCategories] = useState([]); 
  const formRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginObj({ ...loginObj, [name]: value });
  };

 

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };


  const handleSignupRedirect = () => {
    navigate("/signup"); // Navigate to the signup page
  };

  const handleForgotRedirect = () => {
    navigate("/forgot"); // Navigate to the signup page
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
             apiUrls.PUT_USER_CATEGORY);
        const data = await response.json();
        setCategories(data); // Since API returns an array of strings, directly set it
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories(); // Call the function to fetch data when the component mounts
  }, []);
  



  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    // Ensure both emailId and password are filled
    if (!loginObj.identifier || !loginObj.password) {
      setError("Email and password are required.");
      return;
    }

    setIsLoading(true); // Show loader

    try {
      // API call to login
      const response = await fetch(
        apiUrls.PUT_USER_LOGIN,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginObj),
        }
      );

      let result;

      // Check if response is JSON
      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        result = await response.json(); // Parse as JSON
      } else {
        result = await response.text(); // Parse as text if it's not JSON
      }

      console.log("Response Status:", response.status);
      console.log("Raw Response:", result); // Log the full response to check its structure

      // Check for successful response
      if (response.ok) {
        if (result === "Invalid email/mobile or password") {
          alert(result); // Show the invalid login message
          return;
        }

        const userRole = result.role || "User";
        const userId = result.userId || "";
        const name = result.name || "";

        localStorage.setItem("role", userRole);
        localStorage.setItem("userId", userId);
        localStorage.setItem("name", name);
        // alert("Login successful!");

        navigate("/cntrollers-table-data");
      } else {
        console.error("Login failed with response:", result);
        alert(result.message || result || "Login failed, please try again.");
        setError(result.message || result || "Login failed, please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Something went wrong, please try again later.");
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  
  
  


  return (
    <div className="login-body"  style={{
      backgroundImage: `url(${backgroundImage})`, // Use the imported background image
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    }}>
  <div className="login-container">
    {/* Overlay Loader */}
    {isLoading && (
      <div className="overlay-loader">
        <div className="loader">
          <div
            style={{
              width: "50px",
              height: "50px",
              border: "5px solid #ccc",
              borderTop: "5px solid #e0782c",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          />
        </div>
      </div>
    )}

    {/* Login Form Section */}
    <div className="form-section">
      <div className="form-content">
        <img src={logo} alt="ACTUS Logo" className="logo" />
        <h5>Sign Into Your Account</h5>


      

        <div className="form-group">
          {loading ? (
            <p>Loading categories...</p>
          ) : (
            <select
              name="options"
              required
              className="custom-dropdown1"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="" disabled>
                Category
              </option>
              {/* Dynamically render options from API data */}
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          )}
        </div>

        <form onSubmit={handleLoginSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="identifier"
              value={loginObj.identifier}
              onChange={handleChange}
              required
              placeholder="Enter Email ID/Mobile No *"
              className="form-control"
            />
          </div>

          <div className="form-group1 password-field" style={{ marginTop: "20px" }}>
            <div className="input-container1">
              <input
                type={hidePassword ? "password" : "text"}
                name="password"
                value={loginObj.password}
                onChange={handleChange}
                required
                placeholder="Enter password *"
                className="form-control"
              />
              <button
                type="button"
                className="toggle-password"
                onClick={() => setHidePassword((prev) => !prev)}
              >
                {hidePassword ? (
                  <i className="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            </div>
          </div>

          <div className="form-footer">
            <span onClick={handleForgotRedirect} className="forgot-password">
              Forgot Password?
            </span>
            <button type="submit" className="btn2 btn2-primary">
              Login
            </button>
          </div>
        </form>

        <p className="signup-text">
          Don't have an account?{" "}
          <span onClick={handleSignupRedirect} className="signup-link">
            Signup
          </span>
        </p>

        {error && <span className="error-text">{error}</span>}
      </div>
    </div>
  </div>

  {/* Add overlay loader styles */}
  <style>
    {`
      .overlay-loader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      
    `}
  </style>
</div>

  );
};

export default LoginEmp;
