import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { Form, Modal, Button } from "react-bootstrap";
import logo from "../images/logo-white-2.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
function EnrollTopNavBar() {
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [optionss, setOptionss] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [courseduration, setCoursess] = useState([]); 
    const [offlinerobotics, setOfflinerobotics] = useState(null);
    const [availableSubFields, setAvailableSubFields] = useState([]);
    const [showWebDevDropdown, setShowWebDevDropdown] = useState(false);
  const [showMechanicalDropdown, setShowMechanicalDropdown] = useState(false);
  const [showDataScienceDropdown, setShowDataScienceDropdown] = useState(false)
    const [subNavData, setSubNavData] = useState([]); // State to hold sub-navigation data
    const [loading, setLoading] = useState(false); // State to manage loading status
    const [error, setError] = useState(null); // State for error handling
    const { navId: routeNavId } = useParams(); // Get navId from route parameters
    const [navId, setNavId] = useState(() => {
        // Initialize navId from route params or localStorage
        const storedNavId = localStorage.getItem('directoryNavId');
        return routeNavId ? Number(routeNavId) : storedNavId ? Number(storedNavId) : undefined;
    });
    const location = useLocation(); // Get current location
    const navigate = useNavigate(); // Initialize useNavigate
    const [userId, setUserId] = useState(''); // State for userId input
    // const subNavData = [
    //     { subNavId: 1, name: "Mechanical Engineering", subsidenavbar: { name: "Mechanical Engineering" } },
    //     { subNavId: 2, name: "Data Science", subsidenavbar: { name: "Data Science" } },
    //     { subNavId: 3, name: "Web Development", subsidenavbar: { name: "Web Development" } },
    //     // Other existing items
    //   ];
    useEffect(() => {
        // Update navId whenever routeNavId changes
        if (routeNavId) {
            setNavId(Number(routeNavId));
        }
    }, [routeNavId]);


    const handlePrint = () => {
        const printContent = document.querySelector(".form-contents");
        if (!printContent) {
          console.error("Element not found for printing");
          return;
        }
    
        const printWindow = window.open('', '_blank', 'height=600,width=800');
        printWindow.document.write(`
          <html>
              <head>
                  <title>Print Table</title>
                  <style>
                      body { font-family: Arial, sans-serif; }
                      table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                      th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                      th { background-color: #f2f2f2; }
                      @media print {
                          @page { margin: 50px; }
                          body { margin: 50px; }
                      }
                  </style>
              </head>
              <body>
                  <h2 style="text-align: center;">Form Details</h2>
                  ${printContent.innerHTML}
              </body>
          </html>
      `);
        printWindow.document.close(); // Close the document to finish loading
        printWindow.print(); // Trigger the print dialog
        printWindow.close(); // Close the print window after printing
      };


    const handleDownloads = () => {
        setTimeout(() => {
          const input = document.querySelector(".form-contents");
          if (!input) {
            console.error("Element not found for download");
            return;
          }
    
          html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
            console.log("Canvas generated:", canvas); // Log the canvas
            const imgData = canvas.toDataURL("image/png");
            console.log("Image Data URL:", imgData); // Log the image data URL
    
            if (imgData && imgData.startsWith("data:image/png;base64,")) {
              const pdf = new jsPDF("p", "mm", "a4");
              const imgWidth = 210; // A4 width in mm
              const pageHeight = 297; // A4 height in mm
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
              const topMargin = 10; // Set your desired top margin in mm
              let heightLeft = imgHeight - topMargin; // Adjust heightLeft to account for top margin
              let position = topMargin; // Start drawing after the top margin
    
              // Add the first image
              pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
              heightLeft -= (pageHeight - topMargin); // Adjust height left for the next page
    
              // Loop for additional pages
              while (heightLeft >= 0) {
                pdf.addPage(); // Add a new page
                position = heightLeft - imgHeight + topMargin; // Maintain top margin for subsequent pages
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight; // Reduce height left for the next iteration
              }
    
              pdf.save("form-data.pdf");
            } else {
              console.error("Invalid image data URL:", imgData);
            }
    
          }).catch((error) => {
            console.error("Error generating PDF:", error);
          });
        }, 100); // Delay added to ensure the element is fully rendered
      };
    


    const handlePopUp = () => {
    
        setIsPopupOpen(true); // Open the popup
      };


    const handleChange1 = (e) => {
        setFormData({ ...formData, mode: e.target.value });
      };


    const handleFieldChange = async (e) => {
        const { name, value } = e.target;
    
        if (name === "courseDuration") {
          // Update the formData with the selected course duration
          setFormData({ ...formData, courseDuration: value });
    
          console.log("Fetching timings for duration:", value);
    
          await fetchTime(value); // Fetch timings based on selected course duration
        } else {
          const selectedField = value;
          let subFields = [];
    
          // Set subFields based on the selected course
          // if (selectedField === "mechanical-engineering") {
          //   subFields = [
          //     { value: "mech1", label: "9AM-6PM" },
          //   ];
          // } else if (selectedField === "technotalks") {
          //   subFields = [
          //     { value: "tech1", label: "9AM-1PM" },
          //     { value: "tech2", label: "2PM-6PM" },
          //   ];
          // }
    
          setAvailableSubFields(subFields);
          setFormData({ ...formData, course: selectedField });
    
          // Reset subfield when main field changes
    
          // Fetch price for the selected course
          try {
            const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/amount?course=${selectedField}`);
            if (!response.ok) {
              throw new Error("Failed to fetch price data");
            }
            const result = await response.json();
            setOfflinerobotics(result);
            setFormData((prev) => ({ ...prev, coursePrice: result })); // Update price in formData
          } catch (error) {
            setError(error);
          }
        }
      };


      const fetchTime = async (time) => {
        try {
          const response = await fetch(`https://iiaserver.iiautomate.com/IIA-Website-1.0.0/register/timings?courseDuration=${time}`);
          if (!response.ok) {
            throw new Error("Failed to fetch timings");
          }
          const result = await response.json();
    
          // Map the response to a more usable format
          const subFieldOptions = result.map((item) => ({
            value: item,  // Keep the original value
            label: item.replace(/_/g, ' ').substring(1), // Replace underscores and remove leading underscore
          }));
    
          setAvailableSubFields(subFieldOptions);
        } catch (error) {
          setError(error);
        }
      };
    
    


    const handleDropdownToggle = (itemName) => {
        if (itemName === 'web development') {
          setShowWebDevDropdown((prev) => !prev);
        } else if (itemName === 'mechanical') {
          setShowMechanicalDropdown((prev) => !prev);
        } else if (itemName === 'data science') {
          setShowDataScienceDropdown((prev) => !prev);
        }
      };
      const handleNavigation = (link) => {
        
        navigate(link); // Navigate to the specified route
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const [formData, setFormData] = useState({
        source: '',
        sourceName: '',
        courses: '',
        duration: '_160_HR',
        timings: '_9AM_6PM',
        mode: '',
        branches: '',
        coursePrice: '1000' // Include coursePrice if it's part of the form
      });


      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page
    
        // Log the userId to check its value before submission
        console.log("User ID:", userId);
    
        // Prepare post data
        const postData = {
          source: formData.source,
          sourceName: formData.sourceName,
          courses: formData.courses.replace(/\s/g, ''), // Remove spaces if needed
          duration: formData.duration,
          timings: formData.timings,
          mode: formData.mode,
          branches: formData.branches,
          coursePrice: formData.coursePrice // Include coursePrice in postData
        };
    
        // Log post data before making the API call
        console.log("Post Data:", JSON.stringify(postData, null, 2));
    
        // Check for missing required fields
        const missingFields = Object.keys(postData).filter(key => !postData[key]);
    
        if (missingFields.length > 0) {
          console.error('Missing required fields:', missingFields);
          alert(`Please fill in the following fields: ${missingFields.join(', ')}`);
          return; // Prevent submission if required fields are empty
        }
    
        try {
          const response = await fetch(
            `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/course/addcourse/${userId}`, // Ensure userId is appended correctly
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(postData),
            }
          );
    
          if (!response.ok) {
            // Log the response for debugging
            const errorData = await response.text(); // Get response body as text
            throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorData}`);
          }
    
          const data = await response.json();
          console.log('API Response:', data);
          alert('Data added successfully!'); // Success alert
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('Error submitting form: ' + error.message);
        }
      };
    

      useEffect(() => {
        const fetchSubNavData = async () => {
            if (!navId) {
                console.error('navId is not set');
                setLoading(false);
                return; // Exit if navId is not defined
            }
    
            const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
            if (!userId) {
                console.error('User ID not found in localStorage.');
                setLoading(false);
                return;
            }
    
            try {
                const response = await fetch(
                    `https://iiaserver.iiautomate.com/IIA-Website-1.0.0/controllerScreen/subnavbar?userId=${userId}&navId=${navId}`
                );
    
                if (!response.ok) {
                    throw new Error('Failed to fetch sub-navigation data: ' + response.statusText);
                }
    
                const data = await response.json();
                console.log("Fetched Data:", data); // Log the data to inspect its structure
    
                // Assuming data is an array of items (as seen in the example response)
                if (Array.isArray(data)) {
                    setSubNavData(data); // Directly set the array as subNavData
                } else {
                    console.log('Invalid response format or no data returned');
                    setSubNavData([]); // Reset state if data is not in expected format
                }
            } catch (error) {
                console.error('Error fetching sub-navigation data:', error);
                setError(error.message); // Set error message to state
            } finally {
                setLoading(false); // Stop loading regardless of success or failure
            }
        };
    
        fetchSubNavData(); // Call the fetch function whenever navId changes
    }, [navId]); // Fetch data whenever navId changes
    
   

    // Save navId to localStorage whenever it changes
    useEffect(() => {
        if (navId !== undefined) {
            localStorage.setItem('directoryNavId', navId);
        }
    }, [navId]);

    return (
        <div>
          <nav className="navbar" style={{ marginTop: '-160px' }}>
        <div
          className="navbar-links"
          style={{
            display: 'flex',
            gap: '2.6rem',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            paddingLeft: '10px',
            marginRight: '100px',
          }}
        >
          <Link to="/stage1">Stage 1</Link>
          <Link to="/stage1">Stage 2</Link>
          <Link to="/stage-3">Stage 3</Link>
          <Link to="/stage-4">Stage 4</Link>
          <Link to="/stage-5">Stage 5</Link>
        </div>
      </nav>

      {/* Form */}
      <div className="form-container" style={{ marginTop: '260px', maxWidth: '900px', margin: '0 auto' }}>
      <form className="donate-form" onSubmit={handleSubmit}>
        <div className="row" style={{ paddingBottom: '25px' }}>
          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Source*</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="source"
              value={formData.source}
              onChange={handleChange}
              required
            >
              <option value="">Select Field</option>
              {optionss.length > 0 &&
                optionss.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Source Name*</Form.Label>
            <Form.Control
              type="text"
              style={{ height: '40px' }}
              placeholder="Source Name"
              name="sourceName"
              value={formData.sourceName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Courses*</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="courses"
              value={formData.courses}
              onChange={handleChange}
              required
            >
              <option value="">Select Course</option>
              <option value="ToolandFixtureDesign">Tool & Fixture Design</option>
              <option value="Offline Robotics">Offline Robotics</option>
              <option value="Online Robotics">Online Robotics</option>
              <option value="Java">Java</option>
              <option value="React Js">React Js</option>
              <option value="Flutter">Flutter</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Courses Duration*</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="duration"
              value={formData.duration}
              onChange={handleFieldChange}
              required
            >
              <option value="">Choose your course</option>
              {courseduration.map((duration, index) => (
                <option key={index} value={duration}>
                  {duration.replace(/_/g, ' ')}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>

        <div className="row" style={{ paddingBottom: '25px' }}>
          <Form.Group className="col-md-3" controlId="subFieldSelect">
            <Form.Label>Time*</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="timings"
              value={formData.timings}
              onChange={(e) => setFormData({ ...formData, timings: e.target.value })}
              required
            >
              <option value="">Select Sub-Field</option>
              {availableSubFields.map((subField) => (
                <option key={subField.value} value={subField.value}>
                  {subField.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mode*</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="mode"
              value={formData.mode}
              onChange={handleChange1}
              required
            >
              <option value="">Select Mode</option>
              <option value="Offline">OFFLINE</option>
              <option value="Online">ONLINE</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Branches*</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="branches"
              value={formData.branches}
              onChange={handleChange}
              required
            >
              <option value="">Select Any Field</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Bangloore">Bangloore</option>
              <option value="Chennai">Chennai</option>
              <option value="Pune">Pune</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="col-md-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Registration Fee*</Form.Label>
            <Form.Control
              type="text"
              style={{ height: '40px' }}
              placeholder={offlinerobotics?.coursePrice || 1000}
              value={formData.coursePrice || ''}
              required
            />
          </Form.Group>
        </div>

        <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
          {/* Add the "PAY NOW" button at the center */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handlePopUp}
          >
            PAY NOW
          </button>
        </div>
      </form>
    </div>
    {isPopupOpen && (
  <div className="popup" style={{ height: '50%',width:'56%', overflowY:'auto', marginTop: '80px' }}>
  <button className="close-button1"  style={{marginLeft:'670px'}} onClick={() => setIsPopupOpen(false)}>
    &times; {/* Close symbol */}
  </button>
  <div className="popup-content" style={{ textAlign: 'left' }}>

<div className="container">

                                    {/* <hr></hr> */}


                                    <div className="row" style={{ fontSize: "22px" }}>
                                      <div></div>

                                      <div className="form-contents" style={{
                                        width: "794px", // A4 width in pixels
                                        height: "783px", // A4 height in pixels
                                        backgroundColor: "#ffffff", // Change to your desired background color
                                        padding: "20px", // Add padding around the content
                                        border: "1px solid rgb(98 86 86)", // Optional: add a border
                                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
                                        margin: "0 auto" // Center the A4 container
                                      }}>
                                        <div className="row" style={{ fontSize: "22px" }}>
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            padding: "0px",
                                            flexWrap: "wrap",
                                            paddingBottom: "16px",
                                            marginTop: "20px"
                                          }}>
                                            <img src={logo} alt="" style={{ width: "83px", height: "70px", marginRight: "20px" }} />
                                            <h3 style={{ color: "#f58938", margin: 0 }}>
                                              INTERNATIONAL INSTITUTE OF AUTOMATE
                                            </h3>

                                            {/* <p style={{ textAlign: "right" }}>
                                                <i className="fa fa-phone m-r5"></i>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" /> +91 80961201731
                                                    </p>
                                                    <p style={{ textAlign: "right" }}>
                                                     {" "}
                                                     <i className="fa fa-envelope-o m-r5"></i>{" "}
                                                   <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                                                    Info@iiautomate.com
                                                </p> */}


                                          </div>
                                          {/* <div >
                                            <p style={{ textAlign: "right" }}>
                                              <i className="fa fa-phone m-r5"></i>
                                              +91 80961201731
                                            </p>
                                            <p style={{ textAlign: "right" }}>
                                              {" "}
                                              <i className="fa fa-envelope-o m-r5"></i>{" "}
                                              {" "}
                                              Info@iiautomate.com
                                            </p>
                                          </div> */}

                                        </div>
                                        {/* <br></br> */}
                                        <table method="Get" style={{ paddingTop: '15px', paddingBottom: "0px", borderSpacing: '10px',marginLeft:'60px' }}>
                                        <tbody>
                                          <tr>
                                            <td>Full Name</td>
                                            <td>Naresh</td>
                                          </tr>
                                          <tr>
                                            <td>Email Id</td>
                                            <td style={{ wordBreak: "break-word" }}>nareshbojja0009@gmail.com</td>
                                          </tr>
                                          <tr>
                                            <td>Mobile Number</td>
                                            <td>84774874884</td>
                                          </tr>
                                          <tr>
                                            <td>Gender</td>
                                            <td>Male</td>
                                          </tr>
                                          <tr>
                                            <td>Date of Birth</td>
                                            <td>6-10-1998</td>
                                          </tr>
                                          <tr>
                                            <td>Course</td>
                                            <td>MCA</td>
                                          </tr>
                                          {/* <tr>
                                            <td>Particular</td>
                                            <td>Offline Robotics</td>
                                          </tr> */}
                                          <tr>
                                            <td>Mode</td>
                                            <td>Online</td>
                                          </tr>
                                          {/* <tr>
                                            <td>Batch</td>
                                            <td>{toolbarfixture[0].batch ?? ""}</td>
                                          </tr> */}
                                          <tr>
                                            <td>Duration</td>
                                            <td>160 HRS</td>
                                          </tr>
                                          <tr>
                                            <td>Time</td>
                                            <td>10:00PM</td>
                                          </tr>
                                          <tr>
                                            <td>Branch</td>
                                            <td>MCA</td>
                                          </tr>
                                          <tr>
                                            <td>Registration fee</td>
                                            <td>1000</td>
                                          </tr>
                                          <tr>
                                            <td>Status</td>
                                            <td>Paid</td>
                                          </tr>
                                        </tbody>
                                      </table>

                                      </div>
                                      <div className="row" style={{ justifyContent: 'center', padding: '20px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                          <button type="button" class="btn btn-primary" onClick={handleDownloads}>
                                            Download
                                          </button>
                                          <button type="button" class="btn btn-primary" onClick={handlePrint}>
                                            Print
                                          </button>
                                          <button type="button" class="btn btn-primary">
                                            Next
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  </div>
                                  </div>
                                          )}

      </div>
        
      );
    };

export default EnrollTopNavBar;
